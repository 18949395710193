import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { reducers } from './app/reducers';
import * as storage from './app/utils/storage';

// A nice helper to tell us if we're on the server
export const isServer = !(typeof window !== 'undefined' && window.document && window.document.createElement);

export default (url = '/') => {
    // Create a history depending on the environment
    const history = isServer
        ? createMemoryHistory({
              initialEntries: [url],
          })
        : createBrowserHistory();

    const enhancers = [];

    // Dev tools are helpful
    if (process.env.NODE_ENV === 'development' && !isServer) {
        // @ts-ignore
        const { devToolsExtension } = window;

        if (typeof devToolsExtension === 'function') {
            // @ts-ignore
            enhancers.push(devToolsExtension());
        }
    }

    const middleware = [thunk, routerMiddleware(history)];

    const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

    const savedState = () => {
        if (isServer) {
            return {};
        }

        const state = JSON.parse(storage.get('reduxState') || '{}');

        delete state.article;
        // delete state.player;
        delete state.recommendation;
        delete state.content;
        delete state.user;
        delete state.search;
        delete state.section;
        delete state.modal;
        // delete state.subscription;
        delete state.app;
        delete state.info;
        delete state.code;
        delete state.filter;
        delete state.advert;
        delete state.form;
        delete state.sms;
        delete state.router;
        delete state.collection;
        delete state.profile;
        delete state.specialAccess;
        delete state.companySubscription;
        delete state.player;
        delete state.ui;

        // if (state.player) {
        //     state.player.playing = false;
        //     state.player.audio = null;
        // }

        return { ...state };
    };

    const initialState = !isServer
        ? {
              // @ts-ignore
              ...window.__PRELOADED_STATE__,
              ...savedState(),
          }
        : {};

    // Delete it once we have it stored in a variable
    if (!isServer) {
        // @ts-ignore
        delete window.__PRELOADED_STATE__;
    }

    // Create the store
    const store = createStore(
        reducers(history),
        initialState,
        // @ts-ignore
        composedEnhancers,
    );

    if (!isServer) {
        store.subscribe(() => {
            const state = store.getState();
            storage.set('reduxState', JSON.stringify(state));
        });
    }

    return {
        store,
        history,
    };
};
