enum ModalTypes {
    Share,
    SignIn,
    SignUp,
    LogInNew,
    SignUpNew,
    SignUpNewDirectToSignupSuccess,
    SignUpToSubscribe,
    SignUpRemoveWatermark,
    SignUpToFollowCollection,
    SignUpToFollowProfile,
    ForgotPassword,
    PremiumContent,
    ShowMoreDescription,
    GetTheApp,
    GetTheAppForFeature,
    DownloadTheApp,
    CancelSubscription,
    SubscriptionError,
}

export default ModalTypes;
