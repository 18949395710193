import * as _ from 'lodash';
import IPlaylistNew from '../types/models/IPlaylistNew';
import { isServer } from '../../store';
import { MpTrackingButtons } from '../config/buttons';
import { MpAccordionType } from '../config/MpAccordionTypes';
import { mpTrackingPopups } from '../config/popups';
import { mpTrackingScreens } from '../config/screens';
import { PLATFORM_WEB } from '../constants/app';
import IPlayItem from '../types/IPlayItem';
import ItemTypes from '../types/ItemTypes';
import IArticle from '../types/models/IArticle';
import IItem from '../types/models/IItem';
import IPlaylist from '../types/models/IPlaylist';
import IUser from '../types/models/IUser';
import ICollection from '../domaindriven/collections/types/ICollection';

const getMixpanel = async () => {
    if (isServer) {
        return;
    }

    let retries = 20;
    while (retries > 0) {
        // @ts-ignore
        const { mixpanel } = window;

        if (mixpanel && _.isFunction(mixpanel.track) && _.isFunction(mixpanel.get_distinct_id)) {
            return mixpanel;
        }

        console.log("MP - Didn't find mixpanel, retrying...");

        retries -= 1;
        await new Promise((resolve) => setTimeout(resolve, 500));
    }

    return undefined;
};

export const mpInit = () => {
    const token =
        process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'staging'
            ? '59d23f328104fb6705ddf30c3be242e7'
            : 'cae6f505b6edd1f160105f161721e537';

    // @ts-ignore
    if (isServer || !window.mixpanel) {
        console.log('MP - Init - Fail');
        return;
    }

    // @ts-ignore
    window.mixpanel.init(token, { api_host: 'https://api-eu.mixpanel.com' });

    console.log('MP - Init - Success');
};

export const mpSetBaseSuperProperties = async () => {
    const mixpanel = await getMixpanel();
    if (!mixpanel) {
        return;
    }

    const trackingPoperties = {
        UserPlatform: PLATFORM_WEB,
    };

    mixpanel.register(trackingPoperties);
    mixpanel.people.set(trackingPoperties);
};

export const mpSetUserSuperProperties = async (user: IUser) => {
    const mixpanel = await getMixpanel();
    if (!mixpanel) {
        return;
    }

    mixpanel.identify(user.id);

    const trackingPoperties = {
        $email: user.email,
        $first_name: user.firstName,
        UserId: user.id,
        UserName: user.firstName,
        UserSubscription: user.subscription,
        UserListenBalance: user.listenBalance,
        UserPlatform: PLATFORM_WEB,
        EffectiveSubscriptionStatus: user.effectiveSubscriptionStatus,
        DetailedSubscriptionStatus: user.detailedSubscriptionStatus,
        PaymentPlatform: user.paymentPlatform,
    };

    mixpanel.people.set(trackingPoperties);

    mixpanel.register(trackingPoperties);

    console.log(`MP - SetUserSuperProperties:\n${JSON.stringify(trackingPoperties)}\n`);
};

export const mpMaybeMergeId = async (id: string) => {
    const mixpanel = await getMixpanel();
    if (!mixpanel) {
        return;
    }

    let mixpanelLoaded = false;
    let retryCount = 3;

    do {
        mixpanelLoaded = _.isFunction(mixpanel.get_distinct_id);

        if (!mixpanelLoaded) {
            console.log('MP - Waiting for mixpanel to load...');
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }

        retryCount -= 1;
    } while (!mixpanelLoaded && retryCount > 0);

    const currentId = mixpanel.get_distinct_id();

    if (currentId !== id) {
        mixpanel.alias(currentId, id);
    }
};

// EVENTS

export const mpTrackPageView = (
    pathname: string,
    trackingScreen: mpTrackingScreens,
    title?: string,
    sbu?: string,
    extraTrackingProps?: { [key: string]: string }[],
) => {
    const trackingPoperties = {
        PageName: trackingScreen.toString(),
        SeriesName: trackingScreen === mpTrackingScreens.singleSeries ? title : undefined,
        ArticleName: trackingScreen === mpTrackingScreens.articlePlayer ? title : undefined,
        JournalistName: trackingScreen === mpTrackingScreens.singleJournalist ? title : undefined,
        PublisherName: trackingScreen === mpTrackingScreens.singlePublisher ? title : undefined,
        CategoryName: trackingScreen === mpTrackingScreens.singleCategory ? title : undefined,
        CollectionName: trackingScreen === mpTrackingScreens.singleCollection ? title : undefined,
        ProfileName: trackingScreen === mpTrackingScreens.singleProfile ? title : undefined,
        ObjectName: title,
        PathName: pathname,
        SharedByUserUUID: sbu,

        // combining all the objects into one object and then spreading it.
        ...(extraTrackingProps || []).reduce((prev, curr) => ({ ...prev, ...curr }), {}),
    };

    trackEvent('PageView', trackingPoperties);
};

export const mpTrackPopupView = (trackingPopup: mpTrackingPopups) => {
    const trackingPoperties = {
        PopupName: trackingPopup.toString(),
    };

    trackEvent('PopupView', trackingPoperties);
};

export const mpTrackIntervalListen = (item: IPlayItem, time: number) => {
    const trackingPoperties = {
        ArticleName: item.name,
        ArticleId: item.id,
        PublisherName: item.publisher,
        AudioLengthSecs: Math.round(item.audioLength),
        ListenProgressSecs: Math.round(time),
        ListenProgressPercent: +((Math.round(time) / Math.round(item.audioLength)) * 100).toFixed(2),
        DidListenThroughSeries: item.isPlayingThroughSeries,
        SeriesName: item.isPlayingThroughSeries && item.series ? item.series.name : undefined,
        SeriesId: item.isPlayingThroughSeries && item.series ? item.series.id : undefined,
    };

    trackEvent('ArticleListenInterval', trackingPoperties);
    trackFbqEvent('ArticleListenInterval', trackingPoperties);
};

export const mpTrackArticleEnd = (item: IPlayItem, time: number) => {
    const trackingPoperties = {
        ArticleName: item.name,
        ArticleId: item.id,
        PublisherName: item.publisher,
        AudioLengthSecs: Math.round(item.audioLength),
        ListenProgressSecs: Math.round(time),
        ListenProgressPercent: +((Math.round(time) / Math.round(item.audioLength)) * 100).toFixed(2),
        DidListenThroughSeries: item.isPlayingThroughSeries,
        SeriesName: item.isPlayingThroughSeries && item.series ? item.series.name : undefined,
        SeriesId: item.isPlayingThroughSeries && item.series ? item.series.id : undefined,
    };

    trackEvent('ArticleEnd', trackingPoperties);
    trackFbqEvent('ArticleEnd', trackingPoperties);
};

export const mpTrackAdvertStart = (advert?: IPlayItem | null) => {
    const trackingPoperties = {
        AdvertName: advert?.name,
        AdvertId: advert?.id,
        AudioLengthSecs: advert ? Math.round(advert.audioLength) : undefined,
    };

    trackEvent('AdvertStart', trackingPoperties);
};

export const mpTrackAdvertEnd = (advert?: IPlayItem | null, time?: number) => {
    const trackingPoperties = {
        AdvertName: advert?.name,
        AdvertId: advert?.id,
        AudioLengthSecs: advert?.audioLength,
        ListenProgressSecs: time ? Math.round(time) : advert ? Math.round(advert?.audioLength) : undefined,
    };

    trackEvent('AdvertEnd', trackingPoperties);
};

export const mpTrackButtonClick = (
    button: MpTrackingButtons,
    article?: IArticle,
    seriesId?: number,
    seriesName?: string,
) => {
    const trackingPoperties = {
        ArticleName: article ? article.name : undefined,
        ArticleId: article ? article.id : undefined,
        PublisherName: article ? article.publisher : undefined,
        SeriesName: seriesName || undefined,
        SeriesId: seriesId || undefined,
        ButtonName: button.toString(),
    };

    trackEvent('ButtonClick', trackingPoperties);
};

export const mpTrackStripeError = ({
    errorCode,
    declineCode,
    stripeProductId,
    currency,
    stripePriceId,
    cardBrand,
    errorMessage,
}: {
    errorCode: string;
    declineCode?: string;
    stripeProductId: string;
    currency: string;
    stripePriceId: string;
    cardBrand?: string;
    errorMessage: string;
}) => {
    const trackingPoperties = {
        ErrorCode: errorCode,
        DeclineCode: declineCode,
        StripeProductId: stripeProductId,
        Currency: currency,
        StripePriceId: stripePriceId,
        CardBrand: cardBrand,
        ErrorMessage: errorMessage,
    };

    trackEvent('StripeError', trackingPoperties);
};

export const mpTrackContentView = (item: IPlaylist | IArticle | IPlaylistNew | ICollection) => {
    const itemAsArticle = item as IArticle;
    const itemAsPlaylist = item as IPlaylist;

    const trackingPoperties = {
        ArticleName: item.type === ItemTypes.Articles ? itemAsArticle.name : undefined,
        ArticleId: item.type === ItemTypes.Articles ? itemAsArticle.id : undefined,
        PublisherName: item.type === ItemTypes.Articles ? itemAsArticle.publisher.name : undefined,
        SeriesName:
            item.type === ItemTypes.Playlists || item.type === ItemTypes.Playlist ? itemAsPlaylist.name : undefined,
        SeriesId: item.type === ItemTypes.Playlists || item.type === ItemTypes.Playlist ? itemAsPlaylist.id : undefined,
        CollectionName: item.type === ItemTypes.COLLECTION ? item.name : undefined,
        CollectionId: item.type === ItemTypes.COLLECTION ? item.id : undefined,
    };

    trackEvent('ContentView', trackingPoperties);
};

export const mpTrackShare = (item: IItem, user?: IUser) => {
    const trackingPoperties = {
        ArticleName: item.type === ItemTypes.Articles ? item.name : undefined,
        ArticleId: item.type === ItemTypes.Articles ? item.id : undefined,
        PublisherName: item.type === ItemTypes.Publishers ? item.name : undefined,
        PublisherId: item.type === ItemTypes.Publishers ? item.id : undefined,
        SeriesName: item.type === ItemTypes.Playlists || item.type === ItemTypes.Playlist ? item.name : undefined,
        SeriesId: item.type === ItemTypes.Playlists || item.type === ItemTypes.Playlist ? item.id : undefined,
        CollectionName: item.type === ItemTypes.COLLECTION ? item.name : undefined,
        CollectionId: item.type === ItemTypes.COLLECTION ? item.id : undefined,
        ProfileName: item.type === ItemTypes.PROFILE ? item.name : undefined,
        ProfileId: item.type === ItemTypes.PROFILE ? item.id : undefined,
        ShareUrl: item.shareUrl,
        SharedByUserUUID: user ? user.uuid : undefined,
    };

    trackEvent('Share', trackingPoperties);
};

export const mpTrackAccordionItemExpanded = (name: string, type: MpAccordionType) => {
    const trackingPoperties = {
        Name: name,
        Type: type,
    };

    trackEvent('AccordionItemExpanded', trackingPoperties);
};

export const mpTrackGoogleOneTapSignIn = (selectBy: string) => {
    const trackingPoperties = {
        SelectBy: selectBy,
    };

    trackEvent('GoogleOneTapSignIn', trackingPoperties);
};

const trackEvent = async (eventName: string, eventProps: any) => {
    const mixpanel = await getMixpanel();
    if (!mixpanel) {
        return;
    }

    await mpSetBaseSuperProperties();

    mixpanel.track(eventName, eventProps);

    console.log(`MP - ${eventName}:\n${JSON.stringify(eventProps)}\n`);
};

const trackFbqEvent = async (eventName: string, eventProps: any) => {
    // @ts-ignore
    const { fbq } = window;

    if (!fbq) {
        console.log('fbq not found');
        return;
    }

    fbq('track', eventName, eventProps);

    console.log(`fbq - ${eventName}:\n${JSON.stringify(eventProps)}\n`);
};
