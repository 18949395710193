import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router-dom';
import { Location, History } from 'history';

import { isIE } from 'react-device-detect';
import loadable from '@loadable/component';
import MobileDeveloper from '../containers/careers/MobileDeveloper';
import { isServer } from '../../store';
import * as modalActions from '../actions/modal';
import IUser from '../types/models/IUser';
import Toast from '../components/Toast';
import config from '../config';
import Modal from '../containers/Modal';
import ErrorMessage from '../components/ErrorMessage';
import text from '../locale';
import ListenBalanceBanner from '../components/ListenBalanceBanner';
import { isRouteValidForNavbar, isRouteStandalone, isRouteValidForPlayer } from '../utils/route';
import MobileBottomNav from '../containers/MobileBottomNav';
import NavTop from '../containers/NavTop';
import { mpTrackingScreens } from '../config/screens';
import LoadingPage from '../components/LoadingPage';
import Content from '../containers/Content';
import LazyLoadAbout from '../domaindriven/about/lazyload/LazyLoadAbout';
import LazyLoadLanding from '../domaindriven/landing/lazyload/LazyLoadLanding';
import LazyLoadFaq from '../domaindriven/faq/lazyload/LazyLoadFaq';
import LazyLoadFaqSection from '../domaindriven/faq/lazyload/LazyLoadFaqSection';
import LazyLoadSubscribe from '../domaindriven/subscribe/lazyload/LazyLoadSubscribe';
import LazyLoadSearch from '../domaindriven/search/lazyload/LazyLoadSearch';
import LazyLoadLatest from '../domaindriven/articles/lazyload/LazyLoadLatest';
import LazyLoadGiftSubscription from '../domaindriven/subscribe/lazyload/LazyLoadGiftSubscription';
import newtheme from '../newtheme';
import LazyLoadCollections from '../domaindriven/collections/lazyload/LazyLoadCollections';
import LazyLoadProfile from '../domaindriven/profiles/lazyload/LazyLoadProfile';
import VpOfMarketing from '../containers/careers/VpOfMarketing';
import SeniorBackendEngineer from '../containers/careers/SeniorBackendEngineer';
import ProducerBusinessEconomics from '../containers/careers/ProducerBusinessEconomics';
import ModalTypes from '../types/ModalTypes';
import LazyLoadCheckout from '../domaindriven/subscribe/lazyload/LazyLoadCheckout';
import LazyLoadLandingEmbedContainer from '../domaindriven/landingembed/lazyload/LazyLoadLandingEmbedContainer';
import LinkedInCallbackPage from '../domaindriven/auth/components/LinkedInCallbackPage';
import LazyLoadSubscriptionSuccess from '../domaindriven/subscribe/components/LazyLoadSubscriptionSuccess';
import LazyLoadSignupSuccess from '../domaindriven/subscribe/components/LazyLoadSignupSuccess';
import LazyLoadCheckIfEmployerPays from '../domaindriven/companysubscription/lazyload/LazyLoadCheckIfEmployerPays';
import CookiesBanner from '../components/CookiesBaner';

interface IProps {
    user?: IUser;
    location: Location<any>;
    history: History<any>;
    openModal: typeof modalActions.openModal;
}

interface IState {}

class Routes extends React.Component<IProps, IState> {
    public divElement: any;

    public Home = loadable(() => import('../containers/Home'), {
        fallback: <LoadingPage />,
    });

    public Library = loadable(() => import('../containers/Library'), {
        fallback: <LoadingPage />,
    });

    public MyAccount = loadable(() => import('../containers/MyAccount'), {
        fallback: <LoadingPage />,
    });

    public DeleteAccount = loadable(() => import('../containers/InitDeleteAccount'), {
        fallback: <LoadingPage />,
    });

    public CannotDeleteAccount = loadable(() => import('../containers/CannotDeleteAccount'), {
        fallback: <LoadingPage />,
    });

    public SuccessfulDeleteAccount = loadable(() => import('../containers/SuccessfulDeleteAccount'), {
        fallback: <LoadingPage />,
    });

    public Favourites = loadable(() => import('../containers/Favourites'), {
        fallback: <LoadingPage />,
    });

    public EnterpriseGift = loadable(() => import('../containers/EnterpriseGift'), {
        fallback: <LoadingPage />,
    });

    public DownloadApp = loadable(() => import('../containers/DownloadApp'), {
        fallback: <LoadingPage />,
    });

    public Auth = loadable(() => import('../containers/Auth'), {
        fallback: <LoadingPage />,
    });

    public Played = loadable(() => import('../containers/Played'), {
        fallback: <LoadingPage />,
    });

    public LandingPage = loadable(() => import('../containers/LandingPage'), {
        fallback: <LoadingPage />,
    });

    public Player = loadable(() => import('../containers/Player'), {
        fallback: <LoadingPage />,
    });

    public EmbedPlayer = loadable(() => import('../containers/EmbedPlayer'), {
        fallback: <LoadingPage />,
    });

    public Publishers = loadable(() => import('../domaindriven/publishers/containers/Publishers'), {
        fallback: <LoadingPage isDark />,
    });

    public Team = loadable(() => import('../domaindriven/team/containers/Team'), {
        fallback: <LoadingPage isDark />,
    });

    public OurStory = loadable(() => import('../domaindriven/ourstory/containers/OurStory'), {
        fallback: <LoadingPage isDark />,
    });

    public EditorialPrinciples = loadable(
        () => import('../domaindriven/editorialprinciples/containers/EditorialPrinciples'),
        {
            fallback: <LoadingPage isDark />,
        },
    );

    public AssocBusinessEditor = loadable(() => import('../containers/careers/AssocBusinessEditor'), {
        fallback: <LoadingPage />,
    });

    public Editor = loadable(() => import('../containers/careers/Editor'), {
        fallback: <LoadingPage />,
    });

    public ProducerBusinessEconomics = loadable(() => import('../containers/careers/ProducerBusinessEconomics'), {
        fallback: <LoadingPage />,
    });

    public Privacy = loadable(() => import('../components/Privacy'), {
        fallback: <LoadingPage />,
    });

    public Terms = loadable(() => import('../components/Terms'), {
        fallback: <LoadingPage />,
    });

    public CookiePolicy = loadable(() => import('../components/CookiePolicy'), {
        fallback: <LoadingPage />,
    });

    public componentDidMount() {
        if (this.props.location.pathname === config.routes.download) {
            // hidden temporarily
            this.props.openModal(ModalTypes.DownloadTheApp);
        }
    }

    public AuthRequiredRoute = ({ component: Component, ...props }) => (
        <Route
            {...props}
            render={(data) => {
                if (this.props.user) {
                    return <Component {...data} />;
                }

                return <Redirect to={`${config.routes.login}?redirectAfterAuth=${data.location.pathname}`} />;
            }}
        />
    );

    /*
    public MobileAppStoreRedirectRoute = ({ component: Component, ...props }) => (
        <Route
            {...props}
            render={() => {
                this.props.openModal(ModalTypes.DownloadTheApp);
                return <Redirect to={config.routes.home} />;
            }}
        />
    );
    */

    public initializeRoute = (pathname?: string) => {
        if (!isServer && pathname !== config.routes.subscribe && pathname !== config.routes.home) {
            window.scrollTo(0, 0);
        }

        return null;
    };

    public renderContent =
        (route: string, trackingScreen: mpTrackingScreens) => (props: RouteComponentProps<{ id?: string }>) => {
            const url = `${config.api.url}${route}/${props.match.params.id}${props.location.search}`;

            return <Content url={url} route={route} trackingScreen={trackingScreen} />;
        };

    public renderNotFound = () => <ErrorMessage message={text.errors.PAGE_NOT_FOUND} />;

    public renderAllRoutes = () => (
        <Switch>
            {/* Auth required routes */}
            {this.AuthRequiredRoute({
                path: config.routes.myAccount,
                exact: true,
                component: this.MyAccount,
            })}
            {this.AuthRequiredRoute({
                path: config.routes.deleteAccount,
                exact: true,
                component: this.DeleteAccount,
            })}
            {this.AuthRequiredRoute({
                path: config.routes.favourites,
                exact: true,
                component: this.Favourites,
            })}
            {this.AuthRequiredRoute({
                path: `${config.routes.enterprise}/:id/gift`,
                exact: true,
                component: this.EnterpriseGift,
            })}
            <Route exact path="/auth/linkedin_callback" component={LinkedInCallbackPage} />

            {/* Auth routes */}
            <Route exact path={config.routes.signup} component={this.Auth} />
            <Route path={config.routes.login} component={this.Auth} />
            <Route exact path={config.routes.signupAlexa} component={this.Auth} />
            <Route exact path={config.routes.loginAlexa} component={this.Auth} />
            <Route exact path={config.routes.resetPasswordFinal} component={this.Auth} />
            <Route exact path={config.routes.resetPasswordInit} component={this.Auth} />

            {/* Info pages */}
            <Route exact path={config.routes.home} component={LazyLoadLanding} />
            <Route exact path={config.routes.landing} component={LazyLoadLanding} />
            <Route exact path={config.routes.download} component={LazyLoadLanding} />
            <Route exact path={config.routes.landingEmbed} component={LazyLoadLandingEmbedContainer} />
            <Route exact path={config.routes.welcome} component={this.LandingPage} />
            <Route exact path={config.routes.about} component={LazyLoadAbout} />
            <Route exact path={config.routes.mobileAbout} component={LazyLoadAbout} />
            <Route exact path={config.routes.faq} component={LazyLoadFaq} />
            <Route exact path={`${config.routes.faq}/:id`} component={LazyLoadFaqSection} />
            <Route exact path={config.routes.ourPublishers} component={this.Publishers} />
            {/* <Route exact path={config.routes.ourTeam} component={this.Team} /> */}
            <Route exact path={config.routes.ourStory} component={this.OurStory} />
            <Route exact path={config.routes.editorialPrinciples} component={this.EditorialPrinciples} />
            <Route exact path={config.routes.careerAssocBusinessEditor} component={this.AssocBusinessEditor} />
            <Route exact path={config.routes.careerEditor} component={this.Editor} />
            <Route exact path={config.routes.careerVpMarketing} component={VpOfMarketing} />
            <Route exact path={config.routes.careerSeniorsBackendEngineer} component={SeniorBackendEngineer} />
            <Route exact path={config.routes.careerMobileDeveloper} component={MobileDeveloper} />
            <Route exact path={config.routes.careerProducerEconomics} component={ProducerBusinessEconomics} />
            <Route exact path={config.routes.cannotDeleteAccount} component={this.CannotDeleteAccount} />
            <Route exact path={config.routes.successfulDeleteAccount} component={this.SuccessfulDeleteAccount} />

            {/* Web player routes */}
            <Route exact path={config.routes.homeWebPlayer} component={this.Home} />
            <Route exact path={config.routes.contact} component={this.Home} />
            <Route exact path={config.routes.search} component={LazyLoadSearch} />
            <Route exact path={config.routes.latest} component={LazyLoadLatest} />
            <Route exact path={config.routes.played} component={this.Played} />
            <Route exact path={`${config.routes.collections}/:id`} component={LazyLoadCollections} />
            <Route exact path={`${config.routes.profiles}/:id`} component={LazyLoadProfile} />
            <Route
                exact
                path={`${config.routes.articles}/:id`}
                component={this.renderContent(config.api.routes.articles, mpTrackingScreens.articlePlayer)}
            />
            <Route
                exact
                path={`${config.routes.playlists}/:id`}
                component={this.renderContent(config.api.routes.playlists, mpTrackingScreens.singleSeries)}
            />
            <Route
                exact
                path={`${config.routes.publishers}/:id`}
                component={this.renderContent(config.api.routes.publishers, mpTrackingScreens.singlePublisher)}
            />
            <Route
                exact
                path={`${config.routes.journalists}/:id`}
                component={this.renderContent(config.api.routes.journalists, mpTrackingScreens.singleJournalist)}
            />
            <Route
                exact
                path={`${config.routes.sections}/:id`}
                component={this.renderContent(config.api.routes.sections, mpTrackingScreens.singleCategory)}
            />

            {/* Sub pages */}
            <Route exact path={config.routes.subscribe} component={LazyLoadSubscribe} />
            <Route exact path={config.routes.subscriptionSuccess} component={LazyLoadSubscriptionSuccess} />
            <Route exact path={config.routes.signupSuccess} component={LazyLoadSignupSuccess} />
            <Route exact path={config.routes.completeSubscription} component={LazyLoadCheckout} />
            <Route exact path={config.routes.gift} component={LazyLoadGiftSubscription} />
            <Route exact path={config.routes.employerSubscriptionCheck} component={LazyLoadCheckIfEmployerPays} />

            {/* Other */}
            <Route exact path={config.routes.articleEmbed} component={this.EmbedPlayer} />
            <Route exact path={config.routes.loading} component={LoadingPage} />

            {/* Boilerplate pages */}
            <Route exact path={config.routes.privacy} component={this.Privacy} />
            <Route exact path={config.routes.mobilePrivacy} component={this.Privacy} />
            <Route exact path={config.routes.terms} component={this.Terms} />
            <Route exact path={config.routes.mobileTerms} component={this.Terms} />
            <Route exact path={config.routes.cookiePolicy} component={this.CookiePolicy} />

            <Route component={this.renderNotFound} />
        </Switch>
    );

    public renderStandaloneComponent = () => (
        <div>
            <Route component={() => this.initializeRoute(this.props.location.pathname)} />
            {this.renderAllRoutes()}
            <CookiesBanner />
            <Modal />
        </div>
    );

    public render() {
        const { user, location } = this.props;

        for (const redirect of config.routes.redirects) {
            if (location.pathname === redirect.from) {
                return <Redirect to={redirect.to} />;
            }
        }

        if (isRouteStandalone(location.pathname)) {
            return this.renderStandaloneComponent();
        }

        const showPlayer = isRouteValidForPlayer(location.pathname);

        return (
            <>
                <div className={css(styles.container)}>
                    {isRouteValidForNavbar(location.pathname) && <NavTop />}

                    <div
                        className={css(
                            styles.content,
                            location.pathname !== config.routes.articleEmbed && styles.minHeight,
                        )}>
                        {/* tslint:disable-next-line: jsx-no-lambda */}
                        <Route component={() => this.initializeRoute(location.pathname)} />
                        {this.renderAllRoutes()}
                    </div>

                    <div className={css(styles.bottomPanelWrapper)}>
                        <div className={css(styles.bottomPanel)}>
                            {location.pathname !== config.routes.subscriptionSuccess && (
                                <ListenBalanceBanner user={user} locationPathname={location.pathname} />
                            )}
                            {showPlayer && <this.Player />}
                            {isRouteValidForNavbar(location.pathname) && <MobileBottomNav />}
                        </div>
                    </div>
                </div>
                <CookiesBanner />

                {/* Popops */}
                <Toast />
                <Modal />
            </>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: isIE ? '100%' : '-webkit-fill-available',
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
    },
    content: {
        flex: 1,
        position: 'relative',
    },
    minHeight: {
        minHeight: '100vh',
    },
    bottomPanelWrapper: {
        width: '100%',
    },
    bottomPanel: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
    },
});

export default Routes;
