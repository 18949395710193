import {
    PLAYER_PAUSE,
    PLAYER_PLAY,
    TRACKING_LISTEN_INCREMENT,
    PLAYER_CURRENT_TIME,
    CURRENTLY_PLAYING_SUCCESS,
    SET_ARTICLES_TO_LOCAL_QUEUE,
    CURRENTLY_PLAYING_PENDING,
    CURRENTLY_PLAYING_REJECTED,
    PLAY_NEXT_QUEUE_ARTICLE_PENDING,
    PLAY_NEXT_QUEUE_ARTICLE_REJECTED,
    PLAY_PREV_QUEUE_ARTICLE_PENDING,
    PLAY_PREV_QUEUE_ARTICLE_REJECTED,
    SET_ARTICLE_TO_CURRENTLY_PLAYING_PENDING,
    SET_ARTICLE_TO_CURRENTLY_PLAYING_REJECTED,
    SET_PLAYLIST_TO_CURRENTLY_PLAYING_PENDING,
    SET_PLAYLIST_TO_CURRENTLY_PLAYING_REJECTED,
    INCREASE_SPEED,
    DECREASE_SPEED,
} from '../constants/player';
import IPlayItem from '../types/IPlayItem';
import ItemTypes from '../types/ItemTypes';
import IAdvert from '../types/models/IAdvert';
import IArticle from '../types/models/IArticle';
import { advertToPlayItem, articleToPlayItem } from '../utils/transform';

export interface IState {
    currentlyPlaying?: IPlayItem;
    playing: boolean;
    lastAudioIncrementTracked: number;
    currentTime: number;
    isQueueLoading: false;
    localQueue: IArticle[];
    speed: number;
}

interface IAction {
    type: string;
    currentlyPlaying?: IArticle;
    intervalToTrack?: number;
    currentTime?: number;
    articles?: IArticle[];
    speed?: number;
}

export const initialState: IState = {
    playing: false,
    lastAudioIncrementTracked: -TRACKING_LISTEN_INCREMENT,
    currentTime: 0,
    localQueue: [],
    isQueueLoading: false,
    speed: 1,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case PLAYER_PLAY: {
            return {
                ...state,
                playing: true,
            };
        }
        case PLAYER_PAUSE:
            return {
                ...state,
                playing: false,
            };

        case PLAYER_CURRENT_TIME:
            return {
                ...state,
                currentTime: action.currentTime ? action.currentTime : 0,
                lastAudioIncrementTracked: action.intervalToTrack,
            };

        case CURRENTLY_PLAYING_SUCCESS: {
            return {
                ...state,
                isQueueLoading: false,
                currentTime: 0,
                lastAudioIncrementTracked: -TRACKING_LISTEN_INCREMENT,
                currentlyPlaying:
                    action.currentlyPlaying &&
                    (action.currentlyPlaying.type === ItemTypes.Adverts
                        ? advertToPlayItem(action.currentlyPlaying as IAdvert)
                        : articleToPlayItem(action.currentlyPlaying)),
            };
        }

        case CURRENTLY_PLAYING_PENDING: {
            return { ...state, isQueueLoading: true };
        }
        case CURRENTLY_PLAYING_REJECTED: {
            return { ...state, isQueueLoading: false };
        }
        case PLAY_NEXT_QUEUE_ARTICLE_PENDING: {
            return { ...state, isQueueLoading: true };
        }
        case PLAY_NEXT_QUEUE_ARTICLE_REJECTED: {
            return { ...state, isQueueLoading: false };
        }
        case PLAY_PREV_QUEUE_ARTICLE_PENDING: {
            return { ...state, isQueueLoading: true };
        }
        case PLAY_PREV_QUEUE_ARTICLE_REJECTED: {
            return { ...state, isQueueLoading: false };
        }
        case SET_ARTICLE_TO_CURRENTLY_PLAYING_PENDING: {
            return { ...state, isQueueLoading: true };
        }
        case SET_ARTICLE_TO_CURRENTLY_PLAYING_REJECTED: {
            return { ...state, isQueueLoading: false };
        }
        case SET_PLAYLIST_TO_CURRENTLY_PLAYING_PENDING: {
            return { ...state, isQueueLoading: true };
        }
        case SET_PLAYLIST_TO_CURRENTLY_PLAYING_REJECTED: {
            return { ...state, isQueueLoading: false };
        }

        case SET_ARTICLES_TO_LOCAL_QUEUE: {
            return { ...state, localQueue: action.articles };
        }

        case INCREASE_SPEED: {
            return { ...state, speed: state.speed < 2 ? parseFloat((state.speed + 0.1).toFixed(1)) : state.speed };
        }

        case DECREASE_SPEED: {
            return { ...state, speed: state.speed > 0.5 ? parseFloat((state.speed - 0.1).toFixed(1)) : state.speed };
        }

        default:
            return state;
    }
};
