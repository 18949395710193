import {
    FOLLOW_COLLECTION_SUCCESS,
    GET_COLLECTIONS_FAILURE,
    GET_COLLECTIONS_REQUEST,
    GET_COLLECTIONS_SUCCESS,
    GET_COLLECTION_FAILURE,
    GET_COLLECTION_REQUEST,
    GET_COLLECTION_SUCCESS,
    UNFOLLOW_COLLECTION_SUCCESS,
} from '../constants/collection';
import ICollection from '../domaindriven/collections/types/ICollection';

export interface IState {
    collection?: ICollection;
    collections?: ICollection[];
    loading: boolean;
    error?: any;
}

interface IAction {
    type: string;
    error?: Error;
    collection?: ICollection;
    collections?: ICollection[];
}

export const initialState: IState = {
    loading: false,
    collection: undefined,
    collections: undefined,
    error: null,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_COLLECTION_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case GET_COLLECTION_SUCCESS:
            return {
                ...state,
                collection: action.collection,
                loading: false,
            };
        case GET_COLLECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case GET_COLLECTIONS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case GET_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collections: action.collections,
                loading: false,
            };
        case GET_COLLECTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case FOLLOW_COLLECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                collection: action.collection,
            };
        case UNFOLLOW_COLLECTION_SUCCESS:
            return {
                ...state,
                loading: false,
                collection: action.collection,
            };

        default:
            return state;
    }
};
