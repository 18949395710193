import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LandingEmbedContainerLazy = loadable(() => import('../containers/LandingEmbedContainer'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadLandingEmbedContainer extends React.Component {
    render() {
        return (
            <Page noCrawl={false} trackingScreen={mpTrackingScreens.landingEmbed}>
                <LandingEmbedContainerLazy />
            </Page>
        );
    }
}

export default LazyLoadLandingEmbedContainer;
