import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconClose extends React.Component<IProps> {
    public render() {
        return (
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className} onClick={this.props.onClick}>
                <path d="M17.2635 17.2635L1 1" stroke="black" strokeWidth="2" />
                <path d="M17.5135 1.13181L1.25 17.3953" stroke="black" strokeWidth="2" />
            </svg>
        );
    }
}

export default IconClose;
