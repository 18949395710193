import config from '../config';

export interface IGetArticles {
    url?: string;
    limit?: number;

    append?: boolean;
    loadAll?: boolean;

    trending?: boolean;
    nutshell?: boolean;
    filtering?: boolean;
    history?: boolean;
    favourite?: boolean;

    disablePremiumOnly: boolean;
}

export const requestUrlToGetArticles = (params: IGetArticles, page: number) => {
    const nutshell = params.nutshell ? '&nutshell=true' : '';
    const trending = params.trending ? '&trending=true' : '';
    const filtering = params.filtering ? '&filtering=true' : '';
    const history = params.history ? '&history=true' : '';
    const favourite = params.favourite ? '&favourite=true' : '';
    const regularQueries = `${nutshell}${trending}${filtering}${history}${favourite}`;
    const url = params.url ? `${params.url}&` : `${config.api.url}${config.api.routes.articles}?`;

    if (params.limit) {
        return `${url}limit=${params.limit}${regularQueries}`;
    }

    if (params.loadAll) {
        return `${url}limit=${config.limitArticles * page + config.limitArticles}${regularQueries}`;
    }

    if (params.append) {
        return `${url}limit=${config.limitArticles}&offset=${config.limitArticles * page}${regularQueries}`;
    }

    return `${url}limit=${config.limitArticles}${regularQueries}`;
};
