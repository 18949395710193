import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { toast } from 'react-toastify';
import config from '../../../config';
import { mpTrackButtonClick, mpTrackPopupView } from '../../../tracking/mixpanel';
import { mpTrackingPopups } from '../../../config/popups';
import { MpTrackingButtons } from '../../../config/buttons';
import { getLocationInfo } from '../../../actions/location';
import newtheme from '../../../newtheme';
import BaseModal from '../../../components/modals/newModals/BaseModal';
import IconClose from '../../../assets/icons/IconClose';
import TickAndText from '../../misc/components/TickAndText';
// import PhoneInputField from '../../../components/PhoneInputField';

interface IProps {
    loading: boolean;
    smsSent: boolean;
    error: boolean;
    isOpen: boolean;
    selectedCountryAlpha2?: string;
    sendSMS: (phoneNumber: string, mode: string) => void;
    reset: () => void;
    closeModal: () => void;
    getLocationInfo: typeof getLocationInfo;
}

class DownloadTheAppModalComponent extends React.Component<IProps> {
    public UNSAFE_componentWillReceiveProps(props) {
        if (!props || !props.smsSent) {
            return;
        }

        if (props.smsSent) {
            toast("Check your phone, we've sent you a download link!");
            this.props.closeModal();
        }
    }

    public componentDidMount = () => {
        mpTrackPopupView(mpTrackingPopups.downloadApp);
        this.props.getLocationInfo();
    };

    public onClose = () => {
        this.props.closeModal();
    };

    public onClickDownloadTheApp = () => {
        window.open(config.downloadAppLink, '_blank');
    };

    public onClickIosAppStore = (e: any) => {
        e.stopPropagation();
        mpTrackButtonClick(MpTrackingButtons.NAVIGATE_TO_IOS_APP_STORE);
    };

    public onClickAndroidAppStore = (e: any) => {
        e.stopPropagation();
        mpTrackButtonClick(MpTrackingButtons.NAVIGATE_TO_ANDROID_APP_STORE);
    };

    public render() {
        const { isOpen } = this.props;

        return (
            <BaseModal modalId="DownloadTheAppModalComponent" isOpen={isOpen} onClose={this.onClose} fullScreenMobile>
                <div className={css(styles.modalWrapper)}>
                    <div className={css(styles.topSection)}>
                        <div className={css(styles.closeIconWrapper)} onClick={this.onClose}>
                            <IconClose className={css(styles.closeIcon)} />
                        </div>

                        <div className={css(styles.appStoreImageWrapper, newtheme.coreStyles.hideSmall)}>
                            <div className={css(styles.appStoreImages)}>
                                <a
                                    target="_blank"
                                    href={config.iosAppLink}
                                    onClick={this.onClickIosAppStore}
                                    rel="noreferrer">
                                    <img
                                        alt="Apple app store"
                                        src="/assets/images/appstore/DownloadOnIosAppStore_1x.png"
                                        className={css(styles.appStoreImage)}
                                    />
                                </a>
                                <a
                                    target="_blank"
                                    href={config.androidAppLink}
                                    onClick={this.onClickAndroidAppStore}
                                    rel="noreferrer">
                                    <img
                                        alt="Google play store"
                                        src="/assets/images/appstore/DownloadOnGooglePlayStore_1x.png"
                                        className={css(styles.appStoreImage)}
                                    />
                                </a>
                            </div>
                        </div>

                        <h1 className={css(styles.title)}>Download the Noa app for the best listening experience</h1>
                        <div className={css(styles.benefits)}>
                            <TickAndText text="Increase or decrease narration speed" />
                            <TickAndText text="Download articles and Series to listen later offline" />
                            <TickAndText text="Get exclusive content suggestions based on your interests." />
                        </div>
                    </div>
                    {/* <div className={css(styles.bottomSection, newtheme.coreStyles.hideSmall)}>
                        <div className={css(styles.phoneInput)}>
                            <p>Text yourself a link to download Noa</p>
                            <PhoneInputField
                                colour="TRANSPARENT"
                                smsSent={this.props.smsSent}
                                sendSMS={this.props.sendSMS}
                                smsMode={config.smsMode.regular}
                                loading={this.props.loading}
                                error={this.props.error}
                                reset={this.props.reset}
                                selectedCountryAlpha2={this.props.selectedCountryAlpha2}
                            />
                        </div>
                    </div> */}
                    <div className={css(styles.appStoreImageWrapper, newtheme.coreStyles.showSmall)}>
                        <div className={css(styles.appStoreImages)}>
                            <a
                                target="_blank"
                                href={config.iosAppLink}
                                onClick={this.onClickIosAppStore}
                                rel="noreferrer">
                                <img
                                    alt="Apple app store"
                                    src="/assets/images/appstore/DownloadOnIosAppStore_1_5x.png"
                                    className={css(styles.appStoreImage)}
                                />
                            </a>
                            <a
                                target="_blank"
                                href={config.androidAppLink}
                                onClick={this.onClickAndroidAppStore}
                                rel="noreferrer">
                                <img
                                    alt="Google play store"
                                    src="/assets/images/appstore/DownloadOnGooglePlayStore_1_5x.png"
                                    className={css(styles.appStoreImage)}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </BaseModal>
        );
    }
}

const styles = StyleSheet.create({
    modalWrapper: {
        [newtheme.cssBreakpoints.medium]: {
            width: 600,
        },
        [newtheme.cssBreakpoints.small]: {
            width: '100%',
            height: '100vh',
        },
        maxWidth: '100%',
        width: 700,
        textAlign: 'center',
        backgroundColor: newtheme.darkmodeColors.white,
        color: newtheme.darkmodeColors.black,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    topSection: {
        [newtheme.cssBreakpoints.medium]: {
            paddingTop: 20,
            marginBottom: 30,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 15,
        },
        width: '80%',
        margin: '0 auto',
        paddingTop: 50,
        marginBottom: 40,
    },
    bottomSection: {
        backgroundColor: '#F7F7F7',
        paddingTop: 30,
        paddingBottom: 30,
    },
    title: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 32,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 28,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 26,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 24,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            fontSize: 21,
        },
        fontSize: 36,
        marginBottom: 20,
    },
    benefits: {
        [newtheme.cssBreakpoints.tiny]: {
            width: '90%',
        },
        width: '80%',
        margin: '0 auto',
    },
    phoneInput: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 22,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 20,
        },
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: 24,
    },
    appStoreImageWrapper: {
        [newtheme.cssBreakpoints.large]: {
            paddingTop: 30,
            paddingBottom: 30,
        },
        [newtheme.cssBreakpoints.medium]: {
            paddingTop: 20,
        },
        paddingTop: 40,
        paddingBottom: 40,
    },
    appStoreImages: {
        [newtheme.cssBreakpoints.tiny]: {
            flexDirection: 'column',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    appStoreImage: {
        [newtheme.cssBreakpoints.tiny]: {
            width: '60%',
            marginBottom: 15,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            width: '50%',
        },
        width: 160,
        maxWidth: 250,
        marginLeft: 10,
        marginRight: 10,
    },
    closeIconWrapper: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        right: 0,
        width: 100,
        height: 70,
    },
    closeIcon: {
        [newtheme.cssBreakpoints.tiny]: {
            top: 25,
            right: 25,
        },
        position: 'absolute',
        top: 40,
        right: 40,
        width: 16,
        height: 16,
        cursor: 'pointer',
    },
});

export default DownloadTheAppModalComponent;
