import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import { isIOS, isAndroid } from 'react-device-detect';
import config from '../../config/index';
import theme from '../../theme';
import IUser from '../../types/models/IUser';
import text from '../../locale';
import IconNewNoaLogo from '../../assets/icons/IconNewNoaLogo';
import newtheme from '../../newtheme';
import IconNewNoaLogoWhiteText from '../../assets/icons/IconNewNoaLogoWhiteText';

interface IProps {
    user?: IUser;
    pathname: string;
    width: number;
    onClickGetTheApp: () => void;
    onClickHome: () => void;
}

interface IState {
    hidden: boolean;
}

class NavbarTopMobile extends React.Component<IProps, IState> {
    public lastScrollTop = 0;

    constructor(props) {
        super(props);

        this.state = { hidden: false };
        this.handleScroll = this.handleScroll.bind(this);
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    public handleScroll = () => {
        const currentScrollTop = window.pageYOffset;

        // We're scrolling up
        if (currentScrollTop > this.lastScrollTop && currentScrollTop > 50) {
            if (!this.state.hidden) {
                this.setState({ hidden: true });
            }
        } else if (this.state.hidden) {
            this.setState({ hidden: false });
        }

        this.lastScrollTop = currentScrollTop;
    };

    public isDarkMode = () => true;

    public showNavbar = () => {
        this.setState({ hidden: false });
    };

    public renderTopNavLink(linkText: string, onClickAction: () => void, route?: string, requiresLogin: boolean = false) {
        const { user, pathname } = this.props;

        if (!route) {
            return (
                <li className={css(styles.listItem, this.isDarkMode() && styles.listItemDarkMode)} id={linkText}>
                    {linkText}
                </li>
            );
        }

        const selected = route === config.routes.homeWebPlayer ? route === pathname : pathname.match(route);

        if (requiresLogin && !user) {
            return null;
        }

        return (
            <Link to={route} className={css(this.isDarkMode() ? styles.linkTopNavDarkMode : styles.linkTopNav, selected && styles.active)} onClick={onClickAction}>
                <li className={css(styles.listItem)} id={linkText}>
                    {linkText}
                </li>
            </Link>
        );
    }

    public renderHomeIcon = () => {
        const { pathname } = this.props;

        const selected = pathname === config.routes.homeWebPlayer;

        return (
            <Link to={config.routes.homeWebPlayer} className={css(styles.link, selected && styles.active)} onClick={this.props.onClickHome}>
                {this.isDarkMode() ? <IconNewNoaLogoWhiteText className={css(styles.homeIcon)} /> : <IconNewNoaLogo className={css(styles.homeIcon)} />}
            </Link>
        );
    };

    public renderRightJustifiedMenuOptions = () => (
        <div onClick={this.props.onClickGetTheApp}>
            {isIOS ? (
                <img alt="iOS App Store logo" className={css(styles.storeButton)} src="/downloadAppleClear.png" />
            ) : isAndroid ? (
                <img alt="Android Play Store logo" className={css(styles.storeButton)} src="/downloadAndroidClear.png" />
            ) : (
                <></>
            )}
        </div>
    );

    public render() {
        return (
            <div className={css(styles.navWrapper)}>
                <Navbar className={css(styles.nav, this.isDarkMode() && styles.navyNav, this.state.hidden && styles.hidden)}>
                    {/* Items on the left side of navbar */}
                    <div>{this.renderHomeIcon()}</div>

                    <ul className={css(styles.list)}>
                        {this.renderTopNavLink(text.nav.ABOUT, () => null, config.routes.about)}
                        {this.renderTopNavLink(text.nav.FAQ, () => null, config.routes.faq)}
                        {this.renderTopNavLink(text.nav.CONTACT, () => null, undefined)}
                    </ul>
                </Navbar>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    navWrapper: {
        height: 60,
        minHeight: 60,
        width: '100%',
    },
    nav: {
        backgroundColor: 'white',
        width: '100%',
        top: 0,
        height: 60,
        minHeight: 60,
        position: 'fixed',
        zIndex: 999,
        borderBottom: '1px solid #EEE',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        transition: 'top 0.3s',
        fontFamily: 'Poppins',
    },
    navyNav: {
        backgroundColor: newtheme.darkmodeColors.navbarNavy,
        borderBottom: 'none',
    },
    hidden: {
        top: -60,
    },
    homeIcon: {
        height: 30.52,
        width: 89,
    },
    button: {
        marginLeft: 10,
        fontWeight: 500,
    },
    buttonLeftMost: {
        marginLeft: 0,
    },
    link: {
        [`@media (min-width: ${theme.breakpoints.large}px)`]: {
            ':hover svg': {
                fill: theme.textColorSelected,
            },
            ':hover': {
                color: theme.textColorSelected,
            },
        },
        color: theme.textColor,
        fontSize: '1.3rem',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    list: {
        color: newtheme.colors.primaryBlue,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '17px',
        padding: 0,
        margin: 0,
    },
    linkTopNav: {
        ':hover': {
            textDecoration: 'none',
        },
        color: newtheme.colors.primaryBlue,
    },
    linkTopNavDarkMode: {
        ':hover': {
            color: newtheme.colors.primaryBlue,
        },
        color: newtheme.colors.white,
    },
    listItem: {
        [`@media (max-width: ${theme.breakpoints.smallerThanTiny}px)`]: {
            fontSize: 13,
            marginLeft: 15,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
            marginLeft: 10,
        },
        ':visited': {
            color: newtheme.colors.primaryBlue,
        },
        display: 'inline-block',
        fontSize: 14,
        marginLeft: 30,
        cursor: 'pointer',
    },
    listItemDarkMode: {
        ':visited': {
            color: newtheme.colors.white,
        },
        ':hover': {
            color: newtheme.colors.primaryBlue,
        },
        color: newtheme.colors.white,
    },
    active: {
        color: newtheme.colors.primaryBlue,
        fontWeight: 600,
    },
    storeButton: {
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 30,
        },
        height: 35,
    },
});

export default NavbarTopMobile;
