import { LISTENING_ITEM, SAVE_HISTORY_FAILURE, SAVE_HISTORY_REQUEST, SAVE_HISTORY_SUCCESS } from '../constants/history';
import IPlayItem from '../types/IPlayItem';

export interface IState {
    loading: boolean;
    mapPlayItems: { [key: string]: IPlayItem & { time: number } };
    error?: Error;
}

interface IAction {
    type: string;
    item?: IPlayItem;
    error?: Error;
    time?: number;
}

export const initialState: IState = {
    loading: false,
    mapPlayItems: {},
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case LISTENING_ITEM: {
            const item = action.item as IPlayItem;
            const itemMapId = itemId(item);
            const map = { ...state.mapPlayItems };
            const mappedItem = state.mapPlayItems[itemMapId];

            if (mappedItem) {
                map[itemMapId] = { ...mappedItem, time: action.time as number };
            } else {
                map[itemMapId] = { ...item, time: 0 };
            }

            const newState: IState = {
                ...state,
                mapPlayItems: map,
            };

            return newState;
        }
        case SAVE_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAVE_HISTORY_SUCCESS: {
            if (action.item) {
                delete state.mapPlayItems[itemId(action.item)];
            }

            const newState: IState = {
                ...state,
                mapPlayItems: { ...state.mapPlayItems },
                loading: false,
            };

            return newState;
        }
        case SAVE_HISTORY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        default:
            return state;
    }
};

const itemId = (item?: IPlayItem) => {
    if (!item) {
        return '';
    }

    return `${item.type}:${item.id}`;
};
