// @ts-ignore
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface ILocalProps {
    to: string;
    className: any;
}

/**
 * Link that also works for external URL's
 */
export default class LinkDuo extends Component<ILocalProps> {
    public isExternal = (url: string) => /^https?:\/\//.test(url);

    render() {
        // eslint-disable-next-line react/jsx-no-target-blank
        return this.isExternal(this.props.to) ? <a href={this.props.to} target="_blank" {...this.props} /> : <Link {...this.props} />;
    }
}
