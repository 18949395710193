import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class Icon5Stars extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 210 53" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <rect width="210" height="53" rx="24.5" fill="#0A233D" />
                <path d="M40.423 16.7571L37.5865 22.4698L31.1718 23.3718C30.0372 23.5436 29.6008 24.918 30.4299 25.7341L35.0119 30.1582L33.921 36.3863C33.7464 37.5031 34.9683 38.3621 35.9719 37.8467L41.6885 34.883L47.3614 37.8467C48.3651 38.3621 49.5869 37.5031 49.4124 36.3863L48.3214 30.1582L52.9034 25.7341C53.7325 24.918 53.2961 23.5436 52.1616 23.3718L45.7904 22.4698L42.9103 16.7571C42.4303 15.7692 40.9466 15.7263 40.423 16.7571Z" fill="#039BE5" />
                <path d="M72.257 16.7571L69.4205 22.4698L63.0058 23.3718C61.8712 23.5436 61.4348 24.918 62.2639 25.7341L66.8459 30.1582L65.7549 36.3863C65.5804 37.5031 66.8023 38.3621 67.8059 37.8467L73.5225 34.883L79.1954 37.8467C80.199 38.3621 81.4209 37.5031 81.2464 36.3863L80.1554 30.1582L84.7374 25.7341C85.5665 24.918 85.1301 23.5436 83.9955 23.3718L77.6244 22.4698L74.7443 16.7571C74.2643 15.7692 72.7806 15.7263 72.257 16.7571Z" fill="#039BE5" />
                <path d="M104.089 16.7571L101.253 22.4698L94.8378 23.3718C93.7032 23.5436 93.2668 24.918 94.096 25.7341L98.6779 30.1582L97.587 36.3863C97.4124 37.5031 98.6343 38.3621 99.638 37.8467L105.355 34.883L111.027 37.8467C112.031 38.3621 113.253 37.5031 113.078 36.3863L111.987 30.1582L116.569 25.7341C117.399 24.918 116.962 23.5436 115.828 23.3718L109.456 22.4698L106.576 16.7571C106.096 15.7692 104.613 15.7263 104.089 16.7571Z" fill="#039BE5" />
                <path d="M135.923 16.7571L133.087 22.4698L126.672 23.3718C125.537 23.5436 125.101 24.918 125.93 25.7341L130.512 30.1582L129.421 36.3863C129.246 37.5031 130.468 38.3621 131.472 37.8467L137.188 34.883L142.861 37.8467C143.865 38.3621 145.087 37.5031 144.912 36.3863L143.821 30.1582L148.403 25.7341C149.233 24.918 148.796 23.5436 147.662 23.3718L141.29 22.4698L138.41 16.7571C137.93 15.7692 136.447 15.7263 135.923 16.7571Z" fill="#039BE5" />
                <path d="M167.757 16.7571L164.921 22.4698L158.506 23.3718C157.371 23.5436 156.935 24.918 157.764 25.7341L162.346 30.1582L161.255 36.3863C161.08 37.5031 162.302 38.3621 163.306 37.8467L169.022 34.883L174.695 37.8467C175.699 38.3621 176.921 37.5031 176.746 36.3863L175.655 30.1582L180.237 25.7341C181.066 24.918 180.63 23.5436 179.496 23.3718L173.124 22.4698L170.244 16.7571C169.764 15.7692 168.281 15.7263 167.757 16.7571Z" fill="#039BE5" />
            </svg>
        );
    }
}

export default Icon5Stars;
