export const isValidJSON = (str: string): boolean => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const errorToJson = (error: Error) => {
    const obj = {};
    Object.getOwnPropertyNames(error).forEach((key) => {
        obj[key] = error[key];
    });

    return obj;
};
