import loadable from '@loadable/component';
import * as React from 'react';
import LoadingPage from '../../../components/LoadingPage';

const LoadableProfile = loadable(() => import('../containers/ProfilePage'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadProfile extends React.Component {
    render() {
        return <LoadableProfile />;
    }
}

export default LazyLoadProfile;
