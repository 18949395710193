import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import * as url from 'url';

import { isServer } from '../../store';
import config from '../config';
import { isAndroid } from './android';
import { isIOS } from './browser';

export const pathnameWithoutVersion = (urlString?: string) => {
    if (!urlString) {
        return '';
    }

    let pathname = '';

    if (isServer) {
        const urlParsed = url.parse(urlString);
        pathname = urlParsed.pathname || '';
    } else {
        const link = document.createElement('a');
        link.href = urlString;
        pathname = link.pathname;
    }

    return `/${encodeURI(_.chain(pathname).split('/').takeRight(2).join('/')
        .value())}`;
};

export const urlWithoutParams = (urlToTransform?: string) => {
    if (!urlToTransform) {
        return urlToTransform;
    }

    return urlToTransform.split(/[?#]/)[0];
};

export const getQueryString = (search: string): { [key: string]: string } => {
    const cleanedSearch = _.size(search) > 0 && search[0] === '?' ? search.substr(1) : search;
    const vars = cleanedSearch.split('&');
    let qs = {};

    for (const v of vars) {
        const pair = v.split('=');

        if (_.size(pair) > 1) {
            qs = { ...qs, [pair[0]]: decodeURIComponent(pair[1]) };
        }
    }

    return qs;
};

export const getDownloadIosDynamicLink = () => (isMobile ? config.iosAppLink : config.routes.download);

export const getDownloadAndroidDynamicLink = () => (isMobile ? config.androidAppLink : config.routes.download);

export const getDownloadMobileDynamicLink = () => (isIOS ? config.iosAppLink : isAndroid ? config.androidAppLink : config.routes.download);
