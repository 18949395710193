import * as React from 'react';

interface IProps {
    className?: string;
}

class InfoIcon extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={this.props.className}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 25.6667C20.4434 25.6667 25.6667 20.4433 25.6667 14C25.6667 7.55667 20.4434 2.33333 14 2.33333C7.55672 2.33333 2.33337 7.55667 2.33337 14C2.33337 20.4433 7.55672 25.6667 14 25.6667ZM15.1667 8.16666C15.1667 8.81099 14.6444 9.33333 14 9.33333C13.3557 9.33333 12.8334 8.81099 12.8334 8.16666C12.8334 7.52233 13.3557 7 14 7C14.6444 7 15.1667 7.52233 15.1667 8.16666ZM14 11.6667C14.6444 11.6667 15.1667 12.131 15.1667 12.7037L15.1667 19.963C15.1667 20.5357 14.6444 21 14 21C13.3557 21 12.8334 20.5357 12.8334 19.963V12.7037C12.8334 12.131 13.3557 11.6667 14 11.6667Z"
                    fill="#039BE5"
                />
            </svg>
        );
    }
}

export default InfoIcon;
