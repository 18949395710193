import { StyleSheet } from 'aphrodite';

const breakpoints = {
    minuscule: 360,
    iPhoneSE: 375,
    smallerThanTiny: 410,
    tiny: 575,
    small: 767,
    medium: 991,
    large: 1199,
    massive: 1500,
};

const cssBreakpoints = {
    minuscule: `@media (max-width: ${breakpoints.minuscule}px), (max-width: ${breakpoints.tiny}px) and (max-height: 600px)`,
    iPhoneSE: `@media (max-width: ${breakpoints.iPhoneSE}px)`,
    smallerThanTiny: `@media (max-width: ${breakpoints.smallerThanTiny}px)`,
    tiny: `@media (max-width: ${breakpoints.tiny}px)`,
    small: `@media (max-width: ${breakpoints.small}px), (max-height: 500px)`,
    medium: `@media (max-width: ${breakpoints.medium}px), (max-height: 700px)`,
    large: `@media (max-width: ${breakpoints.large}px), (max-height: 900px)`,
    greaterThanLarge: `@media (min-width: ${breakpoints.large + 1}px)`,
    largeWidthSmallHeight: `@media (min-width: ${breakpoints.medium + 1}px) and (max-height: 600px)`,
    largeWidthMediumHeight: `@media (min-width: ${breakpoints.medium + 1}px) and (max-height: 700px)`,
    largeWidthLargeHeight: `@media (min-width: ${breakpoints.medium + 1}px) and (max-height: 800px)`,
};

const cssHeightBreakpoints = {
    minuscule: '@media (max-height: 400px)',
    tiny: '@media (max-height: 500px)',
    small: '@media (max-height: 600px)',
    medium: '@media (max-height: 700px)',
    large: '@media (max-height: 800px)',
};

const cssBreakpointsWithoutHeight = {
    minuscule: `@media (max-width: ${breakpoints.minuscule}px)`,
    smallerThanTiny: `@media (max-width: ${breakpoints.smallerThanTiny}px)`,
    tiny: `@media (max-width: ${breakpoints.tiny}px)`,
    small: `@media (max-width: ${breakpoints.small}px)`,
    medium: `@media (max-width: ${breakpoints.medium}px)`,
    large: `@media (max-width: ${breakpoints.large}px)`,
    greaterThanLarge: `@media (min-width: ${breakpoints.large + 1}px)`,
    massive: `@media (max-width: ${breakpoints.massive}px)`,
};

const colors = {
    primaryBlue: '#039BE5',
    backgroundNavy: '#031629',
    cardNavy: '#122F4C',
    navbarNavy: '#0D283F',
    googleRed: '#de5246',
    lightNavy: '#122F4C',
    navy: '#06032F',
    white: '#FFFFFF',
    lightBlue: '#EBF7FF',
    light: '#FAFBFB',
    light2: '#F7F9FA',
    facebookPrimary: '#3A579D',
    googlePrimary: '#4285F4',
    linkedInPrimary: '#0974B0',
    applePrimary: '#000000',
};

const darkmodeColors = {
    backgroundNavy: '#021524',
    cardNavy: '#0A2337',
    cardNavyHover: '#0F2B42',
    buttonNavy: '#13334D',
    fadedText: 'rgba(255, 255, 255, 0.6)',
    fadedText80pc: 'rgba(255, 255, 255, 0.8)',
    fadedText85pc: 'rgba(255, 255, 255, 0.85)',
    slightlyFadedText: 'rgba(255, 255, 255, 0.9)',
    white: '#FFFFFF',
    black: '#000000',
    inputNavy: '#153755',
    navbarNavy: '#0D283F',
    seekbarNavy: '#021424',
};

const utils = {
    toRgba: (hexCode, opacity = 1) => {
        const hex = hexCode.slice(1, hexCode.length);

        if (hex.length !== 6) {
            throw new Error('Only six-digit hex colors are allowed.');
        }

        const aRgbHex = hex.match(/.{1,2}/g);
        const aRgb = [parseInt(aRgbHex[0], 16), parseInt(aRgbHex[1], 16), parseInt(aRgbHex[2], 16)];
        return `rgba(${[...aRgb, opacity].join(',')})`;
    },
};

const coreStyles = StyleSheet.create({
    highlightLink: {
        ':hover': {
            color: 'white',
        },
        color: colors.primaryBlue,
        cursor: 'pointer',
    },
    highlightLinkDark: {
        ':hover': {
            color: colors.navy,
            textDecoration: 'underline',
        },
        color: colors.primaryBlue,
        cursor: 'pointer',
    },
    removeLinkDecoration: {
        textDecoration: 'none',
    },
    hideMedium: {
        [cssBreakpoints.medium]: {
            display: 'none',
        },
    },
    showMedium: {
        [cssBreakpoints.medium]: {
            display: 'block',
        },
        display: 'none',
    },
    hideSmall: {
        [cssBreakpoints.small]: {
            display: 'none',
        },
    },
    showSmall: {
        [cssBreakpoints.small]: {
            display: 'block',
        },
        display: 'none',
    },
    hideTiny: {
        [cssBreakpoints.tiny]: {
            display: 'none',
        },
    },
    showTiny: {
        [cssBreakpoints.tiny]: {
            display: 'block',
        },
        display: 'none',
    },
    hidden: {
        display: 'none',
    },
    noVisibility: {
        visibility: 'hidden',
    },
    noGutterCol: {
        padding: 0,
    },
    centerRowHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    rowCenterHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    centerColHorizontal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    centerColVertical: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    alignRow: {
        display: 'flex',
        flexDirection: 'row',
    },
});

const newtheme = {
    colors,
    darkmodeColors,
    coreStyles,
    cssBreakpoints,
    cssHeightBreakpoints,
    cssBreakpointsWithoutHeight,
    utils,
};

export default newtheme;
