import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';

import IItem from '../../types/models/IItem';
import IJournalist from '../../types/models/IJournalist';
import { event, EventCategories, EventActions } from '../../utils/metric';
import { pathnameWithoutVersion } from '../../utils/url';
import theme from '../../theme/index';

interface IProps {
    journalist: IJournalist;
}

class ItemJournalist extends React.Component<IProps> {
    public clickOnLink = (item: IItem) => () => {
        event(
            EventCategories.JOURNALIST,
            EventActions.TAP,
            `${item.type}-${item.id}`,
        );
    };

    public renderImage = (placeholder?: boolean) => {
        const { journalist } = this.props;
        const imageUrl = journalist.image
            ? journalist.image
            : Array.isArray(journalist.publishers) && journalist.publishers.length > 0
                ? journalist.publishers[0].largeImage
                : null;

        const style = placeholder ? {} : { backgroundImage: `url(${imageUrl})` };

        return (
            <div
                className={css(styles.card)}
                style={style}
            />
        );
    };

    public render() {
        const { journalist } = this.props;

        const pathname = pathnameWithoutVersion(journalist.url);

        return (
            <div className={css(styles.item)}>
                <Link
                    to={pathname}
                    className={css(styles.link)}
                    onClick={this.clickOnLink(journalist)}
                >
                    {this.renderImage()}
                </Link>
                <p className={css(styles.name)}>{journalist.name}</p>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    // @ts-ignore
    card: {
        ...theme.styles.circleCard,
        borderRadius: 200,
    },
    // @ts-ignore
    name: theme.styles.cardDescription,
    // @ts-ignore
    item: {
        ...theme.styles.item,
        flex: '1 0 16.3%',
        maxWidth: '16.3%',
    },
    link: {
        textDecoration: 'none',
    },
});

export default ItemJournalist;
