import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { isIE } from 'react-device-detect';
import theme from '../../theme/index';
import { event, EventCategories, EventActions } from '../../utils/metric';

interface IProps {
    text?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    styleObj?: object | object[];
    disabled?: boolean;
    mode?: 'CIRCLE' | 'SQUARE';
    className?: string;
    backgroundColor?: string;
    color?: string;
    children?: any;
    fontSize?: number;
    submit?: boolean;
    height?: number;
    borderColor?: string;
    borderRadius?: number;
}

class Button extends React.Component<IProps> {
    public onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { onClick, text } = this.props;

        if (onClick) {
            onClick(e);
        }

        event(EventCategories.BUTTON, EventActions.TAP, text);
    };

    public render() {
        const {
            styleObj,
            text,
            disabled,
            mode,
            className,
            backgroundColor,
            fontSize,
            color,
            children,
            submit,
            height,
            borderColor,
            borderRadius,
        } = this.props;
        const btnStyle = !mode || mode === 'SQUARE' ? styles.buttonSquare : styles.buttonCircle;
        const submitProp = submit ? { type: 'submit' } : {};
        const style = {
            borderColor,
            borderRadius,
            backgroundColor: backgroundColor || theme.tertiaryColor,
            color: color || 'white',
            fontSize: fontSize || 18,
            height: height || 38,
        };

        return (
            <button
                // @ts-ignore
                type="button"
                className={`${css(styles.button, btnStyle, !!borderColor && styles.border, styleObj)} ${className}`}
                disabled={!!disabled}
                style={style}
                onClick={this.onClick}
                {...submitProp}>
                {isIE ? (
                    <>
                        {children}
                        {text}
                    </>
                ) : (
                    <span className={css(styles.centerSpan)}>
                        {children}
                        <span className={css(styles.centerSpan)}>{text}</span>
                    </span>
                )}
            </button>
        );
    }
}

const styles = StyleSheet.create({
    centerSpan: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    button: {
        ':focus': {
            outline: 0,
        },
        ':hover': {
            filter: 'brightness(90%)',
        },
        ':active': {
            filter: 'brightness(80%)',
        },
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 7,
        paddingBottom: 7,
        cursor: 'pointer',
        border: 0,
        lineHeight: 0,
        whiteSpace: 'nowrap',
    },
    buttonSquare: {
        borderRadius: 10,
    },
    buttonCircle: {
        borderRadius: 100,
    },
    border: {
        borderStyle: 'solid',
        borderWidth: 1,
    },
});

export default Button;
