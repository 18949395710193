import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

import { IState } from '../reducers/index';
import theme from '../theme';
import IUser from '../types/models/IUser';
import {
    event,
    EventCategories,
    EventActions,
    EventLabels,
} from '../utils/metric';
import { isServer } from '../../store';
import { openModal } from '../actions/modal';
import ModalTypes from '../types/ModalTypes';
import NavbarTopDesktop from '../components/navbar/NavbarTopDesktop';
import NavbarTopMobile from '../components/navbar/NavbarTopMobile';

interface IPropsFromState {
    user?: IUser;
    currentPath: string;
}

interface IPropsFromDispatch {
    openModal: typeof openModal;
}

interface IInnerState {
    width: number;
}

class NavTop extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps, IInnerState> {
    constructor(props) {
        super(props);

        this.state = { width: 0 };
    }

    public componentDidMount = () => {
        if (!isServer) {
            this.updateWidth();
            window.addEventListener('resize', this.updateWidth);
        }
    };

    public UNSAFE_componentWillReceiveProps = () => {
        if (!isServer) {
            this.updateWidth();
        }
    };

    public isMobile = () => {
        const { width } = this.state;

        if (width === 0) {
            return false;
        }

        return width <= theme.breakpoints.small;
    };

    public updateWidth = () => {
        this.setState({ width: window.innerWidth });
    };

    public onClickGetTheApp = () => {
        this.props.openModal(ModalTypes.GetTheApp);
    };

    public onClickEnterprise = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.HOME);
    };

    public onClickHome = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.HOME);
    };

    public onClickLatest = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.LATEST);
    };

    public onClickSearch = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.SEARCH);
    };

    public onClickLibrary = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.LIBRARY);
    };

    public onClickSettings = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.SETTINGS);
    };

    public onClickLogin = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.LOGIN);
    };

    public onClickSignup = () => {
        event(EventCategories.OTHER, EventActions.TAP, EventLabels.SIGNUP);
    };

    public render() {
        const { user } = this.props;

        if (this.isMobile()) {
            return (
                <>
                    <NavbarTopMobile
                        user={user}
                        pathname={this.props.location.pathname}
                        width={this.state.width}
                        onClickGetTheApp={this.onClickGetTheApp}
                        onClickHome={this.onClickHome}
                    />
                </>
            );
        }

        return (
            <NavbarTopDesktop
                user={user}
                pathname={this.props.location.pathname}
                onClickGetTheApp={this.onClickGetTheApp}
                onClickEnterprise={this.onClickEnterprise}
                onClickHome={this.onClickHome}
                onClickLatest={this.onClickLatest}
                onClickSearch={this.onClickSearch}
                onClickLibrary={this.onClickLibrary}
                onClickSettings={this.onClickSettings}
                onClickLogin={this.onClickLogin}
                onClickSignup={this.onClickSignup}
            />
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    const currentPath = !isServer ? window.location.pathname : '';

    return {
        user: state.user.user,
        currentPath,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({ openModal }, dispatch);
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(NavTop),
);
