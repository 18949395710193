import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableAbout = loadable(() => import('../containers/About'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadAbout extends React.Component {
    render() {
        return (
            <Page noCrawl={false} title="About Us" trackingScreen={mpTrackingScreens.about}>
                <LoadableAbout />
            </Page>
        );
    }
}

export default LazyLoadAbout;
