import { isValidJSON } from './json';

const responseCheck = (response: Response, { returnErrorBody }: { returnErrorBody?: boolean } = {}): Promise<any> => {
    if (response.status >= 200 && response.status < 300) {
        return response.text().then((body) => {
            if (isValidJSON(body)) {
                return JSON.parse(body);
            }
            return Promise.resolve(body);
        });
    }

    if (response.status === 401) {
        throw new Error('Unauthorized');
    }

    if (returnErrorBody) {
        return response.json().then((res) => Promise.reject(res));
    }

    throw new Error(`Invalid Http Status_${response.status}`);
};

export default responseCheck;
