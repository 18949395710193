import * as React from 'react';

interface IProps {
    className?: string;
}

class IconNewNoaLogoWhiteText extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 155 56" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M75.5473 14.3035C78.6416 14.3035 81.1235 15.3027 82.993 17.3011C84.8625 19.2996 85.7973 22.1522 85.7973 25.8589V41.379H80.2855V26.4391C80.2855 24.0539 79.6731 22.2166 78.4482 20.9273C77.2234 19.6058 75.5473 18.945 73.4199 18.945C71.2281 18.945 69.4714 19.6541 68.1498 21.0724C66.8283 22.4584 66.1675 24.5051 66.1675 27.2127V41.379H60.6557V14.7386H66.1675V20.5889C66.8766 18.5904 68.0692 17.0433 69.7454 15.9473C71.4215 14.8514 73.3554 14.3035 75.5473 14.3035Z" fill="white" />
                <path d="M103.46 14.4002C106.039 14.4002 108.36 14.9481 110.423 16.044C112.485 17.14 114.097 18.7194 115.257 20.7823C116.418 22.8452 116.998 25.2626 116.998 28.0346C116.998 30.8389 116.418 33.2725 115.257 35.3354C114.097 37.3983 112.485 38.9777 110.423 40.0736C108.36 41.1695 106.039 41.7175 103.46 41.7175C100.882 41.7175 98.5609 41.1695 96.498 40.0736C94.4673 38.9777 92.8556 37.3983 91.663 35.3354C90.5026 33.2402 89.9225 30.8067 89.9225 28.0346C89.9225 25.2626 90.5026 22.8452 91.663 20.7823C92.8556 18.7194 94.4673 17.14 96.498 16.044C98.5609 14.9481 100.882 14.4002 103.46 14.4002ZM103.46 19.1867C102.042 19.1867 100.737 19.5091 99.5439 20.1537C98.3513 20.7984 97.3844 21.7976 96.643 23.1514C95.9339 24.4729 95.5793 26.1007 95.5793 28.0346C95.5793 30.0008 95.9339 31.6447 96.643 32.9663C97.3844 34.2878 98.3513 35.2709 99.5439 35.9156C100.737 36.5602 102.042 36.8825 103.46 36.8825C104.878 36.8825 106.184 36.5602 107.377 35.9156C108.569 35.2709 109.52 34.2878 110.229 32.9663C110.97 31.6447 111.341 30.0008 111.341 28.0346C111.341 26.1007 110.97 24.4729 110.229 23.1514C109.52 21.7976 108.569 20.7984 107.377 20.1537C106.184 19.5091 104.878 19.1867 103.46 19.1867Z" fill="white" />
                <path d="M132.015 14.4002C134.433 14.4002 136.496 14.9965 138.204 16.1891C139.912 17.3817 141.121 18.9611 141.83 20.9273V14.7386H147.342V41.379H141.83V35.1903C141.121 37.1565 139.912 38.7359 138.204 39.9285C136.496 41.1212 134.433 41.7175 132.015 41.7175C129.695 41.7175 127.616 41.1695 125.778 40.0736C123.973 38.9777 122.555 37.3983 121.524 35.3354C120.492 33.2725 119.976 30.8389 119.976 28.0346C119.976 25.2626 120.492 22.8452 121.524 20.7823C122.555 18.7194 123.973 17.14 125.778 16.044C127.616 14.9481 129.695 14.4002 132.015 14.4002ZM133.708 19.2351C131.258 19.2351 129.292 20.0248 127.809 21.6042C126.358 23.1514 125.633 25.2949 125.633 28.0346C125.633 30.7744 126.358 32.934 127.809 34.5134C129.292 36.0606 131.258 36.8342 133.708 36.8342C135.255 36.8342 136.641 36.4796 137.866 35.7705C139.09 35.0292 140.057 33.9977 140.767 32.6762C141.476 31.3546 141.83 29.8074 141.83 28.0346C141.83 26.2941 141.476 24.763 140.767 23.4415C140.057 22.0877 139.09 21.0562 137.866 20.3471C136.641 19.6058 135.255 19.2351 133.708 19.2351Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M45.77 35.2286C44.3817 39.2896 41.8674 42.8722 38.5205 45.5585C35.1735 48.2449 31.1317 49.9243 26.8665 50.4009C22.6013 50.8775 18.2885 50.1316 14.4312 48.2502C13.5989 47.8443 13.2533 46.8406 13.6592 46.0083C14.0651 45.1761 15.0688 44.8305 15.9011 45.2364C19.187 46.8391 22.8608 47.4745 26.4941 47.0685C30.1274 46.6625 33.5704 45.2319 36.4216 42.9435C39.2727 40.6551 41.4145 37.6033 42.5971 34.1439C43.7797 30.6846 43.9544 26.9603 43.1008 23.4054C42.2472 19.8506 40.4005 16.6117 37.776 14.0664C35.1516 11.5212 31.8576 9.77464 28.2783 9.03036C24.6989 8.28608 20.9818 8.5748 17.5603 9.86284C15.3712 10.6869 13.3609 11.8983 11.6206 13.4288C11.891 13.9831 12.0428 14.606 12.0428 15.2643C12.0428 17.5792 10.1662 19.4558 7.85139 19.4558C5.53652 19.4558 3.65996 17.5792 3.65996 15.2643C3.65996 12.9495 5.53652 11.0729 7.85139 11.0729C8.2631 11.0729 8.66095 11.1323 9.03685 11.2429C11.1628 9.2889 13.6528 7.75095 16.3789 6.7247C20.3954 5.21265 24.7591 4.87372 28.9609 5.74744C33.1628 6.62116 37.0296 8.67151 40.1105 11.6594C43.1913 14.6472 45.3592 18.4494 46.3612 22.6225C47.3633 26.7956 47.1582 31.1676 45.77 35.2286ZM20.0905 22.667V33.9573C20.0905 35.1498 21.3751 35.9005 22.414 35.3152L32.4342 29.67C33.4922 29.0739 33.4922 27.5504 32.4342 26.9543L22.414 21.3091C21.3751 20.7238 20.0905 21.4745 20.0905 22.667Z" fill="#039BE5" />
            </svg>
        );
    }
}

export default IconNewNoaLogoWhiteText;
