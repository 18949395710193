import * as React from 'react';

interface IProps {
    className: string;
}

class IconPlayCircular extends React.Component<IProps> {
    public render() {
        return (
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <circle cx="40" cy="40" r="40" fill="#039BE5" />
                <path
                    d="M52.9046 38.0088L32.8567 25.2436C31.205 24.2003 28.756 25.2436 28.756 27.7598L28.756 53.2903C28.756 55.6224 31.0342 57.0339 32.8567 55.8679L52.9046 43.1026C54.6702 41.9366 54.6702 39.1749 52.9046 38.0088Z"
                    fill="white"
                    fillOpacity="0.87"
                />
            </svg>
        );
    }
}

export default IconPlayCircular;
