import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import config from '../config';
import {
    ARTICLE_CLEAR,
    ARTICLES_FAILURE,
    ARTICLE_FOLLOW_FAILURE,
    ARTICLE_FOLLOW_REQUEST,
    ARTICLE_FOLLOW_SUCCESS,
    ARTICLES_REQUEST,
    ARTICLES_SUCCESS,
    ARTICLE_FAILURE,
    ARTICLE_REQUEST,
    ARTICLE_UNFOLLOW_FAILURE,
    ARTICLE_UNFOLLOW_REQUEST,
    ARTICLE_UNFOLLOW_SUCCESS,
    ARTICLES_NEW_SUCCESS,
    ARTICLES_NEW_FAILURE,
    ARTICLES_NEW_REQUEST,
    ARTICLE_EMBED_SUCCESS,
    ARTICLE_IS_QUEUED_SUCCESS,
    ARTICLE_IS_QUEUED_PENDING,
    ARTICLE_IS_QUEUED_REJECTED,
    ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_SUCCESS,
    ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_PENDING,
    ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_REJECTED,
} from '../constants/article';
import { IState } from '../reducers/index';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import text from '../locale';
import { requestUrlToGetArticles, IGetArticles } from '../utils/article';
import IArticle from '../types/models/IArticle';

export const clearArticles = () => (dispatch: Dispatch) => dispatch({ type: ARTICLE_CLEAR });

export const setArticleIsQueuedSuccess =
    ({ articleId, isQueued }: { articleId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_SUCCESS, isQueued: { articleId, status: isQueued } });

export const setArticleIsQueuedPending =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_PENDING, isQueued: { articleId } });

export const setArticleIsQueuedRejected =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_REJECTED, isQueued: { articleId } });

export const setArticleIsQueuedForSinglePageSuccess =
    ({ articleId, isQueued }: { articleId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_SUCCESS, isQueued: { articleId, status: isQueued } });

export const setArticleIsQueuedForSinglePagePending =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_PENDING, isQueued: { articleId } });

export const setArticleIsQueuedForSinglePageRejected =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_REJECTED, isQueued: { articleId } });

export const getArticles = (params: IGetArticles) => (dispatch: Dispatch, getState: () => IState) => {
    const state = getState().article;
    if (state.hasAllArticles && params.append) {
        return Promise.resolve(null);
    }

    dispatch({ type: ARTICLES_REQUEST });

    const requestUrl = requestUrlToGetArticles(params, state.page);

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => maybeTransformArticles(response, params))
        .then((response) => dispatch(articlesSuccess(response, !!params.append)))
        .catch((error) => dispatch(articlesFailure(error)));
};

export const getArticlesByUrl = (url: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: ARTICLES_NEW_REQUEST });

    return fetch(getState)(url)
        .then(responseCheck)
        .then((response) => dispatch(articlesNewSuccess(response)))
        .catch((error) => dispatch(articlesNewFailure(error)));
};

export const getEmbedArticle =
    (publisherCode: string, articleCode: string) => (dispatch: Dispatch, getState: () => IState) => {
        dispatch({ type: ARTICLE_REQUEST });

        const requestUrl = `${config.api.url}${config.api.routes.articlePlayer}?code=${articleCode}`;

        return fetch(getState)(requestUrl, {
            headers: {
                authorization: publisherCode,
            },
        })
            .then(responseCheck)
            .then((response) => dispatch(articleSuccess(response)))
            .catch((error) => dispatch(articleFailure(error)));
    };

export const followArticle = (id: number) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: ARTICLE_FOLLOW_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}/articles/${id}`;

    return fetch(getState)(requestUrl, { method: 'PUT' })
        .then(responseCheck)
        .then(() => dispatch(followArticleSuccess()))
        .catch((error) => dispatch(followArticleFailure(error)));
};

export const unfollowArticle = (id: number) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: ARTICLE_UNFOLLOW_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}/articles/${id}`;

    return fetch(getState)(requestUrl, { method: 'DELETE' })
        .then(responseCheck)
        .then(() => dispatch(unfollowArticleSuccess()))
        .catch((error) => dispatch(unfollowArticleFailure(error)));
};

const maybeTransformArticles = (response: any, params: IGetArticles) => {
    const { articles } = response.data;

    let articlesModified: IArticle[] = articles;

    if (params.disablePremiumOnly) {
        articlesModified = articles.map((a) => ({
            ...a,
            canPlay: true,
            isPremium: false,
        }));
    }

    return Promise.resolve(articlesModified);
};

const articlesSuccess = (articles: IArticle[], append: boolean) => ({
    type: ARTICLES_SUCCESS,
    articles,
    append,
});

const articlesFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: ARTICLES_FAILURE, error };
};

const articlesNewSuccess = (response: any) => ({
    type: ARTICLES_NEW_SUCCESS,
    articlesNew: response.data.articles,
});

const articlesNewFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: ARTICLES_NEW_FAILURE, error };
};

const articleSuccess = (response: any) => ({
    type: ARTICLE_EMBED_SUCCESS,
    article: response.data.article,
});

const articleFailure = (error: Error) => ({ type: ARTICLE_FAILURE, error });
const followArticleSuccess = () => {
    toast(text.notifications.ARTICLE_ADDED_TO_YOUR_FAVORITES);
    return { type: ARTICLE_FOLLOW_SUCCESS };
};

const followArticleFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: ARTICLE_FOLLOW_FAILURE, error };
};

const unfollowArticleSuccess = () => {
    toast(text.notifications.ARTICLE_REMOVED_FROM_YOUR_FAVORITES);
    return { type: ARTICLE_UNFOLLOW_SUCCESS };
};

const unfollowArticleFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: ARTICLE_UNFOLLOW_FAILURE, error };
};
