import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { toast } from 'react-toastify';
import { followArticle, unfollowArticle } from '../actions/article';
import {
    pause,
    play,
    addArticleToQueue,
    setCurrentlyPlayingArticle,
    removeArticleFromQueue,
    addPlaylistToQueue,
    removePlaylistFromQueue,
    setArticlesToLocalQueue,
} from '../actions/player';
import { followPlaylist, unfollowPlaylist } from '../actions/playlist';
import ItemArticle from '../components/items/ItemArticle';
import ItemJournalist from '../components/items/ItemJournalist';
import ItemPublisher from '../components/items/ItemPublisher';
import ItemSection from '../components/items/ItemSection';
import { IState } from '../reducers';
import IPlayItem from '../types/IPlayItem';
import IArticle from '../types/models/IArticle';
import IItem from '../types/models/IItem';
import IJournalist from '../types/models/IJournalist';
import IPlaylist from '../types/models/IPlaylist';
import IPublisher from '../types/models/IPublisher';
import ISection from '../types/models/ISection';
import IUser from '../types/models/IUser';
import { event, EventCategories, EventActions } from '../utils/metric';
import ItemTypes from '../types/ItemTypes';
import { copyText } from '../utils/text';
import text from '../locale';
import { openModal } from '../actions/modal';
import ModalTypes from '../types/ModalTypes';
import ItemPlaylist from '../components/items/ItemPlaylist';
import { mpTrackShare } from '../tracking/mixpanel';

interface IProps {
    item: IItem;
    refresh?: () => void;
    removeGap?: boolean;
    isQueueLoading?: boolean;
    displayResurfacedMessage?: boolean;
    fullWidthPlaylists?: boolean;
}

interface IPropsFromState {
    user?: IUser;
    isPlayingAudio: boolean;
    articles: IArticle[];
    recommendedArticles: IArticle[];
    currentlyPlayingCloudArticle?: IPlayItem;
}

interface IPropsFromDispatch {
    pause: typeof pause;
    play: typeof play;
    followArticle: (id: number) => Promise<void>;
    unfollowArticle: (id: number) => Promise<void>;
    followPlaylist: (playlist: IPlaylist) => Promise<void>;
    unfollowPlaylist: (playlist: IPlaylist) => Promise<void>;
    openModal: typeof openModal;
    addArticleToQueue: typeof addArticleToQueue;
    removeArticleFromQueue: typeof removeArticleFromQueue;
    addPlaylistToQueue: typeof addPlaylistToQueue;
    removePlaylistFromQueue: typeof removePlaylistFromQueue;
    setCurrentlyPlayingArticle: typeof setCurrentlyPlayingArticle;
    setArticlesToLocalQueue: typeof setArticlesToLocalQueue;
}

class Item extends React.PureComponent<IProps & IPropsFromState & IPropsFromDispatch> {
    public followOrUnfollowPlaylist = () => {
        const playlist = this.props.item as IPlaylist;

        const command = playlist.isFavourite
            ? this.props.unfollowPlaylist(playlist)
            : this.props.followPlaylist(playlist);

        command.then(() => {
            if (this.props.refresh) {
                this.props.refresh();
            }
        });

        event(
            EventCategories.PLAYLIST,
            playlist.isFavourite ? EventActions.UNFOLLOW : EventActions.FOLLOW,
            `${playlist.type}:${playlist.id}`,
        );
    };

    public followOrUnfollowArticle = () => {
        const article = this.props.item as IArticle;

        const command = article.isFavourite
            ? this.props.unfollowArticle(article.id)
            : this.props.followArticle(article.id);

        command.then(() => {
            if (this.props.refresh) {
                this.props.refresh();
            }
        });

        event(
            EventCategories.ARTICLE,
            article.isFavourite ? EventActions.UNFOLLOW : EventActions.FOLLOW,
            `${article.type}:${article.id}`,
        );
    };

    public play = () => {
        const { item, currentlyPlayingCloudArticle, user } = this.props;
        const article = item as IArticle;

        if (currentlyPlayingCloudArticle?.id === article.id) {
            if (this.props.isPlayingAudio) {
                this.props.pause();
            } else {
                this.props.play();
            }
            return;
        }

        /*
        - !firstArticle.sharedContentSourceInfo
        - making this check to make if the article is shared by a premium user then the PremiumContent Modal should not pops up
        */

        if (
            !article.sharedContentSourceInfo &&
            ((this.props.user && !article.canPlay) || (!this.props.user && article.isPremium))
        ) {
            event(EventCategories.ARTICLE, EventActions.FORBID, `${article.type}:${article.id}`);
            this.props.openModal(ModalTypes.PremiumContent);
            return;
        }

        if (!user) {
            const articlesToQueue = this.getArticlesToLocalQueue();
            this.props.setArticlesToLocalQueue([article, ...articlesToQueue.filter((a) => a.id !== article.id)]);
        } else {
            this.setCurrentlyPlayingArticleToCloudQueue();
        }

        event(EventCategories.ARTICLE, EventActions.PLAY, `${article.type}-${article.id}`);
    };

    public share = () => {
        const { item, user } = this.props;
        if (!item) {
            return;
        }

        const link = item.shareUrl;

        if (copyText(link)) {
            event(item.type, EventActions.SHARE, `${item.id}`);
            toast(text.notifications.COPIED_TO_CLIPBOARD);
            mpTrackShare(item, user);
            return;
        }

        this.props.openModal(ModalTypes.Share, { url: link });
    };

    public getArticlesToLocalQueue = () => {
        const { item, articles, recommendedArticles } = this.props;
        const article = item as IArticle;

        if (Array.isArray(articles) && articles.length > 0) {
            return articles;
        }

        if (Array.isArray(recommendedArticles) && recommendedArticles.length > 0) {
            return recommendedArticles;
        }

        return [article];
    };

    public addArticleToQueue = () => {
        const { item, user } = this.props;
        if (item.type === ItemTypes.Articles) {
            if (user) {
                this.props.addArticleToQueue(item as IArticle);
            } else {
                this.props.openModal(ModalTypes.SignUpNew);
            }
        }
    };

    public removeArticleFromQueue = () => {
        const { item } = this.props;
        this.props.removeArticleFromQueue(item as IArticle);
    };

    public setCurrentlyPlayingArticleToCloudQueue = () => {
        const { item } = this.props;
        this.props.setCurrentlyPlayingArticle(item as IArticle);
    };

    public addPlaylistToQueue = () => {
        const { item, user } = this.props;
        if (user) {
            this.props.addPlaylistToQueue(item as IPlaylist);
        } else {
            this.props.openModal(ModalTypes.SignUpNew);
        }
    };

    public removePlaylistFromQueue = () => {
        const { item } = this.props;
        this.props.removePlaylistFromQueue(item as IPlaylist);
    };

    public render() {
        const { item, removeGap } = this.props;

        if (item.type === ItemTypes.Articles) {
            const playing = this.props.currentlyPlayingCloudArticle?.id === item.id;

            return (
                <ItemArticle
                    article={item as IArticle}
                    playOrPause={this.play}
                    followOrUnfollow={this.followOrUnfollowArticle}
                    hasFollow={!!this.props.user && !!this.props.refresh}
                    share={this.share}
                    playing={playing}
                    isPlayingAudio={this.props.isPlayingAudio}
                    removeGap={removeGap}
                    addToQueue={this.addArticleToQueue}
                    openModal={this.props.openModal}
                    removeFromQueue={this.removeArticleFromQueue}
                    displayResurfacedMessage={this.props.displayResurfacedMessage}
                    isArticleQueueLoading={!!this.props.isQueueLoading}
                />
            );
        }

        if (item.type === ItemTypes.Playlists) {
            return (
                <ItemPlaylist
                    playlist={item as IPlaylist}
                    hasFollow={!!this.props.user && !!this.props.refresh}
                    openModal={this.props.openModal}
                    share={this.share}
                    addToQueue={this.addPlaylistToQueue}
                    removeFromQueue={this.removePlaylistFromQueue}
                    isPlaylistQueueLoading={!!this.props.isQueueLoading}
                    isFullWidth={!!this.props.fullWidthPlaylists}
                />
            );
        }

        if (item.type === ItemTypes.Journalists) {
            return <ItemJournalist journalist={item as IJournalist} />;
        }

        if (item.type === ItemTypes.Publishers) {
            return <ItemPublisher publisher={item as IPublisher} />;
        }

        if (item.type === ItemTypes.Sections) {
            return <ItemSection section={item as ISection} />;
        }

        return null;
    }
}

function mapStateToProps(state: IState, ownProps: IProps): IProps & IPropsFromState {
    const { item } = ownProps;

    return {
        user: state.user.user,
        item,
        refresh: ownProps.refresh,
        isPlayingAudio: state.player.playing,
        articles: state.article.articles,
        recommendedArticles: state.recommendation.articles,
        currentlyPlayingCloudArticle: state.player.currentlyPlaying,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    // @ts-ignore
    return bindActionCreators(
        {
            followArticle,
            unfollowArticle,
            followPlaylist,
            unfollowPlaylist,
            openModal,
            play,
            pause,
            addArticleToQueue,
            setCurrentlyPlayingArticle,
            removeArticleFromQueue,
            addPlaylistToQueue,
            removePlaylistFromQueue,
            setArticlesToLocalQueue,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);
