import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as _ from 'lodash';

import { toast } from 'react-toastify';
import { Container, Row } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { clearArticles, getArticles, followArticle, unfollowArticle } from '../actions/article';
import { clearContent, getContent, getPlaylist } from '../actions/content';
import {
    pause,
    play,
    setCurrentlyPlayingPlaylist,
    addPlaylistToQueue,
    removePlaylistFromQueue,
    removeArticleFromQueue,
    addArticleToQueue,
    setCurrentlyPlayingArticle,
    setArticlesToLocalQueue,
} from '../actions/player';
import { IState } from '../reducers';

import { followPlaylist, unfollowPlaylist } from '../actions/playlist';
import List from '../components/List';
import Loading from '../components/Loading';
import Page from '../components/Page';
import ViewArticle from '../components/views/ViewArticle';
import ViewJournalist from '../components/views/ViewJournalist';
import ViewPlaylist from '../components/views/ViewPlaylist';
import ViewPublisher from '../components/views/ViewPublisher';
import ViewSection from '../components/views/ViewSection';
import text from '../locale';
import IArticle from '../types/models/IArticle';
import IItem from '../types/models/IItem';
import IJournalist from '../types/models/IJournalist';
import IPlaylist from '../types/models/IPlaylist';
import IPublisher from '../types/models/IPublisher';
import ISection from '../types/models/ISection';
import IUser from '../types/models/IUser';
import { event, EventCategories, EventActions, EventLabels } from '../utils/metric';
import Item from './Item';
import { isServer } from '../../store';
import { getRecommendedArticles } from '../actions/recommendation';
import ItemFormats from '../types/ItemFormats';
import ItemTypes from '../types/ItemTypes';
import { copyText } from '../utils/text';
import { isOnEndOfPage, removeOnScroll, addOnScroll } from '../utils/screen';
import { openModal } from '../actions/modal';
import ModalTypes from '../types/ModalTypes';
import ErrorMessage from '../components/ErrorMessage';
import { clientLoaded } from '../actions/app';
import * as urlUtils from '../utils/url';
import { mpTrackingScreens } from '../config/screens';
import { mpTrackShare } from '../tracking/mixpanel';
import { urlWithoutParams } from '../utils/url';
import FrequentQuestions from '../components/landingpagenew/FrequentQuestions';
import LandingPageNewSitemap from '../components/landingpagenew/LandingPageNewSitemap';
import Reviews from '../components/landingpagenew/Reviews';
import newtheme from '../newtheme';
import CreateAccountMinified from '../components/landingpagenew/CreateAccountMinified';
import NewSitemapSeparator from '../components/landingpagenew/NewSitemapSeparator';
import LandingEmbedActions from '../domaindriven/landingembed/components/LandingEmbedActions';
import { getSelectedSubscriptionProduct } from '../domaindriven/subscribe/state/selectors';
import ISubscriptionProduct from '../domaindriven/subscribe/types/ISubscriptionProduct';
import config from '../config';
import IPlayItem from '../types/IPlayItem';

interface IProps {
    url: string;
    route: string;
    trackingScreen: mpTrackingScreens;
}

interface IPropsFromState {
    item?: IItem;
    articles: IArticle[];
    user?: IUser;
    loading: boolean;
    playing: boolean;
    currentlyPlaying?: IPlayItem;
    error?: Error;
    serverLoaded: boolean;
    requestedUrl?: string;
    subscriptionProduct?: ISubscriptionProduct;
    isQueuePendingForArticleSinglePage: boolean;
    isQueuePendingForPlaylistSinglePage: boolean;
    pendingQueueArticleIds: number[];
    pendingQueueSectionPlaylistIds: number[];
}

interface IPropsFromDispatch {
    getContent: (url: string) => Promise<void>;
    getArticles: typeof getArticles;
    followPlaylist: (playlist: IPlaylist) => Promise<void>;
    unfollowPlaylist: (playlist: IPlaylist) => Promise<void>;
    followArticle: (id: number) => Promise<void>;
    unfollowArticle: (id: number) => Promise<void>;
    pause: typeof pause;
    play: typeof play;

    clearContent: typeof clearContent;
    clearArticles: typeof clearArticles;
    getRecommendedArticles: typeof getRecommendedArticles;
    getPlaylist: (url: string) => Promise<void>;
    openModal: typeof openModal;
    clientLoaded: typeof clientLoaded;
    setCurrentlyPlayingPlaylist: typeof setCurrentlyPlayingPlaylist;
    removePlaylistFromQueue: typeof removePlaylistFromQueue;
    addPlaylistToQueue: typeof addPlaylistToQueue;
    removeArticleFromQueue: typeof removeArticleFromQueue;
    addArticleToQueue: typeof addArticleToQueue;
    setCurrentlyPlayingArticle: typeof setCurrentlyPlayingArticle;
    setArticlesToLocalQueue: typeof setArticlesToLocalQueue;
}

class Content extends React.Component<
    IProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps<{ id?: string }>
> {
    public componentDidMount() {
        if (isServer) {
            return;
        }

        if (this.props.serverLoaded) {
            this.props.clientLoaded();
        } else {
            this.loadContent().then(() => this.loadContentItems(false, false));
        }

        this.maybeFollowContent();
        addOnScroll('CONTENT', _.debounce(this.onScroll, 100));
    }

    public componentDidUpdate(prevProps: IProps & IPropsFromState & IPropsFromDispatch) {
        if (isServer) {
            return;
        }

        if (!prevProps.user && this.props.user) {
            this.refresh();
        }

        if (!this.props.location) {
            return;
        }

        const locationState = this.props.location.state;

        // @ts-ignore
        if (!locationState || !locationState.autoplay) {
            return;
        }

        if (this.props.articles && !_.isEmpty(this.props.articles)) {
            // @ts-ignore
            this.props.location.state.autoplay = false;
            this.listenHere();
        }
    }

    public componentWillUnmount() {
        this.props.clearContent();
        this.props.clearArticles();

        removeOnScroll('CONTENT');
    }

    public loadContent = (): Promise<any> => {
        const { url } = this.props;

        if (url.includes('/playlist/')) {
            return this.props.getPlaylist(url);
        }

        return this.props.getContent(url);
    };

    public loadContentItems = (loadAll: boolean, append: boolean) => {
        const { item } = this.props;

        if (!item || item.type === ItemTypes.Sections) {
            return;
        }

        if (item.type === ItemTypes.Articles) {
            this.props.getRecommendedArticles(item.id);
            return;
        }

        if (item.articlesUrl) {
            this.props.getArticles({
                append,
                loadAll,
                url: item.articlesUrl,
                nutshell: true,
                disablePremiumOnly: false,
            });
        }
    };

    public refresh = () => {
        // this.loadContent().then(() => this.loadContentItems(true, false));
    };

    public playViewArticle = () => {
        const article = this.props.item as IArticle;
        const { currentlyPlaying, playing: isPlaying } = this.props;

        if (currentlyPlaying?.id === article.id) {
            if (isPlaying) {
                this.props.pause();
            } else {
                this.props.play();
            }
            return;
        }

        /*
        - !firstArticle.sharedContentSourceInfo
        - making this check to make if the article is shared by a premium user then the PremiumContent Modal should not pops up
        */

        if (
            ((this.props.user && !article.canPlay) || (!this.props.user && article.isPremium)) &&
            !article.sharedContentSourceInfo
        ) {
            event(EventCategories.ARTICLE, EventActions.FORBID, `${article.type}:${article.id}`);
            this.props.openModal(ModalTypes.PremiumContent);
            return;
        }

        if (!this.props.user) {
            this.props.setArticlesToLocalQueue([article, ...this.props.articles]);
        } else {
            this.props.setCurrentlyPlayingArticle(article);
        }

        event(EventCategories.ARTICLE, EventActions.PLAY, `${article.type}-${article.id}`);
    };

    public followOrUnfollowPlaylist = (playlist: IPlaylist) => {
        const command = playlist.isFavourite
            ? this.props.unfollowPlaylist(playlist)
            : this.props.followPlaylist(playlist);

        command.then(() => this.refresh());

        event(
            EventCategories.PLAYLIST,
            playlist.isFavourite ? EventActions.UNFOLLOW : EventActions.FOLLOW,
            `${playlist.type}:${playlist.id}`,
        );
    };

    public followOrUnfollowArticle = () => {
        const article = this.props.item as IArticle;

        const command = article.isFavourite
            ? this.props.unfollowArticle(article.id)
            : this.props.followArticle(article.id);

        command.then(() => this.refresh());

        event(
            EventCategories.ARTICLE,
            article.isFavourite ? EventActions.UNFOLLOW : EventActions.FOLLOW,
            `${article.type}:${article.id}`,
        );
    };

    public share = () => {
        const { item, user } = this.props;
        if (!item) {
            return;
        }

        const link = item.shareUrl;

        if (copyText(link)) {
            event(item.type, EventActions.SHARE, `${item.id}`);
            toast(text.notifications.COPIED_TO_CLIPBOARD);
            mpTrackShare(item, user);
            return;
        }

        this.props.openModal(ModalTypes.Share, { url: link });
    };

    public followOrUnfollowPlaylistProp = (playlist: IPlaylist) => () => {
        this.followOrUnfollowPlaylist(playlist);
    };

    public showMoreDescription = (playlist: IPlaylist) => () => {
        event(EventCategories.PLAYLIST, EventActions.SHOW, `${playlist.type}-${playlist.id}`);
        this.props.openModal(ModalTypes.ShowMoreDescription, {
            title: playlist.name,
            description: playlist.description,
        });
    };

    public listenOnNoaApp = (playlist: IPlaylist) => () => {
        event(
            EventCategories.PLAYLIST,
            EventActions.SHOW,
            `${EventLabels.LISTEN_ON_THE_NOA_APP}${playlist.type}-${playlist.id}`,
        );

        this.props.openModal(ModalTypes.GetTheApp);
    };

    public listenHere = () => {
        event(EventCategories.PLAYLIST, EventActions.PLAY_ALL);

        const { user, articles, currentlyPlaying } = this.props;

        if (!user) {
            // to add to localQueue
            this.props.setArticlesToLocalQueue(articles.filter((a) => a.id !== currentlyPlaying?.id));
        } else {
            this.props.setCurrentlyPlayingPlaylist(this.props.item as IPlaylist);
        }
    };

    public showSignup = () => {
        this.props.openModal(ModalTypes.SignUp);
    };

    public showSignupPublisherFlow = () => {
        this.props.openModal(ModalTypes.SignUpNew);
    };

    public onSignupClickedNavToSignupSuccess = () => {
        if (this.props.user) {
            this.props.history.push(config.routes.signupSuccess);
            return;
        }

        this.props.openModal(ModalTypes.SignUpNewDirectToSignupSuccess);
    };

    public addPlaylistToQueue = () => {
        const { item, user } = this.props;
        if (item?.type === ItemTypes.Playlist) {
            if (user) {
                this.props.addPlaylistToQueue(item as IPlaylist);
            } else {
                this.props.openModal(ModalTypes.SignUpNew);
            }
        }
    };

    public removePlaylistToQueue = () => {
        const { item } = this.props;
        if (item?.type === ItemTypes.Playlist) {
            this.props.removePlaylistFromQueue(item as IPlaylist);
        }
    };

    public addArticleToQueue = () => {
        const { item, user } = this.props;
        if (item?.type === ItemTypes.Articles) {
            if (user) {
                this.props.addArticleToQueue(item as IArticle);
            } else {
                this.props.openModal(ModalTypes.SignUpNew);
            }
        }
    };

    public removeArticleToQueue = () => {
        const { item } = this.props;
        if (item?.type === ItemTypes.Articles) {
            this.props.removeArticleFromQueue(item as IArticle);
        }
    };

    public isQueueLoadingForRecommendationsItem = (articleId: number) =>
        this.props.pendingQueueArticleIds.some((aid) => aid === articleId);

    public isQueueLoadingForSectionPlaylists = (playlistId: number) =>
        this.props.pendingQueueSectionPlaylistIds.some((aid) => aid === playlistId);

    public renderComponent = () => {
        const { articles, item, user } = this.props;

        if (!item) {
            return null;
        }

        if (item.type === ItemTypes.Journalists) {
            const articlesToRender = articles || [];

            return (
                <ViewJournalist journalist={item as IJournalist} user={user} share={this.share}>
                    <List name={text.content.LATEST_ARTICLES} itemsFormat={ItemFormats.Line}>
                        {articlesToRender.map((article) => (
                            <Item key={article.id} item={article} refresh={this.refresh} />
                        ))}
                    </List>
                </ViewJournalist>
            );
        }

        if (item.type === ItemTypes.Playlist) {
            const playlist = item as IPlaylist;
            const articlesToRender = articles || [];

            return (
                <ViewPlaylist
                    playlist={playlist}
                    removeFromQueue={this.removePlaylistToQueue}
                    addToQueue={this.addPlaylistToQueue}
                    user={user}
                    isPlaylistQueueLoading={this.props.isQueuePendingForPlaylistSinglePage}
                    share={this.share}
                    followOrUnfollow={this.followOrUnfollowPlaylistProp(playlist)}
                    showMoreDescription={this.showMoreDescription(playlist)}
                    listenOnNoaApp={this.listenOnNoaApp(playlist)}
                    listenHere={this.listenHere}
                    openModal={this.props.openModal}>
                    <Container itemsFormat={ItemFormats.Line}>
                        <Row>
                            {articlesToRender.map((article) => (
                                <Item
                                    isQueueLoading={this.isQueueLoadingForRecommendationsItem(article.id)}
                                    key={article.id}
                                    item={article}
                                    refresh={this.refresh}
                                    displayResurfacedMessage
                                />
                            ))}
                        </Row>
                    </Container>
                </ViewPlaylist>
            );
        }

        if (item.type === ItemTypes.Sections) {
            const section = item as ISection;
            const playlistsToRender = section.playlists || [];

            if (!isServer) {
                removeOnScroll('CONTENT');
            }

            return (
                <ViewSection section={section} user={user} share={this.share}>
                    <List itemsFormat={ItemFormats.Rectangle} name={section.name}>
                        {playlistsToRender.map((p) => (
                            <Item
                                key={p.id}
                                item={p}
                                isQueueLoading={this.isQueueLoadingForSectionPlaylists(p.id)}
                                refresh={this.refresh}
                            />
                        ))}
                    </List>
                </ViewSection>
            );
        }

        if (item.type === ItemTypes.Publishers) {
            const articlesToRender = articles || [];

            return (
                <ViewPublisher
                    publisher={item as IPublisher}
                    user={user}
                    share={this.share}
                    openModal={this.props.openModal}
                    play={this.listenHere}>
                    <Row>
                        {articlesToRender.map((article) => (
                            <Item key={article.id} item={article} refresh={this.refresh} />
                        ))}
                    </Row>
                </ViewPublisher>
            );
        }

        if (item.type === ItemTypes.Articles) {
            const article = item as IArticle;
            const playing = this.isPlaying(article) && this.props.playing;

            if (!isServer) {
                removeOnScroll('CONTENT');
            }

            return (
                <ViewArticle
                    article={article}
                    removeFromQueue={this.removeArticleToQueue}
                    addToQueue={this.addArticleToQueue}
                    isArticleQueueLoading={this.props.isQueuePendingForArticleSinglePage}
                    user={user}
                    share={this.share}
                    followOrUnfollow={this.followOrUnfollowArticle}
                    // tslint:disable-next-line:jsx-no-lambda
                    play={this.playViewArticle}
                    playing={playing}
                    openModal={this.props.openModal}>
                    {!!user && !_.isEmpty(articles) && (
                        <List name={text.content.article.COMING_UP} itemsFormat={ItemFormats.Line}>
                            {articles.map((a) => {
                                const art = a as IArticle;

                                return <Item key={art.id} item={art} />;
                            })}
                        </List>
                    )}
                </ViewArticle>
            );
        }

        return null;
    };

    public onScroll = () => {
        const { articles, item } = this.props;

        if (isOnEndOfPage() && !_.isEmpty(articles)) {
            if (!item) {
                return;
            }

            this.loadContentItems(false, true);
            event(EventCategories.OTHER, EventActions.SCROLL, `${item.type}-${item.id}`);
        }
    };

    public maybeFollowContent = () => {
        const { user, item, location } = this.props;
        const qsParams = urlUtils.getQueryString(location.search);

        if (!user || !item || item.type !== ItemTypes.Playlists || (!qsParams.company && !qsParams.onboarding)) {
            return;
        }

        const playlist = item as IPlaylist;
        if (playlist.isFavourite) {
            return;
        }

        this.props.followPlaylist(playlist).then(() => this.loadContent());
    };

    public isContentReady = () => {
        const { item, requestedUrl, url } = this.props;

        if (!item) {
            return false;
        }

        if (urlWithoutParams(requestedUrl) !== urlWithoutParams(url)) {
            return false;
        }

        return true;
    };

    public isPlaying(article: IArticle): boolean {
        const { currentlyPlaying } = this.props;

        return !!(currentlyPlaying && currentlyPlaying.id === article.id);
    }

    public renderPublisherEmbedActions = () => (
        <>
            <LandingEmbedActions
                onSignupClicked={this.showSignupPublisherFlow}
                onSignupClickedNavToSignupSuccess={this.onSignupClickedNavToSignupSuccess}
            />
            <div className={css(styles.spacer)} />
            <NewSitemapSeparator />
            <LandingPageNewSitemap />
        </>
    );

    public renderInfoBanners = () => {
        const { user, item, subscriptionProduct } = this.props;

        return user || (item && item.type === ItemTypes.Publishers) ? (
            <div className={css(styles.bannerBackground)}>
                <NewSitemapSeparator />
                <LandingPageNewSitemap />
            </div>
        ) : subscriptionProduct && subscriptionProduct.offerId.includes('hbr_reader') ? (
            this.renderPublisherEmbedActions()
        ) : (
            <div className={css(styles.bannerBackground)}>
                <CreateAccountMinified />
                <Reviews />
                <FrequentQuestions mode="STANDARD" onSignupClicked={this.showSignup} />
                <NewSitemapSeparator />
                <LandingPageNewSitemap />
            </div>
        );
    };

    public render() {
        const { loading, item, error } = this.props;
        const meta = item
            ? {
                  title: item.name,
                  image: item.image,
                  description: (item as any)?.summary || item.description,
              }
            : {};

        const published =
            item && item.type === ItemTypes.Articles
                ? // @ts-ignore
                  new Date(item.createdAt).toISOString()
                : undefined;

        if (!loading && error) {
            return <ErrorMessage message={text.errors.CONTENT_NOT_FOUND} icon="EMPTY_BOX" />;
        }

        if (!this.isContentReady()) {
            return <Loading loading={loading} />;
        }

        return (
            <Page
                id="main"
                noCrawl={false}
                published={published}
                {...meta}
                trackingScreen={this.props.trackingScreen}
                className={css(styles.contentPage)}>
                <Loading loading={loading} />
                {this.renderComponent()}
                {this.renderInfoBanners()}
            </Page>
        );
    }
}

function mapStateToProps(state: IState, ownProps: IProps): IPropsFromState & IProps {
    const articles = !_.isEmpty(state.article.articles) ? state.article.articles : state.recommendation.articles;
    const item = state.content.content;

    return {
        articles,
        playing: state.player.playing,
        currentlyPlaying: state.player.currentlyPlaying,
        user: state.user.user,
        url: ownProps.url,
        item,
        requestedUrl: state.content.requestedUrl,
        loading: state.content.loading || state.article.loading || state.recommendation.loading,
        route: ownProps.route,
        error: state.content.error,
        isQueuePendingForArticleSinglePage: state.content.isQueuePendingForArticleSinglePage,
        isQueuePendingForPlaylistSinglePage: state.content.isQueuePendingForPlaylistSinglePage,
        pendingQueueArticleIds: state.recommendation.pendingQueueArticleIds,
        pendingQueueSectionPlaylistIds: state.content.pendingQueueSectionPlaylistIds,
        serverLoaded: state.app.serverLoaded,
        trackingScreen: ownProps.trackingScreen,
        subscriptionProduct: getSelectedSubscriptionProduct(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    // @ts-ignore
    return bindActionCreators(
        {
            getRecommendedArticles,
            getContent,
            getArticles,
            getPlaylist,
            clearContent,
            followPlaylist,
            unfollowPlaylist,
            followArticle,
            unfollowArticle,
            pause,
            play,
            clearArticles,
            openModal,
            clientLoaded,
            setCurrentlyPlayingPlaylist,
            addPlaylistToQueue,
            removePlaylistFromQueue,
            addArticleToQueue,
            removeArticleFromQueue,
            setCurrentlyPlayingArticle,
            setArticlesToLocalQueue,
        },
        dispatch,
    );
}

const styles = StyleSheet.create({
    contentPage: {
        minHeight: '100vh',
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
    },
    bannerBackground: {
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        color: newtheme.darkmodeColors.white,
    },
    spacer: {
        [newtheme.cssBreakpoints.small]: {
            marginTop: 80,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginTop: 20,
        },
        marginTop: 100,
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
