import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { ClipLoader } from 'react-spinners';
import { Container, Row, Col } from 'reactstrap';
import IPlaylist from '../../types/models/IPlaylist';
import IUser from '../../types/models/IUser';
import { textToFirstLinebreak } from '../../utils/text';
import { openModal } from '../../actions/modal';
import { mpTrackButtonClick } from '../../tracking/mixpanel';
import { MpTrackingButtons } from '../../config/buttons';
import newtheme from '../../newtheme';
import IconPlayBlue from '../../assets/icons/IconPlayBlue';
import IconShareWhite from '../../assets/icons/IconShareWhite';

import QueueCheckedIcon from '../../assets/icons/QueueCheckedIcon';
import QueueUncheckedIcon from '../../assets/icons/QueueUncheckedIcon';

interface IProps {
    playlist: IPlaylist;
    user?: IUser;
    share: () => void;
    children?: any;
    followOrUnfollow: () => void;
    showMoreDescription: () => void;
    listenOnNoaApp: () => void;
    listenHere: () => void;
    openModal: typeof openModal;
    isPlaylistQueueLoading: boolean;
    removeFromQueue: () => void;
    addToQueue: () => void;
}

interface IState {}

class ViewPlaylist extends React.Component<IProps, IState> {
    public onShareClick = () => {
        this.props.share();
        mpTrackButtonClick(MpTrackingButtons.SHARE, undefined, this.props.playlist.id, this.props.playlist.name);
    };

    public onStartSeriesClick = () => {
        this.props.listenHere();
        mpTrackButtonClick(MpTrackingButtons.PLAY_SERIES, undefined, this.props.playlist.id, this.props.playlist.name);
    };

    public onReadDescriptionClick = () => {
        this.props.showMoreDescription();
        mpTrackButtonClick(
            MpTrackingButtons.SHOW_DESCRIPTION,
            undefined,
            this.props.playlist.id,
            this.props.playlist.name,
        );
    };

    public toggleQueue = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const { playlist, isPlaylistQueueLoading } = this.props;

        if (isPlaylistQueueLoading) return;

        if (playlist.isQueued) {
            this.props.removeFromQueue();
        } else {
            this.props.addToQueue();
        }
    };

    public renderTitle = () => {
        const { playlist } = this.props;

        return <h1 className={css(styles.title)}>{playlist.name}</h1>;
    };

    public maybeRenderSharedByBanner = () => {
        const { playlist, user } = this.props;

        if (!playlist.sharedContentSourceInfo) {
            return null;
        }

        if (user && playlist.sharedContentSourceInfo.userId === user.id) {
            return null;
        }

        const isPremiumUser = user && user.effectiveSubscriptionStatus === 'Premium';

        return (
            <Container className={css(shareBannerStyles.main)}>
                <p className={css(shareBannerStyles.text)}>
                    {isPremiumUser ? (
                        <>
                            This Series has been
                            <br className={css(newtheme.coreStyles.showTiny)} /> recommended by{' '}
                            {playlist.sharedContentSourceInfo?.userName}.
                        </>
                    ) : (
                        <>
                            Listen to this Series for free,
                            <br className={css(newtheme.coreStyles.showTiny)} /> courtesy of{' '}
                            {playlist.sharedContentSourceInfo?.userName}.
                        </>
                    )}
                </p>
                <button type="button" onClick={this.onStartSeriesClick} className={css(shareBannerStyles.button)}>
                    {isPremiumUser ? 'Listen now' : 'Listen for free'}
                </button>
            </Container>
        );
    };

    public renderCurator = () => {
        const { playlist } = this.props;

        if (!playlist.curator) {
            return null;
        }

        return (
            <div className={css(styles.curatorWrapper)}>
                <img
                    alt={`Headshot of ${playlist.curator.fullName}`}
                    src={playlist.curator.image}
                    className={css(styles.curatorImage)}
                />
                <div className={css(styles.curatorTextWrapper)}>
                    <p className={css(styles.curatedByText)}>Curated by</p>
                    <p className={css(styles.curatorName)}>{playlist.curator.fullName}</p>
                </div>
            </div>
        );
    };

    public render() {
        const { playlist } = this.props;

        return (
            <div className={css(styles.main)}>
                {this.maybeRenderSharedByBanner()}
                <Container className={css(styles.container)}>
                    <Row>
                        <Col xs={12} className={css(styles.content)}>
                            <img alt="Playlist pic" src={playlist.image} className={css(styles.playlistImage)} />
                            <div className={css(styles.contentExceptImage)}>
                                {this.renderTitle()}

                                <div className={css(styles.curatorAndDesc)}>
                                    {this.renderCurator()}

                                    <p className={css(styles.description)}>
                                        {textToFirstLinebreak(playlist.description, 200)}{' '}
                                        <span className={css(styles.readMore)} onClick={this.onReadDescriptionClick}>
                                            Read more
                                        </span>{' '}
                                    </p>
                                </div>

                                <div className={css(styles.actionButtons)}>
                                    <button
                                        type="button"
                                        className={css(styles.playButton)}
                                        onClick={this.onStartSeriesClick}>
                                        <IconPlayBlue className={css(styles.iconPlayPause)} /> Play Series
                                    </button>
                                    <button
                                        className={css(styles.queueButton)}
                                        type="button"
                                        onClick={this.toggleQueue}>
                                        {this.props.isPlaylistQueueLoading ? (
                                            <ClipLoader size={24} color="#ffffff" />
                                        ) : playlist.isQueued ? (
                                            <QueueCheckedIcon className={css(styles.queueIcon)} />
                                        ) : (
                                            <QueueUncheckedIcon className={css(styles.queueIcon)} />
                                        )}
                                    </button>
                                    <IconShareWhite
                                        className={css(styles.actionIconShare)}
                                        onClick={this.onShareClick}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {this.props.children}
            </div>
        );
    }
}

const shareBannerStyles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.medium]: {
            padding: 25,
        },
        backgroundColor: newtheme.darkmodeColors.cardNavy,
        width: '100%',
        display: 'flex',
        padding: 30,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        marginBottom: 50,
        borderRadius: 20,
    },
    text: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 22,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 20,
            marginBottom: 15,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            fontSize: 17,
        },
        margin: 0,
        fontWeight: 600,
        fontSize: 25,
        marginBottom: 20,
        textAlign: 'center',
    },
    button: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: '5',
        },
        [newtheme.cssBreakpoints.tiny]: {
            width: '100%',
        },
        ':hover': {
            backgroundColor: 'white',
            color: 'black',
            stroke: 'black',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        stroke: 'white',
        border: 'none',
        borderRadius: 10,
        fontSize: 18,
        fontWeight: 500,
        padding: '10px 40px',
        maxWidth: 300,
    },
});

const styles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.medium]: {
            paddingTop: 35,
        },
        [newtheme.cssBreakpoints.small]: {
            paddingTop: 25,
        },
        paddingTop: 50,
        paddingBottom: 50,
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        minHeight: '100vh',
    },
    header: {},
    container: {
        paddingBottom: 60,
    },
    content: {
        [newtheme.cssBreakpoints.small]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'row',
    },
    contentExceptImage: {
        [newtheme.cssBreakpoints.small]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        width: '100%',
    },
    playlistImage: {
        [newtheme.cssBreakpoints.large]: {
            height: 177,
            width: 300,
            minWidth: 300,
        },
        [newtheme.cssBreakpoints.medium]: {
            height: 148,
            width: 250,
            minWidth: 250,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 25,
            marginRight: 0,
        },
        borderRadius: 10,
        height: 207,
        width: 350,
        minWidth: 350,
        marginRight: 30,
    },
    title: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 40,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 30,
        },
        [newtheme.cssBreakpoints.small]: {
            textAlign: 'center',
        },
        fontSize: 48,
        fontWeight: 500,
        lineHeight: '140%',
        marginTop: -5,
        color: newtheme.darkmodeColors.white,
        width: '100%',
    },
    description: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 14,
        },
        [newtheme.cssBreakpoints.small]: {
            textAlign: 'center',
        },
        fontWeight: 400,
        fontSize: 18,
        color: newtheme.darkmodeColors.fadedText,
        marginBottom: 24,
        width: '100%',
    },
    readMore: {
        ':hover': {
            color: 'white',
        },
        color: newtheme.colors.primaryBlue,
        cursor: 'pointer',
    },
    curatorAndDesc: {
        [newtheme.cssBreakpoints.small]: {
            flexDirection: 'column-reverse',
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'column',
    },
    actionButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    queueButton: {
        ':hover': {
            transform: 'scale(1.3)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },

            marginLeft: 'auto',
            marginRight: 16,
        },

        background: 'none',
        outline: 'none',
        border: 'none',
        padding: '0',
        margin: '0px',
        marginRight: 18,
        lineHeight: 0,
        transition: 'transform .2s',
    },
    queueIcon: {
        width: 29,

        [newtheme.cssBreakpoints.tiny]: {
            width: 24,
        },
    },
    playButton: {
        ':hover': {
            transform: 'scale(1.1)',
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.small]: {
            marginLeft: 60,
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        border: 'none',
        fontSize: 18,
        fontWeight: 400,
        padding: '10px 20px',
        borderRadius: 24,
        marginRight: 30,
    },
    iconPlayPause: {
        height: 26,
        width: 26,
        marginRight: 5,
        fill: 'white',
    },
    actionIconShare: {
        ':hover': {
            transform: 'scale(1.5)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        height: 32,
        width: 32,
        cursor: 'pointer',
    },
    curatorWrapper: {
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 35,
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: newtheme.darkmodeColors.white,
        marginBottom: 15,
    },
    curatorImage: {
        [newtheme.cssBreakpoints.large]: {
            height: 50,
            width: 50,
        },
        [newtheme.cssBreakpoints.medium]: {
            height: 42,
            width: 42,
        },
        height: 60,
        width: 60,
        borderRadius: 30,
        marginRight: 15,
    },
    curatorTextWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    curatedByText: {
        fontSize: 14,
        color: newtheme.darkmodeColors.fadedText,
        marginBottom: 0,
    },
    curatorName: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 16,
        },
        fontSize: 18,
        fontWeight: 600,
        marginBottom: 0,
    },
});

export default ViewPlaylist;
