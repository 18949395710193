// @ts-ignore
import React from 'react';

import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { css, StyleSheet } from 'aphrodite';
import newtheme from '../../../newtheme';
import theme from '../../../theme';
import Button from '../../../components/buttons/Button';

function GoogleLoginButton({
    onSuccess,
    onFailure,
    text,
    orientation,
}: {
    onSuccess: (credentials: any) => void;
    onFailure: () => void;
    text: string;
    orientation: string;
}) {
    const onTokenResponseSuccess = async (tokenResponse: any) => {
        try {
            const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
                headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
            });
            const data = userInfo.data as any;

            onSuccess({
                googleId: data.sub,
                profileObj: {
                    sub: data.sub,
                    name: data.name,
                    givenName: data.given_name,
                    familyName: data.family_name,
                    picture: data.picture,
                    email: data.email,
                    emailVerified: data.email_verified,
                    locale: data.locale,
                },
            });
        } catch {
            onFailure();
        }
    };

    const login = useGoogleLogin({
        onSuccess: onTokenResponseSuccess,
        onError: onFailure,
    });

    return (
        <Button
            className={css(styles.googleButton, orientation === 'column' && styles.googleButtonColumn, styles.button)}
            onClick={() => login()}>
            <span>
                <FontAwesomeIcon icon={faGoogle} /> &nbsp;&nbsp; {text}
            </span>
        </Button>
    );
}

const styles = StyleSheet.create({
    button: {
        [newtheme.cssHeightBreakpoints.large]: {
            height: 40,
            minHeight: 40,
            fontSize: 14,
            marginBottom: 10,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 50,
            minHeight: 50,
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            height: 40,
            minHeight: 40,
            fontSize: 13,
        },
        fontFamily: 'Poppins',
        height: 55,
        minHeight: 55,
        borderRadius: 10,
        fontWeight: 'normal',
        fontSize: 18,
        marginBottom: 20,
    },

    googleButton: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            width: '100%',
            minWidth: '100%',
            marginBottom: 10,
            marginLeft: 0,
            flex: 0,
        },
        flex: 1,
        marginLeft: 5,
        background: newtheme.colors.googlePrimary,
        color: '#fff',
    },

    googleButtonColumn: {
        marginLeft: 0,
        marginBottom: 20,
    },
});

export default GoogleLoginButton;
