import * as _ from 'lodash';

import { isServer } from '../../store';

export enum EventCategories {
    SLIDE = 'slide',
    ARTICLE = 'article',
    PLAYLIST = 'playlist',
    STORY = 'story',
    JOURNALIST = 'journalist',
    SECTION = 'section',
    PUBLISHER = 'publisher',
    POPUP = 'popup',
    OTHER = 'other',
    AUDIO = 'audio',
    SEARCH = 'search',
    EMBED_PLAYER = 'embed-player',
    ACCOUNT = 'account',
    SUBSCRIBE = 'subscribe',
    LATEST = 'latest',
    SIGN = 'sign',
    SIGNUP = 'signup',
    BUTTON = 'button',
    INPUT = 'input',
    CORPORATE = 'corporate',
}

export enum EventActions {
    SHOW = 'show',
    TAP = 'select',
    VIEW = 'view',
    SELECT = 'select',
    CLOSE = 'close',
    NEXT = 'next',
    PREVIOUS = 'previous',
    END = 'end',
    DRAG = 'drag',
    PLAY = 'play',
    PLAY_ALL = 'play-all',
    PAUSE = 'pause',
    SHARE = 'share',
    FORBID = 'forbid',
    TYPE = 'type',
    SCROLL = 'scroll',
    FOLLOW = 'follow',
    UNFOLLOW = 'unfollow',
    OPEN = 'open',
    SUCCESS = 'success',
    LISTEN = 'listen',
}

export enum EventLabels {
    MODAL_CANT_PLAY_ARTICLE_GO_PREMIUM = 'modal-cantplayarticle-gopremium',
    MODAL_CANT_PLAY_ARTICLE_CLOSE = 'modal-cantplayarticle-close',
    MODAL_END_FREE_TRIAL_GO_PREMIUM = 'modal-endfreetrial-gopremium',
    MODAL_SHOW_MORE_DESCRIPTION = 'modal-show-more-description',
    MODAL_END_FREE_TRIAL_CANCEL = 'modal-endfreetrial-cancel',
    MODAL_PREMIUM_GO_PREMIUM = 'modal-premium-gopremium',
    MODAL_PREMIUM_CLOSE = 'modal-premium-close',
    MODAL_SIGNIN_SIGNUP = 'modal-signin-signup',
    MODAL_SIGNIN_LOGIN = 'modal-signin-login',
    MODAL_SHARE = 'modal-share',
    MODAL_SELECT_SUBSCRIPTION_FREE_TRIAL = 'modal-selectsubscription-freetrial',
    MODAL_SELECT_SUBSCRIPTION_GOPREMIUM = 'modal-selectsubscription-gopremium',
    HOME = 'home',
    LATEST = 'latest',
    SEARCH = 'search',
    LIBRARY = 'library',
    SETTINGS = 'settings',
    LOGIN = 'login',
    SIGNUP = 'signup',
    EMBED_PLAYER_SPEED_UP = 'embed-player-speed-up',
    EMBED_PLAYER_SPEED_DOWN = 'embed-player-speed-down',
    EMBED_PLAYER_FEEDBACK = 'embed-player-feedback',
    EMBED_PLAYER_LISTEN_ON_NOA = 'embed-player-listen-on-noa',
    EMBED_PLAYER_POWERED_BY_NOA = 'embed-player-powered-by-noa',
    ALREADY_HAVE_ACCOUNT = 'already-have-account',
    DONT_HAVE_ACCOUNT = 'dont-have-account',
    FORGOT_PASSWORD = 'forgot-password',
    GO_BACK = 'go-back',
    POST_CODE = 'post-code',
    LISTEN_ON_THE_NOA_APP = 'listen-on-noa-app',
}

interface IEvent {
    name: string | EventCategories;
    action: EventActions;
    label?: string | EventLabels;
    value?: number;
}
let eventsQueue: IEvent[] = [];

export const unsetUser = () => {
    if (isServer) {
        return;
    }

    // @ts-ignore
    const { ga } = window;
    // @ts-ignore
    const log = window.noaLog;

    if (ga) {
        ga('set', 'userId', null);

        if (log) {
            console.log('UNSET USER');
        }
    }
};

export const setUser = (userId: number) => {
    if (isServer || !userId) {
        return;
    }

    // @ts-ignore
    const { ga } = window;
    // @ts-ignore
    const log = window.noaLog;

    if (ga) {
        ga('set', 'userId', userId);

        if (log) {
            console.log('SET USER: ', userId);
        }
    }
};

export const event = (name: string | EventCategories, action: EventActions, label?: string | EventLabels, value?: number) => {
    if (isServer) {
        return;
    }

    const labelStr = label ? _.kebabCase(label) : '';
    // @ts-ignore
    const { ga } = window;
    // @ts-ignore
    const log = window.noaLog;

    eventsQueue.push({
        name, action, label: labelStr, value,
    });

    if (ga) {
        eventsQueue.forEach((e) => {
            ga('send', 'event', e.name, e.action, e.label, e.value);

            if (log) {
                console.log('EVENT: ', e.name, e.action, e.label, e.value);
            }
        });

        eventsQueue = [];
    }
};
