import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import {
    CANCEL_SUBSCRIPTION_FAILURE,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    INITIATE_SUBSCRIPTION_FAILURE,
    INITIATE_SUBSCRIPTION_REQUEST,
    INITIATE_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_PLANS_REQUEST,
    GET_SUBSCRIPTION_PLANS_FAILURE,
    GET_SUBSCRIPTION_PLANS_SUCCESS,
    APPLY_DISCOUNT_FAILURE,
    APPLY_DISCOUNT_SUCCESS,
    APPLY_DISCOUNT_REQUEST,
    APPLY_DISCOUNT_CLEAR,
    APPLY_DISCOUNT_APPLIED,
    RESET_SUBSCRIPTION,
    SUBSCRIPTION_PAYMENT_FAILED,
    SUBSCRIPTION_PAYMENT_SUCCEEDED,
} from '../constants/subscription';
import { isServer } from '../../../../../store';
import ISubscribeRequest from '../../../../types/request/ISubscribeRequest';
import responseCheck from '../../../../utils/response-check';
import config from '../../../../config';
import text from '../../../../locale';
import fetch from '../../../../utils/fetch';
import { IState } from '../../../../reducers';
import { openModal } from '../../../../actions/modal';
import ModalTypes from '../../../../types/ModalTypes';
import { mpTrackStripeError } from '../../../../tracking/mixpanel';
import { getSubscriptionErrorMessage } from '../../../../utils/subscription';

export const cancelSubscription = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: CANCEL_SUBSCRIPTION_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.subscription.cancel}`;

    return fetch(getState)(requestUrl, { method: 'PUT' })
        .then(responseCheck)
        .then(() => dispatch(cancelSubscriptionSuccess()))
        .catch((error) => dispatch(cancelSubscriptionFailure(error)));
};

export const inititateSubscription = (params: ISubscribeRequest) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: INITIATE_SUBSCRIPTION_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.subscription.subscribe}`;
    const token = isServer
        ? new Buffer(`${params.authToken}:${params.authToken}`, 'binary').toString('base64')
        : btoa(`${params.authToken}:${params.authToken}`);

    return fetch(getState)(requestUrl, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'Content-Type': 'application/json',
            authorization: `Basic ${token}`,
        },
    })
        .then((response) => responseCheck(response, { returnErrorBody: true }))
        .then((response) => dispatch(initiateSubscriptionSuccess(response)))
        .catch((error) => {
            if (error && error.errors?.length) {
                mpTrackStripeError({
                    errorCode: error.errors[0].code,
                    declineCode: error.errors[0].decline_code,
                    stripeProductId: params.paymentDetails.stripeProductId,
                    currency: params.paymentDetails.currency,
                    stripePriceId: params.paymentDetails.stripePriceId,
                    cardBrand: params.paymentMethod.card?.brand,
                    errorMessage: getSubscriptionErrorMessage(error.errors[0]).keyMessage,
                });
            }

            dispatch(initiateSubscriptionFailure(error));
            openModal(ModalTypes.SubscriptionError)(dispatch);
        });
};

export const getSubscriptionProduct = (offerId?: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: GET_SUBSCRIPTION_PLANS_REQUEST });

    let requestUrl = `${config.api.url}${config.api.routes.subscription.product}`;
    requestUrl += offerId ? `?offerId=${offerId}` : '';

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => dispatch(getSubscriptionsSuccess(response)))
        .catch((error) => dispatch(getSubscriptionsFailure(error)));
};

export const applyDiscountCode = (code: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: APPLY_DISCOUNT_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.subscription.discount}?code=${code}`;

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => dispatch(applyDiscountSuccess(response)))
        .catch((error) => dispatch(applyDiscountFailure(error)));
};

const triggerSubscribeScaSuccessTag = () => {
    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'WebSubscriptionSCASuccess',
        });
    }
};

const triggerSubscribeScaFailureTag = () => {
    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'WebSubscriptionSCAFailure',
        });
    }
};

const triggerSubscribeInitiatedTag = () => {
    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'WebSubscriptionSuccess',
        });
    }
};

export const clearDiscountCode = () => (dispatch: Dispatch) => {
    dispatch({ type: APPLY_DISCOUNT_CLEAR });
};

export const setDiscountCodeApplied = () => (dispatch: Dispatch) => {
    dispatch({ type: APPLY_DISCOUNT_APPLIED });
};

export const resetSubscription = () => (dispatch: Dispatch) => {
    dispatch({ type: RESET_SUBSCRIPTION });
};

export const onPaymentSucceeded = () => (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_PAYMENT_SUCCEEDED });
    triggerSubscribeScaSuccessTag();
};

export const onPaymentFailed = () => (dispatch: Dispatch) => {
    dispatch({ type: SUBSCRIPTION_PAYMENT_FAILED });
    triggerSubscribeScaFailureTag();
};

const cancelSubscriptionSuccess = () => {
    toast(text.notifications.YOUR_SUBSCRIPTION_CANCELLED);
    return { type: CANCEL_SUBSCRIPTION_SUCCESS };
};

const cancelSubscriptionFailure = (error: any) => {
    toast(text.notifications.THERE_WAS_A_PROBLEM_TO_CANCEL_YOUR_SUBSCRIPTION);
    return { type: CANCEL_SUBSCRIPTION_FAILURE, error };
};

const initiateSubscriptionSuccess = (response: any) => {
    const { confirmPayment } = response.data;
    triggerSubscribeInitiatedTag();
    return { type: INITIATE_SUBSCRIPTION_SUCCESS, confirmPayment };
};

const initiateSubscriptionFailure = (error: Error) => ({ type: INITIATE_SUBSCRIPTION_FAILURE, error });

const getSubscriptionsSuccess = (response) => ({
    type: GET_SUBSCRIPTION_PLANS_SUCCESS,
    subscriptionProductResponse: response.data,
});

const getSubscriptionsFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: GET_SUBSCRIPTION_PLANS_FAILURE, error };
};

const applyDiscountSuccess = (response) => ({ type: APPLY_DISCOUNT_SUCCESS, discountUrl: response.data.discountUrl });

const applyDiscountFailure = (error: Error) => ({ type: APPLY_DISCOUNT_FAILURE, error });
