import * as React from 'react';

interface IProps {
    className?: string;
    textFill?: string;
}

class IconNewNoaLogo extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="145"
                height="46"
                viewBox="0 0 145 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={this.props.className}>
                <path
                    d="M72.5473 9.30347C75.6416 9.30347 78.1235 10.3027 79.993 12.3011C81.8625 14.2996 82.7973 17.1522 82.7973 20.8589V36.379H77.2855V21.4391C77.2855 19.0539 76.6731 17.2166 75.4482 15.9273C74.2234 14.6058 72.5473 13.945 70.4199 13.945C68.2281 13.945 66.4714 14.6541 65.1498 16.0724C63.8283 17.4584 63.1675 19.5051 63.1675 22.2127V36.379H57.6557V9.73861H63.1675V15.5889C63.8766 13.5904 65.0692 12.0433 66.7454 10.9473C68.4215 9.85142 70.3554 9.30347 72.5473 9.30347Z"
                    fill={this.props.textFill || '#031629'}
                />
                <path
                    d="M100.46 9.40016C103.039 9.40016 105.36 9.94812 107.423 11.044C109.485 12.14 111.097 13.7194 112.257 15.7823C113.418 17.8452 113.998 20.2626 113.998 23.0346C113.998 25.8389 113.418 28.2725 112.257 30.3354C111.097 32.3983 109.485 33.9777 107.423 35.0736C105.36 36.1695 103.039 36.7175 100.46 36.7175C97.8816 36.7175 95.5609 36.1695 93.498 35.0736C91.4673 33.9777 89.8556 32.3983 88.663 30.3354C87.5026 28.2402 86.9225 25.8067 86.9225 23.0346C86.9225 20.2626 87.5026 17.8452 88.663 15.7823C89.8556 13.7194 91.4673 12.14 93.498 11.044C95.5609 9.94812 97.8816 9.40016 100.46 9.40016ZM100.46 14.1867C99.042 14.1867 97.7366 14.5091 96.5439 15.1537C95.3513 15.7984 94.3844 16.7976 93.643 18.1514C92.9339 19.4729 92.5793 21.1007 92.5793 23.0346C92.5793 25.0008 92.9339 26.6447 93.643 27.9663C94.3844 29.2878 95.3513 30.2709 96.5439 30.9156C97.7366 31.5602 99.042 31.8825 100.46 31.8825C101.878 31.8825 103.184 31.5602 104.377 30.9156C105.569 30.2709 106.52 29.2878 107.229 27.9663C107.97 26.6447 108.341 25.0008 108.341 23.0346C108.341 21.1007 107.97 19.4729 107.229 18.1514C106.52 16.7976 105.569 15.7984 104.377 15.1537C103.184 14.5091 101.878 14.1867 100.46 14.1867Z"
                    fill={this.props.textFill || '#031629'}
                />
                <path
                    d="M129.015 9.40016C131.433 9.40016 133.496 9.99647 135.204 11.1891C136.912 12.3817 138.121 13.9611 138.83 15.9273V9.73861H144.342V36.379H138.83V30.1903C138.121 32.1565 136.912 33.7359 135.204 34.9285C133.496 36.1212 131.433 36.7175 129.015 36.7175C126.695 36.7175 124.616 36.1695 122.778 35.0736C120.973 33.9777 119.555 32.3983 118.524 30.3354C117.492 28.2725 116.976 25.8389 116.976 23.0346C116.976 20.2626 117.492 17.8452 118.524 15.7823C119.555 13.7194 120.973 12.14 122.778 11.044C124.616 9.94812 126.695 9.40016 129.015 9.40016ZM130.708 14.2351C128.258 14.2351 126.292 15.0248 124.809 16.6042C123.358 18.1514 122.633 20.2949 122.633 23.0346C122.633 25.7744 123.358 27.934 124.809 29.5134C126.292 31.0606 128.258 31.8342 130.708 31.8342C132.255 31.8342 133.641 31.4796 134.866 30.7705C136.09 30.0292 137.057 28.9977 137.767 27.6762C138.476 26.3546 138.83 24.8074 138.83 23.0346C138.83 21.2941 138.476 19.763 137.767 18.4415C137.057 17.0877 136.09 16.0562 134.866 15.3471C133.641 14.6058 132.255 14.2351 130.708 14.2351Z"
                    fill={this.props.textFill || '#031629'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M42.77 30.2286C41.3817 34.2896 38.8675 37.8722 35.5205 40.5585C32.1735 43.2449 28.1317 44.9243 23.8665 45.4009C19.6013 45.8775 15.2885 45.1316 11.4312 43.2502C10.5989 42.8443 10.2533 41.8406 10.6592 41.0083C11.0651 40.1761 12.0689 39.8305 12.9011 40.2364C16.187 41.8391 19.8609 42.4744 23.4942 42.0685C27.1275 41.6625 30.5705 40.2319 33.4216 37.9435C36.2727 35.6551 38.4145 32.6033 39.5971 29.1439C40.7797 25.6846 40.9544 21.9603 40.1008 18.4054C39.2472 14.8506 37.4005 11.6117 34.7761 9.06644C32.1516 6.52123 28.8577 4.77464 25.2783 4.03036C21.699 3.28608 17.9818 3.5748 14.5603 4.86284C12.3712 5.68692 10.3609 6.89832 8.62059 8.4288C8.89105 8.98315 9.04283 9.60602 9.04283 10.2643C9.04283 12.5792 7.16626 14.4558 4.8514 14.4558C2.53654 14.4558 0.659973 12.5792 0.659973 10.2643C0.659973 7.94948 2.53654 6.07291 4.8514 6.07291C5.26312 6.07291 5.66097 6.13228 6.03686 6.24291C8.16286 4.2889 10.6528 2.75095 13.3789 1.7247C17.3954 0.212653 21.7591 -0.126277 25.9609 0.747439C30.1628 1.62116 34.0296 3.67151 37.1105 6.65936C40.1913 9.64721 42.3592 13.4494 43.3613 17.6225C44.3633 21.7956 44.1582 26.1676 42.77 30.2286ZM17.0905 17.667V28.9573C17.0905 30.1498 18.3751 30.9005 19.414 30.3152L29.4342 24.67C30.4922 24.0739 30.4922 22.5504 29.4342 21.9543L19.414 16.3091C18.3751 15.7238 17.0905 16.4745 17.0905 17.667Z"
                    fill="#039BE5"
                />
            </svg>
        );
    }
}

export default IconNewNoaLogo;
