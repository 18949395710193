import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Col, Container, Row } from 'reactstrap';
import newtheme from '../../../newtheme';
import LandingEmbedAction from './LandingEmbedAction';
import ArticleTabsContainer from '../../articletabs/containers/ArticleTabsContainer';
import FrequentQuestions from '../../../components/landingpagenew/FrequentQuestions';
import LandingEmbedVideo from './LandingEmbedVideo';
import IconHeadphones from '../../../assets/icons/IconHeadphones';
import IconQuestionMark from '../../../assets/icons/IconQuestionMark';
import IconCompass from '../../../assets/icons/IconCompass';

interface IProps {
    onSignupClicked: () => void;
    onSignupClickedNavToSignupSuccess: () => void;
}

interface IState {
    contentIsExpanded: boolean;
    faqIsExpanded: boolean;
}

class LandingEmbedActions extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = { contentIsExpanded: false, faqIsExpanded: false };
    }

    public onShowContentClicked = () => {
        this.setState((prevState) => ({ contentIsExpanded: !prevState.contentIsExpanded, faqIsExpanded: false }));
    };

    public onShowFaqClicked = () => {
        this.setState((prevState) => ({ faqIsExpanded: !prevState.faqIsExpanded, contentIsExpanded: false }));
    };

    public render() {
        const { faqIsExpanded, contentIsExpanded } = this.state;

        return (
            <Container>
                <Row>
                    <Col>
                        <h4 className={css(styles.title)}>So, what do you want to do next?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 4, order: 1 }} className={css(styles.action)}>
                        <LandingEmbedAction expandOnClick={false} onClick={this.props.onSignupClicked}>
                            <IconHeadphones className={css(styles.topLeftIcon)} />
                            <p className={css(styles.text)}>I want to start listening!</p>
                            <p className={css(styles.text, styles.highlightedText)}>Sign up to get started</p>
                        </LandingEmbedAction>
                    </Col>
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 4, order: 2 }} className={css(styles.action, faqIsExpanded && styles.expandedAction)}>
                        <LandingEmbedAction isExpanded={faqIsExpanded} expandOnClick onClick={this.onShowFaqClicked}>
                            <IconQuestionMark className={css(styles.topLeftIcon)} />
                            <p className={css(styles.text)}>I have questions and would like</p>
                            <p className={css(styles.text, styles.highlightedText, faqIsExpanded && styles.selectedText)}>to know more about Noa</p>
                        </LandingEmbedAction>
                    </Col>
                    <Col xs={{ size: 12, order: 4 }} md={{ size: 4, order: 3 }} className={css(styles.action, contentIsExpanded && styles.expandedAction)}>
                        <LandingEmbedAction isExpanded={contentIsExpanded} expandOnClick onClick={this.onShowContentClicked}>
                            <IconCompass className={css(styles.topLeftIcon)} />
                            <p className={css(styles.text)}>I&apos;m curious and would like to</p>
                            <p className={css(styles.text, styles.highlightedText, contentIsExpanded && styles.selectedText)}>explore Noa&apos;s content.</p>
                        </LandingEmbedAction>
                    </Col>
                    {faqIsExpanded && (
                        <Col xs={{ size: 12, order: 3 }} md={{ order: 4 }}>
                            <div className={css(styles.expandedView)}>
                                <LandingEmbedVideo />
                                <FrequentQuestions mode="LANDING_EMBED" onSignupClicked={this.props.onSignupClicked} />
                            </div>
                        </Col>
                    )}
                    {contentIsExpanded && (
                        <Col xs={{ size: 12, order: 5 }}>
                            <div className={css(styles.expandedView)}>
                                <ArticleTabsContainer title="Here are some similar articles that we thought you&apos;d enjoy." backgroundColor="#081E31" />

                                <Col xs={12} sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }} className={css(styles.buttonAndText)}>
                                    <p className={css(styles.textAboveButton)}>Why not try listening in the Noa app?</p>
                                    <button type="button" onClick={this.props.onSignupClickedNavToSignupSuccess} className={css(styles.buttonBottom, styles.blueButton)}>Claim free 7-day trial</button>
                                </Col>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        );
    }
}

// To make initial loading look better
const styles = StyleSheet.create({
    topLeftIcon: {
        height: 24,
        width: 24,
        marginBottom: 12,
    },
    title: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 20,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 16,
        },
        marginTop: 10,
        fontSize: 24,
        fontWeight: 400,
        paddingBottom: 5,
        color: newtheme.colors.white,
    },
    text: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 14,
        },
        marginBottom: 0,
        fontSize: 16,
    },
    highlightedText: {
        color: newtheme.colors.primaryBlue,
    },
    selectedText: {
        fontWeight: 600,
        color: newtheme.colors.white,
    },
    expandedAction: {
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 0,
        },
    },
    expandedView: {
        [newtheme.cssBreakpoints.small]: {
            borderTop: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            marginTop: 0,
        },
        border: `4px solid ${newtheme.colors.primaryBlue}`,
        borderRadius: 20,
        paddingTop: 30,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 30,
        marginBottom: 20,
        backgroundColor: '#081E31',
    },
    action: {
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 20,
        },
    },
    buttonAndText: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 30,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 45,
    },
    textAboveButton: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 10,
        },
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 15,
    },
    buttonBottom: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 17,
            padding: '15px 30px',
            borderRadius: 10,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
            padding: '10px 13px',
        },
        ':hover': {
            backgroundColor: 'white',
            color: 'black',
            stroke: 'black',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(3, 155, 229, 0.17)',
        color: 'white',
        stroke: 'white',
        border: 'none',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: 500,
        padding: '15px 10px',
        width: '100%',
    },
    blueButton: {
        backgroundColor: newtheme.colors.primaryBlue,
    },
});

export default LandingEmbedActions;
