import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Col, Container, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Icon5Stars from '../../assets/icons/Icon5Stars';
import newtheme from '../../newtheme';
import ReviewCard, { IReview } from './ReviewCard';

const { Carousel } = require('react-responsive-carousel');

interface IProps {}

class Reviews extends React.Component<IProps> {
    public getReviews = () => {
        const reviews: IReview[] = [
            {
                reviewText:
                    'Hearing every side of the story from multiple sources makes it easy to truly understand what’s happening in the world.',
                reviewerName: 'Bronagh Cassidy',
                reviewSource: 'App Store Review',
            },
            {
                reviewText:
                    "Great narrators, they're on a par with the best in the audiobook business. They deliver the stories very well.",
                reviewerName: 'Gerard Doyle',
                reviewSource: 'Play Store Review',
            },
            {
                reviewText:
                    'The way Noa arranges articles into short playlists (which they call “Series”) is brilliant! These Series make it easy to stay on top of all the news around the world.',
                reviewerName: 'Matt Loughty',
                reviewSource: 'App Store Review',
            },
        ];

        return reviews;
    };

    public renderDesktopReviews = () => {
        const reviews = this.getReviews();
        return (
            <Row className={css(styles.fullWidth)}>
                {reviews.map((review, index) => (
                    <Col
                        key={index}
                        md={6}
                        lg={4}
                        className={index === reviews.length - 1 ? css(styles.hideOnMedium) : css(styles.reviewCard)}>
                        <ReviewCard review={review} />
                    </Col>
                ))}
            </Row>
        );
    };

    public renderSelectedIndicator = (index, label) => (
        <li
            className={css(mobileReviewStyles.carouselIndicator, mobileReviewStyles.carouselIndicatorSelected)}
            aria-label={`Selected: ${label} ${index + 1}`}
            title={`Selected: ${label} ${index + 1}`}
        />
    );

    public renderUnselectedIndicator = (index, label) => (
        <li
            className={css(mobileReviewStyles.carouselIndicator)}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            title={`${label} ${index + 1}`}
            aria-label={`${label} ${index + 1}`}
        />
    );

    public renderMobileReviews = () => (
        <Carousel
            className={css(mobileReviewStyles.carousel)}
            scrollable
            showArrows={false}
            showStatus={false}
            swipeScrollTolerance={5}
            showThumbs={false}
            // tslint:disable-next-line: jsx-no-lambda
            renderIndicator={(onClickHandler, isSelected, index, label) =>
                isSelected ? this.renderSelectedIndicator(index, label) : this.renderUnselectedIndicator(index, label)
            }>
            {this.getReviews().map((review, value) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={value} className={css(mobileReviewStyles.carouselReviewAndIndicator)}>
                    <ReviewCard review={review} />
                </div>
            ))}
        </Carousel>
    );

    public render() {
        return (
            <Container className={css(styles.main)}>
                <Row className={css(styles.fullWidth)}>
                    <Col xs={{ size: 12, offset: 0 }} lg={{ size: 8, offset: 2 }} className={css(styles.center)}>
                        <Icon5Stars className={css(styles.starReviewIcon)} />
                        <h5 className={css(styles.starReviewText)}>HUNDREDS OF 5-STAR REVIEWS</h5>

                        <h2 className={css(styles.topReview)}>
                            <span className={css(styles.quotationMark)}>“</span>
                            The best app to explore audio journalism!
                            <span className={css(styles.quotationMark)}>”</span>
                        </h2>

                        <p className={css(styles.topReviewName)}>Carrie Hamelin</p>
                        <p className={css(styles.topReviewSource)}>App Store Review</p>
                    </Col>
                </Row>

                {isMobile ? this.renderMobileReviews() : this.renderDesktopReviews()}
            </Container>
        );
    }
}

const mobileReviewStyles = StyleSheet.create({
    carousel: {
        borderRadius: 10,
        backgroundColor: '#031629',
        width: '100%',
    },
    carouselReviewAndIndicator: {
        backgroundColor: '#031629',
        width: '100%',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 40,
    },
    carouselIndicator: {
        height: 6,
        width: 6,
        borderRadius: 3,
        backgroundColor: '#C4C4C4',
        display: 'inline-block',
        margin: '0px 5px',
        paddingBottom: 0,
    },
    carouselIndicatorSelected: {
        backgroundColor: newtheme.colors.primaryBlue,
        width: 19,
    },
});

const styles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.small]: {
            paddingBottom: 120,
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 100,
        },
        paddingTop: 80,
        paddingBottom: 160,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    hideOnMedium: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            display: 'none',
        },
    },
    reviewCard: {
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 40,
        },
    },
    center: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    starReviewIcon: {
        [newtheme.cssBreakpoints.tiny]: {
            width: 170,
            height: 29,
            marginBottom: 15,
        },
        width: 210,
        height: 53,
        marginBottom: 35,
    },
    starReviewText: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 14,
            marginBottom: 25,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 12,
            marginBottom: 25,
        },
        fontWeight: 400,
        fontSize: 17,
        letterSpacing: '0.18em',
        marginBottom: 30,
    },
    topReview: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 40,
            marginBottom: 20,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 36,
            marginBottom: 10,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 36,
            marginBottom: 10,
        },
        textAlign: 'center',
        fontSize: 48,
        fontWeight: 500,
        marginBottom: 32,
        width: '100%',
    },
    topReviewName: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 19,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 17,
            marginBottom: 0,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 17,
            marginBottom: 0,
        },
        fontWeight: 600,
        fontSize: 21,
        margin: 0,
        marginBottom: 5,
    },
    topReviewSource: {
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 15,
            marginBottom: 40,
        },
        fontWeight: 400,
        fontSize: 18,
        margin: 0,
        marginBottom: 73,
    },
    quotationMark: {
        color: newtheme.colors.primaryBlue,
    },
    fullWidth: {
        width: '100%',
    },
});

export default Reviews;
