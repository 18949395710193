import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import text from '../../../locale/index';
import theme from '../../../theme/index';
import BaseModal from './BaseModal';
import { IState } from '../../../reducers';
import { closeModal, openModal } from '../../../actions/modal';
import { sendSMS, reset } from '../../../actions/sms';
import config from '../../../config';
import { mpTrackPopupView } from '../../../tracking/mixpanel';
import { mpTrackingPopups } from '../../../config/popups';
import { cancelSubscription } from '../../../domaindriven/subscribe/state/actions/subscription';

interface IProps { }

interface IPropsFromState {
    isOpen: boolean;
}

interface IPropsFromDispatch {
    closeModal: () => void;
}

class ModalPremiumContent extends React.Component<IProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps> {
    public onClose = () => {
        this.props.closeModal();
    };

    public componentDidMount = () => {
        mpTrackPopupView(mpTrackingPopups.premiumPublisher);
    };

    public render() {
        const { isOpen } = this.props;

        return (
            <BaseModal modalId="ModalGetTheApp" isOpen={isOpen} onClose={this.onClose}>
                <div className={css(styles.modalWrapper)}>
                    <div className={css(styles.premium)}>
                        <span>Noa Premium</span>
                    </div>

                    <h1 className={css(styles.title)}>{text.modal.premiumContent.TITLE}</h1>

                    <p>
                        Enjoy
                        {' '}
                        <span className={css(styles.highlightedWord)}>unlimited listening</span>
                        {' '}
                        to these free tier publishers
                    </p>
                    <div>
                        <img alt="The Independent (UK) logo" className={css(styles.publisherImg)} src="https://images.newsoveraudio.com/publishers/icon/large/1548844732669_indie_3x.png" />
                        <img alt="The Irish Times logo" className={css(styles.publisherImg)} src="https://images.newsoveraudio.com/publishers/icon/large/1575822067527_irish_times_large_logo.png" />
                        <img alt="Insider logo" className={css(styles.publisherImg)} src="https://images.newsoveraudio.com/publishers/icon/large/1548844560278_bi_3x.png" />
                        <img alt="Bloomberg logo" className={css(styles.publisherImg)} src="https://images.newsoveraudio.com/publishers/icon/large/1548799903673_bloomberg_3x.png" />
                        <img
                            alt="Foreign Affairs logo"
                            className={css(styles.publisherImg)}
                            src="https://noa-prod-images.s3-eu-west-1.amazonaws.com/publishers/icon/large/1574244159811_foreign_affairs___white___large_logo.jpg"
                        />
                        <img alt="Washington Post logo" className={css(styles.publisherImg)} src="https://images.newsoveraudio.com/publishers/icon/large/1567007525784_wp_14.png" />
                    </div>

                    <div className={css(styles.plusWrapper)}>
                        <p className={css(styles.plus)}>+</p>
                    </div>

                    <p>All of these Premium publishers</p>
                    <div>
                        <img alt="The Telegraph logo" className={css(styles.publisherImgPremium)} src="https://images.newsoveraudio.com/publishers/icon/large/1554730124602_tg_3x.png" />
                        <img alt="The Economist logo" className={css(styles.publisherImgPremium)} src="https://images.newsoveraudio.com/publishers/icon/large/1548844615445_economist_3x.png" />
                        <img
                            alt="The New York Times logo"
                            className={css(styles.publisherImgPremium)}
                            src="https://noa-prod-images.s3-eu-west-1.amazonaws.com/publishers/icon/large/1548844795544_nyt_3x.png"
                        />
                        <img
                            alt="The TLS logo"
                            className={css(styles.publisherImgPremium)}
                            src="https://images.newsoveraudio.com/publishers/icon/large/1589495077340_tls_logo_square.jpg"
                        />
                        <img alt="Harvard Business Review logo" className={css(styles.publisherImgPremium)} src="https://images.newsoveraudio.com/publishers/icon/large/1561631587503_hbr_3x.png" />
                    </div>

                    <p className={css(styles.cancelAnytimeText)}>
                        And of course, you can
                        {' '}
                        <span className={css(styles.highlightedWord)}>cancel anytime</span>
                    </p>

                    <button type="button" onClick={this.props.closeModal} className={css(styles.button, styles.buttonBack)}>
                        Close
                    </button>
                    <Link to={config.routes.subscribeDefaultOffer}>
                        <button type="button" onClick={this.props.closeModal} className={css(styles.button)}>
                            Go Premium
                        </button>
                    </Link>
                </div>
            </BaseModal>
        );
    }
}

const styles = StyleSheet.create({
    modalWrapper: {
        [`@media (max-width: ${theme.breakpoints.small}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 800px)`]: {
            fontSize: 16,
            paddingBottom: 10,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 650px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 13,
        },
        maxWidth: '100%',
        width: 600,
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 20,
        paddingBottom: 20,
        fontSize: 18,
    },
    premium: {
        backgroundColor: theme.premium,
        borderTopLeftRadius: 5,
        borderBottomRightRadius: 5,
        position: 'absolute',
        top: 0,
        left: 0,
        padding: '5px 15px',
        color: 'white',
        fontWeight: 500,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.small}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 800px)`]: {
            fontSize: 28,
            marginBottom: 35,
            marginTop: 20,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 650px)`]: {
            fontSize: 20,
            fontWeight: 500,
            marginBottom: 25,
        },
        fontSize: 35,
        marginTop: 25,
        marginBottom: 40,
    },
    publisherImg: {
        [`@media (max-width: ${theme.breakpoints.small}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 800px)`]: {
            height: 60,
            width: 60,
            borderRadius: 30,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 650px)`]: {
            height: 46,
            width: 46,
            borderRadius: 23,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 40,
            width: 40,
            borderRadius: 20,
        },
        height: 70,
        width: 70,
        borderRadius: 35,
        marginLeft: -5,
        marginRight: -5,
        border: '1px solid black',
    },
    publisherImgPremium: {
        [`@media (max-width: ${theme.breakpoints.small}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 800px)`]: {
            height: 70,
            width: 70,
            borderRadius: 35,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 650px)`]: {
            height: 56,
            width: 56,
            borderRadius: 28,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 50,
            width: 50,
            borderRadius: 25,
        },
        height: 80,
        width: 80,
        borderRadius: 40,
        marginLeft: -5,
        marginRight: -5,
        border: `1px solid ${theme.premium}`,
    },
    highlightedWord: {
        color: theme.premium,
        fontWeight: 500,
    },
    plus: {
        [`@media (max-width: ${theme.breakpoints.small}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 800px)`]: {
            fontSize: 70,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 650px)`]: {
            fontSize: 60,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 50,
        },
        fontSize: 80,
        color: theme.premium,
        magin: 0,
        padding: 0,
        lineHeight: 1,
    },
    plusWrapper: {
        magin: 0,
        padding: 0,
    },
    cancelAnytimeText: {
        marginTop: 50,
    },
    button: {
        [`@media (max-width: ${theme.breakpoints.small}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 800px)`]: {
            height: 40,
            fontSize: 18,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px), (min-width: ${theme.breakpoints.small}px) and (max-height: 650px)`]: {
            height: 38,
            marginLeft: 5,
            marginRight: 5,
            fontSize: 16,
            paddingLeft: 20,
            paddingRight: 20,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 35,
            fontSize: 15,
        },
        border: 'none',
        backgroundColor: theme.premium,
        color: 'white',
        height: 45,
        borderRadius: 5,
        fontSize: 20,
        fontWeight: 400,
        marginTop: 10,
        marginBottom: 5,
        paddingLeft: 35,
        paddingRight: 35,
        marginLeft: 10,
        marginRight: 10,
    },
    buttonBack: {
        backgroundColor: '#777777',
        paddingLeft: 15,
        paddingRight: 15,
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isOpen: state.modal.isOpen,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({
        closeModal, cancelSubscription, openModal, sendSMS, reset,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalPremiumContent));
