import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { IState } from '../../../reducers';
import BaseModal from './BaseModal';
import Button from '../../buttons/Button';
import newtheme from '../../../newtheme';
import { getSubscriptionErrorMessage } from '../../../utils/subscription';
import ErrorCrossRoundedIcon from '../../../assets/icons/ErrorCrossRoundedIcon';

interface IProps {
    close: () => void;
}

interface IPropsFromState {
    subscriptionError?: { [key: string]: any };
    isOpen: boolean;
}
interface IPropsFromDispatch {}

class SubscriptionErrorModal extends React.Component<IPropsFromState & RouteComponentProps & IProps> {
    public renderContent = () => {
        const { subscriptionError } = this.props;
        if (!subscriptionError || !subscriptionError.errors?.length) return null;

        const { keyMessage, solution } = getSubscriptionErrorMessage(subscriptionError.errors[0]);

        return (
            <div className={css(styles.modalWrapper)}>
                <div className={css(styles.errorIconWrapper)}>
                    <ErrorCrossRoundedIcon />
                </div>
                <div>
                    <p className={css(styles.keyMessage)}>{keyMessage}</p>
                    <p className={css(styles.solution)}>{solution}</p>
                    <Button onClick={this.props.close} styleObj={styles.button}>
                        OK
                    </Button>
                </div>
            </div>
        );
    };

    public render() {
        const { close, isOpen } = this.props;

        return (
            <BaseModal
                modalId="ModalSubscriptionError"
                isOpen={isOpen}
                onClose={close}
                title={'Subscription Failed'}
                height="EXTRA_LARGE"
                scrollable={false}>
                {this.renderContent()}
            </BaseModal>
        );
    }
}

const styles = StyleSheet.create({
    modalWrapper: {
        maxWidth: '100%',
        width: 600,
        padding: '30px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    keyMessage: {
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 22,
            lineHeight: '24px',
        },
        fontSize: 26,
        fontWeight: 500,
        lineHeight: '30px',
        textAlign: 'left',
        marginBottom: 16,
        color: '#172B4D',
    },
    solution: {
        fontSize: 15,
        fontWeight: 400,
        textAlign: 'left',
        color: '#172B4D',
    },
    button: {
        [newtheme.cssBreakpoints.tiny]: {
            padding: 15,
        },
        fontSize: 16,
        width: '140px !important',
        borderRadius: 4,
        padding: '12px 10px 10px 10px',
        marginTop: 16,
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
        background: '#DE350B',
    },

    errorIconWrapper: {
        marginTop: '4px',
        marginRight: '15px',
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        subscriptionError: state.subscription.subscriptionError,
        isOpen: state.modal.isOpen,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({}, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubscriptionErrorModal));
