export const GET_RECOMMENDED_ARTICLES_REQUEST = 'GET_RECOMMENDED_ARTICLES_REQUEST';
export const GET_RECOMMENDED_ARTICLES_SUCCESS = 'GET_RECOMMENDED_ARTICLES_SUCCESS';
export const GET_RECOMMENDED_ARTICLES_FAILURE = 'GET_RECOMMENDED_ARTICLES_FAILURE';

export const GET_RECOMMENDED_REQUEST = 'GET_RECOMMENDED_REQUEST';
export const GET_RECOMMENDED_SUCCESS = 'GET_RECOMMENDED_SUCCESS';
export const GET_RECOMMENDED_FAILURE = 'GET_RECOMMENDED_FAILURE';

export const RECOMMENDATION_CLEAR = 'RECOMMENDATION_CLEAR';

export const RECOMMENDATION_IS_QUEUED_SUCCESS = 'RECOMMENDATION_IS_QUEUED_SUCCESS';
export const RECOMMENDATION_IS_QUEUED_PENDING = 'RECOMMENDATION_IS_QUEUED_PENDING';
export const RECOMMENDATION_IS_QUEUED_REJECTED = 'RECOMMENDATION_IS_QUEUED_REJECTED';

export const ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_PENDING = 'ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_PENDING';
export const ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_SUCCESS = 'ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_SUCCESS';
export const ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_REJECTED = 'ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_REJECTED';
