import * as React from 'react';

interface IProps {
    className?: string;
}

class IconNavProfile extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="11" r="4.45" stroke="white" strokeOpacity="0.87" strokeWidth="1.1" />
                <mask id="path-2-inside-1" fill="white">
                    <path d="M7 22C7 19.7909 8.79086 18 11 18H21C23.2091 18 25 19.7909 25 22V25.5C25 25.7761 24.7761 26 24.5 26H7.5C7.22386 26 7 25.7761 7 25.5V22Z" />
                </mask>
                <path
                    d="M7 22C7 19.7909 8.79086 18 11 18H21C23.2091 18 25 19.7909 25 22V25.5C25 25.7761 24.7761 26 24.5 26H7.5C7.22386 26 7 25.7761 7 25.5V22Z"
                    stroke="white"
                    strokeOpacity="0.87"
                    strokeWidth="2.2"
                    mask="url(#path-2-inside-1)"
                />
            </svg>
        );
    }
}

export default IconNavProfile;
