import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import theme from '../theme/index';
import { EventCategories, event, EventActions } from '../utils/metric';
import { isEmailValid } from '../utils/text';
import newtheme from '../newtheme';

interface IState {
    focus: boolean;
    error: boolean;
    initialized: boolean;
}

interface IProps {
    id: string;
    type: 'text' | 'password' | 'email';
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    required?: boolean;
    isDarkMode?: boolean;
    noMargin?: boolean;
    disabled?: boolean;
    onFocus?: () => void;
    styles?: object[];
}

class Input extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = { focus: false, error: false, initialized: false };
    }

    public maybeSetError = (value: string, initialized: boolean) => {
        const { required, type } = this.props;

        if (!initialized) {
            this.setState({ error: false });
            return;
        }

        if (required) {
            if (value) {
                this.setState({ error: false });
            } else {
                this.setState({ error: true });
            }
        }

        if (type === 'email') {
            if (isEmailValid(value)) {
                this.setState({ error: false });
            } else {
                this.setState({ error: true });
            }
        }
    };

    public getStyle = () => {
        const { focus, error } = this.state;
        const { isDarkMode, noMargin, disabled } = this.props;

        if (error) {
            return css(
                styles.input,
                noMargin != null && noMargin && styles.removeMargin,
                isDarkMode && styles.darkModeBorder,
                styles.withError,
                isDarkMode && styles.darkModeInput,
                ...(this.props.styles || []),
            );
        }

        return css(
            styles.input,
            noMargin != null && noMargin && styles.removeMargin,
            isDarkMode && styles.darkModeBorder,
            focus ? styles.withFocus : styles.withoutFocus,
            isDarkMode && styles.darkModeInput,
            disabled != null && disabled && styles.disabledInput,
            ...(this.props.styles || []),
        );
    };

    public onFocus = () => {
        this.setState({ focus: true });
        if (this.props.onFocus) this.props.onFocus();
    };

    public onBlur = () => {
        const initialized = true;

        this.setState({ focus: false, initialized });

        this.maybeSetError(this.props.value, initialized);
    };

    public onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { type, id } = this.props;
        const { value } = e.target;

        this.props.onChange(value);
        this.maybeSetError(value, this.state.initialized);

        if (type !== 'password') {
            event(EventCategories.INPUT, EventActions.TAP, `${id}-${value}`);
        }
    };

    public render() {
        const { value, placeholder, type, required, disabled } = this.props;

        return (
            <input
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                className={this.getStyle()}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={this.onChange}
                required={required}
                disabled={disabled != null && disabled}
            />
        );
    }
}

const styles = StyleSheet.create({
    input: {
        [newtheme.cssHeightBreakpoints.large]: {
            height: 40,
            fontSize: 15,
            marginBottom: 10,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 40,
            fontSize: 15,
        },
        height: 50,
        borderRadius: 10,
        fontSize: 18,
        marginBottom: 20,
        width: '100%',
        padding: '10px 20px',
        color: theme.textColorSelected,
        backgroundColor: 'white',
        border: '1px solid #ced4da',
    },
    disabledInput: {
        '::placeholder': {
            color: '#888',
        },
        color: '#888',
    },
    removeMargin: {
        marginBottom: 0,
    },
    darkModeBorder: {
        border: `1px solid ${newtheme.darkmodeColors.cardNavy}`,
    },
    darkModeInput: {
        '::placeholder': {
            color: newtheme.darkmodeColors.fadedText,
        },
        backgroundColor: newtheme.darkmodeColors.inputNavy,
        color: newtheme.colors.white,
    },
    withError: {
        border: `1px solid ${theme.error}`,
        color: theme.textColorSelected,
    },
    withFocus: {
        border: `1px solid ${theme.tertiaryColor}`,
        color: theme.tertiaryColor,
    },
    withoutFocus: {
        color: theme.textColorSelected,
    },
});

export default Input;
