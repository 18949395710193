import _ from 'lodash';
import { IState } from '../../../../reducers';
import ISubscriptionProduct from '../../types/ISubscriptionProduct';

export const getSelectedSubscriptionProduct = (state: IState): ISubscriptionProduct | undefined => {
    const { subscriptionProductResponse } = state.subscription;
    const { countries } = state.info;
    const { selectedCountryAlpha2 } = state.info;

    if (!subscriptionProductResponse || _.isEmpty(subscriptionProductResponse.plans)) {
        return undefined;
    }

    const selectedCountryAlpha = selectedCountryAlpha2 || 'us';

    const selectedCountry = countries ? countries.find((c) => c.alpha2.toLowerCase() === selectedCountryAlpha.toLowerCase()) : undefined;

    const selectedCurrency = selectedCountry && selectedCountry.currencyCodes ? selectedCountry.currencyCodes.toLowerCase() : 'usd';

    let selectedPlan = subscriptionProductResponse.plans.find((p) => p.pricingPlan.currency && p.pricingPlan.currency.toLowerCase() === selectedCurrency);

    if (!selectedPlan) {
        selectedPlan = subscriptionProductResponse.plans.find((p) => p.pricingPlan.currency && p.pricingPlan.currency.toLowerCase() === 'usd');
    }

    if (!selectedPlan) {
        [selectedPlan] = subscriptionProductResponse.plans;
    }

    const subscriptionProduct: ISubscriptionProduct = {
        ...subscriptionProductResponse,
        plan: selectedPlan,
    };

    return subscriptionProduct;
};
