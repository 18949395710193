import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IState } from '../../../reducers';
import { closeModal, openModal } from '../../../actions/modal';
import { sendSMS, reset } from '../../../actions/sms';
import { getLocationInfo } from '../../../actions/location';
import DownloadTheAppModalComponent from '../components/DownloadTheAppModalComponent';

interface IPropsFromState {
    loading: boolean;
    smsSent: boolean;
    error: boolean;
    isOpen: boolean;
    selectedCountryAlpha2?: string;
}

interface IPropsFromDispatch {
    sendSMS: (phoneNumber: string, mode: string) => void;
    reset: () => void;
    closeModal: () => void;
    getLocationInfo: typeof getLocationInfo;
}

class DownloadTheAppModal extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps> {
    public render() {
        return (
            <DownloadTheAppModalComponent {...this.props} />
        );
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isOpen: state.modal.isOpen,
        loading: state.sms.loading,
        smsSent: state.sms.sent,
        error: state.sms.error,
        selectedCountryAlpha2: state.info.selectedCountryAlpha2 ? state.info.selectedCountryAlpha2.toLowerCase() : undefined,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({
        closeModal, openModal, sendSMS, reset, getLocationInfo,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadTheAppModal));
