import * as React from 'react';

import Helmet from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router';

import config from '../config';
import { lang } from '../locale';
import { isServer } from '../../store';
import { mpTrackPageView } from '../tracking/mixpanel';
import { mpTrackingScreens } from '../config/screens';
import { getQueryString } from '../utils/url';

interface IState {
    lastTrackedUrl?: string;
}

interface IProps {
    trackingScreen: mpTrackingScreens;
    extraTrackingProps?: { [key: string]: string }[];
    className?: string;
    id?: string;
    title?: string;
    description?: string;
    image?: string;
    contentType?: string;
    twitter?: string;
    noCrawl?: boolean;
    published?: string;
    updated?: string;
    category?: string;
    tags?: string;
    schema?: string;
}

class Page extends React.Component<IProps & RouteComponentProps, IState> {
    constructor(props) {
        super(props);

        this.state = { lastTrackedUrl: undefined };
    }

    public getMetaTags = (
        {
            title,
            description,
            image,
            contentType,
            twitter,
            noCrawl,
            published,
            updated,
            category,
            tags,
        },
        pathname,
    ) => {
        const theTitle = title
            ? `${title} | Understand the news that matters`
            : config.site.title;

        const theDescription = description
            ? description.substring(0, 155)
            : config.site.description;
        const theImage = image || config.site.defaultImage;

        const metaTags = [
            { itemprop: 'name', content: theTitle },
            { itemprop: 'description', content: theDescription },
            { itemprop: 'image', content: theImage },
            { name: 'description', content: theDescription },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: config.site.twitter },
            { name: 'twitter:title', content: theTitle },
            { name: 'twitter:description', content: theDescription },
            {
                name: 'twitter:creator',
                content: twitter || config.site.twitter,
            },
            { name: 'twitter:image:src', content: theImage },
            { property: 'og:title', content: theTitle },
            { property: 'og:type', content: contentType || 'website' },
            { property: 'og:url', content: `${config.site.url}${pathname}` },
            { property: 'og:image', content: theImage },
            { property: 'og:description', content: theDescription },
            { property: 'og:site_name', content: theTitle },
            { property: 'fb:app_id', content: config.site.facebookAppId },
        ];

        if (noCrawl) {
            metaTags.push({ name: 'robots', content: 'noindex, nofollow' });
        }

        if (published) {
            metaTags.push({
                name: 'article:published_time',
                content: published,
            });
        }
        if (updated) {
            metaTags.push({ name: 'article:modified_time', content: updated });
        }
        if (category) {
            metaTags.push({ name: 'article:section', content: category });
        }
        if (tags) {
            metaTags.push({ name: 'article:tag', content: tags });
        }

        return metaTags;
    };

    public getFullPath = () =>
        `${this.props.location.pathname}${this.props.location.search}`;

    public getTitle = () =>
        this.props.title
            ? `${this.props.title} | Noa: Understand the news that matters`
            : config.site.title;

    public removeMetaTags = () => {
        const originalDescriptionElements = document.querySelectorAll(
            'meta[name=description]',
        );
        originalDescriptionElements.forEach((e) => {
            // @ts-ignore: ignore html.dataset being unknown
            if (!e.dataset) {
                throw new Error(
                    'Helmet: html.dataset is not available in this browser.',
                );

                // @ts-ignore: ignore html.dataset being unknown
            } else if (!e.dataset.reactHelmet && !!e && !!e.parentNode) {
                e.parentNode.removeChild(e);
            }
        });
    };

    public componentDidUpdate = () => {
        this.trackPageView();
    };

    public componentDidMount = () => {
        this.trackPageView();
    };

    public maybeGetSharedByUser = (): string => {
        const queries = getQueryString(this.props.location.search);
        return queries.sbu;
    };

    public trackPageView = () => {
        if (isServer) {
            return;
        }

        const fullPath = this.getFullPath();

        if (
            this.state.lastTrackedUrl &&
            fullPath === this.state.lastTrackedUrl
        ) {
            return;
        }

        // @ts-ignore
        if (!window || !window.ga || typeof window.ga !== 'function') {
            console.log('GA is not available');
            return;
        }

        mpTrackPageView(
            fullPath,
            this.props.trackingScreen,
            this.props.title,
            this.maybeGetSharedByUser(),
            this.props.extraTrackingProps,
        );

        // @ts-ignore
        const { ga } = window;

        console.log(`TP\nPath: ${fullPath}\nTitle: ${this.getTitle()}\n\n`);
        this.setState({ lastTrackedUrl: fullPath });

        ga('set', 'page', fullPath);
        ga('set', 'title', this.getTitle());
        ga('send', 'pageview');
    };

    public render() {
        const {
            children,
            id,
            className,
            title,
            category,
            contentType,
            description,
            image,
            noCrawl,
            published,
            tags,
            twitter,
            updated,
            schema,
        } = this.props;

        return (
            <div id={id} className={className}>
                <Helmet
                    htmlAttributes={{
                        itemscope: undefined,
                        itemtype: `http://schema.org/${schema || 'WebPage'}`,
                        lang,
                    }}
                    title={this.getTitle()}
                    link={[
                        {
                            rel: 'canonical',
                            href: `${config.site.url}${this.props.location.pathname}`,
                        },
                    ]}
                    meta={this.getMetaTags(
                        {
                            title,
                            category,
                            contentType,
                            description,
                            image,
                            noCrawl,
                            published,
                            tags,
                            twitter,
                            updated,
                        },
                        this.props.location.pathname,
                    )}
                />
                {children}
            </div>
        );
    }
}

export default withRouter(Page);
