export const set = (key: string, value: any) => {
    if (window && window.localStorage) {
        localStorage.setItem(key, value);
    }
};

export const get = (key: string): any => {
    if (window && window.localStorage) {
        return localStorage.getItem(key);
    }
};
