import * as _ from 'lodash';

import {
    MODAL_OPEN,
    MODAL_CLOSE,
} from '../constants/modal';
import ModalTypes from '../types/ModalTypes';

export interface IState {
    modals: ModalTypes[];
    isOpen: boolean;
    extra?: object;
}

interface IAction {
    type: string;
    modals?: ModalTypes[];
    extra?: object;
}

export const initialState: IState = {
    modals: [],
    isOpen: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case MODAL_OPEN: {
            const newModals = _.uniq([...state.modals, ...action.modals as ModalTypes[]]);

            return {
                ...state,
                modals: newModals,
                extra: action.extra,
                isOpen: true,
            };
        }
        case MODAL_CLOSE: {
            const modals = _.clone(state.modals);
            modals.shift();
            const newModalsArray = [...modals];

            return {
                ...state,
                modals: newModalsArray,
                extra: _.size(newModalsArray) > 0 ? state.extra : undefined,
                isOpen: _.size(newModalsArray) > 0,
            };
        }
        default:
            return state;
    }
};
