import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import IUser from '../types/models/IUser';
import { hasListenBalance, getListenBalance } from '../utils/user';
import config from '../config';
import theme from '../theme';
import { isRouteValidForListenBalance } from '../utils/route';
import newtheme from '../newtheme';
import IconRightArrowBlue from '../assets/icons/IconRightArrowBlue';
import { IState } from '../reducers';
import { getSelectedSubscriptionProduct } from '../domaindriven/subscribe/state/selectors';
import ISubscriptionProduct from '../domaindriven/subscribe/types/ISubscriptionProduct';
import IconRightArrow from '../assets/icons/IconRightArrow';
import { mpTrackButtonClick } from '../tracking/mixpanel';
import { MpTrackingButtons } from '../config/buttons';
import { openModal } from '../actions/modal';
import ModalTypes from '../types/ModalTypes';

interface ILocalProps {
    user?: IUser;
    locationPathname: string;
}

interface ILocalState {
    hasClickedClose: boolean;
}

interface IPropsFromState {
    subscriptionProduct?: ISubscriptionProduct;
}

interface IPropsFromDispatch {
    openModal: typeof openModal;
}

class ListenBalanceBanner extends React.Component<
    IPropsFromState & IPropsFromDispatch & ILocalProps & RouteComponentProps,
    ILocalState
> {
    public hasClickedClose = false;

    constructor(props) {
        super(props);

        this.state = { hasClickedClose: false };
    }

    public onClickClose = (e: any) => {
        e.stopPropagation();
        this.setState({ hasClickedClose: true });
    };

    public onBannerClicked = () => {
        const { subscriptionProduct, user } = this.props;

        if (!user && subscriptionProduct && subscriptionProduct.metadata.homePage.showSignup) {
            this.showSignup();
            return;
        }

        this.props.history.push(config.routes.subscribe);
    };

    public showSignup = () => {
        mpTrackButtonClick(MpTrackingButtons.SHOW_SIGNUP_LISTEN_BAL_BANNER);

        this.props.openModal(ModalTypes.SignUpNew);
    };

    public renderOfferBanner = (subscriptionProduct: ISubscriptionProduct) => {
        const { user } = this.props;

        if (
            this.state.hasClickedClose ||
            !isRouteValidForListenBalance(this.props.locationPathname) ||
            (user && !user.canSubscribe)
        ) {
            return null;
        }

        return (
            <div className={css(styles.banner)}>
                <Container className={css(styles.offerBanner, styles.offerBannerCenter)}>
                    <div className={css(styles.offerInfo)}>
                        <div className={css(styles.leftSection)}>
                            <p className={css(styles.premiumText, styles.offerText)}>
                                {subscriptionProduct.metadata.general.listenBalanceBannerText}{' '}
                            </p>
                            <div className={css(styles.offerAttributesWrapper)}>
                                {subscriptionProduct.plan.checkoutInfo.trialInfo && (
                                    <div className={css(styles.offerAttribute, styles.offerAttributeLong)}>
                                        <p className={css(styles.offerAttributeText)}>
                                            {subscriptionProduct.plan.checkoutInfo.trialInfo.text}
                                        </p>
                                    </div>
                                )}
                                {subscriptionProduct.plan.checkoutInfo.showPercentOff &&
                                    subscriptionProduct.plan.checkoutInfo.percentOff && (
                                        <div className={css(styles.offerAttribute)}>
                                            <p className={css(styles.offerAttributeText)}>
                                                {subscriptionProduct.plan.checkoutInfo.percentOff?.amountStr} OFF
                                            </p>
                                        </div>
                                    )}
                            </div>
                        </div>
                        <button type="button" onClick={this.onBannerClicked} className={css(styles.button)}>
                            Claim offer now
                            {'   '}
                            <IconRightArrow className={css(styles.rightArrow)} />
                        </button>
                    </div>
                </Container>
            </div>
        );
    };

    public render() {
        const { user, subscriptionProduct } = this.props;

        if (subscriptionProduct && subscriptionProduct.metadata.general.listenBalanceBannerText) {
            return this.renderOfferBanner(subscriptionProduct);
        }

        if (!user || !user.canSubscribe || !isRouteValidForListenBalance(this.props.locationPathname)) {
            return null;
        }

        if (this.state.hasClickedClose || !hasListenBalance(user)) {
            return null;
        }

        const articlesRemainingText = `${getListenBalance(user)} listens remaining${isMobile ? '' : ' this week'} `;

        return (
            <div className={css(styles.banner)}>
                <Link to={config.routes.subscribeDefaultOffer} className={css(styles.link)}>
                    <span>
                        {articlesRemainingText}{' '}
                        <span className={css(styles.premiumText)}>
                            Go Premium <IconRightArrowBlue className={css(styles.rightArrow)} />
                        </span>
                    </span>
                </Link>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    banner: {
        [newtheme.cssBreakpoints.medium]: {
            height: 60,
        },
        [newtheme.cssBreakpoints.small]: {
            height: 90,
        },
        [newtheme.cssBreakpoints.tiny]: {
            height: 150,
        },
        height: 75,
        width: '100%',
        backgroundColor: newtheme.darkmodeColors.white,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 18,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 16,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
        },
        flexGrow: 1,
        color: '#000',
        fontSize: 16,
        fontWeight: 500,
        textDecoration: 'none',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    premiumText: {
        paddingTop: 2,
        color: newtheme.colors.primaryBlue,
    },
    close: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 11,
            justifyContent: 'start',
            marginLeft: 5,
            padding: '5px 10px',
            right: -5,
        },
        fontSize: 15,
        cursor: 'pointer',
        padding: '5px 40px',
        position: 'absolute',
        top: 0,
        right: -20,
    },
    rightArrow: {
        height: 20,
        width: 20,
        color: 'white',
        stroke: 'white',
        marginLeft: 10,
    },
    offerBanner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    offerBannerCenter: {
        justifyContent: 'center',
    },
    offerInfo: {
        [newtheme.cssBreakpoints.tiny]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    leftSection: {
        [newtheme.cssBreakpoints.small]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            flexGrow: 1,
        },
        [newtheme.cssBreakpoints.tiny]: {
            width: '100%',
        },
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    offerText: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 17,
            marginRight: 20,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 14,
            marginRight: 10,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 16,
            marginBottom: 10,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
            marginBottom: 15,
        },
        margin: 0,
        marginRight: 40,
        fontSize: 20,
        color: 'black',
        fontWeight: 500,
    },
    button: {
        ':hover': {
            backgroundColor: 'black',
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 12,
            padding: '10px 10px',
            borderRadius: 10,
        },
        [newtheme.cssBreakpoints.tiny]: {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        stroke: 'white',
        border: 'none',
        borderRadius: 10,
        fontSize: 14,
        fontWeight: 500,
        padding: '12px 20px',
    },
    offerAttributesWrapper: {
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 12,
            justifyContent: 'center',
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'row',
    },
    offerAttribute: {
        [newtheme.cssBreakpoints.large]: {
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 18,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 12,
            paddingLeft: 10,
            paddingRight: 10,
            height: 35,
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingLeft: 40,
            paddingRight: 40,
            fontSize: 20,
            marginLeft: 5,
            marginRight: 5,
        },
        [newtheme.cssBreakpoints.smallerThanTiny]: {
            paddingLeft: 15,
            paddingRight: 15,
            fontSize: 18,
            height: 45,
        },
        background: 'rgba(3, 155, 229, 0.2)',
        color: newtheme.colors.primaryBlue,
        fontWeight: 600,
        fontSize: 24,
        borderRadius: 30,
        marginRight: 10,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 40,
        paddingRight: 40,
        whiteSpace: 'nowrap',
    },
    offerAttributeText: {
        marginBottom: 0,
        letterSpacing: '0.2em',
    },
    offerAttributeLong: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 15,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 12,
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 14,
        },
        [newtheme.cssBreakpoints.smallerThanTiny]: {
            height: 45,
            lineHeight: '20px',
            paddingLeft: 15,
            paddingRight: 15,
        },
        fontSize: 16,
        paddingLeft: 20,
        paddingRight: 20,
        whiteSpace: 'pre-wrap',
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        subscriptionProduct: getSelectedSubscriptionProduct(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            openModal,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListenBalanceBanner));
