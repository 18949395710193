import { Container, Row, Col } from 'reactstrap';
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Page from '../../components/Page';
import theme from '../../theme';
import { mpTrackingScreens } from '../../config/screens';
import newtheme from '../../newtheme';
import LandingPageNewSitemap from '../../components/landingpagenew/LandingPageNewSitemap';
import NewSitemapSeparator from '../../components/landingpagenew/NewSitemapSeparator';

class MobileDeveloper extends React.Component {
    public render() {
        return (
            <Page
                className={css(styles.page)}
                title="We're hiring | Mobile Developer"
                trackingScreen={mpTrackingScreens.careers}
                image="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringmobiledev.png"
            >
                <Container className={css(styles.content)}>
                    <Row
                        className={css(newtheme.coreStyles.centerColHorizontal)}
                    >
                        <Col md={10} lg={8} xl={8}>
                            <img
                                alt="We are hiring cover"
                                src="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringmobiledev.png"
                                className={css(styles.topImage)}
                            />

                            <h1 className={css(styles.title)}>
                                Mobile Developer
                            </h1>

                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                <span className={css(styles.titleColor)}>
                                    Salary range:
                                </span>
                                {' '}
                                €55,000-€70,000
                                <br />
                                <span className={css(styles.titleColor)}>
                                    Location:
                                </span>
                                {' '}
                                Dublin or remote
                                <br />
                                <span className={css(styles.titleColor)}>
                                    Position:
                                </span>
                                {' '}
                                Permanent, full-time
                                <br />
                            </p>

                            <h5 className={css(styles.subTitle)}>About Noa </h5>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                Noa is the home of audio journalism. We help our
                                listeners understand the news with spoken-word
                                articles from our partnering publishers such as
                                The New York Times, Harvard Business Review, The
                                Irish Times, The Washington Post and many more.
                            </p>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                At the core of our offering are what we call
                                {' '}
                                <i>&quot;Series&quot;</i>
                                . These are short
                                playlists, curated by our editorial team, that
                                provide multiple perspectives from multiple
                                publishers, on the same topic. This means our
                                listeners can walk away truly informed, having
                                heard all sides of the story. No more filter
                                bubbles, no more broken mirrors, and a far
                                better way to tackle fake news.
                            </p>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                Noa is available across all audio-supported
                                devices including Android, iOS, Web, Amazon
                                Alexa, Google Assistant, Apple CarPlay and
                                Android Auto.
                            </p>

                            <br />

                            <h5 className={css(styles.subTitle)}>
                                Why you will find this role attractive:
                            </h5>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                You will play a pivotal role in creating a world-class mobile app experience for our listeners all over the world. You will be given significant responsibility and influence over the development of Noa’s iOS and Android apps, in-car apps, CI/CD pipelines and data tracking.
                            </p>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                You will become part of the Noa team who are
                                ambitious and highly motivated by the positive
                                impact that we can have on the world. We have a
                                proven track record and are now positioning
                                ourselves to grow significantly. We are an open
                                company that places a lot of emphasis on giving
                                people an environment where they feel free to
                                develop their careers, take on real
                                responsibility, and actively contribute in a
                                meaningful way towards the company’s goals.
                            </p>

                            <br />

                            <h5 className={css(styles.subTitle)}>
                                What you will do:
                            </h5>

                            <ul
                                className={css(
                                    styles.list,
                                    styles.standardText,
                                )}
                            >
                                <li>
                                    Collaborate with the product team to architect solutions for mobile features;
                                </li>
                                <li>
                                    Implement changes and new features in a manner which promotes efficient, reusable, readable and performant code;
                                </li>
                                <li>
                                    Write automated tests including unit and integration tests and help build out automated app delivery via CI/CD pipelines; and
                                </li>
                                <li>
                                    Manage app releases from end to end, including providing time estimates for feature delivery, writing release notes, and submitting app releases on the app stores.
                                </li>
                            </ul>

                            <br />

                            <h5 className={css(styles.subTitle)}>About you:</h5>
                            <ul
                                className={css(
                                    styles.list,
                                    styles.standardText,
                                )}
                            >
                                <li>
                                    You are an engineer at heart who loves to
                                    build things and stay up to date with the
                                    latest technologies;
                                </li>
                                <li>
                                    You have significant and demonstrable experience developing and releasing native iOS and Android applications;
                                </li>
                                <li>
                                    You have experience working with RESTful APIs and can provide input on their design;
                                </li>
                                <li>
                                    You recognise that automated testing and CI/CD pipelines are critical to building high quality, maintainable software;
                                </li>
                                <li>
                                    You welcome the opinions of others and enjoy
                                    discussing solutions to problems to find the
                                    best one; and,
                                </li>
                                <li>
                                    You thrive on responsibility and love the
                                    idea of having autonomy over your work in a
                                    fast-paced and growing company.
                                </li>
                            </ul>

                            <br />

                            <h5 className={css(styles.subTitle)}>
                                Next steps:
                            </h5>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                If this opportunity is for you, please get in touch with our recruitment manager Philomena to arrange a chat with our CTO:
                                <br />
                                <ul
                                    className={css(
                                        styles.list,
                                        styles.standardText,
                                    )}
                                >
                                    <li>
                                        <span className={css(styles.titleColor)}>
                                            Email:
                                        </span>
                                        {' '}
                                        <a href="mailto:philomena@newsoveraudio.com?subject=Mobile%20Developer%20Application">philomena@newsoveraudio.com</a>
                                    </li>
                                    <li>
                                        <span className={css(styles.titleColor)}>
                                            Phone:
                                        </span>
                                        {' '}
                                        <a href="tel:0035319073360">+353 190 73360</a>
                                    </li>
                                </ul>

                            </p>

                        </Col>
                    </Row>
                </Container>

                <NewSitemapSeparator />
                <LandingPageNewSitemap />
            </Page>
        );
    }
}

const styles = StyleSheet.create({
    page: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 20,
            paddingTop: 50,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
            paddingTop: 30,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 16,
            paddingTop: 30,
        },
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        fontSize: 20,
        paddingTop: 80,
    },
    content: {
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
        },
        paddingBottom: 80,
        color: newtheme.darkmodeColors.slightlyFadedText,
    },
    topImage: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 75,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 40,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 40,
        },
        width: '100%',
        display: 'block',
        margin: '0 auto',
        marginBottom: 60,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 35,
            marginBottom: 40,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 30,
            marginBottom: 25,
        },
        fontSize: 40,
        marginTop: 0,
        marginBottom: 50,
        borderBottom: '1px solid #DDDDDD',
        color: newtheme.darkmodeColors.white,
    },
    subTitle: {
        fontWeight: 600,
        marginBottom: 10,
        color: newtheme.darkmodeColors.white,
        fontSize: '110%',
    },
    paragraph: {
        marginBottom: 25,
    },
    standardText: {
        fontSize: '110%',
    },
    list: {
        listStyleType: 'disc',
    },
    titleColor: {
        color: newtheme.darkmodeColors.white,
        fontWeight: 600,
    },
});

export default MobileDeveloper;
