import { StyleSheet } from 'aphrodite';
import theme from './index';

export default StyleSheet.create({
    imageAspectFill: {
        height: '100%',
        width: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    animationEnabled: {
        animation: 'placeholder 3s ease infinite alternate',
        transition: 'box-shadow 0.3s',
    },
    onImageHover: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            boxShadow: 'none',
        },
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 0px 0px, 0px 0px 20px 150px #00000040 inset',
    },
    noaItem: {
        ':hover': {
            transform: 'scale(1.04)',
            cursor: 'pointer',
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            marginTop: 5,
            marginBottom: 5,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        marginTop: 10,
        marginBottom: 10,
        padding: 15,
    },
    noaItemTitle: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 16,
            fontWeight: 600,
        },
        color: '#373737',
        fontSize: 18,
        fontWeight: 500,
        marginBottom: 5,
    },
    noaListTitle: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 26,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 23,
            fontWeight: 600,
            textAlign: 'center',
            borderBottom: 'none',
            paddingBottom: 0,
            marginBottom: -5,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 22,
            margin: 0,
            textAlign: 'left',
        },
        color: 'white',
        fontSize: 36,
        fontWeight: 500,
        paddingBottom: 5,
    },
    noaItemDesc: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 13,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 12,
        },
        color: '#787878',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 0,
    },
    link: {
        textDecoration: 'none',
    },
    centerSpan: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
});
