import loadable from '@loadable/component';
import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableFaqSection = loadable(() => import('../components/FaqSection'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadFaqSection extends React.Component<RouteComponentProps<{ id?: string }>> {
    render() {
        return (
            <Page noCrawl={false} title="FAQ" trackingScreen={mpTrackingScreens.faq}>
                <LoadableFaqSection {...this.props} />
            </Page>
        );
    }
}

export default LazyLoadFaqSection;
