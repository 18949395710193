export const GET_GIFT_PRICES_REQUEST = 'GET_GIFT_PRICES_REQUEST';
export const GET_GIFT_PRICES_SUCCESS = 'GET_GIFT_PRICES_SUCCESS';
export const GET_GIFT_PRICES_FAILURE = 'GET_GIFT_PRICES_FAILURE';

export const INITIATE_PURCHASE_GIFT_REQUEST = 'INITIATE_PURCHASE_GIFT_REQUEST';
export const INITIATE_PURCHASE_GIFT_SUCCESS = 'INITIATE_PURCHASE_GIFT_SUCCESS';
export const INITIATE_PURCHASE_GIFT_FAILURE = 'INITIATE_PURCHASE_GIFT_FAILURE';

export const PURCHASE_GIFT_REQUEST = 'PURCHASE_GIFT_REQUEST';
export const PURCHASE_GIFT_SUCCESS = 'PURCHASE_GIFT_SUCCESS';
export const PURCHASE_GIFT_FAILURE = 'PURCHASE_GIFT_FAILURE';

export const RESET_GIFT_FORM = 'RESET_GIFT_FORM';
