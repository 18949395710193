import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import config from '../config';
import {
    ARTICLE_IS_QUEUED_FOR_SEARCH_PENDING,
    ARTICLE_IS_QUEUED_FOR_SEARCH_REJECTED,
    ARTICLE_IS_QUEUED_FOR_SEARCH_SUCCESS,
    SEARCH_CLEAR,
    SEARCH_FAILURE,
    PLAYLIST_IS_QUEUED_FOR_SEARCH_PENDING,
    PLAYLIST_IS_QUEUED_FOR_SEARCH_REJECTED,
    PLAYLIST_IS_QUEUED_FOR_SEARCH_SUCCESS,
    SEARCH_REQUEST,
    SEARCH_SUCCESS,
} from '../constants/search';
import { IState } from '../reducers';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import text from '../locale';

export const clearSearch = () => (dispatch: Dispatch) => {
    dispatch({ type: SEARCH_CLEAR });
};

export const search =
    (params: { url?: string | null; text?: string | null; segment?: string | null }) =>
    (dispatch: Dispatch, getState: () => IState) => {
        dispatch({ type: SEARCH_REQUEST });

        let fullUrl = '';

        if (params.url) {
            fullUrl = params.url;
        } else if (params.text && params.segment) {
            fullUrl = `${config.api.url}${config.api.routes.search}?text=${params.text}&segment=${params.segment}`;
        } else if (params.text) {
            fullUrl = `${config.api.url}${config.api.routes.search}?text=${params.text}`;
        }

        return fetch(getState)(fullUrl)
            .then(responseCheck)
            .then((response) => dispatch(searchSuccess(response)))
            .catch((error) => dispatch(searchFailure(error)));
    };

export const setSearchPlaylistIsQueuedSuccess =
    ({ playlistId, isQueued }: { playlistId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: PLAYLIST_IS_QUEUED_FOR_SEARCH_SUCCESS, isQueued: { itemId: playlistId, status: isQueued } });

export const setSearchPlaylistIsQueuedPending =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: PLAYLIST_IS_QUEUED_FOR_SEARCH_PENDING, isQueued: { itemId: playlistId } });

export const setSearchPlaylistIsQueuedRejected =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: PLAYLIST_IS_QUEUED_FOR_SEARCH_REJECTED, isQueued: { itemId: playlistId } });

export const setArticleIsQueuedForSearchSuccess =
    ({ articleId, isQueued }: { articleId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_FOR_SEARCH_SUCCESS, isQueued: { itemId: articleId, status: isQueued } });

export const setArticleIsQueuedForSearchPending =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_FOR_SEARCH_PENDING, isQueued: { itemId: articleId } });

export const setArticleIsQueuedForSearchRejected =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: ARTICLE_IS_QUEUED_FOR_SEARCH_REJECTED, isQueued: { itemId: articleId } });

const searchSuccess = (response: any) => ({ type: SEARCH_SUCCESS, segments: response.data });

const searchFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: SEARCH_FAILURE, error };
};
