enum ItemTypes {
    Articles = 'ARTICLES',
    Publishers = 'PUBLISHERS',
    Playlists = 'PLAYLISTS',
    Playlist = 'PLAYLIST',
    Journalists = 'JOURNALISTS',
    Sections = 'SECTIONS',
    Adverts = 'ADVERTS',
    PROFILE = 'PROFILE',
    COLLECTION = 'COLLECTION',
}

export default ItemTypes;
