import * as React from 'react';

interface IProps {
    className?: string;
}

class Icon5StarsStyle2 extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 151 22" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M10.423 0.757119L7.58654 6.46977L1.17178 7.37177C0.0372013 7.54358 -0.399176 8.91805 0.429941 9.73414L5.01191 14.1582L3.92096 20.3863C3.74641 21.5031 4.96827 22.3621 5.97194 21.8467L11.6885 18.883L17.3614 21.8467C18.3651 22.3621 19.5869 21.5031 19.4124 20.3863L18.3214 14.1582L22.9034 9.73414C23.7325 8.91805 23.2961 7.54358 22.1616 7.37177L15.7904 6.46977L12.9103 0.757119C12.4303 -0.230783 10.9466 -0.273735 10.423 0.757119Z" fill="#039BE5" />
                <path d="M42.2563 0.757119L39.4199 6.46977L33.0051 7.37177C31.8705 7.54358 31.4342 8.91805 32.2633 9.73414L36.8453 14.1582L35.7543 20.3863C35.5798 21.5031 36.8016 22.3621 37.8053 21.8467L43.5218 18.883L49.1947 21.8467C50.1984 22.3621 51.4203 21.5031 51.2457 20.3863L50.1548 14.1582L54.7367 9.73414C55.5659 8.91805 55.1295 7.54358 53.9949 7.37177L47.6238 6.46977L44.7437 0.757119C44.2637 -0.230783 42.78 -0.273735 42.2563 0.757119Z" fill="#039BE5" />
                <path d="M74.0896 0.757119L71.2532 6.46977L64.8384 7.37177C63.7039 7.54358 63.2675 8.91805 64.0966 9.73414L68.6786 14.1582L67.5876 20.3863C67.4131 21.5031 68.6349 22.3621 69.6386 21.8467L75.3551 18.883L81.0281 21.8467C82.0317 22.3621 83.2536 21.5031 83.079 20.3863L81.9881 14.1582L86.57 9.73414C87.3992 8.91805 86.9628 7.54358 85.8282 7.37177L79.4571 6.46977L76.577 0.757119C76.097 -0.230783 74.6133 -0.273735 74.0896 0.757119Z" fill="#039BE5" />
                <path d="M105.923 0.757119L103.087 6.46977L96.6718 7.37177C95.5372 7.54358 95.1008 8.91805 95.9299 9.73414L100.512 14.1582L99.421 20.3863C99.2464 21.5031 100.468 22.3621 101.472 21.8467L107.188 18.883L112.861 21.8467C113.865 22.3621 115.087 21.5031 114.912 20.3863L113.821 14.1582L118.403 9.73414C119.233 8.91805 118.796 7.54358 117.662 7.37177L111.29 6.46977L108.41 0.757119C107.93 -0.230783 106.447 -0.273735 105.923 0.757119Z" fill="#039BE5" />
                <path d="M137.756 0.757119L134.92 6.46977L128.505 7.37177C127.371 7.54358 126.934 8.91805 127.763 9.73414L132.345 14.1582L131.254 20.3863C131.08 21.5031 132.302 22.3621 133.305 21.8467L139.022 18.883L144.695 21.8467C145.698 22.3621 146.92 21.5031 146.746 20.3863L145.655 14.1582L150.237 9.73414C151.066 8.91805 150.629 7.54358 149.495 7.37177L143.124 6.46977L140.244 0.757119C139.764 -0.230783 138.28 -0.273735 137.756 0.757119Z" fill="#039BE5" />
            </svg>
        );
    }
}

export default Icon5StarsStyle2;
