import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import theme from '../theme';
import IconError from '../assets/icons/IconError';
import IconEmptyBox from '../assets/icons/IconEmptyBox';
import text from '../locale';
import newtheme from '../newtheme';

interface IProps {
    message: string;
    icon?: 'EMPTY_BOX' | 'ERROR';
}

class ErrorMessage extends React.Component<IProps> {
    public renderIcon = () => {
        const { icon } = this.props;

        if (!icon) {
            return <IconError className={css(styles.error)} />;
        }

        if (icon === 'EMPTY_BOX') {
            return <IconEmptyBox className={css(styles.errorBox)} />;
        }

        return <IconError className={css(styles.error)} />;
    };

    public render() {
        const { message } = this.props;

        return (
            <div className={css(styles.content)}>
                {this.renderIcon()}
                <p className={css(styles.message)}>{message}</p>
                <button type="button" className={css(chatStyles.button)} id={text.nav.CONTACT}>
                    Need help? Chat to us
                </button>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    content: {
        [newtheme.cssBreakpoints.tiny]: {
            height: 'calc(100vh - 60px)',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 200px)',
    },
    message: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
        },
        color: theme.textColor,
        fontSize: 22,
        marginBottom: 0,
        paddingLeft: 15,
        paddingRight: 15,
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
    },
    error: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            width: 40,
            height: 40,
        },
        width: 50,
        height: 50,
        fill: theme.iconColor,
        paddingBottom: 10,
    },
    errorBox: {
        width: 60,
        height: 60,
        fill: theme.iconColor,
        paddingBottom: 10,
    },
});

const chatStyles = StyleSheet.create({
    container: {
        [newtheme.cssBreakpoints.medium]: {
            alignItems: 'center',
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginTop: 40,
        },
        marginTop: 60,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    header: {
        fontSize: 18,
        fontWeight: 400,
        padding: 0,
        margin: 0,
    },
    button: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 50,
        },
        [newtheme.cssBreakpoints.tiny]: {
            alignItems: 'center',
            marginLeft: 0,
            marginRight: 0,
        },
        color: newtheme.colors.white,
        fontSize: 16,
        height: 55,
        border: 'none',
        borderRadius: 5,
        marginTop: 40,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: newtheme.colors.primaryBlue,
    },
});

export default ErrorMessage;
