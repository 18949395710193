import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableCheckout = loadable(() => import('../containers/CheckoutContainer'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadCheckout extends React.Component {
    render() {
        return (
            <Page noCrawl={false} title="Complete Subscription" trackingScreen={mpTrackingScreens.completeSubscription}>
                <LoadableCheckout />
            </Page>
        );
    }
}

export default LazyLoadCheckout;
