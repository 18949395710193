import { Container, Row, Col } from 'reactstrap';
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Page from '../../components/Page';
import theme from '../../theme';
import { mpTrackingScreens } from '../../config/screens';
import newtheme from '../../newtheme';
import LandingPageNewSitemap from '../../components/landingpagenew/LandingPageNewSitemap';
import NewSitemapSeparator from '../../components/landingpagenew/NewSitemapSeparator';

class ProducerBusinessEconomics extends React.Component {
    public render() {
        return (
            <Page
                className={css(styles.page)}
                title="We're hiring | Producer (Business &amp; Economics)"
                trackingScreen={mpTrackingScreens.careers}
                image="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringproducerbusinesseconomics.png"
            >
                <Container className={css(styles.content)}>
                    <Row className={css(newtheme.coreStyles.centerColHorizontal)}>
                        <Col md={10} lg={8} xl={8}>
                            <img alt="We are hiring cover" src="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringproducerbusinesseconomics.png" className={css(styles.topImage)} />

                            <h1 className={css(styles.title)}>Producer (Business &amp; Economics)</h1>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                Noa’s mission is to help curious people understand and know more about the topics shaping the world. We bring listeners narrated articles from some of the world’s most
                                respected publications, including
                                {' '}
                                <i>The New York Times</i>
                                ,
                                {' '}
                                <i>The Economist</i>
                                ,
                                {' '}
                                <i>Bloomberg</i>
                                ,
                                {' '}
                                <i>Harvard Business Review</i>
                                ,
                                {' '}
                                <i>The Telegraph</i>
                                ,
                                <i>The Irish Times</i>
                                , and many more.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                This is a chance for an energetic, creative, start-up-minded audio (or video) producer to play an active (pivotal!) part in our growth journey. As part of the content team at Noa you will research and pitch business and economic topics, curate insightful articles from our partnering publishers, write explainer-style introductions and conclusions, and put it all together into a cohesive narrative, essentially producing a short-form audio documentary (which we call a “Series”).
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                Each Series on Noa is made up of between two and five audio articles, plus complimentary explainer pieces to help guide our listeners from beginning to end. The goal is to help people understand the subject at hand.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                The key deliverable of this role is to produce insightful, easy to understand Series on behalf of Noa subscribers on topics they care about. You’ll need to align your curation with Noa’s brand values, which sometimes means producing Series on topics that are super important, but not very popular. This can be challenging and requires creativity and an ability to make unknown topics relatable to a curious audience.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                To be successful, we need you to be willing to think outside the box, speak with Noa listeners, communicate effectively with the team, and manage your time wisely. Teamwork and collaboration with the software and marketing teams are also very important. In return, you’ll receive plenty of room to grow within the company as we scale and reach millions of listeners globally.
                            </p>

                            <br />

                            <h5 className={css(styles.subTitle)}>Skills and responsibilities include:</h5>

                            <ul className={css(styles.list, styles.standardText)}>
                                <li>A strong knowledge of business, finance, economics and politics;</li>
                                <li>Managing the full lifecycle of Series – from concept/idea to curation to introduction/explainer writing to narration scheduling to in-app promotion to feedback and data review;</li>
                                <li>An ability to connect the dots between global topics and spot insightful narratives;</li>
                                <li>Be able to write compelling introductions and explainers (mostly short-form) within tight deadlines;</li>
                                <li>A self-critical mindset with high attention to detail at all times;</li>
                                <li>Be a collaborative team player, ready to take on challenges, support colleagues, and manage competing priorities;</li>
                                <li>Be comfortable using Word, Excel, PowerPoint, Outlook;</li>
                                <li>Be willing to upskill on audio editing software (to an intermediate level);</li>
                                <li>Entrepreneurial spirit, willing to get involved in projects outside of your core area.</li>
                            </ul>

                            <br />

                            <p className={css(styles.paragraph, styles.standardText)}>
                                We’re a team of people from diverse backgrounds and ethnicities, but we share a common goal. We see a world in which digital consumers engage with, and pay for, quality journalism. We see a world in which people seek depth and understanding, not simplicity or like-minded views. And most important of all, we see a world in which digital consumers – of all ages – are equipped with the insights and perspectives to always question, empathise, and reason in their quest to find rational solutions to complex problems. We like to say, “if Hans Rosling built a news app, Noa would be it”. (If you’re unfamiliar with Hans Rosling look him up J.) If this mission motivates you then reach out ASAP.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                We are offering a competitive package, with the option of equity and results-based bonuses. While Noa is headquartered in Dublin, and proximity would be an advantage, for the right candidate we are more than happy to facilitate remote working.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                If you love what we are doing at Noa, apply in strict confidence to
                                {' '}
                                <a href="mailto:recruitment@newsoveraudio.com">recruitment@newsoveraudio.com</a>
                                . Alongside your CV,
                                please explain in c. 250 words how you think Noa will change the world of media and build impact with audiences.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                P.S. Before applying, get one day of free Premium access to familiarise yourself with Noa and start to truly understand its potential.
                                Visit
                                {' '}
                                <Link to="/welcome?mrkt_id=producer" target="_blank">
                                    this
                                </Link>
                                {' '}
                                web page and create a new account.
                            </p>

                            <p className={css(styles.paragraph)}>
                                <span className={css(styles.titleColor)}>Position:</span>
                                {' '}
                                Permanent, full-time.
                                <br />
                                <span className={css(styles.titleColor)}>Location:</span>
                                {' '}
                                Dublin, Ireland or remote
                                <br />
                            </p>

                        </Col>
                    </Row>
                </Container>

                <NewSitemapSeparator />
                <LandingPageNewSitemap />
            </Page>
        );
    }
}

const styles = StyleSheet.create({
    page: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 20,
            paddingTop: 50,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
            paddingTop: 30,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 16,
            paddingTop: 30,
        },
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        fontSize: 20,
        paddingTop: 80,
    },
    content: {
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
        },
        paddingBottom: 80,
        color: newtheme.darkmodeColors.slightlyFadedText,
    },
    topImage: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 75,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 40,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 40,
        },
        width: '100%',
        display: 'block',
        margin: '0 auto',
        marginBottom: 60,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 35,
            marginBottom: 25,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 30,
            marginBottom: 20,
        },
        fontSize: 40,
        marginTop: 0,
        marginBottom: 30,
        borderBottom: '1px solid #DDDDDD',
        color: newtheme.darkmodeColors.white,
    },
    subTitle: {
        fontWeight: 600,
        marginBottom: 10,
        color: newtheme.darkmodeColors.white,
        fontSize: '110%',
    },
    paragraph: {
        marginBottom: 25,
    },
    standardText: {
        fontSize: '110%',
    },
    list: {
        listStyleType: 'disc',
    },
    titleColor: {
        color: newtheme.darkmodeColors.white,
        fontWeight: 600,
    },
});

export default ProducerBusinessEconomics;
