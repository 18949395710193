import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import newtheme from '../../../newtheme';
import IconArrowWithCircleBg from '../../../assets/icons/IconArrowWithCircleBg';

interface IProps {
    onClick?: () => void;
    expandOnClick: boolean;
    isExpanded?: boolean;
    children: any;
}

class LandingEmbedAction extends React.Component<IProps> {
    public onClick = () => {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    public render() {
        const { isExpanded } = this.props;

        const isSelected = isExpanded && this.props.expandOnClick;

        return (
            <div className={css(styles.main, isSelected && styles.selected)} onClick={this.onClick}>
                <div className={css(styles.leftSide)}>
                    {this.props.children}
                </div>
                <div>
                    <IconArrowWithCircleBg className={css(styles.arrowIcon, isExpanded && styles.rotateArrow)} />
                </div>
            </div>
        );
    }
}

// To make initial loading look better
const styles = StyleSheet.create({
    main: {
        ':hover': {
            border: `4px solid ${newtheme.colors.primaryBlue}`,
        },
        [newtheme.cssBreakpoints.large]: {
            padding: 10,
        },
        [newtheme.cssBreakpoints.medium]: {
            padding: 12,
        },
        position: 'relative',
        backgroundColor: '#081E31',
        color: 'white',
        border: '4px solid #153755',
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        padding: 15,
        cursor: 'pointer',
    },
    selected: {
        ':hover': {
            [newtheme.cssBreakpoints.small]: {
                border: `4px solid ${newtheme.colors.primaryBlue}`,
                borderBottom: 'none',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        [newtheme.cssBreakpoints.small]: {
            borderBottom: 'none',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        border: `4px solid ${newtheme.colors.primaryBlue}`,
    },
    leftSide: {
        flexGrow: 1,
    },
    arrowIcon: {
        height: 32,
        width: 32,
        transition: 'transform 0.5s',
        marginBottom: 10,
    },
    rotateArrow: {
        [newtheme.cssBreakpoints.small]: {
            transform: 'rotate(-90deg)',
        },
    },
});

export default LandingEmbedAction;
