import * as _ from 'lodash';
import { Dispatch } from 'redux';

import config from '../config';
import { LISTENING_ITEM, SAVE_HISTORY_FAILURE, SAVE_HISTORY_REQUEST, SAVE_HISTORY_SUCCESS } from '../constants/history';
import { IState } from '../reducers';
import IPlayItem from '../types/IPlayItem';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import ItemTypes from '../types/ItemTypes';
import { PLATFORM_WEB } from '../constants/app';
import { mpTrackAdvertEnd, mpTrackArticleEnd } from '../tracking/mixpanel';

export const saveHistory = () => (dispatch: Dispatch, getState: () => IState) => {
    const state = getState();

    const histories = _.mapValues(state.history.mapPlayItems, (item: IPlayItem & { time: number }) => {
        if (item.time <= config.minHistoryAudioLength) {
            return; // new Promise((r) => r);
        }

        dispatch({ type: SAVE_HISTORY_REQUEST });

        const request = mountRequest(item);

        return fetch(getState)(request.url, {
            method: 'POST',
            body: request.body,
        })
            .then(responseCheck)
            .then(() => dispatch(saveHistorySuccess(item)))
            .catch((error) => dispatch(saveHistoryFailure(error)));
    });

    return Promise.all(_.values(histories));
};

export const listeningItem = (item: IPlayItem, time: number) => (dispatch: Dispatch) =>
    dispatch({ item, type: LISTENING_ITEM, time });

const saveHistorySuccess = (item: IPlayItem) => ({ type: SAVE_HISTORY_SUCCESS, item });

const saveHistoryFailure = (error: Error) => ({ type: SAVE_HISTORY_FAILURE, error });

const mountRequest = (item: IPlayItem & { time: number }) => {
    if (item.type === ItemTypes.Adverts) {
        mpTrackAdvertEnd(item, item.time);
        return {
            url: `${config.api.url}${config.api.routes.userAdvertHistory}`,
            body: JSON.stringify({
                request: {
                    advertId: item.id,
                    userAdvertListenLength: item.time,
                    userAdvertWatchLength: 0,
                    articleId: 1,
                    platform: PLATFORM_WEB,
                },
            }),
        };
    }

    mpTrackArticleEnd(item, item.time);
    return {
        url: `${config.api.url}${config.api.routes.userArticleHistory}`,
        body: JSON.stringify({
            request: {
                articleName: item.name,
                articleId: item.id,
                publisherName: item.publisher,
                audioLength: item.audioLength,
                userArticleListenLength: item.time,
                didListenThroughSeries: item.isPlayingThroughSeries,
                series: item.series,
                platform: PLATFORM_WEB,
            },
        }),
    };
};
