import { isServer } from '../../store';
import { isIOS } from './browser';

export const copyText = (str: string): boolean => {
    if (isServer) {
        return false;
    }

    if (isIOS) {
        return false;
    }

    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    return true;
};

export const isEmailValid = (email: string) => {
    if (!email) {
        return false;
    }

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};

export const textToFirstLinebreak = (text?: string, maxChars?: number): string => {
    if (!text) { return ''; }

    const descToFirstLineBreak = text.split(/\r?\n/)[0];

    if (maxChars && descToFirstLineBreak.length > maxChars) {
        const shortenedDesc = descToFirstLineBreak.substr(0, maxChars);
        return `${shortenedDesc}...`;
    }

    return descToFirstLineBreak;
};
