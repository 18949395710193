import * as _ from 'lodash';

import {
    ARTICLE_CLEAR,
    ARTICLES_FAILURE,
    ARTICLES_REQUEST,
    ARTICLES_SUCCESS,
    ARTICLE_FAILURE,
    ARTICLE_REQUEST,
    ARTICLE_EMBED_SUCCESS,
    ARTICLES_NEW_REQUEST,
    ARTICLES_NEW_SUCCESS,
    ARTICLES_NEW_FAILURE,
    ARTICLE_IS_QUEUED_SUCCESS,
    ARTICLE_IS_QUEUED_PENDING,
    ARTICLE_IS_QUEUED_REJECTED,
} from '../constants/article';
import IArticle from '../types/models/IArticle';

export interface IState {
    loading: boolean;
    articles: IArticle[];
    pendingQueueArticleIds: number[];
    articlesNew?: IArticle[];
    queue: IArticle[];
    playingOnQueue: number;
    error?: Error;
    page: number;
    hasAllArticles: boolean;
    article?: IArticle;
}

interface IAction {
    type: string;
    articlesNew?: IArticle[];
    articles?: IArticle[];
    article?: IArticle;
    append?: boolean;
    isQueued?: {
        articleId: number;
        status: boolean;
    };
    error?: Error;
}

export const initialState: IState = {
    loading: false,
    articles: [],
    articlesNew: [],
    queue: [],
    playingOnQueue: 0,
    page: 0,
    pendingQueueArticleIds: [],
    hasAllArticles: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case ARTICLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARTICLES_SUCCESS: {
            const existentIds = _.map(state.articles, (a) => a.id);
            const actionArticles = action.articles || [];
            const actionArticlesCleaned = _.filter(actionArticles, (a: IArticle) => existentIds.indexOf(a.id) === -1);
            const isActionArticlesCleanedEmpty = _.isEmpty(action.articles);

            const hasAllArticles = !!(action.append && isActionArticlesCleanedEmpty && state.page !== 0);
            const page = !hasAllArticles || state.page === 0 ? state.page + 1 : state.page;

            const newArticles = action.append ? [...state.articles, ...actionArticlesCleaned] : action.articles;

            return {
                ...state,
                hasAllArticles,
                page,
                articles: newArticles,
                loading: false,
            };
        }
        case ARTICLES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case ARTICLES_NEW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARTICLES_NEW_SUCCESS:
            return {
                ...state,
                loading: true,
                articlesNew: action.articlesNew,
            };
        case ARTICLES_NEW_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case ARTICLE_CLEAR:
            return {
                ...state,
                hasAllArticles: false,
                articles: [],
                page: 0,
            };
        case ARTICLE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ARTICLE_EMBED_SUCCESS:
            return {
                ...state,
                article: {
                    ...action.article,
                    canPlay: true,
                },
                loading: false,
            };
        case ARTICLE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case ARTICLE_IS_QUEUED_SUCCESS: {
            const { articleId, status } = action.isQueued!;
            const articleIndex = state.articles.findIndex((a) => a.id === articleId);

            if (articleIndex === -1) return state;

            const updatedArticle = { ...state.articles[articleIndex], isQueued: status };
            const clonedArticles = [...state.articles];
            clonedArticles[articleIndex] = updatedArticle;

            return {
                ...state,
                articles: clonedArticles,
                pendingQueueArticleIds: state.pendingQueueArticleIds.filter((aid) => aid !== articleId),
            };
        }

        case ARTICLE_IS_QUEUED_PENDING: {
            const { articleId } = action.isQueued!;

            return {
                ...state,
                pendingQueueArticleIds: [...state.pendingQueueArticleIds, articleId],
            };
        }

        case ARTICLE_IS_QUEUED_REJECTED: {
            const { articleId } = action.isQueued!;

            return {
                ...state,
                pendingQueueArticleIds: state.pendingQueueArticleIds.filter((aid) => aid !== articleId),
            };
        }

        default:
            return state;
    }
};
