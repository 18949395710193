import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { Navbar, Container, NavbarBrand, Row } from 'reactstrap';
import IUser from '../../types/models/IUser';
import theme from '../../theme';
import config from '../../config/index';
import { isServer } from '../../../store';
import text from '../../locale';
import IconNewNoaLogo from '../../assets/icons/IconNewNoaLogo';
import newtheme from '../../newtheme';
import IconNewNoaLogoWhiteText from '../../assets/icons/IconNewNoaLogoWhiteText';

interface IProps {
    user?: IUser;
    pathname: string;
    onClickGetTheApp: () => void;
    onClickEnterprise: () => void;
    onClickHome: () => void;
    onClickLatest: () => void;
    onClickSearch: () => void;
    onClickLibrary: () => void;
    onClickSettings: () => void;
    onClickLogin: () => void;
    onClickSignup: () => void;
}

interface IState {}

class NavbarTopDesktop extends React.Component<IProps, IState> {
    public isDarkMode = () => true;

    public renderTopNavLink(
        linkText: string,
        onClickAction: () => void,
        route: string,
        hideLoggedOut: boolean = false,
        hideLoggedIn: boolean = false,
    ) {
        const { user, pathname } = this.props;

        const selected = route === config.routes.homeWebPlayer ? route === pathname : pathname.match(route);

        if ((hideLoggedOut && !user) || (hideLoggedIn && !!user)) {
            return null;
        }

        return (
            <Link
                to={route}
                className={css(
                    this.isDarkMode() ? styles.linkTopNavDarkMode : styles.linkTopNav,
                    selected && styles.active,
                )}
                onClick={onClickAction}>
                <li className={css(styles.listItem)} id={linkText}>
                    {linkText}
                </li>
            </Link>
        );
    }

    public renderHomeIcon = () => (
        <Link to={config.routes.homeWebPlayer} onClick={this.props.onClickHome}>
            {this.isDarkMode() ? (
                <IconNewNoaLogoWhiteText className={css(styles.homeIcon)} />
            ) : (
                <IconNewNoaLogo className={css(styles.homeIcon)} />
            )}
        </Link>
    );

    public render() {
        if (isServer) {
            return <div className={css(styles.navWrapper)} />;
        }

        const redirectAfterAuthQuery =
            this.props.pathname !== config.routes.login && this.props.pathname !== config.routes.signup
                ? `?redirectAfterAuth=${this.props.pathname}`
                : '';

        return (
            <div className={css(styles.navWrapper)}>
                <Navbar className={css(styles.nav, this.isDarkMode() && styles.navyNav)}>
                    <Container>
                        <Row className={css(styles.navContents)}>
                            {/* Items on the left side of navbar */}
                            <NavbarBrand>{this.renderHomeIcon()}</NavbarBrand>
                            <ul className={css(styles.list, this.isDarkMode() && styles.darkModeList)}>
                                {this.renderTopNavLink(text.nav.ABOUT, () => null, config.routes.about)}
                                {this.renderTopNavLink(text.nav.FAQ, () => null, config.routes.faq)}
                            </ul>

                            {/* Items on right side of navbar */}
                            <div className="ml-auto d-flex align-items-center">
                                <ul className={css(styles.list, this.isDarkMode() && styles.darkModeList)}>
                                    {this.renderTopNavLink(
                                        text.nav.HOME,
                                        this.props.onClickHome,
                                        config.routes.homeWebPlayer,
                                    )}
                                    {this.renderTopNavLink(
                                        text.nav.LATEST,
                                        this.props.onClickLatest,
                                        config.routes.latest,
                                    )}
                                    {this.renderTopNavLink(
                                        text.nav.SEARCH,
                                        this.props.onClickSearch,
                                        config.routes.search,
                                    )}
                                    {this.renderTopNavLink(
                                        text.nav.LOGIN,
                                        this.props.onClickLogin,
                                        `${config.routes.login}${redirectAfterAuthQuery}`,
                                        false,
                                        true,
                                    )}
                                    {this.renderTopNavLink(
                                        text.nav.MY_ACCOUNT,
                                        this.props.onClickSettings,
                                        config.routes.myAccount,
                                        true,
                                        false,
                                    )}
                                </ul>
                                {!this.props.user && (
                                    <Link
                                        to={`${config.routes.signup}${redirectAfterAuthQuery}`}
                                        className={css(newtheme.coreStyles.removeLinkDecoration)}>
                                        <button type="button" className={css(styles.learnMoreButton)}>
                                            Sign Up
                                        </button>
                                    </Link>
                                )}
                            </div>
                        </Row>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    navWrapper: {
        [newtheme.cssBreakpoints.medium]: {
            height: 70,
            minHeight: 70,
        },
        height: 80,
        minHeight: 80,
        width: '100%',
    },
    nav: {
        [newtheme.cssBreakpoints.medium]: {
            height: 70,
            minHeight: 70,
        },
        backgroundColor: 'white',
        width: '100%',
        height: 80,
        minHeight: 80,
        position: 'fixed',
        zIndex: 999,
        borderBottom: '1px solid #DDD',
        fontFamily: 'Poppins',
    },
    navContents: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    navyNav: {
        backgroundColor: newtheme.darkmodeColors.navbarNavy,
        borderBottom: 'none',
    },
    homeIcon: {
        [newtheme.cssBreakpoints.medium]: {
            height: 32,
            width: 90,
        },
        height: 40,
        width: 111,
    },
    link: {
        [`@media (min-width: ${theme.breakpoints.large}px)`]: {
            ':hover svg': {
                fill: theme.textColorSelected,
            },
            ':hover': {
                color: theme.textColorSelected,
            },
        },
        color: theme.textColorSelected,
        fontSize: '1.3rem',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    list: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 13,
        },
        color: newtheme.colors.primaryBlue,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '17px',
        padding: 0,
        margin: 0,
        marginRight: -15,
    },
    darkModeList: {
        color: newtheme.colors.white,
    },
    linkTopNav: {
        ':hover': {
            color: newtheme.colors.navy,
            borderColor: newtheme.colors.navy,
        },
        color: newtheme.colors.primaryBlue,
    },
    linkTopNavDarkMode: {
        ':hover': {
            color: newtheme.colors.primaryBlue,
            textDecoration: 'underline',
        },
        color: newtheme.colors.white,
    },
    listItem: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            padding: '5px 15px',
        },
        ':visited': {
            color: newtheme.colors.primaryBlue,
        },
        display: 'inline-block',
        padding: '5px 20px',
        cursor: 'pointer',
    },
    active: {
        color: newtheme.colors.primaryBlue,
        fontWeight: 600,
    },
    learnMoreButton: {
        ':hover': {
            transform: 'scale(1.1)',
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 14,
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
            fontSize: 12,
        },
        transition: 'transform .2s',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        border: 'none',
        fontSize: 16,
        fontWeight: 400,
        padding: '8px 20px',
        borderRadius: 10,
        maxHeight: 50,
        marginLeft: 25,
    },
});

export default NavbarTopDesktop;
