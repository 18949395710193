enum PaymentPlatform {
    STRIPE = 'STRIPE',
    STRIPE_MANUAL = 'STRIPE_MANUAL',
    IOS_IAP = 'IOS_IAP',
    IOS_IAP_TEST = 'IOS_IAP_TEST',
    ANDROID_IAP = 'ANDROID_IAP',
    ANDROID_IAP_TEST = 'ANDROID_IAP_TEST',
    MANUAL = 'MANUAL_NON_PAYING',
    FREE_OFFER = 'FREE_OFFER',
    GIFT = 'GIFT',
}

export default PaymentPlatform;
