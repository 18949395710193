import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { css, StyleSheet } from 'aphrodite';
import * as Cookies from 'js-cookie';
import * as jose from 'jose';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { toast } from 'react-toastify';
import { isServer } from '../store';
import {
    setCurrentUserFromCookie,
    getUser,
    cookieName,
    updateUser,
    socialLogin,
} from './domaindriven/auth/state/actions/user';
import { IState } from './reducers';
import Routes from './routes';
import config from './config/index';
import { saveHistory } from './actions/history';
import { getCurrentlyPlayingArticle } from './actions/player';
import IUser from './types/models/IUser';
import { openModal } from './actions/modal';
import theme from './theme';
import { getQueryString } from './utils/url';
import { mpInit, mpTrackGoogleOneTapSignIn } from './tracking/mixpanel';
import { getGiftLink, setOfferSource } from './domaindriven/auth/state/actions/usersource';
import { getLocationInfo } from './actions/location';
import newtheme from './newtheme';
import ISubscriptionProduct from './domaindriven/subscribe/types/ISubscriptionProduct';
import { getSubscriptionProduct } from './domaindriven/subscribe/state/actions/subscription';
import text from './locale';
import { LOGIN_SUCCESS } from './domaindriven/auth/state/constants/user';

interface IPropsFromState {
    user?: IUser;
    subscriptionProduct?: ISubscriptionProduct;
}

interface IPropsFromDispatch {
    setCurrentUserFromCookie: typeof setCurrentUserFromCookie;
    saveHistory: typeof saveHistory;
    getUser: typeof getUser;
    updateUser: typeof updateUser;
    openModal: typeof openModal;
    getGiftLink: typeof getGiftLink;
    getSubscriptionProduct: typeof getSubscriptionProduct;
    getLocationInfo: typeof getLocationInfo;
    socialLogin: typeof socialLogin;
    getCurrentlyPlayingArticle: typeof getCurrentlyPlayingArticle;
}

interface IInnerState {
    sentryOn: boolean;
    updatedUser: boolean;
    loadedCurrentlyPlayingArticle: boolean;
}

class App extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>, IInnerState> {
    constructor(props) {
        super(props);

        this.state = { sentryOn: false, updatedUser: false, loadedCurrentlyPlayingArticle: false };
    }

    public componentDidMount() {
        const { user } = this.props;

        if (isServer) {
            return;
        }

        mpInit();
        this.initializeSentry();
        this.updateUser();
        this.removeLoading();

        const userFromCookie = Cookies.getJSON(cookieName);
        if (userFromCookie && !user) {
            this.loadUser();
        }

        if (user) {
            this.props.saveHistory();
        }

        const queries = getQueryString(this.props.location.search);

        if (queries.linkId) {
            this.props.getUser(queries.linkId);
        }

        if (queries.giftLinkId) {
            this.props.getGiftLink(queries.giftLinkId);
        }

        // If this is the Go1 special offer then don't get any offer, just get the default
        if (queries.utm_source === 'go1') {
            this.props.getSubscriptionProduct();
        } else if (this.props.location.pathname === '/hacks') {
            this.props.getSubscriptionProduct('hacks');
        } else if (queries.offerId) {
            this.props.getSubscriptionProduct(queries.offerId);
        } else if (this.props.subscriptionProduct) {
            // Ensures the existing product gets refreshed in case of any changes
            this.props.getSubscriptionProduct(this.props.subscriptionProduct.offerId);
        } else {
            // Gets the default product
            this.props.getSubscriptionProduct();
        }

        /*
        if (queries.mpId) {
           // mpMaybeMergeId(queries.mpId);
        }
        */
    }

    public componentDidUpdate() {
        // const prevQueries = getQueryString(prevProps.location.search);
        const queries = getQueryString(this.props.location.search);

        // if (queries.offerId != null && queries.offerId !== prevQueries.offerId) {
        // this.props.getSubscriptionProduct(queries.offerId);
        // }

        if (this.props.subscriptionProduct?.isOfferExpired) {
            if (queries.embedPubName != null) {
                return window.location.replace(`${config.site.url}/home?offerId=premium`);
            }
            window.location.replace(`${config.site.url}/subscribe?offerId=premium`);
        }

        if (this.props.location.pathname === '/' && this.props.user) {
            this.props.history.push(config.routes.latest);
        }

        this.initializeSentry();
        this.updateUser();
        this.loadCurrentlyPlayingArticle();
    }

    public componentDidCatch(error: any, errorInfo: any) {
        // @ts-ignore
        const raven = window.Raven;

        if (!isServer && raven) {
            raven.captureException(error, { extra: errorInfo });
        }
    }

    public updateUser = () => {
        if (!this.props.user || this.state.updatedUser) {
            return;
        }

        this.setState({ updatedUser: true });
        this.props.updateUser();
    };

    public loadCurrentlyPlayingArticle = () => {
        if (!this.props.user || this.state.loadedCurrentlyPlayingArticle) {
            return;
        }

        this.setState((state) => ({ ...state, loadedCurrentlyPlayingArticle: true }));
        this.props.getCurrentlyPlayingArticle({ keepPaused: true });
    };

    public initializeSentry = () => {
        if (this.state.sentryOn) {
            return;
        }

        // @ts-ignore
        let raven = window.Raven;

        if (process.env.REACT_APP_NODE_ENV === 'production') {
            if (raven) {
                this.setState({ sentryOn: true });
                return raven
                    .config(config.ravenUrl, {
                        environment: process.env.REACT_APP_NODE_ENV,
                    })
                    .install();
            }

            const ravenTag = window.document.querySelector('#raven-js');

            if (ravenTag) {
                ravenTag.addEventListener('load', () => {
                    // @ts-ignore
                    raven = window.Raven;

                    if (raven) {
                        this.setState({ sentryOn: true });
                        raven
                            .config(config.ravenUrl, {
                                environment: process.env.REACT_APP_NODE_ENV,
                            })
                            .install();
                    }
                });
            }
        }
    };

    public loadUser = () => {
        this.props.setCurrentUserFromCookie();
        this.props.getUser();
    };

    public removeLoading = () => {
        const element = document.getElementById('initial-loader');

        if (!!element && !!element.parentNode) {
            element.parentNode.removeChild(element);
        }
    };

    public onGoogleLogin = (response) => {
        if (response.aud !== config.google.appId) {
            return;
        }

        const { location, subscriptionProduct } = this.props;

        this.props
            .socialLogin({
                ssoId: response.sub,
                email: response.email,
                firstName: response.given_name,
                methodTypeId: config.google.methodTypeId,
            })
            // @ts-ignore
            .then((resp) => {
                if (resp && resp.type === LOGIN_SUCCESS) {
                    this.props.getUser();

                    if (subscriptionProduct && subscriptionProduct.metadata.homePage.redirectToPage) {
                        this.props.history.push(subscriptionProduct.metadata.homePage.redirectToPage);
                        return;
                    }

                    if (location.pathname === config.routes.home) {
                        this.props.history.push(config.routes.homeWebPlayer);
                    } else {
                        window.location.reload();
                    }
                }
            });
    };

    public onGoogleFailure = () => {
        toast(text.notifications.NOT_POSSIBLE_TO_LOGIN);
    };

    public onGoogleSuccess = (response: any) => {
        console.log(response);
        const responsePayload = jose.decodeJwt(response.credential);

        if (responsePayload.sub) {
            mpTrackGoogleOneTapSignIn(response && response.select_by ? response.select_by : 'unknown');
            this.onGoogleLogin(responsePayload);
            return;
        }

        this.onGoogleFailure();
    };

    public render() {
        const { user, location } = this.props;

        return (
            <div id="app" className={css(styles.app)}>
                <div id="content" className={css(styles.background)}>
                    <GoogleOAuthProvider clientId={config.google.appId}>
                        <Routes
                            user={user}
                            location={location}
                            openModal={this.props.openModal}
                            history={this.props.history}
                        />
                        {!isServer && !user && (
                            <div style={{ display: 'none' }}>
                                <GoogleLogin
                                    onSuccess={this.onGoogleSuccess}
                                    onError={this.onGoogleFailure}
                                    useOneTap
                                    auto_select
                                    itp_support
                                />
                            </div>
                        )}
                    </GoogleOAuthProvider>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: IState): {} {
    return {
        user: state.user ? state.user.user : null,
        subscriptionProduct: state.subscription.subscriptionProductResponse, // getSelectedSubscriptionProduct(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            setCurrentUserFromCookie,
            saveHistory,
            getUser,
            updateUser,
            openModal,
            getGiftLink,
            setOfferSource,
            getLocationInfo,
            getSubscriptionProduct,
            socialLogin,
            getCurrentlyPlayingArticle,
        },
        dispatch,
    );
}

const styles = StyleSheet.create({
    app: {
        display: 'inline',
    },
    background: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        },
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
