import * as React from 'react';

interface IProps {
    className: string;
}

class IconMinus extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <circle cx="25" cy="25" r="14.6094" stroke="white" strokeWidth="2.03125" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.8125 25C17.8125 24.4822 18.2322 24.0625 18.75 24.0625L31.25 24.0625C31.7678 24.0625 32.1875 24.4822 32.1875 25C32.1875 25.5178 31.7678 25.9375 31.25 25.9375L18.75 25.9375C18.2322 25.9375 17.8125 25.5178 17.8125 25Z" fill="white" />
            </svg>
        );
    }
}

export default IconMinus;
