import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Container } from 'reactstrap';
import Loading2 from './Loading2';
import newtheme from '../newtheme';

interface IProps {
    isDark?: boolean;
    text?: string;
}

class LoadingPage extends React.Component<IProps> {
    public render() {
        return (
            <div className={css(styles.container, this.props.isDark && styles.darkBackground)}>
                <div className={css(styles.firstScreen)}>
                    <Container className={css(styles.loader)}>
                        <Loading2 center loading />
                        {this.props.text && <p className={css(styles.loadingText)}>{this.props.text}</p>}
                    </Container>
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        top: 0,
        left: 0,
        minHeight: '100vh',
        height: '100%',
        width: '100%',
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        zIndex: 1000,
    },
    darkBackground: {
        backgroundColor: newtheme.colors.backgroundNavy,
    },
    firstScreen: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '80vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loader: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingText: {
        marginTop: 15,
        color: 'white',
    },
});

export default LoadingPage;
