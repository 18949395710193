import * as React from 'react';

interface IProps {
    className?: string;
}

class QueueCheckedIcon extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={this.props.className}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.172 4C17.7088 4 17.3333 4.38802 17.3333 4.86667C17.3333 5.34531 17.7088 5.73333 18.172 5.73333L28.4946 5.73333C28.9578 5.73333 29.3333 5.34531 29.3333 4.86667C29.3333 4.38802 28.9578 4 28.4946 4L18.172 4ZM15.5333 24L3.53329 24C3.05465 24 2.66663 24.388 2.66663 24.8667C2.66663 25.3453 3.05465 25.7333 3.53329 25.7333L15.5333 25.7333C16.0119 25.7333 16.4 25.3453 16.4 24.8667C16.4 24.388 16.0119 24 15.5333 24ZM22.7208 26.8285L19.8923 23.9999C19.5538 23.6614 19.0051 23.6614 18.6666 23.9999C18.3281 24.3384 18.3281 24.8871 18.6666 25.2255L22.1076 28.6666C22.2485 28.8075 22.4258 28.8897 22.6092 28.9133C22.8667 28.9466 23.1362 28.8644 23.3339 28.6667L30.1082 21.8923C30.4467 21.5539 30.4467 21.0051 30.1082 20.6667C29.7698 20.3282 29.221 20.3282 28.8826 20.6667L22.7208 26.8285ZM3.53329 17.3333C3.05465 17.3333 2.66663 17.7214 2.66663 18.2C2.66663 18.6786 3.05465 19.0667 3.53329 19.0667L28.4666 19.0667C28.9453 19.0667 29.3333 18.6786 29.3333 18.2C29.3333 17.7214 28.9453 17.3333 28.4666 17.3333L3.53329 17.3333ZM2.66663 11.5333C2.66663 11.0547 3.05465 10.6667 3.53329 10.6667L28.4666 10.6667C28.9453 10.6667 29.3333 11.0547 29.3333 11.5333C29.3333 12.012 28.9453 12.4 28.4666 12.4L3.53329 12.4C3.05465 12.4 2.66663 12.012 2.66663 11.5333Z"
                    fill="#039BE5"
                />
            </svg>
        );
    }
}

export default QueueCheckedIcon;
