import * as React from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

class LinkedInCallbackPage extends React.Component {
    public render() {
        return <LinkedInCallback />;
    }
}

export default LinkedInCallbackPage;
