import IPlayItem from '../types/IPlayItem';
import IAdvert from '../types/models/IAdvert';
import IArticle from '../types/models/IArticle';
import ItemTypes from '../types/ItemTypes';

export const articleToPlayItem = (article: IArticle): IPlayItem => ({
    id: article.id,
    audio: article.audio,
    audioLength: article.audioLength,
    description: article.publisher.name,
    name: article.name,
    publisher: article.publisher.name,
    image: article.publisher.smallImage || '',
    type: ItemTypes.Articles,
    url: article.url,
    entity: article,
    isPlayingThroughSeries: article.isPlayingThroughSeries,
    series: article.series,
    sharedContentSourceInfo: article.sharedContentSourceInfo,
});

export const advertToPlayItem = (advert: IAdvert): IPlayItem => ({
    id: advert.id,
    audio: advert.audio,
    audioLength: advert.audioLength,
    description: advert.description || 'Noa',
    name: advert.name,
    image: advert.image || '',
    type: ItemTypes.Adverts,
    url: advert.url,
    entity: advert,
    isPlayingThroughSeries: false,
});
