import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import LazyLoad from 'react-lazy-load';
import VisibilitySensor from 'react-visibility-sensor';
import theme from '../../theme/index';
import themestyles from '../../theme/themestyles';
import IPlaylist from '../../types/models/IPlaylist';
import { event, EventCategories, EventActions } from '../../utils/metric';
import { pathnameWithoutVersion } from '../../utils/url';
import { openModal } from '../../actions/modal';
import { mpTrackButtonClick, mpTrackContentView } from '../../tracking/mixpanel';
import { MpTrackingButtons } from '../../config/buttons';
import newtheme from '../../newtheme';
import IconArticles from '../../assets/icons/IconArticles';
import IconPlayBlue from '../../assets/icons/IconPlayBlue';
import IconShareWhite from '../../assets/icons/IconShareWhite';
import { dateToString } from '../../utils/date';
import CalendarIcon from '../../assets/icons/CalendarIcon';

import QueueCheckedIcon from '../../assets/icons/QueueCheckedIcon';
import QueueUncheckedIcon from '../../assets/icons/QueueUncheckedIcon';

interface IProps {
    playlist: IPlaylist;
    hasFollow: boolean;
    openModal: typeof openModal;
    share: () => void;
    addToQueue: () => void;
    removeFromQueue: () => void;
    isPlaylistQueueLoading: boolean;
    isFullWidth?: boolean;
}

interface IState {}

class ItemPlaylist extends React.Component<IProps, IState> {
    public onShareClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.share();
        mpTrackButtonClick(MpTrackingButtons.SHARE, undefined, this.props.playlist.id, this.props.playlist.name);
    };

    public clickOnLink = (playlist: IPlaylist) => () => {
        const eventCategory = playlist.isStory ? EventCategories.STORY : EventCategories.PLAYLIST;

        event(eventCategory, EventActions.TAP, `${playlist.type}-${playlist.id}`);
    };

    public renderImage = () => {
        const { playlist, isFullWidth } = this.props;

        return (
            <LazyLoad debounce={false} offsetVertical={200}>
                <div
                    className={`${css(styles.image)} ${isFullWidth ? css(styles.fullWidthImage) : ''}`}
                    style={{
                        backgroundImage: `linear-gradient(180deg, rgba(10, 35, 55, 0) 34.18%, #0A2337 100%), url("${playlist.image}")`,
                    }}>
                    {this.renderPublisherImageList(playlist)}
                    {/* this.renderSeriesDuration(playlist) */}
                </div>
            </LazyLoad>
        );
    };

    public getDescriptionShortened = (): string => {
        const desc = this.props.playlist.description;

        if (!desc) {
            return '';
        }

        let descToFirstLineBreak = desc.split(/\r?\n/)[0];

        if (descToFirstLineBreak.length > 120) {
            descToFirstLineBreak = descToFirstLineBreak.substring(0, 116);

            descToFirstLineBreak = descToFirstLineBreak.trim();

            if (descToFirstLineBreak.charAt(descToFirstLineBreak.length - 1) !== '.') {
                descToFirstLineBreak += '...';
            }
        }

        return descToFirstLineBreak;
    };

    public getTitleShortened = (): string => {
        const title = this.props.playlist.name;

        if (!title) {
            return '';
        }

        if (this.props.isFullWidth) {
            return title;
        }

        if (title.length > 65) {
            return `${title.substring(0, 60)}...`;
        }

        return title;
    };

    public renderPublisherImageList = (playlist: IPlaylist) => {
        const { publishers } = playlist;

        if (!publishers) {
            return null;
        }

        return (
            <div className={css(styles.publisherCollage)}>
                <ul className={css(styles.publisherImages)}>
                    {publishers &&
                        publishers.map((p) => {
                            const img = p.smallImage;
                            return (
                                <li key={p.id} className={css(styles.publisherImageWrapper)}>
                                    <LazyLoad debounce={false} offsetVertical={200}>
                                        <img alt={`${p.name} logo`} className={css(styles.publisherImage)} src={img} />
                                    </LazyLoad>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };

    public renderSeriesDuration = (playlist: IPlaylist) => {
        const { durationMins } = playlist;

        return (
            <div className={css(styles.duration)}>
                <p className={css(styles.durationText)}>{durationMins} minutes</p>
            </div>
        );
    };

    public toggleQueue = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const { playlist, isPlaylistQueueLoading } = this.props;

        if (isPlaylistQueueLoading) return;

        if (playlist.isQueued) {
            this.props.removeFromQueue();
        } else {
            this.props.addToQueue();
        }
    };

    public onChangeVisibility = (isVisible: boolean) => {
        if (isVisible) {
            mpTrackContentView(this.props.playlist);
        }
    };

    public onStartSeriesClick = () => {
        mpTrackButtonClick(MpTrackingButtons.PLAY_SERIES, undefined, this.props.playlist.id, this.props.playlist.name);
    };

    public render() {
        const { playlist, isPlaylistQueueLoading } = this.props;

        const pathname = pathnameWithoutVersion(playlist.url);

        const numArticlesText = `${playlist.numItems} article${playlist.numItems === 1 ? '' : 's'}`;
        const publishedDate = playlist.firstPublishedDate && dateToString(new Date(playlist.firstPublishedDate));

        return (
            <VisibilitySensor onChange={this.onChangeVisibility}>
                <div
                    className={`${css(styles.container)} ${
                        this.props.isFullWidth ? css(styles.containerFullWidth) : ''
                    } ${
                        !this.props.isFullWidth
                            ? 'offset-sm-1 offset-md-0 col-xs-12 col-sm-10 col-md-6 col-lg-4 col-xl-4'
                            : ''
                    }`}>
                    <Link to={{ pathname }} className={`${css(styles.link)}`} onClick={this.clickOnLink(playlist)}>
                        <div className={css(styles.card)}>
                            {this.renderImage()}

                            <div className={css(styles.cardContent)}>
                                <p className={css(styles.numArticles)}>
                                    <span>
                                        <IconArticles />
                                    </span>
                                    <span> {numArticlesText}</span>
                                    {publishedDate && (
                                        <>
                                            <span>
                                                <CalendarIcon className={css(styles.calendarIcon)} />
                                            </span>
                                            <span>{publishedDate}</span>
                                        </>
                                    )}
                                </p>
                                <h2
                                    className={`${css(styles.title)} ${
                                        this.props.isFullWidth ? css(styles.fullWidthTitle) : ''
                                    }`}>
                                    {this.getTitleShortened()}
                                </h2>
                                <p
                                    className={`${css(themestyles.noaItemDesc, styles.desc)} ${
                                        this.props.isFullWidth ? css(styles.fullWidthDesc) : ''
                                    }`}>
                                    {this.getDescriptionShortened()}
                                </p>

                                <div
                                    className={css(
                                        styles.actionButtons,
                                        this.props.isFullWidth && styles.fullWidthActionButtons,
                                    )}>
                                    <Link
                                        to={{ pathname, state: { autoplay: true } }}
                                        onClick={this.onStartSeriesClick}
                                        className={css(newtheme.coreStyles.removeLinkDecoration)}>
                                        <button type="button" className={css(styles.playButton)}>
                                            <IconPlayBlue className={css(styles.iconPlayPause)} /> Play Series
                                        </button>
                                    </Link>

                                    <button
                                        className={css(styles.queueButton)}
                                        type="button"
                                        onClick={this.toggleQueue}>
                                        {isPlaylistQueueLoading ? (
                                            <ClipLoader size={24} color="#ffffff" />
                                        ) : playlist.isQueued ? (
                                            <QueueCheckedIcon className={css(styles.queueIcon)} />
                                        ) : (
                                            <QueueUncheckedIcon className={css(styles.queueIcon)} />
                                        )}
                                    </button>

                                    <IconShareWhite
                                        className={css(styles.actionIconShare)}
                                        onClick={this.onShareClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </VisibilitySensor>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        padding: '15px 7px',
        paddingBottom: 25,
        height: '100%',
    },
    containerFullWidth: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            padding: '0 15px 15px 15px',
        },
        padding: '0 0 15px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 690,
    },
    card: {
        ':hover': {
            transform: 'scale(1.02)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        backgroundColor: newtheme.darkmodeColors.cardNavy,
        height: '100%',
        borderRadius: 20,
    },
    cardContent: {
        [newtheme.cssBreakpoints.tiny]: {
            padding: '20px 16px',
        },
        padding: '20px 24px',
    },
    numArticles: {
        fontSize: 13,
        color: newtheme.darkmodeColors.fadedText,
        marginTop: -22,
        marginBottom: 13,
    },
    calendarIcon: {
        marginLeft: 16,
        marginRight: 5,
        transform: 'translateY(-1px)',
    },
    title: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 20,
            height: 75,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 24,
            height: 88,
        },
        color: newtheme.darkmodeColors.white,
        fontSize: 24,
        fontWeight: 600,
        height: 88,
        marginBottom: 10,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    fullWidthTitle: {
        height: 'auto !important',
        marginTop: 20,
    },
    desc: {
        [newtheme.cssBreakpoints.tiny]: {
            height: 55,
        },
        color: newtheme.darkmodeColors.fadedText,
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 0,
        height: 61,
        overflow: 'hidden',
    },
    fullWidthDesc: {
        height: 'auto !important',
    },
    image: {
        [newtheme.cssBreakpoints.small]: {
            paddingTop: '45%',
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingTop: '45%',
            borderRadius: 7,
        },
        width: '100%',
        height: 0,
        position: 'relative',
        paddingTop: '50%',
        overflow: 'hidden',
        backgroundColor: newtheme.darkmodeColors.cardNavy,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
    },

    fullWidthImage: {
        paddingTop: '33%',
    },

    duration: {
        position: 'absolute',
        top: 20,
        right: 24,
        borderRadius: 20,
        padding: '7px 10px',
        backdropFilter: 'blur(10px)',
        background: 'rgba(0, 0, 0, 0.4)',
    },
    durationText: {
        color: newtheme.darkmodeColors.white,
        margin: 0,
        fontSize: 13,
    },
    publisherCollage: {
        [newtheme.cssBreakpoints.tiny]: {
            top: 16,
            left: 16,
        },
        position: 'absolute',
        top: 20,
        left: 24,
    },
    publisherImages: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            height: 20,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 26,
        },
        height: 30,
        display: 'inline-block',
        alignItems: 'center',
        listStyle: 'none',
        padding: 0,
        margin: 0,
        marginRight: 8,
    },
    publisherImageWrapper: {
        display: 'inline-block',
    },
    publisherImage: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            height: 20,
            width: 20,
            borderRadius: 10,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 26,
            width: 26,
            borderRadius: 13,
        },
        height: 30,
        width: 30,
        borderRadius: 15,
        marginRight: -5,
        backgroundColor: 'white',
        display: 'block',
    },
    link: {
        textDecoration: 'none',
    },
    actionButtons: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    fullWidthActionButtons: {
        justifyContent: 'flex-start',
    },
    actionIcon: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 18,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 16,
        },
        paddingRight: 8,
        height: 20,
        maxWidth: 23,
    },
    playButton: {
        ':hover': {
            transform: 'scale(1.1)',
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.small]: {
            marginLeft: 0,
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        border: 'none',
        fontSize: 18,
        fontWeight: 400,
        padding: '10px 20px',
        borderRadius: 24,
        marginRight: 30,
    },
    iconPlayPause: {
        height: 26,
        width: 26,
        marginRight: 5,
        fill: 'white',
    },
    queueButton: {
        ':hover': {
            transform: 'scale(1.3)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },

            marginLeft: 'auto',
            marginRight: 16,
        },

        background: 'none',
        outline: 'none',
        border: 'none',
        padding: '0',
        margin: '0px',
        marginRight: 18,
        lineHeight: 0,
        transition: 'transform .2s',
    },
    queueIcon: {
        width: 29,

        [newtheme.cssBreakpoints.tiny]: {
            width: 24,
        },
    },
    actionIconShare: {
        ':hover': {
            transform: 'scale(1.3)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        height: 39,
        width: 39,

        [newtheme.cssBreakpoints.tiny]: {
            width: 33,
        },
        cursor: 'pointer',
    },
});

export default ItemPlaylist;
