export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const FOLLOW_PROFILE_REQUEST = 'FOLLOW_PROFILE_REQUEST';
export const FOLLOW_PROFILE_SUCCESS = 'FOLLOW_PROFILE_SUCCESS';
export const FOLLOW_PROFILE_FAILURE = 'FOLLOW_PROFILE_FAILURE';

export const UNFOLLOW_PROFILE_REQUEST = 'UNFOLLOW_PROFILE_REQUEST';
export const UNFOLLOW_PROFILE_SUCCESS = 'UNFOLLOW_PROFILE_SUCCESS';
export const UNFOLLOW_PROFILE_FAILURE = 'UNFOLLOW_PROFILE_FAILURE';
