import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { ClipLoader } from 'react-spinners';
import { Container, Row, Col } from 'reactstrap';
import IArticle from '../../types/models/IArticle';
import { dateToString } from '../../utils/date';
import IUser from '../../types/models/IUser';
import { openModal } from '../../actions/modal';
import newtheme from '../../newtheme';
import IconPlayCircular from '../../assets/icons/IconPlayCircular';
import IconPauseCircular from '../../assets/icons/IconPauseCircular';
import { isIE } from '../../utils/browser';

import QueueCheckedIcon from '../../assets/icons/QueueCheckedIcon';
import QueueUncheckedIcon from '../../assets/icons/QueueUncheckedIcon';

interface IProps {
    article: IArticle;
    user?: IUser;
    share: () => void;
    children?: any;
    followOrUnfollow: () => void;
    play: () => void;
    playing: boolean;
    openModal: typeof openModal;
    isArticleQueueLoading: boolean;
    removeFromQueue: () => void;
    addToQueue: () => void;
}

interface IState {}

class ViewArticle extends React.Component<IProps, IState> {
    public toggleQueue = (e: any) => {
        const { article, isArticleQueueLoading } = this.props;
        e.stopPropagation();

        if (isArticleQueueLoading) return;

        if (article.isQueued) {
            this.props.removeFromQueue();
        } else {
            this.props.addToQueue();
        }
    };

    public maybeRenderSharedByBanner = () => {
        const { article, user } = this.props;

        if (!article.sharedContentSourceInfo) {
            return null;
        }

        if (user && article.sharedContentSourceInfo.userId === user.id) {
            return null;
        }

        const isPremiumUser = user && user.effectiveSubscriptionStatus === 'Premium';

        return (
            <div className={css(shareBannerStyles.main)}>
                <p className={css(shareBannerStyles.text)}>
                    {isPremiumUser ? (
                        <>
                            This article has been
                            <br className={css(newtheme.coreStyles.showTiny)} /> recommended by{' '}
                            {article.sharedContentSourceInfo?.userName}.
                        </>
                    ) : (
                        <>
                            Listen to this article for free,
                            <br className={css(newtheme.coreStyles.showTiny)} /> courtesy of{' '}
                            {article.sharedContentSourceInfo?.userName}.
                        </>
                    )}
                </p>
                <button type="button" onClick={this.props.play} className={css(shareBannerStyles.button)}>
                    {this.props.playing ? 'Pause' : isPremiumUser ? 'Listen now' : 'Listen for free'}
                </button>
            </div>
        );
    };

    private createPlayButton() {
        const text = this.props.playing ? 'Pause' : 'Play';
        return (
            <div className={css(styles.playButtonWrapper)} onClick={this.props.play}>
                {this.props.playing ? (
                    <IconPauseCircular className={css(styles.playButtonCircle)} />
                ) : (
                    <IconPlayCircular className={css(styles.playButtonCircle)} />
                )}
                <p className={css(styles.playButtonText)}>{text}</p>

                <button className={css(styles.queueButton)} type="button" onClick={this.toggleQueue}>
                    {this.props.isArticleQueueLoading ? (
                        <ClipLoader size={24} color="#ffffff" />
                    ) : this.props.article.isQueued ? (
                        <QueueCheckedIcon className={css(styles.queueIcon)} />
                    ) : (
                        <QueueUncheckedIcon className={css(styles.queueIcon)} />
                    )}
                </button>
            </div>
        );
    }

    public render() {
        const { article } = this.props;

        if (!article) {
            return null;
        }

        return (
            <div className={css(styles.main)}>
                {!isIE && (
                    <div
                        className={css(styles.backgroundImage)}
                        style={{
                            background: `linear-gradient(0deg, rgba(3, 22, 41, 0.15), rgba(3, 22, 41, 0.15)), linear-gradient(180deg, rgba(3, 22, 41, 0) 0%, rgba(3, 22, 41, 0.75) 66.93%, #031629 100%), url(${article.image})`,
                        }}
                    />
                )}
                <Container className={css(styles.mainContent)}>
                    <Row>
                        <Col xs={12}>{this.maybeRenderSharedByBanner()}</Col>
                        <Col md={{ size: 12, offset: 0 }} lg={{ size: 10, offset: 1 }}>
                            <div className={css(styles.centerItems)}>
                                <div className={css(styles.image)} style={{ background: `url(${article.image})` }} />

                                {this.props.user && (
                                    <h5 className={css(styles.publishDate)}>
                                        Published on {dateToString(new Date(article.createdAt))}
                                    </h5>
                                )}
                                <h1 className={css(styles.title)}>{article.name}</h1>
                                <div className={css(styles.publisher)}>
                                    <img
                                        alt={`Logo of ${article.publisher.name}`}
                                        src={article.publisher.smallImage}
                                        className={css(styles.publisherImg)}
                                    />
                                    <h2 className={css(styles.publisherName)}>{article.publisher.name}</h2>
                                </div>

                                {this.createPlayButton()}
                            </div>
                        </Col>

                        <Col lg={12} className={css(styles.articleList)}>
                            {this.props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const shareBannerStyles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.medium]: {
            padding: 25,
        },
        backgroundColor: newtheme.darkmodeColors.cardNavy,
        width: '100%',
        display: 'flex',
        padding: 30,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        marginBottom: 50,
        borderRadius: 20,
    },
    text: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 22,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 20,
            marginBottom: 15,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            fontSize: 17,
        },
        margin: 0,
        fontWeight: 600,
        fontSize: 25,
        marginBottom: 20,
        textAlign: 'center',
    },
    button: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: '5',
        },
        [newtheme.cssBreakpoints.tiny]: {
            width: '100%',
        },
        ':hover': {
            backgroundColor: 'white',
            color: 'black',
            stroke: 'black',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        stroke: 'white',
        border: 'none',
        borderRadius: 10,
        fontSize: 18,
        fontWeight: 500,
        padding: '10px 40px',
        maxWidth: 300,
    },
});

const styles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.medium]: {
            paddingTop: 40,
            paddingBottom: 20,
        },
        [newtheme.cssBreakpoints.small]: {
            paddingTop: 40,
            paddingBottom: 10,
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingTop: 20,
            paddingBottom: 0,
        },
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        color: 'white',
        fontWeight: 400,
        paddingTop: 40,
        paddingBottom: 50,
        position: 'relative',
    },
    backgroundImage: {
        [newtheme.cssBreakpoints.tiny]: {
            height: 375,
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 500,
        filter: 'blur(100px)',
    },
    mainContent: {},
    image: {
        [newtheme.cssBreakpoints.medium]: {
            height: 137,
            width: 200,
            marginBottom: 35,
        },
        [newtheme.cssBreakpoints.small]: {
            height: 137,
            width: 200,
            marginBottom: 35,
        },
        [newtheme.cssBreakpoints.tiny]: {
            height: 120,
            width: 175,
            marginBottom: 15,
        },
        height: 175,
        width: 255,
        marginBottom: 35,
        borderRadius: 15,
        textAlign: 'center',
        backgroundSize: 'cover',
    },
    publishDate: {
        [newtheme.cssBreakpoints.tiny]: {
            display: 'none',
        },
        fontSize: 14,
        color: 'rgba(255, 255, 255, 0.6)',
        fontWeight: 400,
        marginBottom: 9,
    },
    title: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 42,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 36,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 32,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 20,
            marginBottom: 15,
        },
        fontSize: 42,
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: '140%',
        marginBottom: 20,
        width: '100%',
    },
    publisher: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 35,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 35,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 20,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginBottom: 45,
    },
    publisherImg: {
        [newtheme.cssBreakpoints.small]: {
            height: 30,
            width: 30,
            marginRight: 8,
        },
        [newtheme.cssBreakpoints.tiny]: {
            height: 25,
            width: 25,
            marginRight: 8,
        },
        height: 35,
        width: 35,
        borderRadius: 20,
        marginRight: 24,
    },
    publisherName: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 18,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 12,
        },
        fontSize: 19,
        fontWeight: 500,
        marginBottom: 0,
    },
    articleList: {
        [newtheme.cssBreakpoints.medium]: {
            marginTop: 30,
        },
    },
    centerItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    playButtonWrapper: {
        ':hover': {
            transform: 'scale(1.1)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
            flexDirection: 'column',
            justifyContent: 'center',
        },
        transition: 'transform .2s',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
    },
    playButtonCircle: {
        [newtheme.cssBreakpoints.medium]: {
            height: 60,
            width: 60,
            borderRadius: 30,
        },
        [newtheme.cssBreakpoints.small]: {
            height: 60,
            width: 60,
            borderRadius: 30,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginRight: 0,
            marginBottom: 15,
            marginLeft: 0,
        },
        height: 80,
        width: 80,
        borderRadius: 40,
        backgroundColor: newtheme.colors.primaryBlue,
        marginRight: 24,
        marginLeft: 30,
    },
    playButtonText: {
        [newtheme.cssBreakpoints.tiny]: {
            minWidth: 0,
            fontSize: 18,
        },
        fontSize: 24,
        fontWeight: 500,
        color: newtheme.colors.primaryBlue,
        marginBottom: 0,
        minWidth: 90,
    },
    queueButton: {
        ':hover': {
            transform: 'scale(1.3)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },

            marginLeft: 'auto',
            marginRight: 16,
        },

        background: 'none',
        outline: 'none',
        border: 'none',
        padding: '0',
        margin: '0px',
        marginRight: 18,
        lineHeight: 0,
        transition: 'transform .2s',
    },
    queueIcon: {
        width: 29,

        [newtheme.cssBreakpoints.tiny]: {
            width: 24,
        },
    },
});

export default ViewArticle;
