import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconSocialTwitter extends React.Component<IProps> {
    public render() {
        return (
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className} onClick={this.props.onClick}>
                <path d="M26.9828 6.15184C28.1212 5.29805 29.1458 4.2735 29.9427 3.07819C28.9181 3.53355 27.7228 3.87506 26.5275 3.9889C27.7797 3.24895 28.6904 2.11056 29.1458 0.687575C28.0074 1.37061 26.6982 1.88288 25.3891 2.16748C24.2507 0.972172 22.7139 0.289139 21.0063 0.289139C17.705 0.289139 15.0298 2.96435 15.0298 6.26568C15.0298 6.72104 15.0867 7.17639 15.2005 7.63175C10.2485 7.34715 5.8088 4.95653 2.84899 1.37061C2.33672 2.2244 2.05212 3.24895 2.05212 4.38734C2.05212 6.43644 3.07667 8.25786 4.72733 9.33933C3.7597 9.28241 2.79207 9.05473 1.9952 8.59938V8.6563C1.9952 11.5592 4.0443 13.9498 6.77643 14.519C6.32108 14.6328 5.75188 14.7467 5.23961 14.7467C4.84117 14.7467 4.49966 14.6898 4.10122 14.6328C4.84117 17.0235 7.06103 18.731 9.67933 18.788C7.63023 20.3817 5.06885 21.3493 2.2798 21.3493C1.76752 21.3493 1.31217 21.2924 0.856812 21.2355C3.47511 22.9431 6.60568 23.9107 10.0208 23.9107C21.0063 23.9107 26.9828 14.8605 26.9828 6.94871C26.9828 6.66412 26.9828 6.43644 26.9828 6.15184Z" />
            </svg>
        );
    }
}

export default IconSocialTwitter;
