import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { css, StyleSheet } from 'aphrodite';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IState } from '../reducers';
import { openModal, closeModal } from '../actions/modal';
import SocialSign from './SocialSign';
import Input from '../components/Input';
import text from '../locale';
import Button from '../components/buttons/Button';
import { isEmailValid } from '../utils/text';
import BaseModal from '../components/modals/newModals/BaseModal';
import theme from '../theme';
import config from '../config';
import { login, forgotPassword, signup, getUser } from '../domaindriven/auth/state/actions/user';
import { SIGNUP_SUCCESS, LOGIN_SUCCESS } from '../domaindriven/auth/state/constants/user';
import { event, EventCategories, EventActions, EventLabels } from '../utils/metric';
import IUser from '../types/models/IUser';
import IconClose from '../assets/icons/IconClose';
import newtheme from '../newtheme';
import IGuestPassText from '../types/models/IGuestPassText';
import { isServer } from '../../store';
import { mpTrackPopupView } from '../tracking/mixpanel';
import { mpTrackingPopups } from '../config/popups';
import IGiftLink from '../types/models/IGiftLink';
import { getQueryString } from '../utils/url';
import ISubscriptionProduct from '../domaindriven/subscribe/types/ISubscriptionProduct';
import { getSelectedSubscriptionProduct } from '../domaindriven/subscribe/state/selectors';

interface ILocalProps {
    showLoginFirst: boolean;
    open: boolean;
    close: any;
    guestPassText?: IGuestPassText;
    dontRedirect?: boolean;
    modalText?: {
        loginTitle: string;
        loginDesc: string;
        loginButton: string;
        signupTitle: string;
        signupDesc: string;
        signupButton: string;
    };
}

interface IPropsFromState {
    open: boolean;
    user?: IUser;
    giftLink?: IGiftLink;
    subscriptionProduct?: ISubscriptionProduct;
}

interface IPropsFromDispatch {
    login: typeof login;
    signup: typeof signup;
    forgotPassword: typeof forgotPassword;
    getUser: typeof getUser;
}

interface ILocalState {
    name: string;
    email: string;
    password: string;
    showLogin: boolean;
}

interface IText {
    title: string;
    subTitle: string;
    buttonText: string;
    switchModeText: string;
}

class AuthModal extends React.Component<
    ILocalProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps,
    ILocalState
> {
    constructor(props: ILocalProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps) {
        super(props);

        this.state = {
            name: '',
            email: '',
            password: '',
            showLogin: props.showLoginFirst,
        };
    }

    public componentDidMount = () => {
        mpTrackPopupView(mpTrackingPopups.authenticate);
    };

    public switchAuthMode = () => {
        this.setState((prevState) => ({ showLogin: !prevState.showLogin }));
    };

    public close = () => {
        this.props.close();
    };

    public submitForm = () => {
        if (this.state.showLogin) {
            this.login();
        } else {
            this.signup();
        }
    };

    public getPublisherGuestPassText = (): IGuestPassText | null => {
        const { location } = this.props;
        const queries = getQueryString(location.search);

        const { publisherId } = queries;

        if (publisherId && !Number.isNaN(+publisherId) && config.publishers[+publisherId]) {
            const publisherConfig = config.publishers[+publisherId];

            return {
                duration: 1,
                topHeadingText: undefined,
                topButtonText: 'Get 1-day free',
                bannerText: publisherConfig.guestPassBannerText,
                boxText1: publisherConfig.guestPassBoxText1,
                boxText2: publisherConfig.guestPassBoxText2,
                signupText: 'Sign up to activate your 1-day free Noa Premium pass',
                loginText: 'Log in to activate your 1-day free Noa Premium pass',
            };
        }

        return null;
    };

    public getGuestPassText = (): IGuestPassText | undefined => {
        const { giftLink, guestPassText } = this.props;

        if (guestPassText) {
            return guestPassText;
        }

        if (giftLink) {
            return {
                duration: giftLink.durationDays,
                topHeadingText: giftLink.title,
                topButtonText: giftLink.buttonText,
                bannerText: giftLink.bannerText,
                boxText1: 'Sign up to get',
                boxText2: `${giftLink.durationText} free`,
                signupText: giftLink.signupText,
                loginText: giftLink.loginText,
            };
        }

        const publisherGuestPass = this.getPublisherGuestPassText();

        if (publisherGuestPass) {
            return publisherGuestPass;
        }

        const duration = this.getGuestPassDuration();

        if (!duration) {
            return undefined;
        }

        if (duration === 1) {
            return {
                duration,
                topHeadingText: undefined,
                topButtonText: 'Get 1-day free',
                bannerText: 'All new users get a free Noa Premium pass.',
                boxText1: 'All new users',
                boxText2: 'get 1-day free',
                signupText: 'Sign up to activate your 1-day free Noa Premium pass',
                loginText: 'Log in to activate your 1-day free Noa Premium pass',
            };
        }

        if (duration < 365) {
            const durationFormatted =
                duration < 30
                    ? `${duration}-days`
                    : duration === 30
                    ? '1-month'
                    : `${Math.round(duration / 30)}-months`;
            return {
                duration,
                topHeadingText: "You've been invited to try Noa Premium for free.",
                topButtonText: `Get ${durationFormatted} free`,
                bannerText: "You've been invited to try Noa Premium for free.",
                boxText1: 'Sign up to get',
                boxText2: `${durationFormatted} free`,
                signupText: `Sign up to activate your ${durationFormatted} free Noa Premium pass`,
                loginText: `Log in to activate your ${durationFormatted} free Noa Premium pass`,
            };
        }

        if (duration === 365) {
            return {
                duration,
                topHeadingText: "You've been given a 1-year free Premium subscription.",
                topButtonText: 'Get 1-year free',
                bannerText: "You've been given a 1-year free Premium subscription.",
                boxText1: 'Sign up to get',
                boxText2: '1 year free',
                signupText: 'Sign up to activate your 1-year free Premium subscription',
                loginText: 'Log in to activate your 1-year free Premium subscription',
            };
        }

        if (duration > 365) {
            return {
                duration,
                topHeadingText: "You've been given a lifetime free Premium subscription!",
                topButtonText: 'Claim free subscription',
                bannerText: "You've been given a lifetime free Premium subscription.",
                boxText1: 'Sign up to get',
                boxText2: 'Premium free for life',
                signupText: 'Sign up to activate your lifetime free Premium subscription',
                loginText: 'Log in to activate your lifetime free Premium subscription',
            };
        }

        return {
            duration,
            topButtonText: `Get ${duration}-days free`,
            bannerText: 'All new users get a free Noa Premium pass.',
            boxText1: 'All new users',
            boxText2: `get ${duration}-days free`,
            signupText: `Sign up to activate your ${duration}-days free Noa Premium pass`,
            loginText: `Log in to activate your ${duration}-days free Noa Premium pass`,
        };
    };

    public getGuestPassDuration = (): number | undefined => {
        const urlParams = getQueryString(this.props.location.search);
        const { guestPassType } = urlParams;

        switch (guestPassType) {
            case 'y1cvlbippz1d':
                return 1;
            case 'pcjqtzugvu7d':
                return 7;
            case 'nssk43rxxw1m':
                return 30;
            case 'dxiqsi13n03m':
                return 90;
            case 'xlejibdmt06m':
                return 180;
            case '35mqpbelvw1y':
                return 365;
            case 'epx1raic2alt':
                return 365 * 100;
            default:
                return undefined;
        }
    };

    public signup = () => {
        const { name, email, password } = this.state;
        const { giftLink } = this.props;

        if (!isEmailValid(email) || !password || !name) {
            return;
        }

        const guestPassText = this.getGuestPassText();

        this.props
            .signup({
                name,
                email,
                password,
                guestPassDays: guestPassText && guestPassText.duration,
                giftLinkParam: giftLink && giftLink.giftLinkParam,
            })
            // @ts-ignore
            .then((resp) => {
                if (resp && resp.type === SIGNUP_SUCCESS) {
                    this.props.getUser();
                    this.close();
                    this.maybeRedirectAfterAuth();
                }
            });
    };

    public login = () => {
        const { email, password } = this.state;
        const { giftLink } = this.props;

        if (!isEmailValid(email) || !password) {
            return;
        }

        const guestPassText = this.getGuestPassText();

        this.props
            .login({
                email,
                password,
                guestPassDays: guestPassText && guestPassText.duration,
                giftLinkParam: giftLink && giftLink.giftLinkParam,
            })
            // @ts-ignore
            .then((resp) => {
                if (resp && resp.type === LOGIN_SUCCESS) {
                    event(EventCategories.SIGN, EventActions.SUCCESS, EventLabels.LOGIN);
                    this.props.getUser();
                    this.close();
                    this.maybeRedirectAfterAuth();
                }
            });
    };

    public maybeRedirectAfterAuth = () => {
        const { subscriptionProduct } = this.props;

        if (this.props.location.pathname !== config.routes.home) {
            return;
        }

        if (this.props.dontRedirect) {
            return;
        }

        if (subscriptionProduct && subscriptionProduct.metadata.homePage.redirectToPage) {
            this.props.history.push(subscriptionProduct.metadata.homePage.redirectToPage);
            return;
        }

        this.props.history.push(config.routes.homeWebPlayer);
    };

    public onSocialSign = () => {
        this.close();
        this.maybeRedirectAfterAuth();
    };

    public onChangeName = (value: string) => {
        this.setState({ name: value });
    };

    public onChangeEmail = (value: string) => {
        this.setState({ email: value });
    };

    public onChangePassword = (value: string) => {
        this.setState({ password: value });
    };

    public onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
    };

    public getText = (): IText => {
        const { guestPassText, modalText } = this.props;

        if (this.state.showLogin) {
            return {
                title: modalText ? modalText.loginTitle : 'Log In',
                subTitle: modalText ? modalText.loginDesc : 'Log in to begin listening.',
                buttonText: modalText ? modalText.loginButton : 'Log In',
                switchModeText: "Don't have an account?",
            };
        }

        return {
            title: modalText ? modalText.signupTitle : guestPassText ? 'Sign up' : 'One last step',
            subTitle: modalText ? modalText.signupDesc : 'Sign up for free to begin listening.',
            buttonText: modalText ? modalText.signupButton : 'Sign up and start listening',
            switchModeText: 'Already have an account?',
        };
    };

    public render() {
        const { open } = this.props;
        const { name, email, password } = this.state;

        if (isServer) {
            return <></>;
        }

        const modalText = this.getText();
        const guestPassText = this.getGuestPassText();

        return (
            <BaseModal
                modalId="ModalSignup"
                isOpen={open}
                onClose={this.close}
                title={modalText.title}
                height="EXTRA_LARGE"
                scrollable={false}
                fullScreenMobile>
                <div className={css(styles.modalWrapper)}>
                    {/* Right styles */}
                    <div className={css(rightStyles.main)}>
                        <div className={css(rightStyles.closeIconWrapper)} onClick={this.close}>
                            <IconClose className={css(rightStyles.closeIcon)} />
                        </div>

                        <h1 className={css(rightStyles.title)}>{modalText.title}</h1>
                        <p className={css(rightStyles.desc)}>{modalText.subTitle}</p>

                        <SocialSign
                            onSignIn={this.onSocialSign}
                            orientation="column"
                            guestPassDays={guestPassText ? guestPassText.duration : undefined}
                        />

                        <div className={css(rightStyles.orWithLines)}>
                            <div className={css(rightStyles.line)} />
                            <p className={css(rightStyles.or)}>Or</p>
                            <div className={css(rightStyles.line)} />
                        </div>

                        <form onSubmit={this.onSubmit} className={css(rightStyles.form)}>
                            {!this.state.showLogin && (
                                <Input
                                    id="signup-name"
                                    type="text"
                                    placeholder={text.signupModal.NAME}
                                    onChange={this.onChangeName}
                                    value={name}
                                    required
                                />
                            )}
                            <Input
                                id="signup-email"
                                type="email"
                                placeholder={text.signupModal.EMAIL}
                                onChange={this.onChangeEmail}
                                value={email}
                                required
                            />
                            <Input
                                id="signup-password"
                                type="password"
                                placeholder={text.signupModal.PASSWORD}
                                onChange={this.onChangePassword}
                                value={password}
                                required
                            />
                            <Button
                                borderRadius={10}
                                className={css(rightStyles.signupButton)}
                                text={modalText.buttonText}
                                onClick={this.submitForm}
                                submit
                            />
                            {this.state.showLogin && (
                                <Link
                                    onClick={this.close}
                                    to={config.routes.resetPasswordInit}
                                    className={css(rightStyles.link, rightStyles.forgotPasswordLink)}>
                                    Forgot your password?
                                </Link>
                            )}
                        </form>

                        <div>
                            <div className={css(rightStyles.terms)}>
                                <span>
                                    By continuing you indicate that you have read and agree to Noa&apos;s&nbsp;
                                    <Link to={config.routes.terms} target="_blank">
                                        Terms of Use
                                    </Link>
                                    &nbsp;and&nbsp;
                                    <Link to={config.routes.privacy} target="_blank">
                                        Privacy Policy
                                    </Link>
                                    .
                                </span>
                            </div>

                            <div className={css(rightStyles.links)}>
                                <a className={css(rightStyles.link)} onClick={this.switchAuthMode}>
                                    {modalText.switchModeText}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </BaseModal>
        );
    }
}

const rightStyles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.large]: {
            padding: 40,
        },
        [newtheme.cssBreakpoints.medium]: {
            padding: '40px 30px',
        },
        [newtheme.cssHeightBreakpoints.large]: {
            padding: '40px 30px',
        },
        [newtheme.cssHeightBreakpoints.medium]: {
            padding: '20px 30px',
        },
        [newtheme.cssBreakpoints.small]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        [newtheme.cssBreakpoints.tiny]: {
            padding: 20,
            paddingTop: 5,
            paddingBottom: 30,
        },
        [newtheme.cssBreakpoints.smallerThanTiny]: {
            padding: 10,
        },
        height: '100%',
        backgroundColor: 'white',
        width: 540,
        padding: '40px 60px',
    },
    title: {
        fontSize: 30,
        [newtheme.cssHeightBreakpoints.large]: {
            fontSize: 24,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 27,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 24,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 20,
        },
        width: '100%',
        textAlign: 'center',
        marginBottom: 5,
        fontWeight: 600,
    },
    desc: {
        fontSize: 16,
        [newtheme.cssHeightBreakpoints.large]: {
            fontSize: 14,
            marginBottom: 20,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 15,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
            marginBottom: 20,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
            marginBottom: 15,
        },
        width: '100%',
        textAlign: 'center',
        marginBottom: 30,
        color: '#06032F',
        whiteSpace: 'pre-wrap',
    },
    orWithLines: {
        [newtheme.cssHeightBreakpoints.large]: {
            paddingTop: 5,
            paddingBottom: 5,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            paddingTop: 5,
            paddingBottom: 5,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        paddingTop: 15,
        paddingBottom: 10,
        marginBottom: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    line: {
        height: 1,
        backgroundColor: '#C4C4C4',
        flex: 1,
    },
    or: {
        [newtheme.cssHeightBreakpoints.large]: {
            fontSize: 15,
        },
        fontSize: 18,
        fontWeight: 400,
        color: theme.textColor,
        textAlign: 'center',
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
    },
    signupButton: {
        [newtheme.cssHeightBreakpoints.large]: {
            height: 45,
            minHeight: 45,
            fontSize: 15,
            borderRadius: 5,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            marginTop: 10,
            width: '100%',
            minWidth: '100%',
            height: 55,
            minHeight: 55,
            fontSize: 16,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 40,
            minHeight: 40,
            fontSize: 13,
        },
        width: '100%',
        minWidth: '100%',
        marginTop: 10,
        height: 55,
        minHeight: 55,
        fontSize: 18,
        fontWeight: 500,
        alignSelf: 'flex-start',
        borderRadius: 10,
    },
    terms: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 12,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 10,
        },
        fontSize: 13,
        textAlign: 'left',
        color: theme.textColor,
        marginBottom: 20,
    },
    links: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    link: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        fontSize: 14,
        color: theme.linkColor,
        cursor: 'pointer',
        fontWeight: 500,
        textAlign: 'left',
    },
    forgotPasswordLink: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 12,
        },
        fontSize: 13,
        marginTop: 10,
    },
    hr: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            display: 'none',
        },
    },
    closeIconWrapper: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        right: 0,
        width: 100,
        height: 70,
    },
    closeIcon: {
        position: 'absolute',
        top: 18,
        right: 25,
        width: 16,
        height: 16,
        cursor: 'pointer',
    },
});

const styles = StyleSheet.create({
    modalWrapper: {
        [newtheme.cssBreakpoints.small]: {
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [newtheme.cssBreakpoints.tiny]: {
            padding: '30px 20px',
        },
        maxWidth: '100%',
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'row',
    },
});

function mapStateToProps(state: IState, ownProps: ILocalProps): IPropsFromState {
    return {
        open: ownProps.open,
        user: state.user ? state.user.user : undefined,
        giftLink: state.userSource.giftLink,
        subscriptionProduct: getSelectedSubscriptionProduct(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            openModal,
            login,
            forgotPassword,
            signup,
            getUser,
            closeModal,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthModal));
