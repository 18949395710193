import * as React from 'react';

import * as _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { IState } from '../reducers/index';
import { closeModal, openModal } from '../actions/modal';
import config from '../config';
import ModalShare from '../components/modals/ModalShare';
import ModalTypes from '../types/ModalTypes';
import ModalShowMoreDescription from '../components/modals/ModalShowMoreDescription';
import ModalGetTheApp from '../components/modals/newModals/ModalGetTheApp';
import ModalPremiumContent from '../components/modals/newModals/ModalPremiumContent';
import ModalCancel from '../components/modals/newModals/ModalCancel';
import AuthModal from './AuthModal';
import IGuestPassText from '../types/models/IGuestPassText';
import DownloadTheAppModal from '../domaindriven/download/containers/DownloadTheAppModal';
import { cancelSubscription } from '../domaindriven/subscribe/state/actions/subscription';
import AuthModalNew from './AuthModalNew';
import SubscriptionErrorModal from '../components/modals/newModals/SubscriptionErrorModal';

interface IPropsFromState {
    modals: ModalTypes[];
    isOpen: boolean;
    extra?: any;
}

interface IPropsFromDispatch {
    closeModal: typeof closeModal;
    cancelSubscription: typeof cancelSubscription;
}

class Modal extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps> {
    public onClose = () => {
        this.props.closeModal();
    };

    public goPremium = () => {
        this.props.history.push(config.routes.subscribe);
        this.props.closeModal();
    };

    public render() {
        const { isOpen, modals, extra } = this.props;

        if (_.size(modals) < 1) {
            return null;
        }

        const modal = modals[0];

        if (modal === ModalTypes.Share) {
            const url = extra ? extra.url : '';

            return <ModalShare close={this.onClose} isOpen={isOpen} url={url} />;
        }

        if (modal === ModalTypes.SignUpToSubscribe) {
            return (
                <AuthModal
                    close={this.onClose}
                    open={isOpen}
                    showLoginFirst={false}
                    dontRedirect
                    modalText={{
                        loginTitle: 'Nearly there...',
                        loginDesc: 'Log in to subscribe.',
                        loginButton: 'Log in',
                        signupTitle: 'Nearly there...',
                        signupDesc: 'Create an account to subscribe.',
                        signupButton: 'Create account',
                    }}
                />
            );
        }

        if (modal === ModalTypes.SignUp) {
            return <AuthModal close={this.onClose} open={isOpen} showLoginFirst={false} />;
        }

        if (modal === ModalTypes.SignIn) {
            return <AuthModal close={this.onClose} open={isOpen} showLoginFirst />;
        }

        if (modal === ModalTypes.SignUpNew) {
            return <AuthModalNew close={this.onClose} open={isOpen} showLoginFirst={false} />;
        }

        if (modal === ModalTypes.SignUpNewDirectToSignupSuccess) {
            return (
                <AuthModalNew
                    close={this.onClose}
                    open={isOpen}
                    showLoginFirst={false}
                    redirectToOverride={config.routes.signupSuccess}
                />
            );
        }

        if (modal === ModalTypes.LogInNew) {
            return <AuthModalNew close={this.onClose} open={isOpen} showLoginFirst />;
        }

        if (modal === ModalTypes.SignUpRemoveWatermark) {
            const guestPassText: IGuestPassText = {
                topHeadingText: 'Sign up',
                topButtonText: 'Sign up',
                bannerText: 'Sign up',
                boxText1: 'Sign up',
                boxText2: 'Sign up',
                signupText: 'Sign up to listen without interruption',
                loginText: 'Log in to listen without interruption',
            };
            return (
                <AuthModal
                    close={this.onClose}
                    open={isOpen}
                    showLoginFirst={false}
                    guestPassText={guestPassText}
                    dontRedirect
                />
            );
        }

        if (modal === ModalTypes.SignUpToFollowCollection) {
            const guestPassText: IGuestPassText = {
                topHeadingText: 'Sign up',
                topButtonText: 'Sign up',
                bannerText: 'Sign up',
                boxText1: 'Sign up',
                boxText2: 'Sign up',
                signupText: 'Sign up to follow this collection',
                loginText: 'Log in to follow this collection',
            };
            return (
                <AuthModal
                    close={this.onClose}
                    open={isOpen}
                    showLoginFirst={false}
                    guestPassText={guestPassText}
                    dontRedirect
                />
            );
        }

        if (modal === ModalTypes.SignUpToFollowProfile) {
            const guestPassText: IGuestPassText = {
                topHeadingText: 'Sign up',
                topButtonText: 'Sign up',
                bannerText: 'Sign up',
                boxText1: 'Sign up',
                boxText2: 'Sign up',
                signupText: 'Sign up to follow this profile',
                loginText: 'Log in to follow this profile',
            };
            return (
                <AuthModal
                    close={this.onClose}
                    open={isOpen}
                    showLoginFirst={false}
                    guestPassText={guestPassText}
                    dontRedirect
                />
            );
        }

        if (modal === ModalTypes.PremiumContent) {
            return <ModalPremiumContent />;
        }

        if (modal === ModalTypes.ShowMoreDescription) {
            return (
                <ModalShowMoreDescription
                    close={this.onClose}
                    isOpen={isOpen}
                    title={extra.title}
                    description={extra.description}
                />
            );
        }

        if (modal === ModalTypes.DownloadTheApp) {
            return <DownloadTheAppModal />;
        }

        if (modal === ModalTypes.GetTheApp) {
            return <ModalGetTheApp isFeature={false} />;
        }

        if (modal === ModalTypes.GetTheAppForFeature) {
            return <ModalGetTheApp isFeature />;
        }

        if (modal === ModalTypes.CancelSubscription) {
            return <ModalCancel />;
        }

        if (modal === ModalTypes.SubscriptionError) {
            return <SubscriptionErrorModal close={this.onClose} />;
        }

        return null;
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isOpen: state.modal.isOpen,
        modals: state.modal.modals,
        extra: state.modal.extra,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({ closeModal, cancelSubscription, openModal }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal));
