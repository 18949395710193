import { isServer } from '../../store';

// @ts-ignore
export const isAndroid = !isServer && window.android;

export const toast = (message: string) => {
    if (isAndroid) {
    // @ts-ignore
        const { android } = window;

        if (android && android.showToast) {
            android.showToast(message);
        }
    }
};

export const navigateBack = () => {
    if (isAndroid) {
    // @ts-ignore
        const { android } = window;

        if (android && android.navigateBack) {
            android.navigateBack();
        }
    }
};
