import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';

import IItem from '../../types/models/IItem';
import IPublisher from '../../types/models/IPublisher';
import { event, EventCategories, EventActions } from '../../utils/metric';
import { pathnameWithoutVersion } from '../../utils/url';
import themestyles from '../../theme/themestyles';
import theme from '../../theme';
import newtheme from '../../newtheme';

interface IProps {
    publisher: IPublisher;
}

class ItemPublisher extends React.Component<IProps> {
    public clickOnLink = (item: IItem) => () => {
        event(EventCategories.PUBLISHER, EventActions.TAP, `${item.type}-${item.id}`);
    };

    public renderImage = (placeholder?: boolean) => {
        const { publisher } = this.props;

        const style = placeholder ? {} : { backgroundImage: `url(${publisher.largeImage})` };

        return <div className={css(styles.publisherImage)} style={style} />;
    };

    public render() {
        const { publisher } = this.props;

        const pathname = pathnameWithoutVersion(publisher.url);

        return (
            <Link to={pathname} className={`${css(styles.link, styles.item)} col-6 col-md-4 col-lg-3`} onClick={this.clickOnLink(publisher)}>
                <div className={css(themestyles.noaItem, styles.itemBackground)}>
                    {this.renderImage()}
                    <h2 className={css(themestyles.noaItemTitle, styles.title)}>{publisher.name}</h2>
                </div>
            </Link>
        );
    }
}

const styles = StyleSheet.create({
    item: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 5,
        },
        padding: '15px 7px',
    },
    itemBackground: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            borderRadius: 0,
        },
        borderRadius: 10,
        padding: 15,
        paddingBottom: 0,
        height: '100%',
        width: '100%',
    },
    publisherImage: {
        '-webkit-box-shadow': '0px 0px 13px 0px rgba(0,0,0,0.10)',
        boxShadow: '0px 0px 13px 0px rgba(0,0,0,0.10)',
        borderRadius: '100%',
        width: '100%',
        paddingTop: '100%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no repeat',
        backgroundSize: 'cover',
    },
    title: {
        marginTop: 10,
        marginBottom: 0,
        textAlign: 'center',
        color: newtheme.darkmodeColors.white,
    },
    link: {
        textDecoration: 'none',
    },
});

export default ItemPublisher;
