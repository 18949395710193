export const PLAYLIST_FOLLOW_REQUEST = 'PLAYLIST_FOLLOW_REQUEST';
export const PLAYLIST_FOLLOW_SUCCESS = 'PLAYLIST_FOLLOW_SUCCESS';
export const PLAYLIST_FOLLOW_FAILURE = 'PLAYLIST_FOLLOW_FAILURE';
export const PLAYLIST_UNFOLLOW_REQUEST = 'PLAYLIST_UNFOLLOW_REQUEST';
export const PLAYLIST_UNFOLLOW_SUCCESS = 'PLAYLIST_UNFOLLOW_SUCCESS';
export const PLAYLIST_UNFOLLOW_FAILURE = 'PLAYLIST_UNFOLLOW_FAILURE';
export const GET_PLAYLISTS_REQUEST = 'GET_PLAYLISTS_REQUEST';
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const GET_PLAYLISTS_FAILURE = 'GET_PLAYLISTS_FAILURE';
export const PLAYLIST_IS_QUEUED = 'PLAYLIST_IS_QUEUED';
export const PLAYLIST_VIEW_IS_QUEUED_SUCCESS = 'PLAYLIST_VIEW_IS_QUEUED_SUCCESS';
export const PLAYLIST_VIEW_IS_QUEUED_PENDING = 'PLAYLIST_VIEW_IS_QUEUED_PENDING';
export const PLAYLIST_VIEW_IS_QUEUED_REJECTED = 'PLAYLIST_VIEW_IS_QUEUED_REJECTED';
