import * as React from 'react';

interface IProps {
    className?: string;
}

class IconArrowWithCircleBg extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M16 32C7.16344 32 -3.13124e-07 24.8366 -6.99382e-07 16C-1.08564e-06 7.16344 7.16344 -3.13124e-07 16 -6.99382e-07C24.8366 -1.08564e-06 32 7.16344 32 16C32 24.8366 24.8366 32 16 32Z" fill="#143551" />
                <path d="M18 11L23 16M23 16L18 21M23 16L8 16" stroke="#039BE5" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default IconArrowWithCircleBg;
