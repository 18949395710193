import * as React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import IArticle from '../../../types/models/IArticle';
import { IState } from '../../../reducers';
import ArticleTabs from '../components/ArticleTabs';
import { getArticles } from '../../../actions/article';
import IArticleTab from '../types/IArticleTab';

interface IProps {
    title: string;
    backgroundColor: string;
    publisherTabs?: IArticleTab[];
}

interface IPropsFromState {
    articles: IArticle[];
    loading: boolean;
}

interface IPropsFromDispatch {
    getArticles: typeof getArticles;
}

class ArticleTabsContainer extends React.Component<
    IProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps<{}>
> {
    public render() {
        return <ArticleTabs {...this.props} />;
    }
}

function mapStateToProps(state: IState): IPropsFromState {
    return {
        articles: state.article.articles,
        loading: state.article.loading,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            getArticles,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleTabsContainer));
