import { Container, Row, Col } from 'reactstrap';
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Page from '../../components/Page';
import theme from '../../theme';
import { mpTrackingScreens } from '../../config/screens';
import newtheme from '../../newtheme';
import LandingPageNewSitemap from '../../components/landingpagenew/LandingPageNewSitemap';
import NewSitemapSeparator from '../../components/landingpagenew/NewSitemapSeparator';

class SeniorBackendEngineer extends React.Component {
    public render() {
        return (
            <Page
                className={css(styles.page)}
                title="We're hiring | Senior Backend Engineer"
                trackingScreen={mpTrackingScreens.careers}
                image="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringseniorbackendengineer.png"
            >
                <Container className={css(styles.content)}>
                    <Row
                        className={css(newtheme.coreStyles.centerColHorizontal)}
                    >
                        <Col md={10} lg={8} xl={8}>
                            <img
                                alt="We are hiring cover"
                                src="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringseniorbackendengineer.png"
                                className={css(styles.topImage)}
                            />

                            <h1 className={css(styles.title)}>
                                Senior Backend Engineer
                            </h1>

                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                <span className={css(styles.titleColor)}>
                                    Salary range:
                                </span>
                                {' '}
                                €70,000-€85,000
                                <br />
                                <span className={css(styles.titleColor)}>
                                    Location:
                                </span>
                                {' '}
                                Dublin or remote
                                <br />
                                <span className={css(styles.titleColor)}>
                                    Position:
                                </span>
                                {' '}
                                Permanent, full-time
                                <br />
                            </p>
                            <br />

                            <h5 className={css(styles.subTitle)}>About Noa </h5>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                Noa is the home of audio journalism. We help our
                                listeners understand the news with spoken-word
                                articles from our partnering publishers such as
                                The New York Times, Harvard Business Review, The
                                Irish Times, The Washington Post and many more.
                            </p>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                At the core of our offering are what we call
                                {' '}
                                <i>&quot;Series&quot;</i>
                                . These are short
                                playlists, curated by our editorial team, that
                                provide multiple perspectives from multiple
                                publishers, on the same topic. This means our
                                listeners can walk away truly informed, having
                                heard all sides of the story. No more filter
                                bubbles, no more broken mirrors, and a far
                                better way to tackle fake news.
                            </p>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                Noa is available across all audio-supported
                                devices including Android, iOS, Web, Amazon
                                Alexa, Google Assistant, Apple CarPlay and
                                Android Auto.
                            </p>

                            <br />

                            <h5 className={css(styles.subTitle)}>
                                Why you will find this role attractive:
                            </h5>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                You will be given significant responsibility and
                                influence over Noa’s backend tech stack. You
                                will have the opportunity to work on and improve
                                not just our NodeJS codebase but also our
                                various AWS integrations, CI/CD pipelines,
                                databases and data analytics software. In
                                addition, you will frequently work with
                                individuals outside the product team, such as
                                with our editorial and marketing teams as well
                                as with our partnering publishers such as
                                Harvard Business Review, The Telegraph and more.
                            </p>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                You will become part of the Noa team who are
                                ambitious and highly motivated by the positive
                                impact that we can have on the world. We have a
                                proven track record and are now positioning
                                ourselves to grow significantly. We are an open
                                company that places a lot of emphasis on giving
                                people an environment where they feel free to
                                develop their careers, take on real
                                responsibility, and actively contribute in a
                                meaningful way towards the company’s goals.
                            </p>

                            <br />

                            <h5 className={css(styles.subTitle)}>
                                What you will do:
                            </h5>

                            <ul
                                className={css(
                                    styles.list,
                                    styles.standardText,
                                )}
                            >
                                <li>
                                    Develop performant RESTful APIs that feed
                                    our consumer-facing apps and/or our internal
                                    content management system;
                                </li>
                                <li>
                                    Write efficient SQL queries and perform
                                    other relational database tasks such as
                                    migrations and table schema creation;
                                </li>
                                <li>
                                    Write automated tests including unit tests
                                    and integration tests to keep our CI/CD
                                    pipelines healthy;
                                </li>
                                <li>
                                    Work on our AWS cloud architecture and
                                    implement new services as needed; and,
                                </li>
                                <li>
                                    Write clean, readable, scalable code using
                                    design patterns and architectural principles
                                    as appropriate.
                                </li>
                            </ul>

                            <br />

                            <h5 className={css(styles.subTitle)}>About you:</h5>
                            <ul
                                className={css(
                                    styles.list,
                                    styles.standardText,
                                )}
                            >
                                <li>
                                    You are an engineer at heart who loves to
                                    build things and stay up to date with the
                                    latest technologies;
                                </li>
                                <li>
                                    You have significant and demonstrable
                                    experience developing applications with
                                    NodeJS, SQL and AWS;
                                </li>
                                <li>
                                    You recognise that automated testing and
                                    CI/CD pipelines are critical to building
                                    high quality, maintainable software;
                                </li>
                                <li>
                                    You are an effective communicator who can
                                    provide the right level of technical detail
                                    relevant to a particular audience;
                                </li>
                                <li>
                                    You welcome the opinions of others and enjoy
                                    discussing solutions to problems to find the
                                    best one; and,
                                </li>
                                <li>
                                    You thrive on responsibility and love the
                                    idea of having autonomy over your work in a
                                    fast-paced and growing company.
                                </li>
                            </ul>

                            <br />

                            <h5 className={css(styles.subTitle)}>
                                Next steps:
                            </h5>
                            <p
                                className={css(
                                    styles.paragraph,
                                    styles.standardText,
                                )}
                            >
                                If this opportunity is for you, please get in touch with our recruitment manager Philomena to arrange a chat with our CTO:
                                <br />
                                <ul
                                    className={css(
                                        styles.list,
                                        styles.standardText,
                                    )}
                                >
                                    <li>
                                        <span className={css(styles.titleColor)}>
                                            Email:
                                        </span>
                                        {' '}
                                        <a href="mailto:philomena@newsoveraudio.com?subject=Senior%20Backend%20Engineer%20Application">philomena@newsoveraudio.com</a>
                                    </li>
                                    <li>
                                        <span className={css(styles.titleColor)}>
                                            Phone:
                                        </span>
                                        {' '}
                                        <a href="tel:0035319073360">+353 190 73360</a>
                                    </li>
                                </ul>

                            </p>

                        </Col>
                    </Row>
                </Container>

                <NewSitemapSeparator />
                <LandingPageNewSitemap />
            </Page>
        );
    }
}

const styles = StyleSheet.create({
    page: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 20,
            paddingTop: 50,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
            paddingTop: 30,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 16,
            paddingTop: 30,
        },
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        fontSize: 20,
        paddingTop: 80,
    },
    content: {
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
        },
        paddingBottom: 80,
        color: newtheme.darkmodeColors.slightlyFadedText,
    },
    topImage: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 75,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 40,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 40,
        },
        width: '100%',
        display: 'block',
        margin: '0 auto',
        marginBottom: 60,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 35,
            marginBottom: 40,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 30,
            marginBottom: 25,
        },
        fontSize: 40,
        marginTop: 0,
        marginBottom: 50,
        borderBottom: '1px solid #DDDDDD',
        color: newtheme.darkmodeColors.white,
    },
    subTitle: {
        fontWeight: 600,
        marginBottom: 10,
        color: newtheme.darkmodeColors.white,
        fontSize: '110%',
    },
    paragraph: {
        marginBottom: 25,
    },
    standardText: {
        fontSize: '110%',
    },
    list: {
        listStyleType: 'disc',
    },
    titleColor: {
        color: newtheme.darkmodeColors.white,
        fontWeight: 600,
    },
});

export default SeniorBackendEngineer;
