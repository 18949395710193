import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconSocialLinkedin extends React.Component<IProps> {
    public render() {
        return (
            <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className} onClick={this.props.onClick}>
                <path d="M24.5353 0.321508H2.62131C1.65368 0.321508 0.856812 1.1753 0.856812 2.19985V24C0.856812 25.0246 1.65368 25.8214 2.62131 25.8214H24.5353C25.5029 25.8214 26.3567 25.0246 26.3567 24V2.19985C26.3567 1.1753 25.5029 0.321508 24.5353 0.321508ZM8.54094 22.1786H4.78425V10.0547H8.54094V22.1786ZM6.6626 8.34715C5.41037 8.34715 4.44274 7.37952 4.44274 6.18421C4.44274 4.9889 5.41037 3.96435 6.6626 3.96435C7.8579 3.96435 8.82553 4.9889 8.82553 6.18421C8.82553 7.37952 7.8579 8.34715 6.6626 8.34715ZM22.7139 22.1786H18.9003V16.259C18.9003 14.8929 18.9003 13.0715 16.965 13.0715C14.9728 13.0715 14.6882 14.6083 14.6882 16.202V22.1786H10.9316V10.0547H14.5175V11.7054H14.5744C15.0867 10.7378 16.3389 9.71322 18.1603 9.71322C21.9739 9.71322 22.7139 12.2746 22.7139 15.519V22.1786Z" />
            </svg>
        );
    }
}

export default IconSocialLinkedin;
