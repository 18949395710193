import * as React from 'react';

import loadable from '@loadable/component';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';
import LoadingPage from '../../../components/LoadingPage';

const LoadableLandingPageNew = loadable(() => import('../components/LandingPageNew'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadLanding extends React.Component {
    render() {
        return (
            <Page noCrawl={false} trackingScreen={mpTrackingScreens.landing}>
                <LoadableLandingPageNew />
            </Page>
        );
    }
}

export default LazyLoadLanding;
