import IConfirmPayment from '../../../../types/IConfirmPayment';
import ISubscriptionProductResponse from '../../types/ISubscriptionProductResponse';
import {
    FREE_TRIAL_FAILURE,
    FREE_TRIAL_REQUEST,
    FREE_TRIAL_SUCCESS,
    CANCEL_SUBSCRIPTION_FAILURE,
    CANCEL_SUBSCRIPTION_REQUEST,
    CANCEL_SUBSCRIPTION_SUCCESS,
    GET_SUBSCRIPTION_PLANS_FAILURE,
    GET_SUBSCRIPTION_PLANS_REQUEST,
    GET_SUBSCRIPTION_PLANS_SUCCESS,
    INITIATE_SUBSCRIPTION_FAILURE,
    INITIATE_SUBSCRIPTION_REQUEST,
    INITIATE_SUBSCRIPTION_SUCCESS,
    APPLY_DISCOUNT_REQUEST,
    APPLY_DISCOUNT_SUCCESS,
    APPLY_DISCOUNT_FAILURE,
    APPLY_DISCOUNT_CLEAR,
    APPLY_DISCOUNT_APPLIED,
    RESET_SUBSCRIPTION,
    SUBSCRIPTION_PAYMENT_SUCCEEDED,
    SUBSCRIPTION_PAYMENT_FAILED,
} from '../constants/subscription';

export interface IState {
    loading: boolean;
    loadingDiscount: boolean;
    error?: any;
    discountError?: any;
    discountUrl?: string;
    discountApplied: boolean;
    subscriptionProductResponse?: ISubscriptionProductResponse;
    confirmPayment?: IConfirmPayment;
    subscriptionError?: { [key: string]: any };
    subscriptionSuccessful: boolean;
}

interface IAction {
    type: string;
    error?: Error;
    discountUrl?: string;
    subscriptionProductResponse?: ISubscriptionProductResponse;
    confirmPayment?: IConfirmPayment;
}

export const initialState: IState = {
    loading: false,
    loadingDiscount: false,
    discountApplied: false,
    subscriptionProductResponse: undefined,
    confirmPayment: undefined,
    subscriptionSuccessful: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case FREE_TRIAL_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FREE_TRIAL_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case FREE_TRIAL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CANCEL_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CANCEL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case INITIATE_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                subscriptionSuccessful: false,
                confirmPayment: undefined,
            };
        case INITIATE_SUBSCRIPTION_SUCCESS: {
            const confirmPaymentStatus = action.confirmPayment?.intentStatus;
            const paymentRequiresSCA =
                confirmPaymentStatus === 'requires_action' || confirmPaymentStatus === 'requires_payment_method';

            return {
                ...state,
                loading: paymentRequiresSCA,
                subscriptionSuccessful: !paymentRequiresSCA,
                confirmPayment: action.confirmPayment,
            };
        }
        case INITIATE_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                loading: false,
                subscriptionError: action.error,
                subscriptionSuccessful: false,
                confirmPayment: undefined,
            };
        }

        case SUBSCRIPTION_PAYMENT_SUCCEEDED:
            return {
                ...state,
                loading: false,
                error: undefined,
                subscriptionSuccessful: true,
                confirmPayment: undefined,
            };
        case SUBSCRIPTION_PAYMENT_FAILED:
            return {
                ...state,
                loading: false,
                error: undefined,
                subscriptionSuccessful: false,
                confirmPayment: undefined,
            };

        case GET_SUBSCRIPTION_PLANS_REQUEST:
            return {
                ...state,
                subscriptionProductResponse: null,
                error: null,
                loading: true,
            };
        case GET_SUBSCRIPTION_PLANS_SUCCESS:
            return {
                ...state,
                subscriptionProductResponse: action.subscriptionProductResponse,
                loading: false,
            };

        case GET_SUBSCRIPTION_PLANS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case APPLY_DISCOUNT_REQUEST:
            return {
                ...state,
                loadingDiscount: true,
                discountError: null,
                error: null,
            };
        case APPLY_DISCOUNT_SUCCESS:
            return {
                ...state,
                loadingDiscount: false,
                discountUrl: action.discountUrl,
                discountError: null,
                error: null,
            };
        case APPLY_DISCOUNT_FAILURE:
            return {
                ...state,
                loadingDiscount: false,
                discountError: action.error,
                error: null,
            };
        case APPLY_DISCOUNT_CLEAR:
            return {
                ...state,
                loadingDiscount: false,
                error: null,
                discountError: null,
                discountUrl: null,
            };
        case APPLY_DISCOUNT_APPLIED:
            return {
                ...state,
                loadingDiscount: false,
                error: null,
                discountUrl: null,
                discountError: null,
                discountApplied: true,
            };

        case RESET_SUBSCRIPTION:
            return {
                ...state,
                loading: false,
                error: null,
                subscriptionSuccessful: false,
                confirmPayment: undefined,
            };
        default:
            return state;
    }
};
