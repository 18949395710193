import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import IItem from '../../types/models/IItem';
import ISection from '../../types/models/ISection';
import { event, EventCategories, EventActions } from '../../utils/metric';
import { pathnameWithoutVersion } from '../../utils/url';
import theme from '../../theme/index';
import themestyles from '../../theme/themestyles';
import newtheme from '../../newtheme';

interface IProps {
    section: ISection;
}

class ItemSection extends React.Component<IProps> {
    public clickOnLink = (item: IItem) => () => {
        event(EventCategories.SECTION, EventActions.TAP, `${item.type}-${item.id}`);
    };

    public renderImage = (placeholder?: boolean) => {
        const { section } = this.props;

        const style = placeholder ? {} : { backgroundImage: `url(${section.image})` };

        return (
            <div className={css(styles.card, themestyles.imageAspectFill)} style={style}>
                <h2 className={css(styles.name)}>{section.name}</h2>
            </div>
        );
    };

    public render() {
        const { section } = this.props;

        const pathname = pathnameWithoutVersion(section.url);

        return (
            <Link to={pathname} className={`${css(styles.link, styles.item)} col-6 col-md-6 col-lg-4 col-xl-4`} onClick={this.clickOnLink(section)}>
                {this.renderImage()}
            </Link>
        );
    }
}

const styles = StyleSheet.create({
    item: {
        ':hover': {
            transform: 'scale(1.04)',
            cursor: 'pointer',
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            height: 175,
            padding: 10,
            marginBottom: 5,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            ':hover': {
                transform: 'none',
            },
            height: 125,
            padding: 7,
            margin: 0,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 115,
        },
        transition: 'transform .2s',
        height: 200,
        marginTop: 5,
        marginBottom: 5,
        padding: 15,
        borderRadius: 10,
    },
    card: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: newtheme.darkmodeColors.cardNavy,
        borderRadius: 10,
    },
    link: {
        textDecoration: 'none',
    },
    name: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 16,
            bottom: 15,
            left: 25,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
            bottom: 7,
            left: 15,
        },
        position: 'absolute',
        bottom: 25,
        left: 35,
        fontSize: 20,
        paddingRight: 15,
        color: 'white',
    },
});

export default ItemSection;
