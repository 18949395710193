import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import * as Modal from 'react-modal';

import theme from '../../theme/index';
import { event, EventCategories, EventActions } from '../../utils/metric';

interface IProps {
    modalId: string;
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    description?: string;
    children?: any;
    footer?: any;
    height?: 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE';
    scrollable?: boolean;
}

class NoaModal extends React.Component<IProps> {
    public onAfterOpen = () => {
        event(EventCategories.POPUP, EventActions.OPEN, this.props.modalId);
    };

    public onAfterClose = () => {
        event(EventCategories.POPUP, EventActions.CLOSE, this.props.modalId);
    };

    public getHeightClass = () => {
        switch (this.props.height) {
            case 'SMALL':
                return styles.smallHeight;
            case 'MEDIUM':
                return styles.mediumHeight;
            case 'LARGE':
                return styles.largeHeight;
            case 'EXTRA_LARGE':
                return styles.extraLargeHeight;
            default:
                return styles.largeHeight;
        }
    };

    public render() {
        const {
            children,
            footer,
            isOpen,
            onClose,
            title,
            description,
            scrollable,
        } = this.props;
        const heightClass = this.getHeightClass();

        return (
            // @ts-ignore
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                closeTimeoutMS={200}
                ariaHideApp={false}
                className={css(styles.modal, heightClass)}
                onAfterOpen={this.onAfterOpen}
                onAfterClose={this.onAfterClose}
            >
                {title && <h3 className={css(styles.title)}>{title}</h3>}
                {description && (
                    <p className={css(styles.description)}>{description}</p>
                )}
                {children && <div className={css(scrollable === false ? null : styles.body)}>{children}</div>}
                {footer && <div className={css(styles.footer)}>{footer}</div>}
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    // @ts-ignore
    modal: theme.styles.modal,
    mediumHeight: {
        height: 400,
    },
    extraLargeHeight: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            height: 500,
        },
        height: 540,
    },
    largeHeight: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            height: 450,
        },
        height: 500,
    },
    smallHeight: {
        height: 300,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 24,
        },
        textAlign: 'center',
        color: theme.title,
        paddingBottom: 20,
        fontSize: 30,
    },
    description: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 14,
        },
        fontSize: 16,
        color: theme.textColor,
        textAlign: 'center',
    },
    footer: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            marginLeft: -20,
            marginRight: -20,
            padding: '0px 10px',
        },
        backgroundColor: 'white',
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px -5px 20px 0px rgba(0, 0, 0, 0.1)',
        marginLeft: -40,
        marginRight: -40,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: '0px 20px',
    },
    body: {
        overflowY: 'auto',
        paddingBottom: 60,
    },
});

export default NoaModal;
