import { isServer } from '../../store';
import { IState } from '../reducers';

const serverFetch = require('node-fetch');

const f = (getState?: () => IState) => (
    endpoint: string,
    options: any = {},
): Promise<Response> => {
    const state = getState ? getState() : null;
    const user = state && state.user ? state.user.user : null;
    let headers: { [key: string]: string } = {
        'Content-Type': 'application/json',
    };

    if (user && user.token) {
        const token = isServer ? new Buffer(`${user.token}:${user.token}`, 'binary').toString('base64') : btoa(`${user.token}:${user.token}`);

        headers.authorization = `Basic ${token}`;
        headers['Cache-Control'] = 'no-cache';
        headers.Pragma = 'no-cache';
    }

    headers = {
        ...headers,
        ...options.headers,
    };

    if (isServer && process.env.NODE_ENV !== 'test') {
        return serverFetch(encodeURI(endpoint), {
            ...options,
            headers,
        });
    }

    return fetch(endpoint, {
        ...options,
        headers,
    });
};

export default f;
