import { Dispatch } from 'redux';
import config from '../../../../config/index';
import appFetch from '../../../../utils/fetch';
import responseCheck from '../../../../utils/response-check';
import { IState } from '../../../../reducers/index';

import IPublisherEmbedSource from '../../../../types/models/IPublisherEmbedSource';
import IOfferSource from '../../../../types/models/IOfferSource';
import {
    GET_GIFT_LINK_FAILURE, GET_GIFT_LINK_REQUEST, GET_GIFT_LINK_SUCCESS, SET_OFFER_SOURCE, SET_PUBLISHED_EMBED_SOURCE,
} from '../constants/usersource';

export const getGiftLink = (giftLinkParam: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: GET_GIFT_LINK_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.giftLink}?giftLinkParam=${giftLinkParam}`;

    return appFetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => dispatch(retrieveGiftLinkSuccess(response)))
        .catch((error) => dispatch(retrieveGiftLinkFailure(error)));
};

export const setPublisherEmbedSource = (publisherId?: string, planId?: string) => (dispatch: Dispatch) => {
    let publisherSource: IPublisherEmbedSource | undefined;

    const publisherIdNum = publisherId ? +publisherId : 0;

    if (publisherIdNum === 79 || planId === 'hbr_reader_exclusive_fng6lh') {
        publisherSource = {
            id: publisherIdNum,
            name: 'Harvard Business Review',
            shortName: 'HBR',
            subscriptionUrl: `${config.routes.subscribe}?planId=hbr_reader_exclusive_fng6lh`,
            pagePath: '/publishers/harvard-business-review-654138',
            image: 'https://images.newsoveraudio.com/publishers/icon/large/1561631587503_hbr_3x.png',
            homeHeroText: `Listen to HBR,
            & so much more.`,
            seriesCarousel: {
                title: 'Be prepared for business',
                description: 'Explore the best strategy, innovation, and leadership ideas from HBR, while also maintaining high commercial awareness.',
                seriesCategory: 'Work & Careers',
            },
            heroImage: {
                desktopWebp: '/OfferTopImage.webp',
                desktopPng: '/OfferTopImage.png',
                mobileWebp: '/OfferTopImageMobile.webp',
            },
            editorInsightsImage: '/hbr_series.webp',
        };
    } else if (publisherIdNum === 83 || planId === 'mit_tr_exclusive_xdf4go') {
        publisherSource = {
            id: publisherIdNum,
            name: 'MIT Technology Review',
            shortName: 'MIT Tech Review',
            subscriptionUrl: `${config.routes.subscribe}?planId=mit_tr_exclusive_xdf4go`,
            pagePath: '/publishers/technology-review-308829',
            image: 'https://images.newsoveraudio.com/publishers/icon/large/1586299583557_mit_tech_t.png',
            homeHeroText: `Listen to 
            MIT Tech Review,
            & so much more.`,
            seriesCarousel: {
                title: 'Say hello to Series',
                description: 'The world’s biggest stories. Told through a collection of hand-picked articles. Selected by Noa’s team of editors.',
                seriesCategory: 'Science & Technology',
            },
            heroImage: {
                desktopWebp: '/OfferTopImage.webp',
                desktopPng: '/OfferTopImage.png',
                mobileWebp: '/OfferTopImageMobile.webp',
            },
            editorInsightsImage: '/multiple_perspectives.webp',
        };
    } else if (publisherIdNum === 87 || planId === 'the_telegraph_exclusive_ne9gc') {
        publisherSource = {
            id: publisherIdNum,
            name: 'The Telegraph',
            shortName: 'The Telegraph',
            subscriptionUrl: `${config.routes.subscribe}?planId=the_telegraph_exclusive_ne9gc`,
            pagePath: '/publishers/the-telegraph-878413',
            image: 'https://images.newsoveraudio.com/publishers/icon/large/1554730124602_tg_3x.png',
            homeHeroText: `Listen to 
            The Telegraph,
            & so much more.`,
            seriesCarousel: {
                title: 'Say hello to Series',
                description: 'The world’s biggest stories. Told through a collection of hand-picked articles. Selected by Noa’s team of editors.',
                seriesCategory: 'World Affairs',
            },
            heroImage: {
                desktopWebp: '/OfferTopImage.webp',
                desktopPng: '/OfferTopImage.png',
                mobileWebp: '/OfferTopImageMobile.webp',
            },
            editorInsightsImage: '/multiple_perspectives.webp',
        };
    } else if (publisherIdNum === 97 || planId === 'the_tls_exclusive_apq0fb') {
        publisherSource = {
            id: publisherIdNum,
            name: 'the TLS',
            shortName: 'the TLS',
            subscriptionUrl: `${config.routes.subscribe}?planId=the_tls_exclusive_apq0fb`,
            pagePath: '/publishers/the-times-literary-supplement-191708',
            image: 'https://images.newsoveraudio.com/publishers/icon/large/1589495077340_tls_logo_square.jpg',
            homeHeroText: `Listen to the TLS,
            & so much more.`,
            seriesCarousel: {
                title: 'Say hello to Series',
                description: 'The world’s biggest stories. Told through a collection of hand-picked articles. Selected by Noa’s team of editors.',
                seriesCategory: 'Publisher Series',
            },
            heroImage: {
                desktopWebp: '/OfferTopImage.webp',
                desktopPng: '/OfferTopImage.png',
                mobileWebp: '/OfferTopImageMobile.webp',
            },
            editorInsightsImage: '/multiple_perspectives.webp',
        };
    } else if (publisherIdNum === 101 || planId === 'fast_company_exclusive_szm4rs') {
        publisherSource = {
            id: publisherIdNum,
            name: 'Fast Company',
            shortName: 'Fast Company',
            subscriptionUrl: `${config.routes.subscribe}?planId=fast_company_exclusive_szm4rs`,
            pagePath: '/publishers/fast-company-599114',
            image: 'https://images.newsoveraudio.com/publishers/icon/large/1605654886812_fast_company_logo_800px.png',
            homeHeroText: `Listen to 
            Fast Company,
            & so much more.`,
            seriesCarousel: {
                title: 'Be prepared for business',
                description: 'Fast Company focuses on the innovation and leadership ideas that are changing the world. They guide the progressive business leaders of tomorrow.',
                seriesCategory: 'Companies',
            },
            heroImage: {
                desktopWebp: '/FastCompanyTopImage.webp',
                desktopPng: '/FastCompanyTopImage.png',
                mobileWebp: '/FastCompanyTopImageMobile.webp',
            },
            editorInsightsImage: '/multiple_perspectives.webp',
        };
    } else if (publisherIdNum === 103 || planId === 'the_conversation_exclusive_szm4rs') {
        publisherSource = {
            id: publisherIdNum,
            name: 'The Conversation',
            shortName: 'The Conversation',
            subscriptionUrl: `${config.routes.subscribe}?planId=the_conversation_exclusive_szm4rs`,
            pagePath: '/publishers/the-conversation-603149',
            image: 'https://images.newsoveraudio.com/publishers/icon/large/1612917405952_the_conversation___square_logo.png',
            homeHeroText: `Listen to 
            The Conversation,
            & so much more.`,
            seriesCarousel: {
                title: 'Unlock expert knowledge',
                description: 'As an independent source of news and information, The Conversation brings you the best insights from the world of academic research.',
                seriesCategory: 'Science & Technology',
            },
            heroImage: {
                desktopWebp: '/ConversationTopImage.webp',
                desktopPng: '/ConversationTopImage.png',
                mobileWebp: '/ConversationTopImageMobile.webp',
            },
            editorInsightsImage: '/multiple_perspectives.webp',
        };
    }
    /* else if (publisherIdNum === 104) {
        publisherSource = {
            id: publisherIdNum,
            name: "I by IMD",
            shortName: "IMD",
            subscriptionUrl: `${config.routes.subscribe}`,
            pagePath: "/publishers/i-by-imd-658687",
            image: "XXXXX",
        };
    } */

    if (publisherSource) {
        return dispatch(setPublisherEmbedSourceSuccess(publisherSource));
    }

    return null;
};

export const setOfferSource = (offerId: string) => (dispatch: Dispatch) => {
    let offerSource: IOfferSource | undefined;

    if (!offerId) {
        return null;
    }

    if (offerId === 'sydney_cricket_ground_yvh5aa') {
        offerSource = {
            offerName: 'Sydney Cricket Ground',
            homeHeroText: `Never miss an
            opportunity to
            know more.`,
            homeHeroSubtitle: `Listen to thought-provoking journalism
            that you don't have time to read.`,
            buttonText: 'Claim Exclusive Offer',
            imagePath: '/sydneyLogo.png',
            monthlyPrice: 'A$11.99',
            subscriptionPageTitle: 'Sydney Cricket Ground exclusive member offer',
            subscriptionPageDescLine1: "Enjoy 30-days free access to the world's leading audio-journalism app.",
            subscriptionPageDescLine2: 'If you like what you hear, get 60% off an annual subscription.',
            subscriptionPageSubText: 'Offer is valid until 30th June 2021.',
            subscriptionUrl: `${config.routes.subscribe}?planId=sydney_cricket_ground_yvh5aa`,
        };
    } else if (offerId === 'prashanth_shanmugan_01') {
        offerSource = {
            offerName: 'Prashanth Shanmugan exclusive',
            homeHeroText: `Never miss an
            opportunity to
            know more.`,
            homeHeroSubtitle: `Listen to thought-provoking journalism
            that you don't have time to read.`,
            buttonText: 'Claim Exclusive Offer',
            monthlyPrice: 'A$11.99',
            subscriptionUrl: `${config.routes.subscribe}?planId=prashanth_shanmugan_01`,
            subscriptionPageTitle: `An Exclusive Offer 
            for My Friends`,
            subscriptionPageDescLine1: '"Noa is indispensable to make sense of the news. The innovative Series concept facilitates the serendipity of ideas and provides a deeper understanding of complex topics.',
            subscriptionPageDescLine2: 'Noa truly is: One App. Multiple Perspectives."',
            subscriptionPageSubText: '– Prashanth Shanmugan –',
        };
    } else if (offerId === 'bbrief') {
        offerSource = {
            offerName: 'BBrief exclusive',
            homeHeroText: `Become a better
            business leader`,
            homeHeroSubtitle: `Listen to the management articles that you
            don’t have time to read.`,
            buttonText: 'Start listening with BBrief',
            monthlyPrice: 'A$11.99',
            subscriptionUrl: `${config.routes.subscribe}?planId=bbrief`,
            subscriptionPageTitle: `An exclusive offer for
            BBrief readers`,
            subscriptionPageDescLine1: '"Enjoy 7-days free access to the world’s leading audio-journalism app.',
            subscriptionPageDescLine2: 'If you like what you hear, get 37% off an annual subscription."',
            subscriptionPageSubText: '– BBrief –',
            seriesCarousel: {
                title: 'Be prepared for business',
                description: `Explore the best strategy, innovation, and leadership ideas
                from the world's leading business minds.`,
                seriesCategory: 'Work & Careers',
            },
        };
    } else if (offerId === 'magnet') {
        offerSource = {
            offerName: 'Exclusive Magnet Offer',
            homeHeroText: `Never miss an
            opportunity to
            know more.`,
            homeHeroSubtitle: `Listen to thought-provoking journalism that you
            don't have time to read.`,
            buttonText: 'Claim Magnet Offer',
            monthlyPrice: 'A$11.99',
            subscriptionUrl: `${config.routes.subscribe}?planId=magnet`,
            subscriptionPageTitle: `An exclusive offer for
            Magnet Customers`,
            subscriptionPageDescLine1: '"Enjoy 12-months free access to the world’s leading audio-journalism app.',
            subscriptionPageDescLine2: 'If you like what you hear, get 37% off an annual subscription."',
            subscriptionPageSubText: '– Magnet –',
        };
    }

    if (offerSource) {
        return dispatch(setOfferSourceSuccess(offerSource));
    }

    return null;
};

export const retrieveGiftLinkSuccess = (response: any) => ({
    type: GET_GIFT_LINK_SUCCESS,
    giftLink: response.data.giftLink,
});

export const retrieveGiftLinkFailure = (error: Error) => ({
    type: GET_GIFT_LINK_FAILURE,
    error,
});

export const setPublisherEmbedSourceSuccess = (publisherSource: IPublisherEmbedSource) => ({
    type: SET_PUBLISHED_EMBED_SOURCE,
    publisherEmbedSource: publisherSource,
});

export const setOfferSourceSuccess = (offerSource: IOfferSource) => ({
    type: SET_OFFER_SOURCE,
    offerSource,
});
