const breakpoints = {
    minuscule: 360,
    smallerThanTiny: 400,
    tiny: 575,
    small: 767,
    medium: 991,
    large: 1199,
};

const defaultCard = {
    ':hover': {
        boxShadow: 'rgba(0, 0, 0, 0.2) 0 0 10px 4px',
    },
    ':active': {
        filter: 'brightness(0.9)',
    },
    transition: 'box-shadow 0.3s ease-in-out',
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 8px 1px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    animation: 'placeholder 3s ease infinite alternate',
};

const colors = {
    bannerBackground: '#F6F6F6',
    borderColor: '#DDD',
    primary: '#039be5',
    premium: '#c29b1f',
};

const theme = {
    colors,
    pink: '#FF3267',
    green: '#27a56e',
    premium: '#c29b1f',
    premiumHover: '#91792a',
    premiumDark: '#A98D32',
    primayDark: '#064F74',
    premiumLight: '#fffbf0',
    primaryColor: '#039be5',
    linkColor: '#0086fa',
    success: '#97C93C',
    secondaryColor: '',
    lightBackgroundColor: '',
    title: '#000000',
    white: '#FFFFFF',
    bottomPanelBackgroundColor: '#F9F9F9',
    mobileBackgroundColor: '#F3F3F3',
    normalBackgroundColor: '#FFFFFF',
    mobileItemBackgroundColor: '#FFFFFF',
    itemBackgroundColor: '#F6F6F6',
    itemBackgroundColorHover: '#E9E9E9',
    tertiaryColor: '#039BE5',
    transparent: 'transparent',
    textColor: '#878787',
    iconColor: '#878787',
    playerImageBorder: '#DDDDDD',
    playerText: '#444444',
    textColorSelected: '#636363',
    iconColorSelected: '#636363',
    gap: 'rgba(188, 188, 188, 0.5)',
    embedTitle: '#404646',
    embedSubtitle: '#828D8D',
    error: '#f44336',
    navWidth: 350,
    breakpoints,
    light: '#DCDCDC',
    styles: {
        item: {
            width: 300,
            marginLeft: 20,
            marginRight: 20,
            display: 'flex',
            flexFlow: 'column',
        },
        circleCard: {
            ...defaultCard,
            [`@media (max-width: ${breakpoints.large}px)`]: {
                width: '20vw',
                height: '20vw',
            },
            [`@media (max-width: ${breakpoints.small}px)`]: {
                width: '44vw',
                height: '44vw',
            },
            width: '10vw',
            height: '10vw',
        },
        rectangleCard: {
            ...defaultCard,
            [`@media (max-width: ${breakpoints.large}px)`]: {
                width: '20vw',
                height: '15vw',
                marginBottom: 0,
            },
            [`@media (max-width: ${breakpoints.small}px)`]: {
                width: '44vw',
                height: '30vw',
            },
            ':hover': {
                boxShadow: 'rgba(0, 0, 0, 0.7) 0px -100px 50px -40px inset, rgba(0, 0, 0, 0) 0 0 10px 4px',
            },
            boxShadow: 'rgba(0, 0, 0, 0.7) 0px -100px 50px -40px inset, rgba(0, 0, 0, 0) 0px 0px 8px 1px',
            width: 300,
            height: 208,
            overflow: 'hidden',
        },
        cardName: {
            [`@media (max-width: ${breakpoints.small}px)`]: {
                fontSize: 20,
            },
            fontSize: 24,
            color: 'white',
        },
        cardDescription: {
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': '2',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            fontSize: 14,
            padding: 10,
            width: '100%',
            color: '#878787',
            textAlign: 'center',
        },
        modal: {
            [`@media (max-width: ${breakpoints.small}px)`]: {
                width: '90vw',
                padding: '40px 20px',
                maxWidth: 380,
                top: '45%',
            },
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            left: '50%',
            width: 600,
            backgroundColor: 'white',
            boxShadow: '0 0 20px 10px rgba(0, 0, 0, 0.1)',
            padding: '40px 40px',
            borderRadius: 10,
            transform: 'translate(-50%, -50%)',
            top: '40%',
        },
    },
};

export default theme;
