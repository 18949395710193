import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import text from '../../locale/index';
import NoaModal from './NoaModal';
import Btn from '../buttons/Btn';

interface IProps {
    title: string;
    description: string;
    isOpen: boolean;
    close: () => void;
}

class ModalShowMoreDescription extends React.Component<IProps> {
    public onClose = () => {
        this.props.close();
    };

    public render() {
        const { isOpen, title, description } = this.props;

        return (
            <NoaModal
                modalId="ModalShowMoreDescription"
                isOpen={isOpen}
                onClose={this.onClose}
                title={title}
                height="LARGE"
                footer={(
                    <Btn width={170} onClick={this.onClose}>
                        {text.common.CLOSE}
                    </Btn>
                )}
            >
                <p className={css(styles.text)}>
                    {description}
                </p>
            </NoaModal>
        );
    }
}

const styles = StyleSheet.create({
    text: {
        whiteSpace: 'pre-line',
    },
});

export default ModalShowMoreDescription;
