import * as React from 'react';

interface IProps {
    className: string;
}

class IconPauseCircular extends React.Component<IProps> {
    public render() {
        return (
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <circle cx="40" cy="40" r="40" fill="#039BE5" />
                <path d="M29.875 25.8125V54.5" stroke="white" strokeOpacity="0.87" strokeWidth="6.75" strokeLinecap="round" />
                <path d="M50.125 25.8125V54.5" stroke="white" strokeOpacity="0.87" strokeWidth="6.75" strokeLinecap="round" />
            </svg>
        );
    }
}

export default IconPauseCircular;
