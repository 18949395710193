import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableLatest = loadable(() => import('../component/Latest'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadLatest extends React.Component {
    render() {
        return (
            <Page noCrawl={false} title="Latest" trackingScreen={mpTrackingScreens.articles}>
                <LoadableLatest />
            </Page>
        );
    }
}

export default LazyLoadLatest;
