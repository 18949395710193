import ISpecialAccessResponse from '../types/ISpecialAccessResponse';
import {
    EDIT_EMAIL, RESET, VERIFY_SPECIAL_ACCESS_FAILURE, VERIFY_SPECIAL_ACCESS_REQUEST, VERIFY_SPECIAL_ACCESS_SUCCESS,
} from './contants';

export interface IState {
    email: string;
    accessData: ISpecialAccessResponse;
    accessDetermined: boolean;
    loading: boolean;
    error?: any;
}

interface IAction {
    type: string;
    error?: Error;
    email?: string;
    accessData?: boolean;
}

export const initialState: IState = {
    email: '',
    accessData: {
        hasAccess: false,
        alreadyHasAccount: false,
    },
    accessDetermined: false,
    loading: false,
    error: null,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case VERIFY_SPECIAL_ACCESS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                accessData: false,
                accessDetermined: false,
            };
        case VERIFY_SPECIAL_ACCESS_SUCCESS:
            return {
                ...state,
                loading: false,
                accessData: action.accessData,
                accessDetermined: true,
            };
        case VERIFY_SPECIAL_ACCESS_FAILURE:
            return {
                ...state,
                loading: false,
                accessDetermined: false,
                accessData: false,
                error: action.error,
            };
        case EDIT_EMAIL:
            return {
                ...state,
                email: action.email,
                error: undefined,
            };
        case RESET:
            return {
                ...state,
                error: undefined,
                accessData: false,
                accessDetermined: false,
                loading: false,
            };

        default:
            return state;
    }
};
