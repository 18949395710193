import * as React from 'react';

interface IProps {
    className?: string;
}

class IconQuestionMark extends React.Component<IProps> {
    public render() {
        return (
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M16.0272 25.3333C17.1312 25.3333 18.0272 26.2293 18.0272 27.3333C18.0272 28.4373 17.1312 29.3333 16.0272 29.3333C14.9232 29.3333 14.0272 28.4373 14.0272 27.3333C14.0272 26.2293 14.9232 25.3333 16.0272 25.3333ZM16.0272 2.66666C20.4459 2.66666 24.0272 6.24799 24.0272 10.6667C24.0272 13.5533 23.0232 15.0533 20.4619 17.2307C17.8926 19.4133 17.3606 20.396 17.3606 22.6667H14.6939C14.6939 19.368 15.7432 17.74 18.7352 15.1987C20.7579 13.48 21.3606 12.5787 21.3606 10.6667C21.3606 7.71999 18.9739 5.33332 16.0272 5.33332C13.0806 5.33332 10.6939 7.71999 10.6939 10.6667V12H8.02722V10.6667C8.02722 6.24799 11.6086 2.66666 16.0272 2.66666Z" fill="#039BE5" />
            </svg>
        );
    }
}

export default IconQuestionMark;
