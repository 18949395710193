import { combineReducers } from 'redux';

import { FormStateMap, reducer as formReducer } from 'redux-form';

import { connectRouter, RouterState } from 'connected-react-router';
import { IState as IAdvertState, reducer as advertReducer, initialState as advertInitialState } from './advert';
import { IState as IArticleState, reducer as articleReducer, initialState as articleInitialState } from './article';
import {
    IState as IPublisherState,
    reducer as publisherReducer,
    initialState as publisherInitialState,
} from './publisher';
import { IState as IPlaylistState, reducer as playlistReducer, initialState as playlistInitialState } from './playlist';
import {
    IState as ICollectionState,
    reducer as collectionReducer,
    initialState as collectionInitialState,
} from './collection';
import { IState as IProfileState, reducer as profileReducer, initialState as profileInitialState } from './profile';
import { IState as IContentState, reducer as contentReducer, initialState as contentInitialState } from './content';
import { IState as IHistoryState, reducer as historyReducer, initialState as historyInitialState } from './history';
import { IState as IPlayerState, reducer as playerReducer, initialState as playerInitialState } from './player';
import { IState as ISearchState, reducer as searchReducer, initialState as searchInitialState } from './search';
import { IState as ISectionState, reducer as sectionReducer, initialState as sectionInitialState } from './section';
import {
    IState as IUserState,
    reducer as userReducer,
    initialState as userInitialState,
} from '../domaindriven/auth/state/reducers/user';
import {
    IState as IRecommendationState,
    reducer as recommendationReducer,
    initialState as recommendationInitialState,
} from './recommendation';
import { IState as IModalState, reducer as modalReducer, initialState as modalInitialState } from './modal';
import {
    IState as ISubscriptionState,
    reducer as subscriptionReducer,
    initialState as subscriptionInitialState,
} from '../domaindriven/subscribe/state/reducers/subscription';
import { IState as IAppState, reducer as appReducer, initialState as appInitialState } from './app';
import { IState as IInfoState, reducer as infoReducer, initialState as infoInitialState } from './location';
import { IState as ISmsState, reducer as smsReducer, initialState as smsInitialState } from './sms';
import { IState as IGiftState, reducer as giftReducer, initialState as giftInitialState } from './gift';
import {
    IState as IUserSourceState,
    reducer as userSourceReducer,
    initialState as userSourceInitialState,
} from '../domaindriven/auth/state/reducers/usersource';
import { IState as ICompanyState, reducer as companyReducer, initialState as companyInitialState } from './company';
import {
    IState as ISpecialAccessState,
    reducer as specialAccessReducer,
    initialState as specialAccessInitialState,
} from '../domaindriven/specialaccess/state/reducer';
import {
    IState as ICompanySubscriptionState,
    reducer as companySubscriptionReducer,
    initialState as companySubscriptionInitialState,
} from '../domaindriven/companysubscription/state/reducers/companysubscription';

const routerInitialState = { action: undefined, location: undefined };

export const initialState = {
    advert: advertInitialState,
    article: articleInitialState,
    publisher: publisherInitialState,
    playlist: playlistInitialState,
    collection: collectionInitialState,
    profile: profileInitialState,
    content: contentInitialState,
    history: historyInitialState,
    player: playerInitialState,
    search: searchInitialState,
    section: sectionInitialState,
    user: userInitialState,
    recommendation: recommendationInitialState,
    modal: modalInitialState,
    subscription: subscriptionInitialState,
    app: appInitialState,
    info: infoInitialState,
    form: {},
    sms: smsInitialState,
    router: routerInitialState,
    gift: giftInitialState,
    userSource: userSourceInitialState,
    company: companyInitialState,
    specialAccess: specialAccessInitialState,
    companySubscription: companySubscriptionInitialState,
};

export interface IState {
    form: FormStateMap;
    search: ISearchState;
    content: IContentState;
    section: ISectionState;
    article: IArticleState;
    publisher: IPublisherState;
    playlist: IPlaylistState;
    collection: ICollectionState;
    profile: IProfileState;
    user: IUserState;
    history: IHistoryState;
    advert: IAdvertState;
    player: IPlayerState;
    recommendation: IRecommendationState;
    modal: IModalState;
    subscription: ISubscriptionState;
    app: IAppState;
    info: IInfoState;
    sms: ISmsState;
    gift: IGiftState;
    userSource: IUserSourceState;
    company: ICompanyState;
    router: RouterState;
    specialAccess: ISpecialAccessState;
    companySubscription: ICompanySubscriptionState;
}

export const reducers = (hist) =>
    combineReducers({
        // auth: authReducer,
        search: searchReducer,
        content: contentReducer,
        form: formReducer,
        section: sectionReducer,
        article: articleReducer,
        publisher: publisherReducer,
        playlist: playlistReducer,
        collection: collectionReducer,
        profile: profileReducer,
        user: userReducer,
        history: historyReducer,
        advert: advertReducer,
        player: playerReducer,
        recommendation: recommendationReducer,
        modal: modalReducer,
        subscription: subscriptionReducer,
        app: appReducer,
        info: infoReducer,
        sms: smsReducer,
        gift: giftReducer,
        userSource: userSourceReducer,
        company: companyReducer,
        specialAccess: specialAccessReducer,
        companySubscription: companySubscriptionReducer,

        router: connectRouter(hist),
    });
