import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { Row, Col } from 'reactstrap';
import theme from '../theme';
import ItemFormats from '../types/ItemFormats';
import themestyles from '../theme/themestyles';

interface IProps {
    name?: string;
    subname?: string;
    description?: string;
    onClickTitle?: () => void;
    children?: any[];
    lessResultsOnSmallScreen?: boolean;
    itemsFormat: ItemFormats;
}

interface IState {
    children?: any[];
}

class List extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = { children: props.children };
    }

    public renderTitle = () => {
        const { name, onClickTitle } = this.props;

        if (!name) {
            return null;
        }

        if (onClickTitle) {
            return (
                <h4 className={css(themestyles.noaListTitle, styles.link)} onClick={onClickTitle}>
                    {name}
                    {' '}
                    <span className={css(styles.seeMore)}>See more</span>
                </h4>
            );
        }

        return <h3 className={css(themestyles.noaListTitle)}>{name}</h3>;
    };

    public renderSubname = () => {
        const { subname } = this.props;

        if (!subname) {
            return null;
        }

        return <h4 className={css(styles.subname)}>{this.props.subname}</h4>;
    };

    public renderDescription = () => {
        const { description } = this.props;

        if (!description) {
            return null;
        }

        return <h2 className={css(styles.description)}>{this.props.description}</h2>;
    };

    public componentDidMount = () => {
        this.setState({ children: this.props.children });
    };

    public componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.children !== this.props.children) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ children: this.props.children });
        }
    };

    public render() {
        const { children } = this.state;

        const title = this.renderTitle();

        return (
            <>
                <Row className={css(styles.titleDiv)}>{title && <Col xs="12">{title}</Col>}</Row>

                <Row>{children}</Row>
            </>
        );
    }
}

const styles = StyleSheet.create({
    titleDiv: {
        marginTop: '5%',
    },
    subname: {
        color: 'white',
        fontSize: 20,
        fontWeight: 400,
    },
    description: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
        },
        color: 'white',
        fontSize: 18,
        fontWeight: 400,
    },
    link: {
        ':hover > span': {
            display: 'initial',
        },
        cursor: 'pointer',
    },
    secondRow: {
        paddingTop: 20,
    },
    seeMore: {
        display: 'none',
        paddingLeft: 10,
        fontSize: 12,
    },
});

export default List;
