import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableGiftSubscription = loadable(() => import('../containers/GiftSubscription'), {
    fallback: <LoadingPage />,
});

class LazyLoadGiftSubscription extends React.Component {
    render() {
        return (
            <Page noCrawl={false} title="Gift" description="Purchase a Noa Premium gift subscription for only $/€/£25" trackingScreen={mpTrackingScreens.gift}>
                <LoadableGiftSubscription />
            </Page>
        );
    }
}

export default LazyLoadGiftSubscription;
