import {
    GET_COUNTRIES_FAILURE, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_LOCATION_FAILURE, GET_LOCATION_REQUEST, GET_LOCATION_SUCCESS, SELECT_COUNTRY,
} from '../constants/location';
import { ICountry } from '../types/ICountry';
import ILocationInfo from '../types/models/ILocationInfo';

export interface IState {
    loading: boolean;
    locationInfo?: ILocationInfo;
    countries?: ICountry[];
    error?: any;
    selectedCountryAlpha2?: string;
}

interface IAction {
    type: string;
    locationInfo?: ILocationInfo;
    countries?: ICountry[];
    error?: Error;
    selectedCountryAlpha2?: string;
}

export const initialState: IState = {
    loading: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_COUNTRIES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.countries,
                loading: false,
            };
        case GET_COUNTRIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case GET_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_LOCATION_SUCCESS:
            return {
                ...state,
                locationInfo: action.locationInfo,
                loading: false,
                selectedCountryAlpha2: state.selectedCountryAlpha2 ? state.selectedCountryAlpha2 : action.locationInfo?.countryCode,
            };
        case GET_LOCATION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                selectedCountryAlpha2: state.selectedCountryAlpha2 || 'US',
            };
        case SELECT_COUNTRY:
            return {
                ...state,
                selectedCountryAlpha2: action.selectedCountryAlpha2,
            };
        default:
            return state;
    }
};
