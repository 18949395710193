import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import theme from '../../theme';
import IUser from '../../types/models/IUser';
import IconHome from '../../assets/icons/IconNavHome';
import config from '../../config/index';
import IconNavArticles from '../../assets/icons/IconNavArticles';
import { isRouteValidForBottomNav } from '../../utils/route';
import newtheme from '../../newtheme';
import IconHomeSelected from '../../assets/icons/IconNavHomeSelected';
import IconNavArticleSelected from '../../assets/icons/IconNavArticleSelected';
import IconNavSearchSelected from '../../assets/icons/IconNavSearchSelected';
import IconNavSearch from '../../assets/icons/IconNavSearch';
import IconNavProfileSelected from '../../assets/icons/IconNavProfileSelected';
import IconNavProfile from '../../assets/icons/IconNavProfile';

interface IProps {
    user?: IUser;
    pathname: string;
    onClickHome: () => void;
    onClickLatest: () => void;
    onClickSearch: () => void;
    onClickLibrary: () => void;
    onClickSettings: () => void;
    onClickSignup: () => void;
}

interface IState {}

class NavbarBottomMobile extends React.Component<IProps, IState> {
    public isDarkMode = () => true;

    public renderHomeOptionMobile = () => {
        const selected = this.props.pathname === config.routes.homeWebPlayer;

        return (
            <li className={css(styles.li)}>
                <Link
                    to={config.routes.homeWebPlayer}
                    className={css(styles.link, this.isDarkMode() && styles.darkLink, selected && styles.active)}
                    onClick={this.props.onClickHome}>
                    <div>
                        {selected ? (
                            <IconHomeSelected className={css(styles.icon)} />
                        ) : (
                            <IconHome className={css(styles.icon)} />
                        )}
                        <p className={css(styles.menuTitle, selected && styles.selectedMenuTitle)}>Home</p>
                    </div>
                </Link>
            </li>
        );
    };

    public renderLatestOption = () => {
        const selected = this.props.pathname.match(config.routes.latest);

        return (
            <li className={css(styles.li)}>
                <Link
                    to={config.routes.latest}
                    className={css(styles.link, this.isDarkMode() && styles.darkLink, selected && styles.active)}
                    onClick={this.props.onClickLatest}>
                    <div>
                        {selected ? (
                            <IconNavArticleSelected className={css(styles.icon)} />
                        ) : (
                            <IconNavArticles className={css(styles.icon)} />
                        )}
                        <p className={css(styles.menuTitle, selected && styles.selectedMenuTitle)}>Latest</p>
                    </div>
                </Link>
            </li>
        );
    };

    public renderSearchOption = () => {
        const selected = this.props.pathname.match(config.routes.search);

        return (
            <li className={css(styles.li)}>
                <Link
                    to={config.routes.search}
                    className={css(styles.link, this.isDarkMode() && styles.darkLink, selected && styles.active)}
                    onClick={this.props.onClickSearch}>
                    <div>
                        {selected ? (
                            <IconNavSearchSelected className={css(styles.icon)} />
                        ) : (
                            <IconNavSearch className={css(styles.icon)} />
                        )}
                        <p className={css(styles.menuTitle, selected && styles.selectedMenuTitle)}>Search</p>
                    </div>
                </Link>
            </li>
        );
    };

    public renderSettingsOption = () => {
        const selected = this.props.pathname === config.routes.myAccount;

        return (
            <li className={css(styles.li)}>
                <Link
                    to={config.routes.myAccount}
                    className={css(styles.link, this.isDarkMode() && styles.darkLink, selected && styles.active)}
                    onClick={this.props.onClickSettings}>
                    <div>
                        {selected ? (
                            <IconNavProfileSelected className={css(styles.icon)} />
                        ) : (
                            <IconNavProfile className={css(styles.icon)} />
                        )}
                        <p className={css(styles.menuTitle, selected && styles.selectedMenuTitle)}>Account</p>
                    </div>
                </Link>
            </li>
        );
    };

    public renderSignupOptionMobile = () => {
        const selected = this.props.pathname === config.routes.signup || this.props.pathname === config.routes.login;

        return (
            <li className={css(styles.li)}>
                <Link
                    to={config.routes.signup}
                    className={css(styles.link, this.isDarkMode() && styles.darkLink, selected && styles.active)}
                    onClick={this.props.onClickSignup}>
                    <div>
                        <IconNavProfile
                            className={css(
                                selected && styles.iconSelected,
                                styles.icon,
                                this.isDarkMode() && styles.iconDarkMode,
                            )}
                        />
                        <p className={css(styles.menuTitle, selected && styles.selectedMenuTitle)}>Signup</p>
                    </div>
                </Link>
            </li>
        );
    };

    public renderMenuOptions = () => (
        <div className={css(!this.isDarkMode() && styles.loggedIn)}>
            <ul className={css(styles.ul)}>
                {this.renderHomeOptionMobile()}
                {this.renderLatestOption()}
                {this.renderSearchOption()}
                {!this.props.user && this.renderSignupOptionMobile()}
                {!!this.props.user && this.renderSettingsOption()}
            </ul>
        </div>
    );

    public render() {
        if (!isRouteValidForBottomNav(this.props.pathname)) {
            return null;
        }

        return (
            <nav
                className={css(
                    newtheme.coreStyles.showMedium,
                    styles.bottomNav,
                    this.isDarkMode() && styles.bottomNavNavy,
                )}>
                {this.renderMenuOptions()}
            </nav>
        );
    }
}

const styles = StyleSheet.create({
    bottomNav: {
        height: 50,
        width: '100%',
        backgroundColor: newtheme.darkmodeColors.navbarNavy,
        zIndex: 100,
    },
    bottomNavNavy: {
        backgroundColor: '#122F4C',
    },
    loggedIn: {
        borderTop: '2px solid white',
    },
    icon: {
        width: 32,
        height: 32,
    },
    iconDarkMode: {},
    iconSelected: {},
    link: {
        color: theme.textColor,
        fontSize: '1.3rem',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    darkLink: {
        color: newtheme.colors.white,
    },
    li: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            flex: 1,
            textAlign: 'center',
        },
        [`@media (min-width: ${theme.breakpoints.large + 1}px)`]: {
            paddingBottom: 15,
        },
    },
    active: {
        color: theme.textColorSelected,
        fontWeight: 500,
    },
    ul: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            flexDirection: 'row',
            display: 'flex',
            width: '100%',
            marginBottom: 0,
            marginLeft: 0,
            paddingLeft: 0,
        },
        paddingLeft: 40,
    },
    menuTitle: {
        fontSize: 11,
        margin: 0,
        padding: 0,
        paddingBottom: 5,
    },
    selectedMenuTitle: {
        color: '#039be5',
    },
});

export default NavbarBottomMobile;
