export const SECTION_REQUEST = 'SECTION_REQUEST';
export const SECTION_SUCCESS = 'SECTION_SUCCESS';
export const SECTION_SUCCESS_V4 = 'SECTION_SUCCESS_V4';
export const SECTION_FAILURE = 'SECTION_FAILURE';
export const SECTION_CLEAR = 'SECTION_CLEAR';

export const SINGLE_SECTION_REQUEST = 'SINGLE_SECTION_REQUEST';
export const SINGLE_SECTION_FAILURE = 'SINGLE_SECTION_FAILURE';
export const SINGLE_SECTION_SUCCESS = 'SINGLE_SECTION_SUCCESS';

export const SECTION_FOLLOW_REQUEST = 'SECTION_FOLLOW_REQUEST';
export const SECTION_FOLLOW_SUCCESS = 'SECTION_FOLLOW_SUCCESS';
export const SECTION_FOLLOW_FAILURE = 'SECTION_FOLLOW_FAILURE';
export const SECTION_PLAYLIST_IS_QUEUED = 'SECTION_PLAYLIST_IS_QUEUED';
export const SECTION_PLAYLIST_IS_QUEUED_SUCCESS = 'SECTION_PLAYLIST_IS_QUEUED_SUCCESS';
export const SECTION_PLAYLIST_IS_QUEUED_PENDING = 'SECTION_PLAYLIST_IS_QUEUED_PENDING';
export const SECTION_PLAYLIST_IS_QUEUED_REJECTED = 'SECTION_PLAYLIST_IS_QUEUED_REJECTED';
