import {
    SECTION_CLEAR,
    SECTION_FAILURE,
    SECTION_REQUEST,
    SECTION_SUCCESS,
    SECTION_FOLLOW_FAILURE,
    SECTION_FOLLOW_REQUEST,
    SECTION_FOLLOW_SUCCESS,
    SECTION_SUCCESS_V4,
    SINGLE_SECTION_REQUEST,
    SINGLE_SECTION_FAILURE,
    SINGLE_SECTION_SUCCESS,
    // SECTION_PLAYLIST_IS_QUEUED,
} from '../constants/section';
import ISection from '../types/models/ISection';
import ISectionWithPlaylists from '../types/models/ISectionWithPlaylists';

export interface IState {
    loading: boolean;
    sections: ISection[];
    sectionsV4: any[];
    section?: ISectionWithPlaylists;
    error?: any;
}

interface IAction {
    type: string;
    sections?: any;
    section?: ISectionWithPlaylists;
    error?: Error;
    isQueued?: {
        playlistId: number;
        status: boolean;
    };
}

export const initialState: IState = {
    loading: false,
    sections: [],
    sectionsV4: [],
    section: undefined,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SECTION_REQUEST:
            return {
                ...state,
                sections: undefined,
                loading: true,
            };
        case SECTION_SUCCESS:
            return {
                ...state,
                sections: action.sections,
                loading: false,
            };
        case SECTION_SUCCESS_V4:
            return {
                ...state,
                sectionsV4: action.sections,
                loading: false,
            };
        case SECTION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SECTION_CLEAR:
            return {
                ...state,
                sections: [],
            };

        case SINGLE_SECTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SINGLE_SECTION_SUCCESS:
            return {
                ...state,
                loading: true,
                section: action.section,
            };

        case SINGLE_SECTION_FAILURE:
            return {
                loading: false,
            };

        case SECTION_FOLLOW_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SECTION_FOLLOW_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case SECTION_FOLLOW_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
