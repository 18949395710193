export const GET_COLLECTION_REQUEST = 'GET_COLLECTION_REQUEST';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_FAILURE = 'GET_COLLECTION_FAILURE';

export const GET_COLLECTIONS_REQUEST = 'GET_COLLECTIONS_REQUEST';
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS';
export const GET_COLLECTIONS_FAILURE = 'GET_COLLECTIONS_FAILURE';

export const FOLLOW_COLLECTION_REQUEST = 'FOLLOW_COLLECTION_REQUEST';
export const FOLLOW_COLLECTION_SUCCESS = 'FOLLOW_COLLECTION_SUCCESS';
export const FOLLOW_COLLECTION_FAILURE = 'FOLLOW_COLLECTION_FAILURE';

export const UNFOLLOW_COLLECTION_REQUEST = 'UNFOLLOW_COLLECTION_REQUEST';
export const UNFOLLOW_COLLECTION_SUCCESS = 'UNFOLLOW_COLLECTION_SUCCESS';
export const UNFOLLOW_COLLECTION_FAILURE = 'UNFOLLOW_COLLECTION_FAILURE';
