import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// @ts-ignore
import * as React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { hydrate, render } from 'react-dom';
import { loadableReady } from '@loadable/component';
import { Provider } from 'react-redux';

import createStore from './store';

import App from './app/app';
import 'bootstrap/dist/css/bootstrap.min.css';

// Create a store and get back itself and its history object
const { store, history } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
    <Provider store={store}>
        <React.Profiler id="Main" onRender={onRenderCallback}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </React.Profiler>
    </Provider>
);

function onRenderCallback(

) {
    // Aggregate or log render timings...
}

const root = document.querySelector('#root');

if (root && root.hasChildNodes() === true) {
    // If it's an SSR, we use hydrate to get fast page loads by just
    // attaching event listeners after the initial render
    loadableReady(() => {
        hydrate(Application, root);
    });
} else {
    // If we're not running on the server, just render like normal
    render(Application, root);
}
