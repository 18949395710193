import * as React from 'react';

interface IProps {
    className?: string;
}

class IconCompass extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M16 29.3333C8.63596 29.3333 2.66663 23.364 2.66663 16C2.66663 8.63599 8.63596 2.66666 16 2.66666C23.364 2.66666 29.3333 8.63599 29.3333 16C29.3333 23.364 23.364 29.3333 16 29.3333ZM16 26.6667C18.8289 26.6667 21.542 25.5429 23.5424 23.5425C25.5428 21.5421 26.6666 18.829 26.6666 16C26.6666 13.171 25.5428 10.4579 23.5424 8.45752C21.542 6.45713 18.8289 5.33332 16 5.33332C13.171 5.33332 10.4579 6.45713 8.45749 8.45752C6.4571 10.4579 5.33329 13.171 5.33329 16C5.33329 18.829 6.4571 21.5421 8.45749 23.5425C10.4579 25.5429 13.171 26.6667 16 26.6667ZM20.6666 11.3333L18 18L11.3333 20.6667L14 14L20.6666 11.3333Z" fill="#039BE5" />
            </svg>
        );
    }
}

export default IconCompass;
