import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { ClipLoader } from 'react-spinners';

import { Col } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import VisibilitySensor from 'react-visibility-sensor';
import { dateToString } from '../../utils/date';
import IArticle from '../../types/models/IArticle';
import IconListened from '../../assets/icons/IconListened';
import { isIE } from '../../utils/browser';
import { openModal } from '../../actions/modal';
import { mpTrackButtonClick, mpTrackContentView } from '../../tracking/mixpanel';
import { MpTrackingButtons } from '../../config/buttons';
import newtheme from '../../newtheme';
import IconPlayBlue from '../../assets/icons/IconPlayBlue';
import IconShareWhite from '../../assets/icons/IconShareWhite';
import IconPauseBlue from '../../assets/icons/IconPauseBlue';
import IconLock from '../../assets/icons/IconLock';
import InfoIcon from '../../assets/icons/InfoIcon';
import MicIcon from '../../assets/icons/MicIcon';
import QueueUncheckedIcon from '../../assets/icons/QueueUncheckedIcon';
import QueueCheckedIcon from '../../assets/icons/QueueCheckedIcon';

interface IProps {
    article: IArticle;
    share: () => void;
    playOrPause: () => void;
    followOrUnfollow: () => void;
    openModal: typeof openModal;
    hasFollow: boolean;
    playing: boolean;
    isPlayingAudio: boolean;
    removeGap?: boolean;
    addToQueue: () => void;
    removeFromQueue: () => void;
    isArticleQueueLoading: boolean;
    displayResurfacedMessage?: boolean;
}

interface IState {
    hover: boolean;
    hoverOverShare: boolean;
}

class ItemArticle extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { hover: false, hoverOverShare: false };
    }

    public onMouseEnter = () => {
        this.setState({ hover: !isMobile });
    };

    public onMouseLeave = () => {
        this.setState({ hover: false });
    };

    public onMouseEnterShare = () => {
        this.setState({ hoverOverShare: !isMobile });
    };

    public onMouseLeaveShare = () => {
        this.setState({ hoverOverShare: false });
    };

    public getMinutesText = (audioLength: string) => {
        const under1Min = +audioLength <= 0;
        const minutesLong = under1Min ? '<1' : audioLength;
        let minutesText = `${minutesLong} minute`;
        minutesText = minutesLong === '1' || under1Min ? minutesText : `${minutesText}s`;

        return minutesText;
    };

    public audioLength = () => {
        const { article } = this.props;

        const audioLength = Math.round(article.audioLength / 60);

        return `${audioLength}`;
    };

    public renderImage = (placeholder?: boolean) => {
        const { article, playing } = this.props;

        const showListened = !playing && !isIE && article.wasListened;

        const style = placeholder ? {} : { background: `url(${article.image})`, backgroundPosition: 'center' };

        return (
            <div
                className={css(styles.articleImage, showListened && styles.shadow, playing && styles.playingShadow)}
                style={style}>
                {showListened && <IconListened className={css(styles.iconImage)} />}
            </div>
        );
    };

    public onShareClick = (e: any) => {
        e.stopPropagation();
        this.props.share();
        mpTrackButtonClick(MpTrackingButtons.SHARE, this.props.article);
    };

    public toggleQueue = (e: any) => {
        const { article, isArticleQueueLoading } = this.props;
        e.stopPropagation();

        if (isArticleQueueLoading) return;

        if (article.isQueued) {
            this.props.removeFromQueue();
        } else {
            this.props.addToQueue();
        }
    };

    public onChangeVisibility = (isVisible: boolean) => {
        if (isVisible) {
            mpTrackContentView(this.props.article);
        }
    };

    public render() {
        const { article, playing, isPlayingAudio, isArticleQueueLoading } = this.props;
        const { hover, hoverOverShare } = this.state;

        const showPlayHover = hover && !hoverOverShare;

        const showPauseIcon = playing && isPlayingAudio;

        const audioLength = this.audioLength();
        const publishedDate = dateToString(
            article.viewDateTime
                ? new Date(article.viewDateTime).getTime() > new Date().getTime()
                    ? new Date()
                    : new Date(article.viewDateTime)
                : article.lastPublishedDate
                ? new Date(article.lastPublishedDate)
                : new Date(article.createdAt),
        );

        return (
            <VisibilitySensor onChange={this.onChangeVisibility}>
                <Col xs={12} className={css(styles.container)}>
                    <div
                        className={css(styles.card)}
                        onClick={this.props.playOrPause}
                        onMouseOver={this.onMouseEnter}
                        onMouseLeave={this.onMouseLeave}>
                        {article.isArticleResurfaced && this.props.displayResurfacedMessage && (
                            <p className={css(styles.resurfacedInfo)}>
                                <span>
                                    <InfoIcon className={css(styles.infoIcon)} />
                                </span>
                                <span>This is an old article, resurfaced for you</span>
                            </p>
                        )}
                        <div className={css(styles.cardInner)}>
                            <div className={css(styles.topSection)}>
                                <div className={css(styles.infoText)}>
                                    <div className={css(styles.articleInfoAndLock)}>
                                        <div className={css(styles.articleInfo)}>
                                            <img
                                                alt={`${article.publisher.name} logo`}
                                                src={article.publisher.smallImage}
                                                className={css(styles.publisherImage)}
                                            />
                                            <div className={css(styles.timeAndPublisher)}>
                                                <p className={`${css(styles.publisherText)}`}>
                                                    {article.publisher.name}
                                                </p>
                                                <p className={css(styles.timeAndDate)}>
                                                    <span>
                                                        <MicIcon className={css(styles.micIcon)} />
                                                    </span>
                                                    <span>{publishedDate}</span>
                                                </p>
                                            </div>
                                        </div>
                                        {article.isPremium && !article.canPlay && (
                                            <IconLock className={css(styles.premiumIcon)} />
                                        )}
                                    </div>

                                    <h2 className={css(styles.title)}>{article.name}</h2>
                                </div>

                                {this.renderImage()}
                            </div>

                            <div className={css(styles.bottomSection)}>
                                <button
                                    type="button"
                                    className={css(styles.playButton, showPlayHover && styles.buttonHover)}>
                                    {showPauseIcon ? (
                                        <IconPauseBlue className={css(styles.iconPlayPause)} />
                                    ) : (
                                        <IconPlayBlue className={css(styles.iconPlayPause)} />
                                    )}{' '}
                                    {this.getMinutesText(audioLength)}
                                </button>

                                <button
                                    className={css(styles.queueButton)}
                                    type="button"
                                    onClick={this.toggleQueue}
                                    onMouseOver={this.onMouseEnterShare}
                                    onMouseLeave={this.onMouseLeaveShare}>
                                    {isArticleQueueLoading ? (
                                        <ClipLoader size={24} color="#ffffff" />
                                    ) : article.isQueued ? (
                                        <QueueCheckedIcon className={css(styles.queueIcon)} />
                                    ) : (
                                        <QueueUncheckedIcon className={css(styles.queueIcon)} />
                                    )}
                                </button>

                                <IconShareWhite
                                    className={css(styles.actionIconShare)}
                                    onClick={this.onShareClick}
                                    onMouseOver={this.onMouseEnterShare}
                                    onMouseLeave={this.onMouseLeaveShare}
                                />
                            </div>
                        </div>
                    </div>
                </Col>
            </VisibilitySensor>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 15,
        },
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        ':hover': {
            cursor: 'pointer',
            transform: 'scale(1.02)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            borderRadius: 10,
            paddingTop: 12,
        },
        paddingTop: 19,
        borderRadius: 20,
        transition: 'transform .2s',
        maxWidth: 690,
        width: '100%',
        backgroundColor: newtheme.darkmodeColors.cardNavy,
    },
    cardInner: {
        marginTop: 10,
        marginBottom: 0,
        padding: '2px 20px 20px 20px',
        paddingBottom: 20,

        [newtheme.cssBreakpoints.tiny]: {
            padding: '0px 10px 10px 10px',
        },
    },
    resurfacedInfo: {
        width: '100%',
        background: 'rgba(255, 255, 255, 0.87)',
        fontSize: 16,
        lineHeight: '140%',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '4px 20px 1px',

        [newtheme.cssBreakpoints.tiny]: {
            padding: '1px 10px 0px',
            fontSize: 14,
        },
    },
    infoIcon: {
        transform: 'translateY(-1px)',
        marginRight: 9,

        [newtheme.cssBreakpoints.tiny]: {
            width: 24,
            transform: 'translateY(-1px)',
            marginRight: 6,
        },
    },
    micIcon: {
        marginRight: 4,

        [newtheme.cssBreakpoints.tiny]: {
            width: 16,
            transform: 'translateY(-1px)',
        },
    },
    topSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    bottomSection: {
        [newtheme.cssBreakpoints.small]: {
            paddingTop: 8,
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 15,
    },
    articleInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    articleInfoAndLock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    articleImage: {
        [newtheme.cssBreakpoints.small]: {
            width: 130,
            height: 100,
        },
        [newtheme.cssBreakpoints.tiny]: {
            width: 86,
            height: 92,
            borderRadius: 7,
            boxShadow: 'none',
        },
        ':active': {
            filter: 'brightness(0.8)',
        },
        width: 210,
        height: 130,
        borderRadius: 10,
        backgroundSize: 'cover',
        animation: 'placeholder 3s ease infinite alternate',
        transition: 'box-shadow 0.3s',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px 1px',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
    },
    title: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            fontSize: 12,
            marginTop: 10,
        },
        color: newtheme.darkmodeColors.white,
        fontSize: 22,
        lineHeight: '145%',
        fontWeight: 400,
        marginTop: 18,
        marginBottom: 5,
    },
    timeAndPublisher: {},
    timeAndDate: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 12,
        },
        color: newtheme.darkmodeColors.fadedText,
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 400,
        margin: 0,
        padding: 0,
    },
    publisherText: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 12,
        },
        color: 'white',
        textAlign: 'left',
        fontSize: 15,
        fontWeight: 400,
        margin: 0,
        padding: 0,
    },
    publisherImage: {
        [newtheme.cssBreakpoints.small]: {
            height: 32,
            width: 32,
            borderRadius: 8,
        },
        height: 40,
        width: 40,
        borderRadius: 10,
        marginRight: 15,
    },
    infoText: {
        [newtheme.cssBreakpoints.small]: {
            paddingRight: 12,
        },
        height: '100%',
        flex: 1,
        paddingLeft: 0,
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    infoSecondary: {
        display: 'flex',
        flexDirection: 'column',
    },
    shadow: {
        boxShadow: '0px 0px 20px 50px #00000040 inset',
    },
    playingShadow: {
        boxShadow: '0px 0px 100px 100px #039BE590 inset',
    },
    iconImage: {
        width: 35,
        fill: 'white',
    },
    iconPlay: {},
    queueButton: {
        ':hover': {
            transform: 'scale(1.3)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },

            marginLeft: 'auto',
            marginRight: 16,
        },

        background: 'none',
        outline: 'none',
        border: 'none',
        padding: '0',
        margin: '0px',
        marginRight: 18,
        lineHeight: 0,
        transition: 'transform .2s',
    },
    queueIcon: {
        width: 29,

        [newtheme.cssBreakpoints.tiny]: {
            width: 24,
        },
    },
    actionIconShare: {
        ':hover': {
            transform: 'scale(1.3)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        height: 39,
        width: 39,

        [newtheme.cssBreakpoints.tiny]: {
            width: 33,
        },
    },
    playButton: {
        ':hover': {
            backgroundColor: 'white',
            color: 'black',
            transform: 'scale(1.03)',
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.darkmodeColors.buttonNavy,
        color: 'white',
        border: 'none',
        fontSize: 16,
        fontWeight: 400,
        padding: '10px 20px',
        borderRadius: 24,
        marginRight: 26,
    },
    buttonHover: {
        [newtheme.cssBreakpoints.small]: {
            /* min-width intentionally turns off hover for tablet/mobile */
            backgroundColor: newtheme.darkmodeColors.buttonNavy,
            color: newtheme.darkmodeColors.white,
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        backgroundColor: 'white',
        color: 'black',
        cursor: 'pointer',
        transform: 'scale(1.03)',
    },
    iconPlayPause: {
        [newtheme.cssBreakpoints.medium]: {
            height: 22,
            width: 22,
        },
        height: 26,
        width: 26,
        marginRight: 5,
        fill: newtheme.colors.primaryBlue,
    },
    premiumIcon: {
        [newtheme.cssBreakpoints.small]: {
            height: 32,
            width: 32,
        },
        height: 44,
        width: 44,
    },
});

export default ItemArticle;
