import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableCheckIfEmployerPays = loadable(() => import('../containers/CheckIfEmployerPaysContainer'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadCheckIfEmployerPays extends React.Component {
    render() {
        return (
            <Page noCrawl={false} trackingScreen={mpTrackingScreens.landing}>
                <LoadableCheckIfEmployerPays />
            </Page>
        );
    }
}

export default LazyLoadCheckIfEmployerPays;
