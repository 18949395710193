import * as React from 'react';

interface IProps {
    className?: string;
}

class IconArrowRightV2 extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="33"
                height="33"
                viewBox="0 0 32 32"
                fill="none"
                stroke="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                {...this.props}>
                <path
                    d="M18 11L23 16L18 21"
                    strokeOpacity="0.87"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 16L23 16"
                    strokeOpacity="0.87"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}

export default IconArrowRightV2;
