import text from '../locale';

const months = {
    0: text.date.months.JAN,
    1: text.date.months.FEB,
    2: text.date.months.MAR,
    3: text.date.months.APR,
    4: text.date.months.MAY,
    5: text.date.months.JUNE,
    6: text.date.months.JULY,
    7: text.date.months.AUG,
    8: text.date.months.SEPT,
    9: text.date.months.OCT,
    10: text.date.months.NOV,
    11: text.date.months.DEC,
};

const MILLISECONDS_IN_ONE_DAY = 86400000;

const getDaySuffix = (day: number): string => {
    if (day > 3 && day < 21) {
        return 'th';
    }

    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const addHours = (date: Date, hours: number): Date => {
    const newDate = new Date(date);
    newDate.setTime(newDate.getTime() + hours * 60 * 60 * 1000);
    return newDate;
};

export const addDays = (date: Date, days: number): Date => addHours(date, days * 24);

export const getMonthName = (date: Date): string => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    return monthNames[date.getMonth()];
};

export const dateToFriendlyString = (date?: Date): string => {
    if (!date) {
        return '';
    }

    const day = date.getUTCDate();
    const month = getMonthName(date);
    const year = date.getFullYear();

    return `${day}${getDaySuffix(day)} ${month}, ${year}`;
};

export const dateToString = (date: Date): string => {
    const now = new Date();
    const yesterday = new Date();
    const oneWeekAgo = new Date();

    yesterday.setDate(yesterday.getDate() - 1);
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);

    if (date.toDateString() === now.toDateString()) {
        return text.date.TODAY;
    }

    if (date.toDateString() === yesterday.toDateString()) {
        return text.date.YESTERDAY;
    }

    if (date.getTime() >= oneWeekAgo.getTime()) {
        const days = now.getTime() - date.getTime();
        if (days < 0) {
            return text.date.TODAY;
        }

        return `${Math.round(days / MILLISECONDS_IN_ONE_DAY)} ${text.date.DAYS_AGO}`;
    }

    if (date.getFullYear() === now.getFullYear()) {
        return `${date.getDate()} ${months[date.getMonth()]}`;
    }

    return `${months[date.getMonth()]} ${date.getFullYear()}`;
};

export const convertDateToUtc = () => { };

export const setDateTimeTo9am = (date: Date) => {
    date.setHours(9);
    date.setMinutes(0);
    date.setMilliseconds(0);

    return date;
};
