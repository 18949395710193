import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import Button from './Button';

interface IProps {
    text: string;
    disabled?: boolean;
    onClick: () => void;
    style?: any;
    styleObj?: object;
}

class ButtonFixed extends React.Component<IProps> {
    public render() {
        const {
            text, styleObj, onClick, disabled, style,
        } = this.props;

        return (
            <div className={css(styles.fixed)} style={style}>
                <Button
                    disabled={disabled}
                    text={text}
                    onClick={onClick}
                    styleObj={[styles.fixedBtn, styleObj]}
                    mode="CIRCLE"
                />
            </div>
        );
    }
}

const styles = StyleSheet.create({
    fixed: {
        position: 'fixed',
        top: 20,
        transform: 'translateX(-50%)',
        left: '50%',
        zIndex: 100,
    },
    fixedBtn: {
        width: 150,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 40px 5px',
    },
});

export default ButtonFixed;
