import * as React from 'react';

interface IProps {
    className?: string;
}

class IconTickBlue extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M1.5 11L5 14.5L13.5 6" stroke="#039BE5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default IconTickBlue;
