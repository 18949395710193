import * as _ from 'lodash';

import { isServer } from '../../store';

const onScrolls = {};
const onScrollThrottled = _.throttle(() => {
    _.forEach(onScrolls, (func: () => void) => {
        func();
        func();
    });
}, 50);

export const isOnEndOfPage = (): boolean => {
    if (isServer) {
        return false;
    }

    const scrollDistance = window.innerHeight + window.scrollY;

    if (scrollDistance + 1500 >= document.body.offsetHeight) {
        return true;
    }

    return false;
};

export const addOnScroll = (key: string, fn: () => void) => {
    if (isServer) {
        return;
    }

    onScrolls[key] = fn;
    window.onscroll = null;
    window.ontouchmove = null;

    window.onscroll = onScrollThrottled;
    window.ontouchmove = onScrollThrottled;
};

export const removeOnScroll = (key: string) => {
    if (isServer) {
        return;
    }

    delete onScrolls[key];
    window.onscroll = null;
    window.ontouchmove = null;
};
