import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

interface IProps {
    loading: boolean;
    center?: boolean;
    color?: string;
}

class Loading2 extends React.Component<IProps> {
    public render() {
        const { loading } = this.props;

        if (!loading) {
            return null;
        }

        return <div className={`${css(styles.loader)}`} />;
    }
}

const spin = {
    '0%': {
        transform: 'rotate(0deg)',
    },
    '100%': {
        transform: 'rotate(360deg)',
    },
};

const styles = StyleSheet.create({
    loader: {
        border: '3px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '3px solid #3498db',
        width: 40,
        height: 40,
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationName: [spin],
    },
});

export default Loading2;
