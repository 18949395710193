import { Container, Row, Col } from 'reactstrap';
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Link } from 'react-router-dom';
import Page from '../../components/Page';
import theme from '../../theme';
import { mpTrackingScreens } from '../../config/screens';
import newtheme from '../../newtheme';
import LandingPageNewSitemap from '../../components/landingpagenew/LandingPageNewSitemap';
import NewSitemapSeparator from '../../components/landingpagenew/NewSitemapSeparator';

class VpOfMarketing extends React.Component {
    public render() {
        return (
            <Page
                className={css(styles.page)}
                title="We're hiring | VP of Marketing"
                trackingScreen={mpTrackingScreens.careers}
                image="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringvpmarketing.png"
            >
                <Container className={css(styles.content)}>
                    <Row className={css(newtheme.coreStyles.centerColHorizontal)}>
                        <Col md={10} lg={8} xl={8}>
                            <img alt="We are hiring cover" src="https://noa-assets.s3.eu-west-1.amazonaws.com/wearehiringvpmarketing.png" className={css(styles.topImage)} />

                            <h1 className={css(styles.title)}>VP of Marketing</h1>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                Noa’s mission is to help curious people understand and know more about the topics shaping our world. We bring listeners narrated articles from some of the world’s most
                                respected publications, including
                                {' '}
                                <i>The New York Times</i>
                                ,
                                {' '}
                                <i>The Economist</i>
                                ,
                                {' '}
                                <i>Bloomberg</i>
                                ,
                                {' '}
                                <i>Harvard Business Review</i>
                                ,
                                {' '}
                                <i>The Telegraph</i>
                                ,
                                <i>The Irish Times</i>
                                ,
                                <i>The Independent</i>
                                , and many more. We empower our listeners to never miss an opportunity to know more.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                This is a chance for an energetic, creative, start-up-minded marketer to play an active (pivotal!) part in our growth journey. We have strong product-market fit among
                                our target listeners with good subscriber traction and a comprehensive data infrastructure. It is now time to accelerate.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                The key deliverable of this role is to build the Noa brand and to drive awareness, acquisition, conversion, and retention. Sticky subscriber growth is the name of the
                                game. You’ll work with the founding team to set direction and then be given the autonomy to see your initiatives through. Budget will be available for continuous
                                testing and experimentation (creativity is key!). We’ll then double down on initiatives that show attractive unit economics – this is where most of the budget will end
                                up.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                To be successful, we need you to be a creative and analytical operator who can forensically understand the Noa listener, deliver programmes that communicate
                                effectively, and manage performance of the subscription funnel to meet Noa’s ambitious business goals. Teamwork and collaboration with editorial, product, and marketing
                                agency teams are also key to success. Hiring and building an internal team will be on the cards as we scale.
                            </p>

                            <br />

                            <h5 className={css(styles.subTitle)}>Skills and responsibilities include:</h5>

                            <ul className={css(styles.list, styles.standardText)}>
                                <li>
                                    Develop creative campaigns to engage target audiences, communicate functional and emotional benefits, and promote listeners through the funnel. This includes
                                    responsibility for design of all marketing efforts and creation of copy that converts and is consistent with Noa’s values.
                                </li>
                                <li>Develop, deliver, and optimize ongoing digital media and partner channel strategy.</li>
                                <li>Monitor key performance metrics, clearly communicate implications of those metrics, and provide insight to inform product development and editorial roadmap.</li>
                                <li>Develop and manage a cost-effective budget, plan use of resources, and manage return on investment.</li>
                                <li>Build and manage performance marketing process and resources, including with a media agency.</li>
                                <li>Hands-on expertise of digital ad platforms, tools, and DSPs.</li>
                                <li>Understand customer journey and work with the product and editorial teams to ensure best possible user experience and retention.</li>
                            </ul>

                            <br />

                            <p className={css(styles.paragraph, styles.standardText)}>
                                You should have tangible experience in a setting where you had a measurable impact on business performance – ideally at a small or start-up company. You should also be
                                able to demonstrate success in working collectively to achieve shared goals. A love for customers is key too – are you someone who can speak with Noa listeners and
                                gather insights? And of course, we encourage you to be open and honest and want to hear about your individual and collective failures, and what you learned along the
                                way.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                We’re a team of people from diverse backgrounds and ethnicities, but we share a common goal. We see a world in which digital consumers engage with, and pay for, quality
                                journalism. We see a world in which people seek depth and understanding, not simplicity or like-minded views. And most important of all, we see a world in which digital
                                consumers – of all ages – are equipped with the insights and perspectives to always question, empathise, and reason in their quest to find rational solutions to complex
                                problems. We like to say “if Hans Rosling built a news app, Noa would be it”. If you’re motivated by this mission, then we want to hear from you.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                We are offering a competitive package, including equity and results-based bonuses. While Noa is headquartered in Dublin, and proximity would be an advantage, for the
                                right candidate we more than happy to facilitate remote working.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                If you would like one day of free Premium access in order to familiarise yourself with Noa and start to truly understand its potential, visit
                                {' '}
                                <Link to="/welcome?mrkt_id=marketingexecutive" target="_blank">
                                    this
                                </Link>
                                {' '}
                                web page and create a new account.
                            </p>

                            <p className={css(styles.paragraph, styles.standardText)}>
                                If you love what we are doing at Noa, apply in strict confidence to
                                {' '}
                                <a href="mailto:recruitment@newsoveraudio.com">recruitment@newsoveraudio.com</a>
                                . Alongside your CV,
                                please explain in c. 250 words how you think Noa will change the world of media and build impact with audiences.
                            </p>
                        </Col>
                    </Row>
                </Container>

                <NewSitemapSeparator />
                <LandingPageNewSitemap />
            </Page>
        );
    }
}

const styles = StyleSheet.create({
    page: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 20,
            paddingTop: 50,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
            paddingTop: 30,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 16,
            paddingTop: 30,
        },
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        fontSize: 20,
        paddingTop: 80,
    },
    content: {
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 40,
            paddingLeft: 20,
            paddingRight: 20,
        },
        paddingBottom: 80,
        color: newtheme.darkmodeColors.slightlyFadedText,
    },
    topImage: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 75,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 40,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 40,
        },
        width: '100%',
        display: 'block',
        margin: '0 auto',
        marginBottom: 60,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            fontSize: 35,
            marginBottom: 25,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 30,
            marginBottom: 20,
        },
        fontSize: 40,
        marginTop: 0,
        marginBottom: 30,
        borderBottom: '1px solid #DDDDDD',
        color: newtheme.darkmodeColors.white,
    },
    subTitle: {
        fontWeight: 600,
        marginBottom: 10,
        color: newtheme.darkmodeColors.white,
        fontSize: '110%',
    },
    paragraph: {
        marginBottom: 25,
    },
    standardText: {
        fontSize: '110%',
    },
    list: {
        listStyleType: 'disc',
    },
    titleColor: {
        color: newtheme.darkmodeColors.white,
        fontWeight: 600,
    },
});

export default VpOfMarketing;
