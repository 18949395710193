import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Container, Row } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import IconLandingRightArrow from '../../assets/icons/IconLandingRightArrow';
import newtheme from '../../newtheme';
import { MpTrackingButtons } from '../../config/buttons';
import IUser from '../../types/models/IUser';
import { openModal } from '../../actions/modal';
import { mpTrackButtonClick } from '../../tracking/mixpanel';
import config from '../../config';
import ModalTypes from '../../types/ModalTypes';
import { IState } from '../../reducers';
import IOfferSource from '../../types/models/IOfferSource';

interface IPropsFromState {
    user?: IUser;
    offerSource?: IOfferSource;
}

interface IPropsFromDispatch {
    openModal: typeof openModal;
}

class CreateAccountMinified extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps> {
    public showSignup = () => {
        mpTrackButtonClick(MpTrackingButtons.SHOW_SIGNUP_BOTTOM);

        if (this.props.user) {
            this.props.history.push(config.routes.homeWebPlayer);
            return;
        }

        this.props.openModal(ModalTypes.SignUp);
    };

    public render() {
        return (
            <div className={css(styles.main)}>
                <Container className={css(styles.container)}>
                    <Row className={css(styles.center)}>
                        <h3 className={css(styles.heading)}>
                            Listen to the world&apos;s
                            <br />
                            best audio-journalism.
                        </h3>
                        <button type="button" className={css(styles.button)} onClick={this.showSignup}>
                            {this.props.offerSource ? this.props.offerSource.buttonText : 'Try Noa today'}
                            {' '}
                            <IconLandingRightArrow className={css(styles.arrow)} />
                        </button>
                    </Row>
                </Container>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    main: {
        position: 'relative',
        backgroundColor: 'white',
        color: 'black',
        overflow: 'hidden',
    },
    container: {
        [newtheme.cssBreakpoints.tiny]: {
            paddingTop: 35,
            paddingBottom: 30,
        },
        paddingTop: 40,
        paddingBottom: 40,
    },
    center: {
        [newtheme.cssBreakpoints.small]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    heading: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 42,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 35,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 27,
            textAlign: 'center',
            marginBottom: 35,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 24,
            marginBottom: 25,
        },
        fontWeight: 500,
        fontSize: 48,
        textAlign: 'left',
        margin: 0,
        marginBottom: 0,
    },
    breakpoint: {
        '@media (min-width: 1500px)': {
            display: 'none',
        },
    },
    button: {
        [newtheme.cssBreakpoints.medium]: {
            padding: '10px 20px',
            fontSize: 16,
            borderRadius: 10,
        },
        [newtheme.cssBreakpoints.small]: {
            padding: '10px 20px',
            fontSize: 14,
            borderRadius: 10,
        },
        ':hover': {
            backgroundColor: newtheme.colors.lightNavy,
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        border: 'none',
        borderRadius: 15,
        fontSize: 18,
        fontWeight: 500,
        padding: '20px 40px',
    },
    arrow: {
        color: 'white',
        height: 31,
        width: 33,
        stroke: 'white',
        marginLeft: 10,
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        user: state.user.user,
        offerSource: state.userSource.offerSource,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({ openModal }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccountMinified));
