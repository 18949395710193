export const FREE_TRIAL_REQUEST = 'FREE_TRIAL_REQUEST';
export const FREE_TRIAL_SUCCESS = 'FREE_TRIAL_SUCCESS';
export const FREE_TRIAL_FAILURE = 'FREE_TRIAL_FAILURE';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';

export const INITIATE_SUBSCRIPTION_REQUEST = 'INITIATE_SUBSCRIPTION_REQUEST';
export const INITIATE_SUBSCRIPTION_SUCCESS = 'INITIATE_SUBSCRIPTION_SUCCESS';
export const INITIATE_SUBSCRIPTION_FAILURE = 'INITIATE_SUBSCRIPTION_FAILURE';

export const SUBSCRIPTION_PAYMENT_SUCCEEDED = 'SUBSCRIPTION_PAYMENT_SUCCEEDED';
export const SUBSCRIPTION_PAYMENT_FAILED = 'SUBSCRIPTION_PAYMENT_FAILED';

export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'GET_SUBSCRIPTION_PLANS_FAILURE';

export const APPLY_DISCOUNT_REQUEST = 'APPLY_DISCOUNT_REQUEST';
export const APPLY_DISCOUNT_SUCCESS = 'APPLY_DISCOUNT_SUCCESS';
export const APPLY_DISCOUNT_FAILURE = 'APPLY_DISCOUNT_FAILURE';
export const APPLY_DISCOUNT_CLEAR = 'APPLY_DISCOUNT_CLEAR';
export const APPLY_DISCOUNT_APPLIED = 'APPLY_DISCOUNT_APPLIED';

export const RESET_SUBSCRIPTION = 'RESET_SUBSCRIPTION';
