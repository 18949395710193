import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import * as Cookies from 'js-cookie';

import config from '../../../../config';
import { IState } from '../../../../reducers/index';
import fetch from '../../../../utils/fetch';
import responseCheck from '../../../../utils/response-check';
import text from '../../../../locale';
import IUser from '../../../../types/models/IUser';
import IAuthUser from '../../../../types/models/IAuthUser';
import * as metric from '../../../../utils/metric';
import { isServer } from '../../../../../store';
import { getLastName, getFirstName } from '../../../../utils/user';
import { clearAdvert } from '../../../../actions/advert';
import { clearContent } from '../../../../actions/content';
import { clearSearch } from '../../../../actions/search';
import { clearSection } from '../../../../actions/section';
import { clearRecommendation } from '../../../../actions/recommendation';
import {
    USER_FAILURE,
    USER_REQUEST,
    USER_SUCCESS,
    SET_CURRENT_USER,
    LOGOUT,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    USER_FAVOURITES_SUCCESS,
    ACCOUNT_DETAILS_SUCCESS,
    ACCOUNT_DETAILS_FAILURE,
    ACCOUNT_DETAILS_REQUEST,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
} from '../constants/user';
import { PLATFORM_WEB } from '../../../../constants/app';
import { mpSetUserSuperProperties } from '../../../../tracking/mixpanel';
import IAccountDetails from '../../../../types/models/IAccountDetails';

export const cookieName = 'noa-web-app';

export const getUserFavourites = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: USER_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}`;

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => dispatch(userFavouritesSuccess(response.data.user)))
        .catch((error) => dispatch(usersFailure(error)));
};

export const getAccountDetails = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: ACCOUNT_DETAILS_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.accountDetailsV4}`;

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => {
            dispatch(accountDetailsSuccess(response.data));
        })
        .catch((error) => dispatch(accountDetailsFailure(error)));
};

export const updateUser = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: UPDATE_USER_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}`;

    return fetch(getState)(requestUrl, {
        method: 'PUT',
        body: JSON.stringify({ platform: PLATFORM_WEB }),
    })
        .then(responseCheck)
        .then(() => dispatch(updateUserSuccess()))
        .catch((error) => dispatch(updateUserFailure(error)));
};

export const getUser = (linkId?: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: USER_REQUEST });

    const linkIdQueryParam = linkId ? `?linkId=${linkId}` : '';
    const requestUrl = `${config.api.url}${config.api.routes.usersV4}${linkIdQueryParam}`;

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then((response) => dispatch(usersSuccess(response.data)))
        .catch((error) => dispatch(usersFailure(error)));
};

export const deleteUser = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: DELETE_USER_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.usersV5}`;

    return fetch(getState)(requestUrl, {
        method: 'DELETE',
        body: JSON.stringify({ platform: PLATFORM_WEB }),
    })
        .then(responseCheck)
        .then(() => dispatch(deleteUserSuccess()))
        .catch((error) => dispatch(deleteUserFailure(error)));
};

export const refreshUser = async (dispatch: Dispatch, getState: () => IState): Promise<IUser | null> => {
    await getUser()(dispatch, getState);
    const userState = getState().user;
    return userState.user ? userState.user : null;
};

export const setCurrentUserFromCookie = (userCookie?: IUser) => (dispatch: Dispatch) => {
    const userFromCookie: IUser = userCookie || Cookies.getJSON(cookieName);

    if (userFromCookie) {
        storeUserInfo(userFromCookie);
        return dispatch({ type: SET_CURRENT_USER, user: userFromCookie });
    }

    clearUserInfo();
    return null;
};

export const logout = () => (dispatch: Dispatch) => {
    clearUserInfo();

    if (
        // @ts-ignore
        typeof google !== 'undefined' &&
        // @ts-ignore
        google.accounts &&
        // @ts-ignore
        google.accounts.id
    ) {
        console.log('[Google] Disabled auto select');

        // @ts-ignore
        google.accounts.id.disableAutoSelect();
    }

    clearState(dispatch);

    return dispatch({ type: LOGOUT });
};

export const socialLogin =
    (params: {
        methodTypeId: number;
        ssoId?: string;
        email?: string;
        firstName?: string;
        linkedInAuthCode?: string;
        linkedInRedirectUri?: string;
        guestPassDays?: number;
        giftLinkParam?: string;
    }) =>
    (dispatch: Dispatch, getState: () => IState) => {
        dispatch({ type: LOGIN_REQUEST });

        let action: Promise<Response>;

        if (params.linkedInAuthCode != null) {
            const requestUrl = `${config.api.url}${config.api.routes.linkedInAuth}`;
            action = fetch(getState)(requestUrl, {
                method: 'POST',
                body: JSON.stringify({
                    ...params,
                    authCode: params.linkedInAuthCode,
                    redirectUri: params.linkedInRedirectUri,
                    methodTypeID: params.methodTypeId,
                    platform: PLATFORM_WEB,
                    guestPassDays: params.guestPassDays,
                    giftLink: params.giftLinkParam,
                }),
            });
        } else {
            const requestUrl = `${config.api.url}${config.api.routes.socialLogin}`;
            action = fetch(getState)(requestUrl, {
                method: 'POST',
                body: JSON.stringify({
                    ...params,
                    sso_id: params.ssoId,
                    methodTypeID: params.methodTypeId,
                    platform: PLATFORM_WEB,
                    guestPassDays: params.guestPassDays,
                    giftLink: params.giftLinkParam,
                }),
            });
        }

        return action
            .then(responseCheck)
            .then((response) => {
                clearState(dispatch);
                return dispatch(socialLoginSuccess(response));
            })
            .catch((error) => dispatch(socialLoginFailure(error)));
    };

export const login =
    (params: { email: string; password: string; guestPassDays?: number; giftLinkParam?: string }) =>
    (dispatch: Dispatch, getState: () => IState) => {
        dispatch({ type: LOGIN_REQUEST });

        const requestUrl = `${config.api.url}${config.api.routes.login}`;

        const paramsWithPlatform = {
            ...params,
            platform: PLATFORM_WEB,
        };

        return fetch(getState)(requestUrl, {
            method: 'POST',
            body: JSON.stringify(paramsWithPlatform),
        })
            .then(responseCheck)
            .then((response) => {
                clearState(dispatch);
                return dispatch(loginSuccess(response));
            })
            .catch((error) => dispatch(loginFailure(error)));
    };

export const signup =
    (params: { name: string; email: string; password: string; guestPassDays?: number; giftLinkParam?: string }) =>
    (dispatch: Dispatch, getState: () => IState) => {
        dispatch({ type: SIGNUP_REQUEST });

        const requestUrl = `${config.api.url}${config.api.routes.signup}`;

        return fetch(getState)(requestUrl, {
            method: 'POST',
            body: JSON.stringify({
                firstName: getFirstName(params.name),
                lastName: getLastName(params.name),
                email: params.email,
                password: params.password,
                platform: PLATFORM_WEB,
                guestPassDays: params.guestPassDays,
                giftLinkParam: params.giftLinkParam,
            }),
        })
            .then(responseCheck)
            .then((response) => {
                clearState(dispatch);
                return dispatch(signupSuccess(response));
            })
            .catch((error) => dispatch(signupFailure(error)));
    };

export const forgotPassword = (email: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.forgotPassword}?email=${encodeURIComponent(email)}`;

    return fetch(getState)(requestUrl)
        .then(responseCheck)
        .then(() => dispatch(forgotPasswordSuccess()))
        .catch((error) => dispatch(forgotPasswordFailure(error)));
};

export const resetPassword =
    (params: { token: string; password: string }) => (dispatch: Dispatch, getState: () => IState) => {
        dispatch({ type: RESET_PASSWORD_REQUEST });

        const requestUrl = `${config.api.url}${config.api.routes.resetPassword}`;

        return fetch(getState)(requestUrl, {
            method: 'POST',
            body: JSON.stringify(params),
        })
            .then(responseCheck)
            .then(() => dispatch(resetPasswordSuccess()))
            .catch((error) => dispatch(resetPasswordFailure(error)));
    };

const loginSuccess = (response: any) => {
    const user = buildAuthUser(response.data);

    storeUserInfo(user);
    triggerLoginSuccessTag(user);

    return { type: LOGIN_SUCCESS, user };
};

// toast(text.notifications.EMAIL_OR_PASSWORD_WRONG);
const loginFailure = (error: Error) => ({ type: LOGIN_FAILURE, error });

const signupSuccess = (response: any) => {
    const user = buildAuthUser(response.data);

    storeUserInfo(user);
    triggerSignupSuccessTag(user);

    return { type: SIGNUP_SUCCESS, user };
};

const signupFailure = (error: Error) => {
    if (error.message?.split('_')?.[1] === '429') {
        toast("You've made too many requests recently. Please wait and try again later.");
    } else {
        toast(text.notifications.EMAIL_INVALID);
    }

    return { type: SIGNUP_FAILURE, error };
};

const forgotPasswordSuccess = () => {
    toast(text.notifications.EMAIL_HAS_BEEN_SENT);

    return { type: FORGOT_PASSWORD_SUCCESS };
};

const forgotPasswordFailure = (error: Error) => {
    toast(text.notifications.NOT_POSSIBLE_TO_RESET_PASSWORD);

    return { type: FORGOT_PASSWORD_FAILURE, error };
};

const socialLoginSuccess = (response: any) => {
    const userAuth = response.data as IAuthUser;
    const user = buildAuthUser(userAuth);

    storeUserInfo(user);

    return { type: LOGIN_SUCCESS, user, isUserNew: userAuth.newUser };
};

const socialLoginFailure = (error: Error) => ({ type: LOGIN_FAILURE, error });

const resetPasswordSuccess = () => {
    toast(text.notifications.PASSWORD_HAS_BEEN_RESET);

    return { type: RESET_PASSWORD_SUCCESS };
};

const resetPasswordFailure = (error: Error) => {
    toast(text.notifications.NOT_POSSIBLE_TO_RESET_PASSWORD);

    return { type: RESET_PASSWORD_FAILURE, error };
};

const buildAuthUser = (user: IAuthUser): IUser => ({
    id: user.ID,
    uuid: user.uuid,
    firstName: user.name,
    email: user.email,
    token: user.userAccessToken,
    listenBalance: user.balance,
});

const clearState = (dispatch: Dispatch) => {
    // clearArticles()(dispatch);
    clearAdvert()(dispatch);
    clearContent()(dispatch);
    clearSearch()(dispatch);
    clearSection()(dispatch);
    clearRecommendation()(dispatch);
};

const usersSuccess = (user: any) => {
    storeUserInfo(user);

    return {
        type: USER_SUCCESS,
        user,
    };
};

const userFavouritesSuccess = (user: any) => ({
    type: USER_FAVOURITES_SUCCESS,
    user,
});

const usersFailure = (error: Error) => ({ type: USER_FAILURE, error });

const updateUserSuccess = () => ({ type: UPDATE_USER_SUCCESS });

const updateUserFailure = (error: Error) => ({
    type: UPDATE_USER_FAILURE,
    error,
});

const deleteUserSuccess = () => ({ type: DELETE_USER_SUCCESS });

const deleteUserFailure = (error: Error) => ({
    type: DELETE_USER_FAILURE,
    error,
});

const accountDetailsSuccess = (accountDetails: IAccountDetails) => ({
    type: ACCOUNT_DETAILS_SUCCESS,
    accountDetails,
});
const accountDetailsFailure = (error: Error) => ({
    type: ACCOUNT_DETAILS_FAILURE,
    error,
});

const storeUserInfo = (user: IUser) => {
    if (isServer) {
        return;
    }

    Cookies.set(cookieName, user);

    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            userId: user.id,
            userEmail: user.email,
            userName: user.firstName,
            userSubscription: user.subscription,
            userListenBalance: user.listenBalance,
            userPlatform: PLATFORM_WEB,
        });
    }

    mpSetUserSuperProperties(user);

    metric.setUser(user.id);
};

const triggerSignupSuccessTag = (user: IUser) => {
    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'WebSignup',
            ...user,
        });
    }
};

const triggerLoginSuccessTag = (user: IUser) => {
    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            event: 'WebLogin',
            ...user,
        });
    }
};

const clearUserInfo = () => {
    if (isServer) {
        return;
    }

    Cookies.remove(cookieName);
    Cookies.remove(cookieName, { domain: config.domain });

    // @ts-ignore
    if (window && window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
            userId: null,
            userEmail: null,
            userName: null,
            userSubscription: null,
            userListenBalance: null,
            userPlatform: PLATFORM_WEB,
        });
    }

    metric.unsetUser();
};
