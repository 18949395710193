import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import * as metric from '../../utils/metric';

interface IProps {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    borderColor?: string;
    borderWidth?: number;
    className?: string;
    children?: any;
    color?: string;
    textColor?: string;
    height?: number;
    fontSize?: number;
    width?: number | string;
    maxWidth?: number | string;
    minWidth?: number | string;
    submit?: boolean;
    disabled?: boolean;
    borderRadius?: number;
}

class Btn extends React.Component<IProps> {
    public onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        const { onClick } = this.props;

        if (onClick) {
            onClick(e);
        }

        metric.event(metric.EventCategories.BUTTON, metric.EventActions.TAP);
    };

    public render() {
        const {
            borderColor,
            borderWidth,
            color,
            className,
            children,
            textColor,
            fontSize,
            height,
            width,
            minWidth,
            maxWidth,
            submit,
            disabled,
            borderRadius,
        } = this.props;

        const submitProp = submit ? { type: 'submit' } : {};
        const style = {
            borderColor: disabled ? '#DDD' : borderColor,
            borderWidth: borderWidth || 1,
            height,
            fontSize,
            width,
            minWidth,
            maxWidth,
            backgroundColor: disabled ? '#DDD' : color,
            color: textColor,
            borderRadius: borderRadius || 5,
        };

        const isDisabled = disabled || false;

        return (
            // @ts-ignore
            <button
                // @ts-ignore
                type="submit"
                disabled={isDisabled}
                className={`${css(
                    styles.button,
                    !color && styles.color,
                    !textColor && styles.textColor,
                    !fontSize && styles.fontSize,
                    !height && styles.height,
                    borderColor ? styles.border : styles.noBorder,
                )} ${className}`}
                style={style}
                onClick={this.onClick}
                {...submitProp}
            >
                {children}
            </button>
        );
    }
}

const styles = StyleSheet.create({
    button: {
        ':focus': {
            outline: 0,
        },
        ':hover': {
            filter: 'brightness(90%)',
        },
        ':active': {
            filter: 'brightness(80%)',
        },
        paddingLeft: 12,
        paddingRight: 12,
        cursor: 'pointer',
        boxShadow: 'initial',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    color: {
        backgroundColor: '#039BE5',
    },
    textColor: {
        color: 'white',
    },
    height: {
        height: 38,
    },
    fontSize: {
        '@media (max-width: 768px)': {
            fontSize: 14,
        },
        fontSize: 18,
    },
    border: {
        borderStyle: 'solid',
    },
    noBorder: {
        borderWidth: 0,
    },
});

export default Btn;
