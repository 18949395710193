import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import text from '../../locale/index';
import NoaModal from './NoaModal';
import theme from '../../theme';
import {
    event,
    EventCategories,
    EventActions,
    EventLabels,
} from '../../utils/metric';
import Btn from '../buttons/Btn';

interface IProps {
    url: string;
    isOpen: boolean;
    close: () => void;
}

class ModalShare extends React.Component<IProps> {
    public onClickShare = () => {
        setTimeout(() => {
            const element = document.getElementById('copy-url') as HTMLInputElement;

            if (element && element.setSelectionRange) {
                element.setSelectionRange(0, 9999);
            }

            event(EventCategories.POPUP, EventActions.TAP, EventLabels.MODAL_SHARE);
        }, 100);
    };

    public onClose = () => {
        this.props.close();
    };

    public render() {
        const { isOpen, url } = this.props;
        this.onClickShare();

        return (
            <NoaModal
                modalId="ModalShare"
                isOpen={isOpen}
                onClose={this.onClose}
                title={text.shareModal.TITLE}
                height="SMALL"
                footer={(
                    <Btn width={170} onClick={this.onClose}>
                        {text.modal.CONTINUE}
                    </Btn>
                )}
            >
                <textarea
                    id="copy-url"
                    onClick={this.onClickShare}
                    value={url}
                    className={css(styles.input)}
                />
            </NoaModal>
        );
    }
}

const styles = StyleSheet.create({
    input: {
        width: '100%',
        height: 150,
        border: 0,
        color: theme.textColor,
        textAlign: 'center',
        resize: 'none',
    },
});

export default ModalShare;
