import {
    SMS_SENDING,
    SMS_SENT,
    SMS_ERROR,
    SMS_RESET,
} from '../constants/sms';

export interface IState {
    loading: boolean;
    sent: boolean;
    error: boolean;
}

interface IAction {
    type: string;
}

export const initialState: IState = {
    loading: false,
    sent: false,
    error: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SMS_RESET:
            return {
                ...initialState,
            };

        case SMS_SENDING:
            return {
                ...state,
                loading: true,
                sent: false,
                error: false,
            };

        case SMS_SENT:
            return {
                ...state,
                loading: false,
                sent: true,
                error: false,
            };

        case SMS_ERROR:
            return {
                ...state,
                loading: false,
                sent: false,
                error: true,
            };

        default:
            return state;
    }
};
