export const ARTICLES_REQUEST = 'ARTICLES_REQUEST';
export const ARTICLES_SUCCESS = 'ARTICLES_SUCCESS';
export const ARTICLES_FAILURE = 'ARTICLES_FAILURE';
export const ARTICLES_NEW_REQUEST = 'ARTICLES_NEW_REQUEST';
export const ARTICLES_NEW_SUCCESS = 'ARTICLES_NEW_SUCCESS';
export const ARTICLES_NEW_FAILURE = 'ARTICLES_NEW_FAILURE';
export const ARTICLE_CLEAR = 'ARTICLE_CLEAR';
export const ARTICLE_ADDED_TO_QUEUE = 'ARTICLE_ADDED_TO_QUEUE';
export const NEXT_ARTICLE = 'NEXT_ARTICLE';
export const PREVIOUS_ARTICLE = 'PREVIOUS_ARTICLE';
export const ARTICLE_FOLLOW_REQUEST = 'ARTICLE_FOLLOW_REQUEST';
export const ARTICLE_FOLLOW_SUCCESS = 'ARTICLE_FOLLOW_SUCCESS';
export const ARTICLE_FOLLOW_FAILURE = 'ARTICLE_FOLLOW_FAILURE';
export const ARTICLE_UNFOLLOW_REQUEST = 'ARTICLE_UNFOLLOW_REQUEST';
export const ARTICLE_UNFOLLOW_SUCCESS = 'ARTICLE_UNFOLLOW_SUCCESS';
export const ARTICLE_UNFOLLOW_FAILURE = 'ARTICLE_UNFOLLOW_FAILURE';
export const ARTICLE_REQUEST = 'ARTICLE_REQUEST';
export const ARTICLE_EMBED_SUCCESS = 'ARTICLE_EMBED_SUCCESS';
export const ARTICLE_FAILURE = 'ARTICLE_FAILURE';
export const ARTICLE_IS_QUEUED_SUCCESS = 'ARTICLE_IS_QUEUED_SUCCESS';
export const ARTICLE_IS_QUEUED_PENDING = 'ARTICLE_IS_QUEUED_PENDING';
export const ARTICLE_IS_QUEUED_REJECTED = 'ARTICLE_IS_QUEUED_REJECTED';
export const ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_SUCCESS = 'ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_SUCCESS';
export const ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_PENDING = 'ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_PENDING';
export const ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_REJECTED = 'ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_REJECTED';
