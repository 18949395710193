import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import config from '../config';
import {
    GET_PLAYLISTS_FAILURE,
    GET_PLAYLISTS_REQUEST,
    GET_PLAYLISTS_SUCCESS,
    PLAYLIST_FOLLOW_FAILURE,
    PLAYLIST_FOLLOW_REQUEST,
    PLAYLIST_FOLLOW_SUCCESS,
    PLAYLIST_UNFOLLOW_FAILURE,
    PLAYLIST_UNFOLLOW_REQUEST,
    PLAYLIST_UNFOLLOW_SUCCESS,
    PLAYLIST_VIEW_IS_QUEUED_PENDING,
    PLAYLIST_VIEW_IS_QUEUED_REJECTED,
    PLAYLIST_VIEW_IS_QUEUED_SUCCESS,
} from '../constants/playlist';
import { IState } from '../reducers/index';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import text from '../locale';
import IPlaylist from '../types/models/IPlaylist';
import { mpTrackButtonClick } from '../tracking/mixpanel';
import { MpTrackingButtons } from '../config/buttons';

export const followPlaylist = (playlist: IPlaylist) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: PLAYLIST_FOLLOW_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}/playlists/${playlist.id}`;

    mpTrackButtonClick(MpTrackingButtons.FOLLOW, undefined, playlist.id, playlist.name);

    return fetch(getState)(requestUrl, { method: 'PUT' })
        .then(responseCheck)
        .then(() => dispatch(followPlaylistSuccess()))
        .catch((error) => dispatch(followPlaylistFailure(error)));
};

export const unfollowPlaylist = (playlist: IPlaylist) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: PLAYLIST_UNFOLLOW_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}/playlists/${playlist.id}`;

    mpTrackButtonClick(MpTrackingButtons.REMOVE_FOLLOW, undefined, playlist.id, playlist.name);

    return fetch(getState)(requestUrl, { method: 'DELETE' })
        .then(responseCheck)
        .then(() => dispatch(unfollowPlaylistSuccess()))
        .catch((error) => dispatch(unfollowPlaylistFailure(error)));
};

export const getPlaylists = (url: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: GET_PLAYLISTS_REQUEST });

    return fetch(getState)(url)
        .then(responseCheck)
        .then((response) => dispatch(getPlaylistsSuccess(response)))
        .catch((error) => dispatch(getPlaylistsFailure(error)));
};

export const setPlaylistViewIsQueuedSuccess =
    ({ playlistId, isQueued }: { playlistId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) => {
        dispatch({ type: PLAYLIST_VIEW_IS_QUEUED_SUCCESS, isQueued: { playlistId, status: isQueued } });
    };

export const setPlaylistViewIsQueuedPending =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) => {
        dispatch({ type: PLAYLIST_VIEW_IS_QUEUED_PENDING, isQueued: { playlistId } });
    };

export const setPlaylistViewIsQueuedRejected =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) => {
        dispatch({ type: PLAYLIST_VIEW_IS_QUEUED_REJECTED, isQueued: { playlistId } });
    };

const followPlaylistSuccess = () => {
    toast(text.notifications.PLAYLIST_ADDED_TO_YOUR_FAVORITES);
    return { type: PLAYLIST_FOLLOW_SUCCESS };
};

const followPlaylistFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);

    return { type: PLAYLIST_FOLLOW_FAILURE, error };
};

const unfollowPlaylistSuccess = () => {
    toast(text.notifications.PLAYLIST_REMOVED_FROM_YOUR_FAVORITES);
    return { type: PLAYLIST_UNFOLLOW_SUCCESS };
};

const unfollowPlaylistFailure = (error: Error) => ({ type: PLAYLIST_UNFOLLOW_FAILURE, error });

const getPlaylistsSuccess = (response: any) => ({ type: GET_PLAYLISTS_SUCCESS, playlists: response.data.playlists });

const getPlaylistsFailure = (error: Error) => ({ type: GET_PLAYLISTS_FAILURE, error });
