import * as React from 'react';

interface IProps {
    className: string;
    onClick?: (e: Event) => void;
}

class IconShare extends React.Component<IProps> {
    public render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.27 51.27" className={this.props.className}>
                <title>share</title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <circle cx="43.64" cy="7.64" r="6" fill="none" stroke="#373737" strokeMiterlimit="10" strokeWidth="3.27" />
                        <circle cx="43.64" cy="43.64" r="6" fill="none" stroke="#373737" strokeMiterlimit="10" strokeWidth="3.27" />
                        <circle cx="7.64" cy="25.64" r="6" fill="none" stroke="#373737" strokeMiterlimit="10" strokeWidth="3.27" />
                        <line x1="38.27" y1="10.32" x2="13" y2="22.95" fill="none" stroke="#373737" strokeMiterlimit="10" strokeWidth="3.27" />
                        <line x1="38.31" y1="40.88" x2="13.01" y2="28.31" fill="none" stroke="#373737" strokeMiterlimit="10" strokeWidth="3.27" />
                    </g>
                </g>
            </svg>
        );
    }
}

export default IconShare;
