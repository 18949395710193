import * as React from 'react';

interface IProps {
    className: string;
}

class IconLock extends React.Component<IProps> {
    public render() {
        return (
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path d="M28.875 20.625V16.5C28.875 12.703 25.797 9.625 22 9.625C18.203 9.625 15.125 12.703 15.125 16.5V20.625" stroke="#039BE5" strokeWidth="1.5675" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.75 20.625C12.9906 20.625 12.375 21.2406 12.375 22L12.375 33C12.375 33.7594 12.9906 34.375 13.75 34.375L30.25 34.375C31.0094 34.375 31.625 33.7594 31.625 33L31.625 22C31.625 21.2406 31.0094 20.625 30.25 20.625L13.75 20.625ZM22 27.5C22.7594 27.5 23.375 26.8844 23.375 26.125C23.375 25.3656 22.7594 24.75 22 24.75C21.2406 24.75 20.625 25.3656 20.625 26.125C20.625 26.8844 21.2406 27.5 22 27.5ZM24.75 26.125C24.75 27.4064 23.8736 28.4831 22.6875 28.7884L22.6875 31.625L21.3125 31.625L21.3125 28.7884C20.1264 28.4831 19.25 27.4064 19.25 26.125C19.25 24.6062 20.4812 23.375 22 23.375C23.5188 23.375 24.75 24.6062 24.75 26.125Z"
                    fill="#039BE5"
                />
            </svg>
        );
    }
}

export default IconLock;
