import * as React from 'react';

interface IProps {
    className?: string;
}

class IconLandingRightArrow extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M18.5 11L23.5 16L18.5 21" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.5 16L23.5 16" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default IconLandingRightArrow;
