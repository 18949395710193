import * as _ from 'lodash';

import config from '../config';
import IUser from '../types/models/IUser';
import UserSubscriptionTypes from '../types/models/UserSubscriptionTypes';

export const getFirstName = (name: string): string | undefined => {
    if (!name) {
        return undefined;
    }

    const names = _.split(name, ' ');

    return _.first(names);
};

export const getLastName = (name: string): string | undefined => {
    if (!name) {
        return undefined;
    }

    const names = _.split(name, ' ');

    return _.first(names) !== _.last(names) ? _.last(names) : undefined;
};

export const isRouteValidForSignin = (pathname: string) => pathname === config.routes.subscribe;

export const hasListenBalance = (user?: IUser): boolean =>
    !!user &&
    !!user.listenBalance &&
    (!user.type || user.type === UserSubscriptionTypes.Basic || user.type === UserSubscriptionTypes.FreeTrial);

export const getListenBalance = (user?: IUser): number => {
    if (!user) {
        return 0;
    }

    let listenBalance = user.listenBalance ? user.listenBalance : 0;
    listenBalance = listenBalance < 0 ? 0 : listenBalance;

    return listenBalance;
};
