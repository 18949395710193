import { Dispatch } from 'redux';

import {
    MODAL_OPEN,
    MODAL_CLOSE,
} from '../constants/modal';
import ModalTypes from '../types/ModalTypes';

export const openModal = (modals: ModalTypes | ModalTypes[], extra?: object) => (
    dispatch: Dispatch,
) => {
    const newModals = Array.isArray(modals) ? modals : [modals];

    dispatch({ type: MODAL_OPEN, modals: newModals, extra });
};

export const closeModal = () => (dispatch: Dispatch) => {
    dispatch({ type: MODAL_CLOSE });
};
