import { Dispatch } from 'redux';
import { IState } from '../reducers/index';
import {
    SMS_SENT, SMS_SENDING, SMS_ERROR, SMS_RESET,
} from '../constants/sms';
import config from '../config/index';
import responseCheck from '../utils/response-check';
import fetch from '../utils/fetch';
import { mpTrackButtonClick } from '../tracking/mixpanel';
import { MpTrackingButtons } from '../config/buttons';

export const sendSMS = (phoneNumber: string, mode: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: SMS_SENDING });
    mpTrackButtonClick(MpTrackingButtons.SEND_APP_STORE_LINK_TO_PHONE);

    return fetch(getState)(`${config.api.url}${config.api.routes.smsDownload}`, {
        method: 'POST',
        body: JSON.stringify({ phoneNumber, mode }),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => dispatch({ type: SMS_SENT }))
        .catch(() => dispatch({ type: SMS_ERROR }));
};

export const reset = () => (dispatch: Dispatch) => {
    dispatch({ type: SMS_RESET });
};
