import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableSignupSuccess = loadable(() => import('./SuccessDownloadApp'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadSignupSuccess extends React.Component {
    render() {
        return (
            <Page noCrawl title="Signup Successful - Download the Noa App" trackingScreen={mpTrackingScreens.signupSuccessDownloadApp}>
                <LoadableSignupSuccess mode="signup" />
            </Page>
        );
    }
}

export default LazyLoadSignupSuccess;
