import * as React from 'react';

interface IProps {
    className: string;
}

class IconNavArticleSelected extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <rect x="8.58387" y="6.58387" width="14.8323" height="18.8323" rx="0.586132" stroke="white" strokeOpacity="0.87" strokeWidth="1.16774" />
                <path d="M12 16H18" stroke="white" strokeOpacity="0.87" strokeWidth="1.16774" strokeLinecap="round" />
                <path d="M12 11L16 11" stroke="white" strokeOpacity="0.87" strokeWidth="1.16774" strokeLinecap="round" />
                <path d="M12 21H20" stroke="white" strokeOpacity="0.87" strokeWidth="1.16774" strokeLinecap="round" />
            </svg>
        );
    }
}

export default IconNavArticleSelected;
