import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconArticles extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="24"
                height="24"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...this.props}>
                <rect
                    x="5.36492"
                    y="4.11492"
                    width="9.27017"
                    height="11.7702"
                    rx="0.366332"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="0.729836"
                />
                <path
                    d="M7.5 10H11.25"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="0.729836"
                    strokeLinecap="round"
                />
                <path
                    d="M7.5 6.875L10 6.875"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="0.729836"
                    strokeLinecap="round"
                />
                <path
                    d="M7.5 13.125H12.5"
                    stroke="white"
                    strokeOpacity="0.7"
                    strokeWidth="0.729836"
                    strokeLinecap="round"
                />
            </svg>
        );
    }
}

export default IconArticles;
