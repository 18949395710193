export enum MpTrackingButtons {
    // Playback
    PLAY = 'Play',
    PAUSE = 'Pause',
    PREVIOUS = 'Previous',
    NEXT = 'Next',
    REWIND = 'Rewind',
    FAST_FORWARD = 'FastForward',
    SCRUB = 'MoveProgressBar',
    ADJUST_VOLUME = 'AdjustVolume',
    ADJUST_SPEED = 'AdjustSpeed',
    PLAY_SERIES = 'PlaySeries',
    PAUSE_SERIES = 'PauseSeries',
    SLEEP = 'Sleep',

    // Content
    DOWNLOAD = 'Download',
    REMOVE_DOWNLOAD = 'RemoveDownload',
    FOLLOW = 'Follow',
    REMOVE_FOLLOW = 'RemoveFollow',
    SAVE = 'Save',
    REMOVE_SAVE = 'RemoveSave',
    QUEUE = 'Queue',
    REMOVE_QUEUE = 'RemoveQueue',
    SHOW_DESCRIPTION = 'ShowDescription',
    SHARE = 'Share',

    // Navigation
    NAVIGATE_TO_NOA = 'NavigateToNoa',
    NAVIGATE_TO_IOS_APP_STORE = 'NavigateToIosAppStore',
    NAVIGATE_TO_ANDROID_APP_STORE = 'NavigateToAndroidAppStore',
    NAVIGATE_TO_INSTAGRAM = 'NavigateToInstagram',
    NAVIGATE_TO_TWITTER = 'NavigateToTwitter',
    NAVIGATE_TO_LINKEDIN = 'NavigateToLinkedin',
    NAVIGATE_TO_FACEBOOK = 'NavigateToFacebook',

    // Miscellaneous
    PLAY_PREVIEW = 'PlayPreview',
    PAUSE_PREVIEW = 'PausePreview',
    FEEDBACK = 'Feedback',
    SEND_APP_STORE_LINK_TO_PHONE = 'SendAppStoreLinkToPhone',
    LOG_OUT = 'LogOut',

    // Landing page
    SHOW_NEXT_SERIES = 'ShowNextSeries',
    SHOW_MORE_PUBLISHERS = 'ShowMorePublishers',

    // Landing page auth
    SHOW_LOGIN_NAVBAR = 'ShowLoginNavbar',
    SHOW_SIGNUP_NAVBAR = 'ShowSignupNavbar',
    SHOW_SIGNUP_LISTEN_BAL_BANNER = 'ShowSignupListenBalBanner',
    SHOW_SIGNUP_HERO = 'ShowSignupHero',
    SHOW_SIGNUP_USE_CASES = 'ShowSignupUseCases',
    SHOW_SIGNUP_BOTTOM = 'ShowSignupFinal',
    SHOW_SIGNUP_SITEMAP = 'ShowSignupSitemap',
    SHOW_SIGNUP_FAQ = 'ShowSignupFaq',

    // Series
    SELECT_SERIES_CATEGORY_BUSINESS = 'SelectSeriesCategoryBusiness',
    SELECT_SERIES_CATEGORY_POLITICS_POLICY = 'SelectSeriesCategoryPoliticsPolicy',
    SELECT_SERIES_CATEGORY_SCIENCE_TECH = 'SelectSeriesCategoryScienceTech',
    SELECT_SERIES_CATEGORY_WORK_CAREERS = 'SelectSeriesCategoryWorkCareers',
    SELECT_SERIES_CATEGORY_WORLD_AFFAIRS = 'SelectSeriesCategoryWorldAffairs',
    SELECT_SERIES_CATEGORY_DEFAULT = 'SelectSeriesCategoryDefault',

    // Use cases
    SELECT_USE_CASE_EXERCISING = 'SelectUseCaseExercising',
    SELECT_USE_CASE_SHOWERING = 'SelectUseCaseShowering',
    SELECT_USE_CASE_COOKING = 'SelectUseCaseCooking',
    SELECT_USE_CASE_WALKING = 'SelectUseCaseWalking',
    SELECT_USE_CASE_SLEEPING = 'SelectUseCaseFallingAsleep',

    // Subscription page
    SUB_PAGE_CLAIM_OFFER = 'SubPageClaimOffer',
    OFFER_STEPS_CLAIM_OFFER = 'OfferStepsClaimOffer',
    OFFER_CONDITIONS_CLAIM_OFFER = 'OfferConditionsClaimOffer',
    SUB_PAGE_MAYBE_LATER = 'SubPageMaybeLater',
    SUB_ONE_CLICK_PAY = 'SubVia1ClickPay',
    SUB_CREDIT_CARD = 'SubViaCard',
    SHOW_CREDIT_CARD_FIELDS = 'ShowCreditCardFields',

    // Landing page
    PLAY_LANDING_PAGE_VIDEO = 'PlayLandingPageVideo',
    PAUSE_LANDING_PAGE_VIDEO = 'PauseLandingPageVideo',
    COMPLETE_LANDING_PAGE_VIDEO = 'CompleteLandingPageVideo',

    // Queue
    QUEUE_ARTICLE = 'QueueArticle',
    REMOVE_QUEUE_ARTICLE = 'RemoveQueueArticle',
    QUEUE_PLAYLIST = 'QueuePlaylist',
    REMOVE_QUEUE_PLAYLIST = 'RemoveQueuePlaylist',
}
