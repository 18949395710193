import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Col, Container, Row } from 'reactstrap';
import newtheme from '../../../newtheme';
import { mpTrackButtonClick } from '../../../tracking/mixpanel';
import { MpTrackingButtons } from '../../../config/buttons';

interface IProps {

}

class LandingEmbedVideo extends React.Component<IProps> {
    public onVideoPlay = () => {
        mpTrackButtonClick(MpTrackingButtons.PLAY_LANDING_PAGE_VIDEO);
    };

    public onVideoPause = () => {
        mpTrackButtonClick(MpTrackingButtons.PAUSE_LANDING_PAGE_VIDEO);
    };

    public onVideoEnded = () => {
        mpTrackButtonClick(MpTrackingButtons.COMPLETE_LANDING_PAGE_VIDEO);
    };

    public render() {
        return (
            <Container className={css(styles.main)}>
                <Row>
                    <Col md={{ offset: 1, size: 10 }} lg={{ offset: 2, size: 8 }} xl={{ offset: 3, size: 6 }}>
                        <p className={css(styles.videoHeading)}>Press play to discover what Noa has to offer</p>
                        <video width="100%" height="auto" controls className={css(styles.video)} onPlay={this.onVideoPlay} onPause={this.onVideoPause} onEnded={this.onVideoEnded}>
                            <source src="https://noa-assets.s3.eu-west-1.amazonaws.com/LandingPageVideo.mp4" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Col>
                </Row>
            </Container>
        );
    }
}

// To make initial loading look better
const styles = StyleSheet.create({
    main: {
        marginBottom: 50,
    },
    videoHeading: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 18,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
        },
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 20,
        width: '100%',
        marginBottom: 25,
        color: 'rgba(255, 255, 255, 0.87)',
    },
    video: {
    },
});

export default LandingEmbedVideo;
