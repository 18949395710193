import * as React from 'react';

interface IProps {
    className?: string;
}

class IconHeadphones extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M16 5.33332C13.171 5.33332 10.4579 6.45713 8.45755 8.45752C6.45716 10.4579 5.33335 13.171 5.33335 16H9.33335C10.0406 16 10.7189 16.2809 11.219 16.781C11.7191 17.2811 12 17.9594 12 18.6667V25.3333C12 26.0406 11.7191 26.7188 11.219 27.2189C10.7189 27.719 10.0406 28 9.33335 28H5.33335C4.62611 28 3.94783 27.719 3.44774 27.2189C2.94764 26.7188 2.66669 26.0406 2.66669 25.3333V16C2.66669 8.63599 8.63602 2.66666 16 2.66666C23.364 2.66666 29.3334 8.63599 29.3334 16V25.3333C29.3334 26.0406 29.0524 26.7188 28.5523 27.2189C28.0522 27.719 27.3739 28 26.6667 28H22.6667C21.9594 28 21.2812 27.719 20.7811 27.2189C20.281 26.7188 20 26.0406 20 25.3333V18.6667C20 17.9594 20.281 17.2811 20.7811 16.781C21.2812 16.2809 21.9594 16 22.6667 16H26.6667C26.6667 13.171 25.5429 10.4579 23.5425 8.45752C21.5421 6.45713 18.829 5.33332 16 5.33332ZM5.33335 18.6667V25.3333H9.33335V18.6667H5.33335ZM22.6667 18.6667V25.3333H26.6667V18.6667H22.6667Z" fill="#039BE5" />
            </svg>
        );
    }
}

export default IconHeadphones;
