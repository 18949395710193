import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import text from '../../../locale/index';
import theme from '../../../theme/index';
import BaseModal from './BaseModal';
import { IState } from '../../../reducers';
import { closeModal, openModal } from '../../../actions/modal';
import IUser from '../../../types/models/IUser';
import PaymentPlatform from '../../../types/PaymentPlatform';
import {
    getAccountDetails,
    getUser,
} from '../../../domaindriven/auth/state/actions/user';
import { mpTrackPopupView } from '../../../tracking/mixpanel';
import { mpTrackingPopups } from '../../../config/popups';
import { cancelSubscription } from '../../../domaindriven/subscribe/state/actions/subscription';

interface IProps {}

interface ILocalState {
    showAndroidCancel: boolean;
    showIosCancel: boolean;
}

interface IPropsFromState {
    loading: boolean;
    error: boolean;
    isOpen: boolean;
    user?: IUser;
}

interface IPropsFromDispatch {
    closeModal: () => void;
    cancelSubscription: typeof cancelSubscription;
    getUser: typeof getUser;
    getAccountDetails: typeof getAccountDetails;
}

class ModalCancel extends React.Component<
    IProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps,
    ILocalState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            showAndroidCancel: false,
            showIosCancel: false,
        };
    }

    public componentDidMount = () => {
        mpTrackPopupView(mpTrackingPopups.cancelConfirm);
    };

    public onClose = () => {
        this.props.closeModal();
    };

    public cancel = () => {
        if (!this.props.user || !this.props.user.subscriptionInfo) {
            this.props.closeModal();
            return;
        }

        const { paymentPlatform } = this.props.user.subscriptionInfo;

        if (
            paymentPlatform === PaymentPlatform.ANDROID_IAP ||
            paymentPlatform === PaymentPlatform.ANDROID_IAP_TEST
        ) {
            this.setState({ showAndroidCancel: true });
            return;
        }

        if (
            paymentPlatform === PaymentPlatform.IOS_IAP ||
            paymentPlatform === PaymentPlatform.IOS_IAP_TEST
        ) {
            this.setState({ showIosCancel: true });
            return;
        }

        if (paymentPlatform === PaymentPlatform.STRIPE) {
            this.props
                .cancelSubscription()
                // @ts-ignore
                .then(() => {
                    this.props.getUser();
                    this.props.getAccountDetails();
                    this.props.closeModal();
                });

            return;
        }

        this.props.closeModal();
    };

    public renderInitialPage = () => (
        <>
            <h1 className={css(styles.title)}>
                {text.cancelSubscriptionModal.TITLE}
            </h1>

            <h3 className={css(styles.cancelTitle)}>
                {text.cancelSubscriptionModal.IF_CANCEL}
            </h3>

            <ul className={css(styles.cancellationPoints)}>
                {text.cancelSubscriptionModal.cancellationPoints.map((p) => (
                    <li key={p}>
                        <span className={css(styles.cancelBulletPoint)}>
                            ✕{' '}
                        </span>
                        {p}
                    </li>
                ))}
            </ul>

            <img
                alt="Collage of Premium publisher logos"
                className={css(styles.premiumPublishers)}
                src="/PremiumPublishers.png"
            />

            <button
                type="button"
                onClick={this.props.closeModal}
                className={css(styles.button)}>
                {text.cancelSubscriptionModal.KEEP_ACCESS}
            </button>
            <div className={css(styles.cancelTextWrapper)}>
                <a onClick={this.cancel} className={css(styles.cancelText)}>
                    Please cancel my subscription and remove
                    <br />
                    all Premium benefits from my account
                </a>
            </div>
        </>
    );

    public renderAndroidCancel = () => (
        <>
            <h1 className={css(mobileCancelStyles.title)}>
                {text.cancelSubscriptionAndroidModal.TITLE}
            </h1>

            <h3 className={css(mobileCancelStyles.subtitle)}>
                {text.cancelSubscriptionAndroidModal.SUBTTILE}
            </h3>

            <h5 className={css(mobileCancelStyles.cancelTitle)}>
                {text.cancelSubscriptionAndroidModal.BULLET_POINT_HEADER}
            </h5>

            <ol className={css(mobileCancelStyles.steps)}>
                {text.cancelSubscriptionAndroidModal.cancellationSteps.map(
                    (p) => (
                        <li key={p}>{p}</li>
                    ),
                )}
            </ol>

            <button
                type="button"
                onClick={this.props.closeModal}
                className={css(styles.button, mobileCancelStyles.button)}>
                {text.cancelSubscriptionAndroidModal.CLOSE_BUTTON}
            </button>
        </>
    );

    public renderIosCancel = () => (
        <>
            <h1 className={css(mobileCancelStyles.title)}>
                {text.cancelSubscriptionIosModal.TITLE}
            </h1>

            <h3 className={css(mobileCancelStyles.subtitle)}>
                {text.cancelSubscriptionIosModal.SUBTTILE}
            </h3>

            <h5 className={css(mobileCancelStyles.cancelTitle)}>
                {text.cancelSubscriptionIosModal.BULLET_POINT_HEADER}
            </h5>

            <ol className={css(mobileCancelStyles.steps)}>
                {text.cancelSubscriptionIosModal.cancellationSteps.map((p) => (
                    <li key={p}>{p}</li>
                ))}
            </ol>

            <button
                type="button"
                onClick={this.props.closeModal}
                className={css(styles.button, mobileCancelStyles.button)}>
                {text.cancelSubscriptionIosModal.CLOSE_BUTTON}
            </button>
        </>
    );

    public render() {
        const { isOpen } = this.props;

        return (
            <BaseModal
                modalId="ModalGetTheApp"
                isOpen={isOpen}
                onClose={this.onClose}>
                <div className={css(styles.modalWrapper)}>
                    {this.state.showAndroidCancel
                        ? this.renderAndroidCancel()
                        : this.state.showIosCancel
                        ? this.renderIosCancel()
                        : this.renderInitialPage()}
                </div>
            </BaseModal>
        );
    }
}

const mobileCancelStyles = StyleSheet.create({
    title: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 40,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 35,
        },
        fontSize: 45,
    },
    subtitle: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 20,
            marginBottom: 40,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 17,
            marginBottom: 30,
        },
        fontSize: 22,
        marginBottom: 50,
        fontWeight: 400,
    },
    cancelTitle: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 20,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 17,
        },
        fontSize: 25,
        textAlign: 'left',
        fontWeight: 700,
    },
    steps: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 13,
        },
        textAlign: 'left',
        fontSize: 20,
    },
    button: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            marginTop: 40,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            marginTop: 30,
        },
        marginTop: 50,
    },
});

const styles = StyleSheet.create({
    modalWrapper: {
        maxWidth: '100%',
        width: 600,
        textAlign: 'center',
        padding: '20px 20px',
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 45,
            marginBottom: 40,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 35,
            marginBottom: 30,
        },
        fontSize: 50,
        marginBottom: 50,
    },
    cancelTitle: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 20,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 17,
        },
        fontSize: 25,
        color: '#FB0000',
        textAlign: 'left',
        fontWeight: 700,
    },
    cancellationPoints: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 18,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 13,
        },
        textAlign: 'left',
        paddingLeft: 0,
        fontSize: 20,
    },
    cancelBulletPoint: {
        color: '#FB0000',
        fontWeight: 700,
    },
    button: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            marginTop: 60,
            height: 50,
            fontSize: 17,
            padding: '0 35px',
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            marginTop: 50,
            height: 45,
            fontSize: 14,
            padding: '0 20px',
        },
        ':hover': {
            backgroundColor: theme.primayDark,
        },
        marginTop: 75,
        height: 60,
        borderRadius: 30,
        backgroundColor: theme.primaryColor,
        color: theme.white,
        border: 'none',
        padding: '0 50px',
        fontWeight: 700,
        fontSize: 20,
    },
    cancelTextWrapper: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            marginTop: 20,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            marginTop: 10,
        },
        marginTop: 25,
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
    },
    cancelText: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 16,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 13,
        },
        ':hover': {
            color: '#FB0000',
        },
        fontSize: 18,
        cursor: 'pointer',
    },
    premiumPublishers: {
        marginTop: 25,
        maxWidth: '80%',
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isOpen: state.modal.isOpen,
        loading: state.sms.loading,
        error: state.sms.error,
        user: state.user.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            closeModal,
            cancelSubscription,
            openModal,
            getUser,
            getAccountDetails,
        },
        dispatch,
    );
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModalCancel),
);
