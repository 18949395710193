import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import Icon5StarsStyle2 from '../../assets/icons/Icon5StarsStyle2';
import newtheme from '../../newtheme';

export interface IReview {
    reviewText: string;
    reviewerName: string;
    reviewerImage?: string;
    reviewSource: string;
}

interface IProps {
    review: IReview;
}

class ReviewCard extends React.Component<IProps> {
    public render() {
        const { review } = this.props;

        return (
            <div className={css(styles.main)}>
                <p className={css(styles.reviewText)}>{review.reviewText}</p>
                <Icon5StarsStyle2 className={css(styles.starIcon)} />

                <p className={css(styles.reviewerName)}>{review.reviewerName}</p>
                <p className={css(styles.reviewSource)}>{review.reviewSource}</p>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    main: {
        padding: '28px 37px',
        background: 'linear-gradient(129.07deg, #2E547A 0%, rgba(46, 84, 122, 0) 52.43%), #122F4C',
        borderRadius: 20,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    reviewText: {
        [newtheme.cssBreakpoints.largeWidthLargeHeight]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 14,
        },
        fontWeight: 400,
        fontSize: 18,
        marginBottom: 37,
        textAlign: 'left',
        width: '100%',
    },
    reviewerName: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 12,
        },
        fontWeight: 600,
        fontSize: 14,
        margin: 0,
    },
    reviewSource: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 10,
        },
        fontWeight: 400,
        fontSize: 12,
        margin: 0,
    },
    starIcon: {
        [newtheme.cssBreakpoints.largeWidthLargeHeight]: {
            marginBottom: 20,
        },
        width: 151,
        height: 22,
        marginBottom: 30,
    },
});

export default ReviewCard;
