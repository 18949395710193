import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    Accordion,
    AccordionItemState,
} from 'react-accessible-accordion';
import IconPlus from '../../assets/icons/IconPlus';
import IconMinus from '../../assets/icons/IconMinus';
import newtheme from '../../newtheme';
import { mpTrackAccordionItemExpanded } from '../../tracking/mixpanel';
import { MpAccordionType } from '../../config/MpAccordionTypes';

export interface IAccordionItem {
    heading: string;
    text: any;
    id: string;
}

interface IProps {
    items: IAccordionItem[];
}

class NoaAccordion extends React.Component<IProps> {
    public trackClick = (name: string) => {
        mpTrackAccordionItemExpanded(name, MpAccordionType.FAQ);
    };

    public render() {
        const { items } = this.props;

        return (
            <div className={css(styles.main)}>
                <Accordion allowZeroExpanded>
                    {items.map((item) => (
                        <AccordionItem className={css(styles.accordionItem)}>
                            <AccordionItemHeading className={css(styles.accordionHeading)}>
                                <AccordionItemButton className={css(styles.accordionButton)}>
                                    <AccordionItemState>
                                        {({ expanded }) => {
                                            if (expanded) {
                                                this.trackClick(item.heading);
                                            }
                                            return expanded ? (
                                                <IconMinus className={css(styles.accordionIcon)} />
                                            ) : (
                                                <IconPlus className={css(styles.accordionIcon)} />
                                            );
                                        }}
                                    </AccordionItemState>
                                    <h5 className={css(styles.accordionHeadingText)}>{item.heading}</h5>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel className={css(styles.accordionPanel)}>
                                <div className={css(styles.accordionPanelText)}>{item.text}</div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        );
    }
}

const opacityKeyframes = {
    from: {
        opacity: 0,
    },

    to: {
        opacity: 1,
    },
};

const styles = StyleSheet.create({
    main: {},
    accordionItem: {
        [newtheme.cssBreakpoints.medium]: {
            padding: '15px 20px',
            borderRadius: 10,
            marginBottom: 20,
        },
        [newtheme.cssBreakpoints.tiny]: {
            flexDirection: 'column',
            padding: '15px 10px',
            borderRadius: 10,
            marginBottom: 15,
        },
        borderRadius: 20,
        backgroundColor: '#122F4C',
        padding: 25,
        paddingRight: 100,
        marginBottom: 30,
    },
    accordionHeading: {},
    accordionButton: {
        ':hover': {
            filter: 'brightness(125%)',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '-webkit-touch-callout': 'none' /* iOS Safari */,
        '-webkit-user-select': 'none' /* Safari */,
        '-khtml-user-select': 'none' /* Konqueror HTML */,
        '-moz-user-select': 'none' /* Old versions of Firefox */,
        '-ms-user-select': 'none' /* Internet Explorer/Edge */,
        'user-select': 'none',
    },
    accordionIcon: {
        [newtheme.cssBreakpoints.medium]: {
            width: 40,
            height: 40,
            minWidth: 40,
            marginRight: 20,
        },
        [newtheme.cssBreakpoints.small]: {
            width: 30,
            height: 30,
            minWidth: 30,
            marginRight: 15,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginRight: 10,
        },
        width: 50,
        height: 50,
        minWidth: 50,
        marginRight: 32,
    },
    accordionHeadingText: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 19,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 17,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 15,
        },
        margin: 0,
        fontSize: 22,
        fontWeight: 600,
        lineHeight: 1.5,
    },
    accordionPanel: {
        [newtheme.cssBreakpoints.medium]: {
            marginLeft: 60,
        },
        [newtheme.cssBreakpoints.small]: {
            marginLeft: 47,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginLeft: 39,
        },
        color: 'white',
        animationName: [opacityKeyframes],
        animationDuration: '0.4s',
        marginLeft: 82,
        paddingTop: 12,
    },
    accordionPanelText: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 17,
            lineHeight: '23px',
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 15,
            lineHeight: '21px',
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
            lineHeight: '19px',
        },
        margin: 0,
        fontSize: 18,
        lineHeight: '26px',
        color: '#6890B9',
        fontWeight: 400,
        animationName: [opacityKeyframes],
        animationDuration: '0.7s',
    },
});

export default NoaAccordion;
