import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import config from '../config/index';
import {
    ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_PENDING,
    ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_REJECTED,
    ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_SUCCESS,
    GET_RECOMMENDED_ARTICLES_FAILURE,
    GET_RECOMMENDED_ARTICLES_REQUEST,
    GET_RECOMMENDED_ARTICLES_SUCCESS,
    GET_RECOMMENDED_FAILURE,
    GET_RECOMMENDED_REQUEST,
    GET_RECOMMENDED_SUCCESS,
    RECOMMENDATION_CLEAR,
    RECOMMENDATION_IS_QUEUED_PENDING,
    RECOMMENDATION_IS_QUEUED_REJECTED,
    RECOMMENDATION_IS_QUEUED_SUCCESS,
} from '../constants/recommendation';
import { IState } from '../reducers/index';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import text from '../locale';

export const clearRecommendation = () => (dispatch: Dispatch) => dispatch({ type: RECOMMENDATION_CLEAR });

export const getRecommended = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: GET_RECOMMENDED_REQUEST });

    return fetch(getState)(`${config.api.url}${config.api.routes.recommendation}`)
        .then(responseCheck)
        .then((response) => dispatch(getRecommendedSuccess(response)))
        .catch((error) => dispatch(getRecommendedFailure(error)));
};

export const getRecommendedArticles = (articleId: number) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: GET_RECOMMENDED_ARTICLES_REQUEST });

    return fetch(getState)(`${config.api.url}${config.api.routes.recommendation}?articleId=${articleId}`)
        .then(responseCheck)
        .then((response) => dispatch(getRecommendedArticlesSuccess(response)))
        .catch((error) => dispatch(getRecommendedArticlesFailure(error)));
};

export const setRecommendationPlaylistIsQueuedSuccess =
    ({ playlistId, isQueued }: { playlistId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: RECOMMENDATION_IS_QUEUED_SUCCESS, isQueued: { playlistId, status: isQueued } });

export const setRecommendationPlaylistIsQueuedPending =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: RECOMMENDATION_IS_QUEUED_PENDING, isQueued: { playlistId } });

export const setRecommendationPlaylistIsQueuedRejected =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: RECOMMENDATION_IS_QUEUED_REJECTED, isQueued: { playlistId } });

export const setArticleIsQueuedForRecommendationPageSuccess =
    ({ articleId, isQueued }: { articleId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_SUCCESS,
            isQueued: { articleId, status: isQueued },
        });
export const setArticleIsQueuedForRecommendationPagePending =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_PENDING,
            isQueued: { articleId },
        });
export const setArticleIsQueuedForRecommendationPageRejected =
    ({ articleId }: { articleId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({
            type: ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_REJECTED,
            isQueued: { articleId },
        });

const getRecommendedSuccess = (response: any) => ({
    type: GET_RECOMMENDED_SUCCESS,
    segments: response.data,
});

const getRecommendedFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: GET_RECOMMENDED_FAILURE, error };
};

const getRecommendedArticlesSuccess = (response: any) => ({
    type: GET_RECOMMENDED_ARTICLES_SUCCESS,
    articles: response.data.articles,
});

const getRecommendedArticlesFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);
    return { type: GET_RECOMMENDED_ARTICLES_FAILURE, error };
};
