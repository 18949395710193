import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

class NewSitemapSeparator extends React.Component {
    public render() {
        return <div className={css(styles.main)} />;
    }
}

const styles = StyleSheet.create({
    main: {
        backgroundColor: '#42505F',
        width: '100%',
        height: 1,
    },
});

export default NewSitemapSeparator;
