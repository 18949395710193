import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { ToastContainer } from 'react-toastify';
import theme from '../theme';

class Toast extends React.Component {
    public render() {
        return (
            <ToastContainer
                className={css(styles.toast)}
                pauseOnHover={false}
                pauseOnFocusLoss={false}
                closeButton={false}
                autoClose={3500}
            />
        );
    }
}

const styles = StyleSheet.create({
    toast: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            top: 70,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            top: 60,
        },
        position: 'absolute',
        top: 80,
        width: 200,
    },
});

export default Toast;
