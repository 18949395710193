export const getSubscriptionErrorMessage = (subscriptionError: {
    [key: string]: any;
}): {
    keyMessage: string;
    solution: string;
} => {
    const { code: errorCode, decline_code: declineCode } = subscriptionError;
    const { declineCodes, errorCodes, default: defaultMessages } = getSubscriptionErrors();

    if (errorCode === 'card_declined') {
        return declineCodes[declineCode] || defaultMessages;
    }

    return errorCodes[errorCode] || defaultMessages;
};

export const getSubscriptionErrors = () => {
    const errorCodes = {
        card_decline_rate_limit_exceeded: {
            keyMessage: 'Your card has been declined too many times.',
            solution:
                'Please check that you have input the correct card details and try the transaction again in 24 hours.',
        },
        expired_card: {
            keyMessage: "Your card's expiry date has passed, or it is invalid.",
            solution: 'Please check the expiration date or use a different card for this transaction.',
        },
        invalid_expiry_month: {
            keyMessage: "Your card's expiry date has passed, or it is invalid.",
            solution: 'Please check the expiration date or use a different card for this transaction.',
        },
        invalid_expiry_year: {
            keyMessage: "Your card's expiry date has passed, or it is invalid.",
            solution: 'Please check the expiration date or use a different card for this transaction.',
        },
        incorrect_cvc: {
            keyMessage: 'The security code, or CVC, provided is incorrect/invalid.',
            solution: "Please check the card's security code, or use a different card for this transaction.",
        },
        invalid_cvc: {
            keyMessage: 'The security code, or CVC, provided is incorrect/invalid.',
            solution: "Please check the card's security code, or use a different card for this transaction.",
        },
        incorrect_number: {
            keyMessage: 'The card number provided is incorrect.',
            solution: "Please check the card's number or use a different card for this transaction.",
        },
        insufficient_funds: {
            keyMessage: "We've been informed that this card has insufficient funds to cover this transaction.",
            solution: 'Please check your balance with your card provider, or use a different card instead.',
        },
        invalid_card_type: {
            keyMessage: 'The card provided does not support this transaction.',
            solution: 'Please use a different card instead.',
        },
        payment_method_provider_decline: {
            keyMessage: 'Your card provided declined this transaction.',
            solution: 'Please contact your card provided for more details, or use a different card instead.',
        },
        payment_method_unsupported_type: {
            keyMessage: "We're sorry, we are unable to accept your card type.",
            solution:
                'Please contact us for more details (support@newsoveraudio.com), or use a different card instead.',
        },
        subscription_already_exists: {
            keyMessage: 'Subscription Already Active',
            solution:
                'It seems like you already have an active subscription. Please cancel your current subscription before subscribing again. If you need any assistance, feel free to contact our support team.',
        },
    };
    const declineCodes = {
        authentication_required: {
            keyMessage: 'Card authentication was incomplete.',
            solution:
                'Please retry the transaction and authenticate your card when prompted. if this issue persists, please contact your card provider.',
        },
        approve_with_id: {
            keyMessage: "This transaction wasn't authorized.",
            solution: 'Please retry the transaction. If this issue persists, please contact your card provider.',
        },
        issuer_not_available: {
            keyMessage: "This transaction wasn't authorized.",
            solution: 'Please retry the transaction. If this issue persists, please contact your card provider.',
        },
        processing_error: {
            keyMessage: "This transaction wasn't authorized.",
            solution: 'Please retry the transaction. If this issue persists, please contact your card provider.',
        },
        reenter_transaction: {
            keyMessage: "This transaction wasn't authorized.",
            solution: 'Please retry the transaction. If this issue persists, please contact your card provider.',
        },
        try_again_later: {
            keyMessage: "This transaction wasn't authorized.",
            solution: 'Please retry the transaction. If this issue persists, please contact your card provider.',
        },
        call_issuer: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        card_velocity_exceeded: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        do_not_honor: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        do_not_try_again: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        fraudulent: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        generic_decline: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        invalid_account: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        invalid_amount: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        lost_card: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        merchant_blacklist: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        new_account_information_available: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        no_action_taken: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        not_permitted: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        pickup_card: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        restricted_card: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        revocation_of_all_authorizations: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        revocation_of_authorization: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        security_violation: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        service_not_allowed: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        stolen_card: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        stop_payment_order: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        transaction_not_allowed: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        withdrawal_count_limit_exceeded: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
        card_not_supported: {
            keyMessage: 'Your card does not support this type of transaction.',
            solution: 'Please contact your card provider, or use a different card instead.',
        },
        currency_not_supported: {
            keyMessage: 'Your card does not support the transaction currency.',
            solution: 'Please contact your card provider, or use a different card instead.',
        },
        duplicate_transaction: {
            keyMessage: 'We believe this transaction is a duplicate and cannot process.',
            solution: 'Please contact us (support@newsoveraudio.com) and we will confirm payment.',
        },
        expired_card: {
            keyMessage: "Your card's expiry date has passed, or it is invalid.",
            solution: 'Please check the expiration date or use a different card for this transaction.',
        },
        invalid_expiry_month: {
            keyMessage: "Your card's expiry date has passed, or it is invalid.",
            solution: 'Please check the expiration date or use a different card for this transaction.',
        },
        invalid_expiry_year: {
            keyMessage: "Your card's expiry date has passed, or it is invalid.",
            solution: 'Please check the expiration date or use a different card for this transaction.',
        },
        incorrect_number: {
            keyMessage: 'The card number provided is incorrect.',
            solution: "Please check the card's number or use a different card for this transaction.",
        },
        invalid_number: {
            keyMessage: 'The card number provided is incorrect.',
            solution: "Please check the card's number or use a different card for this transaction.",
        },
        incorrect_cvc: {
            keyMessage: 'The security code, or CVC, provided is incorrect/invalid.',
            solution: "Please check the card's security code, or use a different card for this transaction.",
        },
        invalid_cvc: {
            keyMessage: 'The security code, or CVC, provided is incorrect/invalid.',
            solution: "Please check the card's security code, or use a different card for this transaction.",
        },
        insufficient_funds: {
            keyMessage: "We've been informed that this card has insufficient funds to cover this transaction.",
            solution: 'Please check your balance with your card provider, or use a different card instead.',
        },
    };

    return {
        errorCodes,
        declineCodes,
        default: {
            keyMessage: 'This transaction was declined.',
            solution: 'Please contact your card provider, or use a different card for this transaction.',
        },
    };
};
