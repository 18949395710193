import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import newtheme from '../../../newtheme';
import IArticleTab from '../types/IArticleTab';

interface IProps {
    tab: IArticleTab;
    isActive: boolean;
    onClick: () => void;
}

interface IState {
}

class ArticleTab extends React.Component<IProps, IState> {
    public componentDidMount() {
    }

    public onClick = () => {
        this.props.onClick();
    };

    public render() {
        const { tab, isActive } = this.props;

        return (
            <div className={css(styles.main, isActive && styles.activeMain)} onClick={this.onClick}>
                <p className={css(styles.text, isActive && styles.activeText)}>{tab.text}</p>
            </div>
        );
    }
}

// To make initial loading look better
const styles = StyleSheet.create({
    main: {
        ':hover': {
            cursor: 'pointer',
        },
        backgroundColor: '#143551',
        borderRadius: 30,
        marginRight: 12,
        flex: 'flex: 0 0 auto',
        border: '1px solid #143551',
    },
    activeMain: {
        border: `1px solid ${newtheme.colors.primaryBlue}`,
    },
    text: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 16,
        },
        fontSize: 20,
        fontWeight: 400,
        color: 'white',
        padding: '8px 8px',
        margin: 0,
        whiteSpace: 'nowrap',
    },
    activeText: {
        color: newtheme.colors.primaryBlue,
    },
});

export default ArticleTab;
