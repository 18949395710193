import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import config from '../config';
import {
    SECTION_CLEAR,
    SECTION_FAILURE,
    SECTION_REQUEST,
    SECTION_SUCCESS,
    SECTION_FOLLOW_FAILURE,
    SECTION_FOLLOW_REQUEST,
    SECTION_FOLLOW_SUCCESS,
    SINGLE_SECTION_REQUEST,
    SINGLE_SECTION_FAILURE,
    SINGLE_SECTION_SUCCESS,
    SECTION_PLAYLIST_IS_QUEUED_SUCCESS,
    SECTION_PLAYLIST_IS_QUEUED_PENDING,
    SECTION_PLAYLIST_IS_QUEUED_REJECTED,
} from '../constants/section';
import { IState } from '../reducers';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import text from '../locale';

export const clearSection = () => (dispatch: Dispatch) => dispatch({ type: SECTION_CLEAR });

export const getSections = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: SECTION_REQUEST });

    return fetch(getState)(`${config.api.url}${config.api.routes.sections}`)
        .then(responseCheck)
        .then((response) => dispatch(sectionsSuccess(response)))
        .catch((error) => dispatch(sectionsFailure(error)));
};

export const getSectionsV4 = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: SECTION_REQUEST });

    return fetch(getState)(`${config.api.url}${config.api.routes.sectionsV4}`)
        .then(responseCheck)
        .then((response) => dispatch(sectionsSuccess(response)))
        .catch((error) => dispatch(sectionsFailure(error)));
};

export const getSingleSectionV4 = (url: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: SINGLE_SECTION_REQUEST });

    return fetch(getState)(url)
        .then(responseCheck)
        .then((response) => dispatch(singleSectionSuccess(response)))
        .catch((error) => dispatch(singleSectionFailure(error)));
};

export const followSections = (ids: number[]) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: SECTION_FOLLOW_REQUEST });

    const requestUrl = `${config.api.url}${config.api.routes.users}/sections`;

    return fetch(getState)(requestUrl, {
        method: 'PUT',
        body: JSON.stringify(ids),
    })
        .then(responseCheck)
        .then(() => dispatch(followSectionsSuccess()))
        .catch((error) => dispatch(followSectionsFailure(error)));
};

export const setSectionPlaylistIsQueuedSuccess =
    ({ playlistId, isQueued }: { playlistId: number; isQueued: boolean }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: SECTION_PLAYLIST_IS_QUEUED_SUCCESS, isQueued: { playlistId, status: isQueued } });

export const setSectionPlaylistIsQueuedPending =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: SECTION_PLAYLIST_IS_QUEUED_PENDING, isQueued: { playlistId } });

export const setSectionPlaylistIsQueuedRejected =
    ({ playlistId }: { playlistId: number }) =>
    (dispatch: Dispatch) =>
        dispatch({ type: SECTION_PLAYLIST_IS_QUEUED_REJECTED, isQueued: { playlistId } });

const sectionsSuccess = (response: any) => ({ type: SECTION_SUCCESS, sections: response.data.sections });

const sectionsFailure = (error: Error) => ({ type: SECTION_FAILURE, error });

const singleSectionSuccess = (response: any) => ({ type: SINGLE_SECTION_SUCCESS, section: response.data });

const singleSectionFailure = (error: Error) => ({ type: SINGLE_SECTION_FAILURE, section: error });

const followSectionsSuccess = () => ({ type: SECTION_FOLLOW_SUCCESS });

const followSectionsFailure = (error: Error) => {
    toast(text.notifications.SOMETHING_WENT_WRONG);

    return { type: SECTION_FOLLOW_FAILURE, error };
};
