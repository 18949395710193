import {
    USER_FAILURE,
    USER_REQUEST,
    USER_SUCCESS,
    SET_CURRENT_USER,
    LOGOUT,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    USER_FAVOURITES_SUCCESS,
    ACCOUNT_DETAILS_REQUEST,
    ACCOUNT_DETAILS_SUCCESS,
    ACCOUNT_DETAILS_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
} from '../constants/user';
import IUser from '../../../../types/models/IUser';
import IAccountDetails from '../../../../types/models/IAccountDetails';

export interface IState {
    loading: boolean;
    user?: IUser;
    accountDetails?: IAccountDetails;
    error?: any;
}

interface IAction {
    type: string;
    user?: IUser;
    error?: Error;
    accountDetails?: IAccountDetails;
}

export const initialState: IState = {
    loading: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_SUCCESS: {
            const user = action.user as IUser;
            const newUser = user;

            return {
                ...state,
                loading: false,
                user: newUser,
            };
        }
        case USER_FAVOURITES_SUCCESS: {
            const existingUser = state.user;

            if (!existingUser) {
                return state;
            }

            existingUser.favourites = action.user?.favourites;

            return {
                ...state,
                loading: false,
                user: existingUser,
            };
        }
        case USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                user: action.user,
            };
        case LOGOUT:
            return {
                ...state,
                user: null,
            };
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.user,
            };
        }
        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SIGNUP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SIGNUP_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.user,
            };
        }
        case SIGNUP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case DELETE_USER_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                accountDetails: action.accountDetails,
            };
        case ACCOUNT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
