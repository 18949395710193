import * as React from 'react';

interface IProps {
    className: string;
}

class IconPauseBlue extends React.Component<IProps> {
    public render() {
        return (
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M8.125 5.6875V19.5" stroke="#039BE5" strokeWidth="3.25" strokeLinecap="round" />
                <path d="M17.875 5.6875V19.5" stroke="#039BE5" strokeWidth="3.25" strokeLinecap="round" />
            </svg>
        );
    }
}

export default IconPauseBlue;
