export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const PLAYLIST_IS_QUEUED_FOR_SEARCH_SUCCESS = 'PLAYLIST_IS_QUEUED_FOR_SEARCH_SUCCESS';
export const PLAYLIST_IS_QUEUED_FOR_SEARCH_PENDING = 'PLAYLIST_IS_QUEUED_FOR_SEARCH_PENDING';
export const PLAYLIST_IS_QUEUED_FOR_SEARCH_REJECTED = 'PLAYLIST_IS_QUEUED_FOR_SEARCH_REJECTED';
export const ARTICLE_IS_QUEUED_FOR_SEARCH_SUCCESS = 'ARTICLE_IS_QUEUED_FOR_SEARCH_SUCCESS';
export const ARTICLE_IS_QUEUED_FOR_SEARCH_PENDING = 'ARTICLE_IS_QUEUED_FOR_SEARCH_PENDING';
export const ARTICLE_IS_QUEUED_FOR_SEARCH_REJECTED = 'ARTICLE_IS_QUEUED_FOR_SEARCH_REJECTED';
