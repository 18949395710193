import { SERVER_LOADED, CLIENT_LOADED } from '../constants/app';

export interface IState {
    serverLoaded: boolean;
}

interface IAction {
    type: string;
}

export const initialState: IState = {
    serverLoaded: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SERVER_LOADED:
            return {
                ...state,
                serverLoaded: true,
            };
        case CLIENT_LOADED:
            return {
                ...state,
                serverLoaded: false,
            };
        default:
            return state;
    }
};
