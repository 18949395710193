import * as React from 'react';

import { Container } from 'reactstrap';
import { css, StyleSheet } from 'aphrodite';
import ISection from '../../types/models/ISection';
import IUser from '../../types/models/IUser';

interface IProps {
    section: ISection;
    user?: IUser;
    share: () => void;
    children?: any;
}

interface IState {}

class ViewSection extends React.Component<IProps, IState> {
    public render() {
        return (
            <div className={css(styles.main)}>
                <Container>{this.props.children}</Container>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    main: {
        paddingBottom: 50,
        minHeight: '100vh',
    },
});

export default ViewSection;
