import * as React from 'react';

interface IProps {
    className: string;
}

class IconArticleSelected extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.17 6C8.52383 6 8 6.52383 8 7.17V24.83C8 25.4762 8.52383 26 9.17 26H22.83C23.4762 26 24 25.4762 24 24.83V7.17C24 6.52383 23.4762 6 22.83 6H9.17ZM12 10.4161C11.6775 10.4161 11.4161 10.6775 11.4161 11C11.4161 11.3225 11.6775 11.5839 12 11.5839H16C16.3225 11.5839 16.5839 11.3225 16.5839 11C16.5839 10.6775 16.3225 10.4161 16 10.4161H12ZM12 15.4161C11.6775 15.4161 11.4161 15.6775 11.4161 16C11.4161 16.3225 11.6775 16.5839 12 16.5839H18C18.3225 16.5839 18.5839 16.3225 18.5839 16C18.5839 15.6775 18.3225 15.4161 18 15.4161H12ZM12 20.4161C11.6775 20.4161 11.4161 20.6775 11.4161 21C11.4161 21.3225 11.6775 21.5839 12 21.5839H20C20.3225 21.5839 20.5839 21.3225 20.5839 21C20.5839 20.6775 20.3225 20.4161 20 20.4161H12Z"
                    fill="#039BE5"
                />
            </svg>
        );
    }
}

export default IconArticleSelected;
