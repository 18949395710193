import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';

const LoadableSubscribe = loadable(() => import('../containers/Subscribe'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadSubscribe extends React.Component {
    render() {
        return <LoadableSubscribe />;
    }
}

export default LazyLoadSubscribe;
