import * as React from 'react';

interface IProps {
    className: string;
}

class IconNavHomeSelected extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path
                    d="M9.35714 26.2857H12.6428C13.1162 26.2857 13.5 25.902 13.5 25.4286V20.4571C13.5 19.9838 13.8837 19.6 14.3571 19.6H17.6428C18.1162 19.6 18.5 19.9838 18.5 20.4571V25.4286C18.5 25.902 18.8837 26.2857 19.3571 26.2857H22.6428C23.1162 26.2857 23.5 25.902 23.5 25.4286V18.4C23.5 17.9266 23.8837 17.5429 24.3571 17.5429H25.9094C26.676 17.5429 27.0571 16.6134 26.5111 16.0753L16.6017 6.30741C16.268 5.97847 15.732 5.97847 15.3983 6.30741L5.48885 16.0753C4.94289 16.6134 5.32395 17.5429 6.09056 17.5429H7.64285C8.11624 17.5429 8.5 17.9266 8.5 18.4V25.4286C8.5 25.902 8.88375 26.2857 9.35714 26.2857Z"
                    fill="#039BE5"
                />
            </svg>
        );
    }
}

export default IconNavHomeSelected;
