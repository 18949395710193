import * as React from 'react';

interface IProps {
    className?: string;
}

class CalendarIcon extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={this.props.className}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.66665 2.79165C6.9658 2.79165 7.20831 3.03416 7.20831 3.33331V4.16667H12.7916V3.33331C12.7916 3.03416 13.0342 2.79165 13.3333 2.79165C13.6325 2.79165 13.875 3.03416 13.875 3.33331V4.16667H15.8333C16.7538 4.16667 17.5 4.91286 17.5 5.83333V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H4.16667C3.24619 17.5 2.5 16.7538 2.5 15.8333V5.83333C2.5 4.91286 3.24619 4.16667 4.16667 4.16667H6.12498V3.33331C6.12498 3.03416 6.36749 2.79165 6.66665 2.79165ZM12.7916 5.25V6.66665C12.7916 6.9658 13.0342 7.20831 13.3333 7.20831C13.6325 7.20831 13.875 6.9658 13.875 6.66665V5.25H15.8333C16.1555 5.25 16.4167 5.51117 16.4167 5.83333V8.62498H3.58333V5.83333C3.58333 5.51117 3.8445 5.25 4.16667 5.25H6.12498V6.66665C6.12498 6.9658 6.36749 7.20831 6.66665 7.20831C6.9658 7.20831 7.20831 6.9658 7.20831 6.66665V5.25H12.7916ZM3.58333 9.70831V15.8333C3.58333 16.1555 3.8445 16.4167 4.16667 16.4167H15.8333C16.1555 16.4167 16.4167 16.1555 16.4167 15.8333V9.70831H3.58333Z"
                    fill="white"
                    fillOpacity="0.6"
                />
            </svg>
        );
    }
}

export default CalendarIcon;
