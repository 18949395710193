import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import IJournalist from '../../types/models/IJournalist';
import IUser from '../../types/models/IUser';
import IconShare from '../../assets/icons/IconShare';
import theme from '../../theme';
import { isServer } from '../../../store';
import { addOnScroll, removeOnScroll } from '../../utils/screen';
import text from '../../locale';
import ButtonFixed from '../buttons/ButtonFixed';

const headerHeight = 380;

interface IProps {
    journalist: IJournalist;
    user?: IUser;
    share: () => void;
    children?: any;
}

interface IState {
    height: number;
    width: number;
}

class ViewJournalist extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        const height = !isServer ? window.scrollY : 0;
        const width = !isServer ? window.innerWidth : 0;

        this.state = { height, width };
    }

    public componentDidMount = () => {
        addOnScroll('ViewJournalist', this.onScroll);
    };

    public componentWillUnmount() {
        removeOnScroll('ViewJournalist');
    }

    public onScroll = () => {
        const height = !isServer ? window.scrollY : 0;
        const width = !isServer ? window.innerWidth : 0;

        this.setState({ height, width });
    };

    public getOpacities = (): { regular: number; inverted: number } => {
        const { height, width } = this.state;

        if (isServer || width > theme.breakpoints.large) {
            return { regular: 1, inverted: 0 };
        }

        return {
            regular: 1 - height / headerHeight,
            inverted: height / headerHeight,
        };
    };

    public render() {
        const { journalist } = this.props;
        const { regular, inverted } = this.getOpacities();
        const publishers = Array.isArray(journalist.publishers) && journalist.publishers.length > 0 ? journalist.publishers.map((p) => p.name).join(', ') : null;
        const imageUrl = journalist.image ? journalist.image : Array.isArray(journalist.publishers) && journalist.publishers.length > 0 ? journalist.publishers[0].largeImage : null;

        return (
            <div>
                <div className={css(styles.header)} style={{ opacity: regular }}>
                    <div className={css(styles.imgContainer)}>
                        <div className={css(styles.image)} style={{ background: ` url(${imageUrl})` }} />
                    </div>
                    <div className={css(styles.info)}>
                        <div>
                            <h1 className={css(styles.title)}>{journalist.name}</h1>
                            {publishers && <h2 className={css(styles.subtitle)}>{publishers}</h2>}
                            <h3 className={css(styles.description)}>{journalist.description}</h3>
                            <div className={css(styles.actions)}>
                                <div onClick={this.props.share} className={css(styles.share)}>
                                    <IconShare className={css(styles.shareIcon)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ButtonFixed
                    style={{ opacity: inverted }}
                    // prevent click follow when is with a lot of opacity
                    disabled={inverted < 0.3}
                    text={text.common.SHARE}
                    onClick={this.props.share}
                />
                <div className={css(styles.content)}>{this.props.children}</div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    header: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            flexDirection: 'column',
            width: '100%',
            marginLeft: 0,
            paddingTop: 40,
            height: 380,
        },
        width: 'calc(100% + 70px)',
        marginLeft: -50,
        boxShadow: 'rgba(0, 0, 0, 0.7) 0px -250px 200px -90px inset',
        display: 'flex',
        height: 340,
        background: 'url(\'/header.jpg\')',
        backgroundSize: 'cover',
        position: 'relative',
    },
    image: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            width: 180,
            height: 180,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            width: 160,
            height: 160,
        },
        width: 200,
        height: 200,
        textAlign: 'center',
        backgroundSize: 'cover',
        animation: 'placeholder 3s ease infinite alternate',
        transition: 'box-shadow 0.3s ease-in-out',
        borderRadius: 200,
    },
    title: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 28,
        },
        fontSize: 40,
        color: 'white',
    },
    subtitle: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            textAlign: 'center',
        },
        fontSize: 25,
        color: 'white',
        fontWeight: 300,
    },
    description: {
        fontSize: 20,
        color: 'white',
        fontWeight: 300,
    },
    info: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imgContainer: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            width: '100%',
            paddingLeft: 0,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 320,
        paddingLeft: 50,
    },
    content: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 20,
        },
        paddingTop: 40,
        paddingLeft: 40,
        paddingRight: 40,
    },
    shareIcon: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            width: 20,
        },
        ':hover': {
            filter: 'brightness(0.8)',
        },
        width: 30,
        flex: 1,
        cursor: 'pointer',
        fill: 'white',
    },
    share: {
        [`@media (min-width: ${theme.breakpoints.large}px)`]: {
            bottom: 70,
            right: 100,
            position: 'absolute',
        },
    },
    actions: {
        [`@media (max-width: ${theme.breakpoints.large}px)`]: {
            justifyContent: 'center',
            paddingTop: 10,
            paddingBottom: 10,
        },
        display: 'flex',
        alignItems: 'center',
    },
});

export default ViewJournalist;
