import * as React from 'react';

interface IProps {
    className: string;
}

class IconPlayBlue extends React.Component<IProps> {
    public render() {
        return (
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M18.805 12.011L8.58953 5.88028C7.74792 5.37921 6.5 5.88028 6.5 7.08875V19.3502C6.5 20.4703 7.66085 21.1482 8.58953 20.5882L18.805 14.4574C19.7047 13.8974 19.7047 12.571 18.805 12.011Z" />
            </svg>
        );
    }
}

export default IconPlayBlue;
