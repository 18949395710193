import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconRightArrowBlue extends React.Component<IProps> {
    public render() {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path d="M13.5 8.25L17.25 12M17.25 12L13.5 15.75M17.25 12L6 12" stroke="#039BE5" strokeWidth="0.975" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default IconRightArrowBlue;
