import { Dispatch } from 'redux';

import * as _ from 'lodash';
import fetch from '../utils/fetch';
import { IState } from '../reducers';
import responseCheck from '../utils/response-check';
import {
    GET_LOCATION_FAILURE, GET_LOCATION_SUCCESS, GET_LOCATION_REQUEST, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE, SELECT_COUNTRY,
} from '../constants/location';
import config from '../config';
import { ICountry } from '../types/ICountry';
import ILocationInfo from '../types/models/ILocationInfo';
import { event, EventCategories, EventActions } from '../utils/metric';

export const getLocationInfo = () => (dispatch: Dispatch) => {
    dispatch({ type: GET_LOCATION_REQUEST });

    return fetch(undefined)('https://ipapi.co/json/')
        .then(responseCheck)
        .then((response) => dispatch(getLocationSuccess(response)))
        .catch((error) => dispatch(getLocationFailure(error)));
};

export const getCountries = () => (dispatch: Dispatch, getState: () => IState) => {
    // No point requesting countries if they've already been retrieved
    const countriesInState = getState().info.countries;
    if (countriesInState && !_.isEmpty(countriesInState)) {
        return;
    }

    dispatch({ type: GET_COUNTRIES_REQUEST });

    return fetch(getState)(`${config.api.url}/v1/countries`)
        .then(responseCheck)
        .then((response) => dispatch(getCountriesSuccess(response)))
        .catch((error) => dispatch(getCountriesFailure(error)));
};

export const selectCountry = (selectedCountryAlpha2?: string) => (dispatch: Dispatch) => {
    const countryCode = selectedCountryAlpha2 || 'ie';
    dispatch({ type: SELECT_COUNTRY, selectedCountryAlpha2: countryCode });
    event(EventCategories.SUBSCRIBE, EventActions.SELECT, 'changecountry');
};

const getLocationSuccess = (response: any) => {
    const locationResponse: ILocationInfo = {
        ip: response.ip,
        city: response.city,
        country: response.country_name,
        countryCode: response.country_code,
        timezone: response.timezone,
        currency: response.currecy,
    };

    return { type: GET_LOCATION_SUCCESS, locationInfo: locationResponse };
};

const getLocationFailure = (error: Error) => ({ type: GET_LOCATION_FAILURE, error });

const getCountriesSuccess = (response: any) => {
    const countries: ICountry[] = response.data;
    return { type: GET_COUNTRIES_SUCCESS, countries };
};

const getCountriesFailure = (error: Error) => ({ type: GET_COUNTRIES_FAILURE, error });
