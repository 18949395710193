import { GET_COMPANY_FAILURE, GET_COMPANY_REQUEST, GET_COMPANY_SUCCESS } from '../constants/company';
import ICompany from '../types/models/ICompany';

export interface IState {
    company?: ICompany;
    loading: boolean;
    error?: any;
    companyGiftFormSubmitted: boolean;
    unauthorised: boolean;
}

interface IAction {
    type: string;
    error?: Error;
    unauthorised: boolean;
    company?: ICompany;
}

export const initialState: IState = {
    loading: false,
    company: undefined,
    error: undefined,
    companyGiftFormSubmitted: false,
    unauthorised: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_COMPANY_REQUEST:
            return {
                ...state,
                error: null,
                company: undefined,
                loading: true,
                unauthorised: false,
            };
        case GET_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.company,
                loading: false,
                unauthorised: false,
            };
        case GET_COMPANY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                unauthorised: action.unauthorised,
            };

        default:
            return state;
    }
};
