import ICompanySubscriptionAccess from '../../types/ICompanySubscriptionAccess';
import {
    COMPANY_SUBSCRIPTION_ACCESS_CLEAR, COMPANY_SUBSCRIPTION_ACCESS_FAILURE, COMPANY_SUBSCRIPTION_ACCESS_REQUEST, COMPANY_SUBSCRIPTION_ACCESS_SUCCESS,
} from '../constants/companysubscription';

export interface IState {
    companySubscriptionAccess?: ICompanySubscriptionAccess;
    loading: boolean;
}

interface IAction {
    type: string;
    error?: Error;
    companySubscriptionAccess?: ICompanySubscriptionAccess;
}

export const initialState: IState = {
    companySubscriptionAccess: undefined,
    loading: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case COMPANY_SUBSCRIPTION_ACCESS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case COMPANY_SUBSCRIPTION_ACCESS_SUCCESS:
            return {
                ...state,
                loading: false,
                companySubscriptionAccess: action.companySubscriptionAccess,
                error: null,
            };
        case COMPANY_SUBSCRIPTION_ACCESS_FAILURE:
            return {
                ...state,
                loading: false,
                companySubscriptionAccess: undefined,
                error: action.error,
            };

        case COMPANY_SUBSCRIPTION_ACCESS_CLEAR:
            return {
                ...state,
                loading: false,
                companySubscriptionAccess: undefined,
                error: null,
            };

        default:
            return state;
    }
};
