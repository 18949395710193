import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import NoaAccordion, { IAccordionItem } from './NoaAccordion';
import newtheme from '../../newtheme';
import config from '../../config';
import text from '../../locale';
import LinkDuo from '../LinkDuo';
import { getDownloadAndroidDynamicLink, getDownloadIosDynamicLink } from '../../utils/url';
import ISubscriptionProduct from '../../domaindriven/subscribe/types/ISubscriptionProduct';
import { MpTrackingButtons } from '../../config/buttons';
import { mpTrackButtonClick } from '../../tracking/mixpanel';
import IconQuestionWithCircleBg from '../../assets/icons/IconQuestionWithCircleBg';
import IconArrowRightV2 from '../../assets/icons/IconArrowRightV2';

interface IProps {
    mode: 'STANDARD' | 'LANDING_EMBED';
    subscriptionProduct?: ISubscriptionProduct;
    onSignupClicked: () => void;
    questions?: IAccordionItem[];
    hideButtons?: boolean;
}

class FrequentQuestions extends React.Component<IProps> {
    public onSignupClicked = () => {
        mpTrackButtonClick(MpTrackingButtons.SHOW_SIGNUP_FAQ);
        this.props.onSignupClicked();
    };

    public getWhatIsNoaQuestion = (): IAccordionItem => ({
        id: 'WHAT_IS_NOA',
        heading: 'What is Noa?',
        text: (
            <p>
                Noa is an audio app, available on both{' '}
                <LinkDuo to={getDownloadIosDynamicLink()} className={css(newtheme.coreStyles.highlightLink)}>
                    iPhone
                </LinkDuo>{' '}
                and{' '}
                <LinkDuo to={getDownloadAndroidDynamicLink()} className={css(newtheme.coreStyles.highlightLink)}>
                    Android
                </LinkDuo>
                .
                <br />
                <br />
                We bring you the world’s very best opinion, feature, and analysis articles, curated by our team of
                editors and read aloud word-for-word by celebrated audiobook narrators.
                <br />
                <br />
                It’s the easiest way to understand and know more about the most important topics shaping the world.
                There are no filter bubbles and no algorithms, and our editorial approach is to be as objective and as
                open-minded as possible to ensure you can form your own opinion having heard multiple perspectives.
                <br />
                <br />
                You can also listen on our website, in-car, and via Alexa.
                <br />
                <br />
                We’re headquartered in Dublin, Ireland but have a global audience and a global team.
                <br />
                <br />
                Connect with the team on{' '}
                <a
                    href={config.externalRoutes.linkedInNoa}
                    target="_blank"
                    className={css(newtheme.coreStyles.highlightLink)}
                    rel="noreferrer">
                    LinkedIn
                </a>
                .
            </p>
        ),
    });

    public getWhatAreSeriesQuestion = (): IAccordionItem => ({
        id: 'WHAT_ARE_SERIES',
        heading: 'What are Series?',
        text: (
            <p>
                In short, Series make you smarter.
                <br />
                <br />
                Series are the name given to the playlists that our editors create on a daily basis. Each Series covers
                a specific topic and includes between two and five articles (which we call Episodes, to stick with the
                ‘TV series’ theme).
                <br />
                <br />
                At the beginning of each Series is a short 60-second to 2-minute introduction, which is written by a Noa
                editor. This intro provides valuable context on the subject at hand.
                <br />
                <br />
                Series are hands down the easiest way to understand and know more about important and complex topics.
                You’ll walk away having heard multiple perspectives from the world’s best journalists and be
                well-equipped to form your own opinion on the subject at hand.
            </p>
        ),
    });

    public getDoesMyHbrSubscriptionWorkOnNoa = (): IAccordionItem => ({
        id: 'DOES_MY_HBR_SUBSCRIPTION_WORK_ON_NOA',
        heading: 'Does my HBR subscription work on Noa?',
        text: (
            <p>
                Your HBR subscription will allow you to listen to as much Noa narrated content as you&apos;d like when
                accessed through the HBR website. However, if you want to listen on the go through the Noa app, you will
                require a separate Noa account. While this will give you access to a selection of free content, premium
                articles do require a Noa subscription.
                <br />
                <br />
                Fortunately, we offer all existing readers of HBR a special discount on Noa membership. Click{' '}
                <a
                    href={this.props.subscriptionProduct?.metadata.homePage.redirectToPage || config.routes.subscribe}
                    target="_blank"
                    className={css(newtheme.coreStyles.highlightLink)}
                    rel="noreferrer">
                    here
                </a>{' '}
                to find out more.
            </p>
        ),
    });

    public getWhatFeaturesAvailableQuestion = (): IAccordionItem => ({
        id: 'WHAT_FEATURES_ARE_AVAILABLE_IN_THE_APP',
        heading: 'What features are available in the app?',
        text: (
            <>
                <p>Within Noa&apos;s dedicated mobile app you&apos;ll find many useful features. These include:</p>
                <ul className={css(styles.list)}>
                    <li>Customizable article queue</li>
                    <li>Offline listen mode (download articles)</li>
                    <li>Playback speed adjustment</li>
                    <li>Sleep timer</li>
                    <li>Rewind 15-seconds</li>
                    <li>Save articles to personal library</li>
                    <li>Listen stats</li>
                    <li>AirPlay (iPhone only)</li>
                    <li>Read along (view the original text while you listen)</li>
                </ul>
                <p>
                    If we&apos;re missing any, please let us know by reaching out{' '}
                    <span className={css(newtheme.coreStyles.highlightLink)} id={text.nav.CONTACT}>
                        here
                    </span>
                    .
                </p>
            </>
        ),
    });

    public getNarratorQuestion = (): IAccordionItem => ({
        id: 'ARE_THE_ARTICLES_READ_BY_REAL_PEOPLE',
        heading: 'Are the articles read by real people?',
        text: (
            <p>
                Yes, we have a team of narrators who record the very best articles word-for-word (or ‘unabridged’ as we
                say in the spoken-word space).
                <br />
                <br />
                Our narrators are a mix of male and female, multiple ethnicities, and are based in the U.S., U.K., and
                Ireland.
            </p>
        ),
    });

    public getWhereCanIListenQuestion = (): IAccordionItem => ({
        id: 'WHERE_CAN_I_LISTEN',
        heading: 'Where can I listen?',
        text: (
            <p>
                You can listen to Noa on our{' '}
                <LinkDuo to={getDownloadIosDynamicLink()} className={css(newtheme.coreStyles.highlightLink)}>
                    iPhone
                </LinkDuo>{' '}
                or{' '}
                <LinkDuo to={getDownloadAndroidDynamicLink()} className={css(newtheme.coreStyles.highlightLink)}>
                    Android
                </LinkDuo>{' '}
                apps, on our website (after signing up), in-car via Android Auto or Apple CarPlay, or on Alexa by saying
                “Alexa, enable News Over Audio”.
                <br />
                <br />
                You can also download audio articles and Series (which is what we call playlists) in the Noa apps.
            </p>
        ),
    });

    public getCancellationQuestion = (): IAccordionItem => ({
        id: 'HOW_EASY_IS_IT_TO_CANCEL',
        heading: 'How easy is it to cancel?',
        text: (
            <p>
                If you are on the free tier of Noa, you will never be charged and therefore do not need to cancel.
                <br />
                <br />
                If, however, you do decide to start a trial or subscribe, you can cancel anytime. How to cancel depends
                on how you first subscribed. If you subscribed on the Noa website, then head over to My Account and hit
                cancel. If you subscribed in the Noa apps, then you will need to go to your Apple settings or Play Store
                to cancel.
                <br />
                <br />
                Contact us{' '}
                <span className={css(newtheme.coreStyles.highlightLink)} id={text.nav.CONTACT}>
                    here
                </span>{' '}
                if you have any questions. We’re happy to help.
            </p>
        ),
    });

    public getWhatPublishersQuestion = (): IAccordionItem => ({
        id: 'WHAT_PUBLISHERS_ARE_AVAILABLE_ON_NOA',
        heading: 'What publishers are available on Noa?',
        text: (
            <p>
                Publishers available on Noa include: The Economist, MIT Tech, Fast Company, HBR, Bloomberg, UnHerd,
                Prospect, Insider (formerly Business Insider), The Conversation, Foreign Affairs, The Independent, The
                New York Times, The TLS, The Telegraph, Evening Standard, The Irish Times, and more.
                <br />
                <br />
                If you would like to see us add another publisher to our line-up let us know by reaching out{' '}
                <span className={css(newtheme.coreStyles.highlightLink)} id={text.nav.CONTACT}>
                    here
                </span>
                .
            </p>
        ),
    });

    public maybeGetCostQuestion = (): IAccordionItem[] => [
        {
            id: 'WHAT_DOES_IT_COST',
            heading: 'What does it cost? And is there a free trial?',
            text: (
                <p>
                    You can listen free by simply signing up. You’ll get a handful of articles every week and don’t need
                    to pay a thing, but some publishers will not be accessible on the free tier (like HBR or the NYT)
                    and you’ll hear occasional audio ads.
                    <br />
                    <br />
                    To get full, ad-free access you can subscribe to Noa Premium.
                    <br />
                    Check our latest pricing{' '}
                    <Link className={css(newtheme.coreStyles.highlightLink)} to={config.routes.subscribe}>
                        here
                    </Link>
                    .
                    <br />
                </p>
            ),
        },
    ];

    public maybeGetStudentQuestion = (): IAccordionItem[] => [
        {
            id: 'IS_THERE_A_STUDENT_OPTION',
            heading: 'Is there a student option?',
            text: (
                <p>
                    Yes. The student subscription is available for anyone with a university email address.
                    <br />
                    <br />
                    Our student tier is only available in our{' '}
                    <LinkDuo to={getDownloadIosDynamicLink()} className={css(newtheme.coreStyles.highlightLink)}>
                        iPhone
                    </LinkDuo>{' '}
                    or{' '}
                    <LinkDuo to={getDownloadAndroidDynamicLink()} className={css(newtheme.coreStyles.highlightLink)}>
                        Android
                    </LinkDuo>{' '}
                    app.
                </p>
            ),
        },
    ];

    public getQuestionsAndAnswers = () => {
        let items: IAccordionItem[] = [];

        if (this.props.mode === 'LANDING_EMBED') {
            items = [
                this.getWhatIsNoaQuestion(),
                this.getDoesMyHbrSubscriptionWorkOnNoa(),
                this.getWhatFeaturesAvailableQuestion(),
                this.getNarratorQuestion(),
                this.getWhatPublishersQuestion(),
            ];
        } else {
            items = [
                this.getWhatIsNoaQuestion(),
                this.getWhatFeaturesAvailableQuestion(),
                this.getWhatAreSeriesQuestion(),
                this.getNarratorQuestion(),
                this.getWhereCanIListenQuestion(),
                ...this.maybeGetCostQuestion(),
                ...this.maybeGetStudentQuestion(),
                this.getCancellationQuestion(),
                this.getWhatPublishersQuestion(),
            ];
        }

        return items;
    };

    public renderBottomActions = () => (
        <Row className={css(styles.bottomActions)}>
            <Col xs={12} md={{ size: 6, offset: 3 }} lg={{ size: 12, offset: 0 }}>
                <Row>
                    <Col xs={12} lg={4} className={css(styles.buttonAndText)}>
                        <p className={css(styles.textAboveButton)}>Have any other questions?</p>
                        <Link
                            to={config.routes.faq}
                            className={css(styles.linkOverButton, newtheme.coreStyles.removeLinkDecoration)}>
                            <button type="button" className={css(styles.buttonBottom)}>
                                <IconQuestionWithCircleBg className={css(styles.buttonIcon)} /> Check out our FAQs
                            </button>
                        </Link>
                    </Col>
                    <Col xs={12} lg={4} className={css(styles.buttonAndText)}>
                        <p className={css(styles.textAboveButton)}>Or feel free to</p>
                        <button type="button" className={css(styles.buttonBottom)} id={text.nav.CONTACT}>
                            <IconQuestionWithCircleBg className={css(styles.buttonIcon)} /> Pop us a message
                        </button>
                    </Col>
                    <Col xs={12} lg={4} className={css(styles.buttonAndText)}>
                        <p className={css(styles.textAboveButton)}>All good to go?</p>
                        <button
                            type="button"
                            onClick={this.props.onSignupClicked}
                            className={css(styles.buttonBottom, styles.blueButton)}>
                            Sign up &amp; start listening <IconArrowRightV2 className={css(styles.arrowIcon)} />
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

    public render() {
        return (
            <Container className={css(styles.main, this.props.mode === 'LANDING_EMBED' && styles.landingEmbedMain)}>
                <Row>
                    <Col lg={12} xl={{ offset: 1, size: 10 }}>
                        {this.props.mode === 'LANDING_EMBED' ? (
                            <h5 className={css(styles.headingSmall)}>
                                Find out more by reading a selection of our FAQs.
                            </h5>
                        ) : (
                            <h3 className={css(styles.heading)}>In case you missed anything.</h3>
                        )}

                        <div>
                            <NoaAccordion items={this.props.questions || this.getQuestionsAndAnswers()} />
                        </div>
                        {!this.props.hideButtons && this.renderBottomActions()}
                    </Col>
                </Row>
            </Container>
        );
    }
}

const styles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.medium]: {
            paddingBottom: 30,
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 20,
        },
        position: 'relative',
        paddingBottom: 80,
        color: 'white',
    },
    landingEmbedMain: {
        [newtheme.cssBreakpoints.medium]: {
            paddingBottom: 0,
        },
        [newtheme.cssBreakpoints.tiny]: {
            paddingBottom: 0,
        },
        paddingBottom: 0,
    },
    heading: {
        [newtheme.cssBreakpoints.largeWidthLargeHeight]: {
            fontSize: 40,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 40,
            marginBottom: 30,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 35,
            marginBottom: 25,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 25,
        },
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 48,
        marginBottom: 45,
        width: '100%',
    },
    headingSmall: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 18,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
        },
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 20,
        width: '100%',
        marginBottom: 25,
        color: 'rgba(255, 255, 255, 0.87)',
    },
    buttonsWrapper: {
        [newtheme.cssBreakpoints.medium]: {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    linkText: {
        fontSize: 18,
        fontWeight: 400,
    },
    link: {
        ':hover': {
            textDecoration: 'none',
        },
    },
    linkArrow: {
        [newtheme.cssBreakpoints.small]: {
            display: 'none',
        },
        height: 31,
        width: 33,
    },
    buttonWidth: {
        [newtheme.cssBreakpoints.medium]: {
            margin: 10,
        },
        [newtheme.cssBreakpoints.small]: {
            width: 250,
        },
        [newtheme.cssBreakpoints.tiny]: {
            width: '50%',
            margin: 5,
        },
        width: 300,
        marginRight: 15,
        marginLeft: 15,
    },
    button: {
        ':hover': {
            color: 'white',
            stroke: 'white',
        },
        [newtheme.cssBreakpoints.medium]: {
            height: 60,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 15,
            padding: 0,
            height: 60,
            justifyContent: 'center',
            width: 250,
        },
        [newtheme.cssBreakpoints.tiny]: {
            height: 55,
            paddingLeft: 5,
            paddingRight: 5,
            fontSize: 12,
            width: '100%',
        },
        background: 'rgba(3, 155, 229, 0.17)',
        color: newtheme.colors.primaryBlue,
        stroke: newtheme.colors.primaryBlue,
        border: 'none',
        borderRadius: 10,
        fontSize: 18,
        fontWeight: 500,
        padding: '15px 25px',
        marginBottom: 14,
        width: 300,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    list: {
        listStyleType: 'disc',
    },
    moreQuestionsHeading: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 20,
            marginBottom: 15,
            marginTop: 50,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 16,
            marginBottom: 10,
            marginTop: 30,
        },
        fontSize: 24,
        fontWeight: 400,
        color: '#6890B9',
        width: '100%',
        textAlign: 'center',
        marginTop: 65,
        marginBottom: 30,
    },
    bottomActions: {
        marginTop: 40,
    },
    buttonAndText: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 30,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 45,
    },
    textAboveButton: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 5,
        },
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 15,
    },
    linkOverButton: {
        width: '100%',
    },
    buttonBottom: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 17,
            borderRadius: 10,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 14,
        },
        ':hover': {
            backgroundColor: 'white',
            color: 'black',
            fill: 'black',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(3, 155, 229, 0.17)',
        color: 'white',
        border: 'none',
        borderRadius: 10,
        fontSize: 12,
        fontWeight: 500,
        height: 45,
        width: '100%',
    },
    blueButton: {
        ':hover': {
            stroke: 'black',
        },
        stroke: 'white',
        backgroundColor: newtheme.colors.primaryBlue,
    },
    buttonIcon: {
        height: 24,
        width: 24,
        marginRight: 5,
    },
    arrowIcon: {
        height: 32,
        width: 32,
        marginLeft: 5,
    },
});

export default FrequentQuestions;
