import { Dispatch } from 'redux';

import { toast } from 'react-toastify';
import {
    CONTENT_CLEAR,
    CONTENT_FAILURE,
    CONTENT_REQUEST,
    CONTENT_SUCCESS,
    LATEST_CONTENT_CLEAR,
    LATEST_CONTENT_FAILURE,
    LATEST_CONTENT_REQUEST,
    LATEST_CONTENT_SUCCESS,
} from '../constants/content';
import { IState } from '../reducers';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import { isServer } from '../../store';
import text from '../locale';
import config from '../config';

export const clearContent = () => (dispatch: Dispatch) => {
    dispatch({ type: CONTENT_CLEAR });
};

export const getContent = (url: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: CONTENT_REQUEST });

    return fetch(getState)(encodeURI(url))
        .then(responseCheck)
        .then((response) => dispatch(contentSuccess(response, url)))
        .catch((error) => dispatch(contentFailure(error)));
};

export const getPlaylist = (url: string) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: CONTENT_REQUEST });

    return fetch(getState)(encodeURI(url))
        .then(responseCheck)
        .then((response) => dispatch(playlistContentSuccess(response, url)))
        .catch((error) => dispatch(contentFailure(error)));
};

export const clearLatestContent = () => (dispatch: Dispatch) => {
    dispatch({ type: LATEST_CONTENT_CLEAR });
};

export const getLatestContent = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: LATEST_CONTENT_REQUEST });
    const urlWithDefaultPage = `${config.api.url}${config.api.routes.articles}/content?limit=10&offset=0`;
    const urlWithNextPage = getState().content.latestContentNextPage;

    return fetch(getState)(urlWithNextPage || urlWithDefaultPage)
        .then(responseCheck)
        .then((response) => dispatch(latestContentSuccess(response)))
        .catch((error) => dispatch(latestContentFailure(error)));
};

const playlistContentSuccess = (response: any, url: string) => ({ type: CONTENT_SUCCESS, content: response.data, url });

const contentSuccess = (response: any, url: string) => {
    const loadedContent =
        response.data.article ||
        response.data.playlist ||
        response.data.journalist ||
        response.data.section ||
        response.data.publisher;

    return { type: CONTENT_SUCCESS, content: loadedContent, url };
};

const contentFailure = (error: Error) => {
    if (!isServer) {
        toast(text.notifications.SOMETHING_WENT_WRONG);
    }

    return { type: CONTENT_FAILURE, error };
};

const latestContentSuccess = (response: any) => {
    const {
        content: loadedContent,
        page: { next: nextPage },
    } = response.data;

    return { type: LATEST_CONTENT_SUCCESS, latestContent: loadedContent, latestContentNextPage: nextPage };
};

const latestContentFailure = (error: Error) => {
    if (!isServer) {
        toast(text.notifications.SOMETHING_WENT_WRONG);
    }

    return { type: LATEST_CONTENT_FAILURE, error };
};
