import {
    ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_PENDING,
    ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_REJECTED,
    ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_SUCCESS,
} from '../constants/article';
import {
    CONTENT_CLEAR,
    CONTENT_FAILURE,
    CONTENT_REQUEST,
    CONTENT_SUCCESS,
    LATEST_CONTENT_REQUEST,
    LATEST_CONTENT_SUCCESS,
    LATEST_CONTENT_FAILURE,
    LATEST_CONTENT_CLEAR,
} from '../constants/content';
import {
    PLAYLIST_VIEW_IS_QUEUED_PENDING,
    PLAYLIST_VIEW_IS_QUEUED_REJECTED,
    PLAYLIST_VIEW_IS_QUEUED_SUCCESS,
} from '../constants/playlist';
import {
    SECTION_PLAYLIST_IS_QUEUED_PENDING,
    SECTION_PLAYLIST_IS_QUEUED_REJECTED,
    SECTION_PLAYLIST_IS_QUEUED_SUCCESS,
} from '../constants/section';
import IArticle from '../types/models/IArticle';
import IItem from '../types/models/IItem';
import IJournalist from '../types/models/IJournalist';
import IPlaylist from '../types/models/IPlaylist';
import IPublisher from '../types/models/IPublisher';
import ISection from '../types/models/ISection';

export interface IState {
    loading: boolean;
    content?: IItem;
    latestContent?: (IArticle | IPlaylist)[];
    latestContentNextPage?: string;
    error?: any;
    requestedUrl?: string;
    isQueuePendingForArticleSinglePage: boolean;
    isQueuePendingForPlaylistSinglePage: boolean;
    pendingQueueSectionPlaylistIds: number[];
}

interface IAction {
    type: string;
    content?: IPlaylist | IArticle | IJournalist | ISection | IPublisher;
    latestContent?: (IArticle | IPlaylist)[];
    latestContentNextPage?: string;
    error?: Error;
    url?: string;
    isQueued?: {
        articleId?: number;
        playlistId?: number;
        status: boolean;
    };
}

export const initialState: IState = {
    loading: false,
    isQueuePendingForArticleSinglePage: false,
    isQueuePendingForPlaylistSinglePage: false,
    pendingQueueSectionPlaylistIds: [],
    latestContent: [],
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case CONTENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                requestedUrl: undefined,
            };

        case CONTENT_SUCCESS:
            return {
                ...state,
                content: action.content,
                loading: false,
                requestedUrl: action.url,
            };

        case CONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case CONTENT_CLEAR:
            return {
                ...state,
                content: undefined,
                requestedUrl: undefined,
            };

        case LATEST_CONTENT_CLEAR:
            return {
                ...state,
                latestContent: [],
                latestContentNextPage: undefined,
            };

        case LATEST_CONTENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case LATEST_CONTENT_SUCCESS:
            return {
                ...state,
                latestContent: [...(state.latestContent || []), ...(action.latestContent || [])],
                latestContentNextPage: action.latestContentNextPage,
                loading: false,
            };

        case LATEST_CONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case SECTION_PLAYLIST_IS_QUEUED_SUCCESS: {
            const section = state.content as ISection;

            const { playlistId, status } = action.isQueued!;

            const playlistIndex = section?.playlists?.findIndex((p) => p.id === playlistId);

            if (!section || !section.playlists || playlistIndex === undefined || playlistIndex === -1) return state;

            const clonedPlaylists = [...section.playlists];
            clonedPlaylists[playlistIndex] = { ...clonedPlaylists[playlistIndex], isQueued: status };

            return {
                ...state,
                content: { ...section, playlists: clonedPlaylists },
                pendingQueueSectionPlaylistIds: state.pendingQueueSectionPlaylistIds.filter(
                    (pid) => pid !== playlistId,
                ),
            };
        }

        case SECTION_PLAYLIST_IS_QUEUED_PENDING: {
            const { playlistId } = action.isQueued!;

            return {
                ...state,
                pendingQueueSectionPlaylistIds: [...state.pendingQueueSectionPlaylistIds, playlistId],
            };
        }

        case SECTION_PLAYLIST_IS_QUEUED_REJECTED: {
            const { playlistId } = action.isQueued!;

            return {
                ...state,
                pendingQueueSectionPlaylistIds: state.pendingQueueSectionPlaylistIds.filter(
                    (pid) => pid !== playlistId,
                ),
            };
        }

        case PLAYLIST_VIEW_IS_QUEUED_SUCCESS: {
            const playlist = state.content as IPlaylist;
            const { playlistId, status } = action.isQueued!;

            if (!playlist || playlist.id !== playlistId) {
                return { ...state, isQueuePendingForPlaylistSinglePage: false };
            }

            return {
                ...state,
                content: { ...state.content, isQueued: status },
                isQueuePendingForPlaylistSinglePage: false,
            };
        }

        case PLAYLIST_VIEW_IS_QUEUED_REJECTED: {
            return {
                ...state,
                isQueuePendingForPlaylistSinglePage: false,
            };
        }

        case PLAYLIST_VIEW_IS_QUEUED_PENDING: {
            return {
                ...state,
                isQueuePendingForPlaylistSinglePage: true,
            };
        }

        case ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_SUCCESS: {
            const article = state.content as IArticle;
            const { articleId, status } = action.isQueued!;

            if (!article || article.id !== articleId) return state;

            return {
                ...state,
                content: { ...state.content, isQueued: status },
                isQueuePendingForArticleSinglePage: false,
            };
        }

        case ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_PENDING: {
            const article = state.content as IArticle;
            const { articleId } = action.isQueued!;

            if (!article || article.id !== articleId) return state;

            return {
                ...state,
                isQueuePendingForArticleSinglePage: true,
            };
        }

        case ARTICLE_IS_QUEUED_FOR_SINGLE_PAGE_REJECTED: {
            const article = state.content as IArticle;
            const { articleId } = action.isQueued!;

            if (!article || article.id !== articleId) return state;

            return {
                ...state,
                isQueuePendingForArticleSinglePage: false,
            };
        }

        default:
            return state;
    }
};
