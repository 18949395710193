export default {
    notifications: {
        ARTICLE_ADDED_TO_YOUR_FAVORITES: 'Article added favorites',
        ARTICLE_REMOVED_FROM_YOUR_FAVORITES: 'Article removed from favorites',
        CANT_LISTEN_SIGNED_OUT: 'Please log in to listen to this article.',
        LISTEN_WHOLE_ADVERT: 'Playback will resume after this advertisement.',
        CANT_LISTEN_MORE: 'You have no weekly listens remaining.',
        PLAYLIST_ADDED_TO_YOUR_FAVORITES: "You're now following this Playlist. Find it in your Library.",
        PLAYLIST_REMOVED_FROM_YOUR_FAVORITES: "You're no longer following this Playlist.",
        THERE_IS_NOTHING_TO_ON_PREVIOUS: 'There are no previous articles in this queue.',
        THERE_IS_NOTHING_TO_ON_NEXT: 'There are no other articles in this queue.',
        COPIED_TO_CLIPBOARD: 'Link copied to clipboard for sharing',
        SOMETHING_WENT_WRONG: 'Something went wrong',
        INVALID_CODE: 'Invalid discount code',
        CANT_LISTEN_NEXT_ARTICLE_SIGNED_OUT: 'You must be logged in to listen to this article.',
        EMAIL_OR_PASSWORD_WRONG: 'Your username or password is incorrect.',
        EMAIL_INVALID: 'Email address already in use or invalid password.',
        EMAIL_HAS_BEEN_SENT: 'An email with instructions has been sent to your inbox.',
        NOT_POSSIBLE_TO_RESET_PASSWORD: 'It was not possible to reset your password.',
        NOT_POSSIBLE_TO_LOGIN: 'It was not possible to login, try later.',
        YOUR_SUBSCRIPTION_CANCELLED: 'Your subscription has been cancelled.',
        THERE_WAS_A_PROBLEM_TO_CANCEL_YOUR_SUBSCRIPTION:
            'There was a problem cancelling your subscription. Please contact Noa Support.',
        PASSWORD_DO_NOT_MATCH: 'Password do not match.',
        PASSWORD_HAS_BEEN_RESET: 'Password has been reset.',
        SUCCESS_SUBSCRIBING: 'You have successfully subscribed to Noa Premium!',
        ALREADY_SUBSCRIBED: 'You are already subscribed.',
        ERROR_SUBSCRIBING: 'Error subscribing, please check your card details and try again.',
    },
    nav: {
        HOME: 'Home',
        LATEST: 'Latest',
        SEARCH: 'Search',
        MY_LIBRARY: 'Library',
        MY_ACCOUNT: 'Account',
        CONTACT: 'Contact',
        ABOUT: 'About Us',
        FAQ: 'FAQs',
        LOGIN: 'Log in',
        SIGNUP: 'Sign up',
    },
    modal: {
        CONTINUE: 'Continue',
        premiumContent: {
            TITLE: 'Get Full Access with No Ads',
            SUBTITLE: 'Cancel Anytime',
            ADVANTAGE_TITLE: 'Benefits include:',
            ADVANTAGES: [
                '✓ Unlimited, ad-free listening',
                '✓ Access to Premium publishers including HBR, NYT, Economist & more...',
                '✓ No commitments, you can cancel at anytime',
            ],
        },
    },
    forgotPasswordModal: {
        TITLE: 'Reset Password',
        DESCRIPTION: 'Enter your email and we will send a reset link to your inbox.',
        EMAIL: 'Enter your email',
        GO_BACK: 'Go back to login',
    },
    loginModal: {
        TITLE: 'Log In',
        EMAIL: 'Email',
        PASSWORD: 'Password',
        OR: 'or',
        FORGOT_PASSWORD: 'Forgot your password?',
        DONT_HAVE_ACCOUNT: "Don't have an account?",
    },
    signupModal: {
        TITLE: 'Sign Up',
        ALREADY_HAVE_ACCOUNT: 'Already have an account?',
        EMAIL: 'Email',
        NAME: 'Name',
        PASSWORD: 'Password',
        OR: 'or',
    },
    getTheAppModal: {
        TITLE: 'Download the App',
        SUBTITLE: 'Enoy offline listening, personalised recommendations, and progress tracking.',
        FEATURE_TITLE: 'Get the App',
        FEATURE_SUBTITLE: 'To access this feature and get the most from Noa, download the app!',
        MESSAGE_1: 'Offline Listening',
        MESSAGE_2: 'Track Your Progress',
        MESSAGE_3: 'Personalised Recommendations',
        GET_THE_APP_LATER: 'Get the app later',
        DOWNLOAD_THE_APP: 'Download the App',
    },
    cancelSubscriptionModal: {
        TITLE: 'Are you sure?',
        IF_CANCEL: 'If you cancel...',
        KEEP_ACCESS: '✓ KEEP MY PREMIUM ACCESS',
        PLEASE_CANCEL: 'Please cancel my subscription and remove all Premium benefits from my account',
        cancellationPoints: [
            'Your unlimited listens will be removed',
            'You will see and hear ads as you use Noa',
            'You will lose access to all Premium publishers',
        ],
        DESCRIPTION: 'Are you sure you want to cancel your subscription?',
        CONFIRM_TEXT: 'Cancel Here',
        CANCEL_TEXT: 'Stay',
    },
    cancelSubscriptionAndroidModal: {
        TITLE: 'Cancel Subscription',
        SUBTTILE:
            'You have subscribed using Android Subscriptions which is not managed by Noa. Please follow the steps below to cancel your subscription.',
        BULLET_POINT_HEADER: 'To cancel your subscription on Android',
        cancellationSteps: [
            'On your Android device, open the Play Store app',
            'Navigate to Menu > Subscriptions',
            'Click the subscription you want to cancel',
            'Click Cancel Subscription',
        ],
        CLOSE_BUTTON: 'CLOSE',
    },
    cancelSubscriptionIosModal: {
        TITLE: 'Cancel Subscription',
        SUBTTILE:
            'You have subscribed using iOS Subscriptions which is not managed by Noa. Please follow the steps below to cancel your subscription.',
        BULLET_POINT_HEADER: 'To cancel your subscription on iOS',
        cancellationSteps: [
            'On your iOS device, open Settings',
            'Navigate to iTunes & App Stores',
            'Press on your Apple ID, then press "View Apple ID"',
            'Go to Subscriptions',
            'Choose the subscription you want to cancel',
            'Click Cancel',
        ],
        CLOSE_BUTTON: 'CLOSE',
    },
    content: {
        article: {
            COMING_UP: 'Playing next...',
        },
        LATEST_ARTICLES: 'Latest Articles',
    },
    search: {
        DISCOVERY_MORE: 'Browse all Series',
        EMPTY: 'No results found',
    },
    latest: {
        tabs: {
            LATEST: 'Latest',
            TRENDING: 'Trending',
        },
        EMPTY: 'No latest articles',
    },
    playlist: {
        MORE_DETAILS: 'More Info',
        GET_UP_TO_SPEED_ON: 'Get up to speed on',
        LISTEN_ON_NOA: 'Listen on the Noa App',
        LISTEN_HERE: 'Listen Here',
    },
    library: {
        TITLE: 'Library',
        MY_PLAYLISTS: "Series I'm Following",
        MY_FAVOURITES: 'My Favourites',
        RECENTLY_PLAYED: 'Recently Played',
    },
    privacy: {
        TITLE: 'Privacy Policy',
    },
    terms: {
        TITLE: 'Terms and Conditions',
    },
    cookiePolicy: {
        TITLE: 'Cookies',
    },
    article: {
        JOURNALIST: 'Written by',
        NARRATOR: 'Read by',
        PUBLISHED: 'Published on',
    },
    section: {
        PLAYLISTS: 'Playlists',
    },
    date: {
        months: {
            JAN: 'Jan',
            FEB: 'Feb',
            MAR: 'Mar',
            APR: 'Apr',
            MAY: 'May',
            JUNE: 'June',
            JULY: 'July',
            AUG: 'Aug',
            SEPT: 'Sept',
            OCT: 'Oct',
            NOV: 'Nov',
            DEC: 'Dec',
        },
        TODAY: 'Today',
        YESTERDAY: 'Yesterday',
        DAYS_AGO: 'days ago',
    },
    common: {
        FOLLOWING: 'Following',
        FOLLOW: 'Follow',
        UNFOLLOW: 'Unfollow',
        SHARE: 'Share',
        IN_NUTSHELL: 'In a Nutshell',
        CLOSE: 'Close',
    },
    filter: {
        TITLE: 'Customise Latest & Trending',
        SAVE: 'Save',
        types: {
            PUBLISHERS: 'Publishers',
            SECTIONS: 'Categories',
        },
    },
    played: {
        TITLE: 'Recently Played',
        EMPTY: 'No recently played articles',
    },
    favourites: {
        TITLE: 'My Favourites',
        EMPTY: 'No favourite articles',
    },
    goPremium: {
        TITLE: 'No Streams Remaining',
        MESSAGE:
            'Your weekly allowance of article streams has reached zero. Go Premium to enjoy unrestricted access to Noa.',
        GO_PREMIUM: 'Go Premium',
        CLOSE: 'Close',
    },
    canPlayArticle: {
        TITLE: 'Premium Content',
        MESSAGE: 'Go Premium and unlock access to premium content.',
        GO_PREMIUM: 'Go Premium',
        CLOSE: 'Close',
    },
    endFreeTrial: {
        TITLE: 'Your Free Trial has ended',
        MESSAGE:
            'Your Trial has ended. Go Premium to enjoy unrestricted access to Noa, or Switch to Noa basic and get a handful of complimentary streams weekly.',
        GO_PREMIUM: 'Go Premium',
        GO_BASIC: 'Switch to Basic',
    },
    listenTimeout: {
        TITLE: 'Log in or Sign up',
        MESSAGE: 'To contine listening, log in or sign up below.',
        SIGN_IN: 'Log in',
        SIGN_UP: 'Sign up',
    },
    selectSections: {
        TITLE: 'Choose the categories that interest you',
        MESSAGE: "We'll start personalising Noa for you based on your favourite topics.",
        START_LISTENING: 'Start Listening',
        SKIP: 'Skip this Step',
    },
    selectSubscription: {
        TITLE: "Choose an option that's right for you",
        MESSAGE: "We'll start personalising Noa for you based on your favourite topics.",
        FREE_TRIAL: 'Free Trial',
        GO_PREMIUM: 'Go Premium',
        messages: {
            freeTrial: {
                TITLE: 'Free Trial',
                MESSAGE1: '20 Article Streams',
                MESSAGE2: 'Restricted Access to Publishers',
                MESSAGE3: 'No Expiry Date',
            },
            premium: {
                TITLE: 'Premium',
                MESSAGE1: 'Unlimited Access & Ad-Free',
                MESSAGE2: '30 Day Free Trial',
                MESSAGE3: '€29.99/month, Cancel Anytime',
            },
        },
    },
    ads: {
        default: {
            MESSAGE: 'Advertisement',
        },
        timeout: {
            MESSAGE: 'Log in or Sign up for the full listening experience.',
        },
    },
    shareModal: {
        TITLE: 'Copy Share Link',
    },
    errors: {
        AUDIO_NOT_FOUND: 'Audio not found',
        PAGE_NOT_FOUND: 'Page not found',
        CONTENT_NOT_FOUND: 'Content not found',
        SOMETHING_WENT_WRONG: 'Something went wrong',
    },
    player: {
        LISTEN_ON: 'Listen on ',
        NOA: 'Noa',
        FEEDBACK: 'Feedback',
        POWERED_BY_NOA: 'Powered by Noa',
        SPEED: 'Speed',
        LISTEN_TO_THIS_ARTICLE: 'Listen to this article',
    },
    confirmModal: {
        DEFAULT_TITLE: 'Confirmation',
        DEFAULT_DESCRIPTION: 'Are you sure?',
        DEFAULT_CONFIRM_TEXT: 'Confirm',
        DEFAULT_CANCEL_TEXT: 'Cancel',
    },
    logoutModal: {
        TITLE: 'Logout',
        DESCRIPTION: 'Are you sure you want to logout?',
        CONFIRM_TEXT: 'Log me out',
        CANCEL_TEXT: 'Stay logged in',
    },
    myInfo: {
        NAME: 'Name',
        EMAIL: 'Email',
        SUBSCRIPTION: 'Membership type',
        PREMIUM_BENEFITS: 'Premium Benefits',
        premiumBenefitsList: [
            'Unlimited listening',
            'Premium publishers',
            'No advertisements',
            'Access to 15k+ articles',
        ],
        LISTEN_BALANCE: 'Listen Balance',
        GO_PREMIUM: 'Start free trial',
        CANCEL: 'Cancel Subscription',
        LOGOUT: 'Log Out',
        TITLE: 'My Account',
    },
    resetPassword: {
        TITLE: 'Reset Password',
        DESCRIPTION: 'Please enter a new password.',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        MESSAGE: "Simply choose a new password and you'll be good to go!",
    },
    subscribe: {
        SUBMIT_BUTTON: 'SUBSCRIBE',
        MESSAGE:
            // eslint-disable-next-line max-len
            'If you choose to purchase Noa Premium payment will be charged to your chosen payment method, and your account will be charged for renewal within the 24 hours before the current period ends. Auto-renewal may be turned off at any time by logging into your account on newsoveraudio.com after purchase.',
        premiumBenefits: [
            'Expert insight & analysis',
            'Premium publishers you can trust',
            'Articles hand-picked by editors',
            'One app, multiple perspectives',
            'Listen anytime, anywhere',
            'Cancelling is easy and takes one minute',
        ],
    },
    success: {
        TITLE: 'Success!',
        TAP_TO_CONTINUE: 'Tap the button below to continue.',
        BUTTON: 'Continue',
    },
    signup: {
        TITLE: 'Sign Up',
        NAME: 'Name',
        EMAIL: 'Email Address',
        PASSWORD: 'Password',
        CREATE_ACCOUNT: 'Create Account',
        LOG_IN: 'Log in',
        SIGN_UP: 'Sign up',
        ALREADY_HAVE_ACCOUNT: 'Already have an account?',
        DONT_HAVE_ACCOUNT: "Don't have an account?",
        PRIVACY_POLICY: 'Privacy Policy',
        TERMS_OF_SERVICE: 'Terms of Service',
        BY_CREATING_THIS_ACCOUNT: 'By creating an account, you agree to our',
        OR: 'or',
        CONTINUE_WITH: 'Continue with',
        LOG_IN_WITH: 'Log in with',
        SIGN_UP_WITH: 'Sign up with',
    },
    onBoarding: {
        WELCOME_STEP: 'Welcome',
        SIGNUP_STEP: 'Sign up',
        SUCCESS_STEP: 'Success',
    },
};
