import IPublisherEmbedSource from '../../../../types/models/IPublisherEmbedSource';
import IGiftLink from '../../../../types/models/IGiftLink';
import IOfferSource from '../../../../types/models/IOfferSource';
import {
    GET_GIFT_LINK_REQUEST, GET_GIFT_LINK_SUCCESS, GET_GIFT_LINK_FAILURE, SET_PUBLISHED_EMBED_SOURCE, SET_OFFER_SOURCE,
} from '../constants/usersource';

export interface IState {
    giftLink?: IGiftLink;
    publisherEmbedSource?: IPublisherEmbedSource;
    offerSource?: IOfferSource;
    loading: boolean;
    error?: any;
}

interface IAction {
    type: string;
    error?: Error;
    giftLink?: IGiftLink;
    publisherEmbedSource?: IPublisherEmbedSource;
    offerSource?: IOfferSource;
}

export const initialState: IState = {
    giftLink: undefined,
    loading: false,
    error: undefined,
    publisherEmbedSource: undefined,
    offerSource: undefined,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        // GET GIFT LINKS
        case GET_GIFT_LINK_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case GET_GIFT_LINK_SUCCESS:
            return {
                ...state,
                giftLink: action.giftLink,
                offerSource: undefined,
                publisherEmbedSource: undefined,
                loading: false,
            };
        case GET_GIFT_LINK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SET_PUBLISHED_EMBED_SOURCE:
            return {
                ...state,
                loading: false,
                offerSource: undefined,
                giftLink: undefined,
                publisherEmbedSource: action.publisherEmbedSource,
            };
        case SET_OFFER_SOURCE:
            return {
                ...state,
                loading: false,
                offerSource: action.offerSource,
                giftLink: undefined,
                publisherEmbedSource: undefined,
            };

        default:
            return state;
    }
};
