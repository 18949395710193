import * as React from 'react';

interface IProps {
    className: string;
}

class IconNavHome extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path
                    d="M8.89579 27H12.4097C12.9159 27 13.3264 26.5896 13.3264 26.0833V20.7667C13.3264 20.2604 13.7368 19.85 14.243 19.85H17.7569C18.2632 19.85 18.6736 20.2604 18.6736 20.7667V26.0833C18.6736 26.5896 19.084 27 19.5902 27H23.1041C23.6104 27 24.0208 26.5896 24.0208 26.0833V18.5667C24.0208 18.0604 24.4312 17.65 24.9375 17.65H26.5976C27.4174 17.65 27.8249 16.656 27.2411 16.0805L16.6435 5.63431C16.2866 5.28252 15.7133 5.28252 15.3565 5.63431L4.75887 16.0805C4.17499 16.656 4.58252 17.65 5.40237 17.65H7.06246C7.56872 17.65 7.97913 18.0604 7.97913 18.5667V26.0833C7.97913 26.5896 8.38953 27 8.89579 27Z"
                    stroke="white"
                    strokeOpacity="0.87"
                    strokeWidth="0.916667"
                />
            </svg>
        );
    }
}

export default IconNavHome;
