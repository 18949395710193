import * as React from 'react';

interface IProps {
    className?: string;
}

class MicIcon extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={this.props.className}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.0833 9.16667V5.83334C13.0833 4.13046 11.7029 2.75001 9.99999 2.75001C8.29711 2.75001 6.91666 4.13046 6.91666 5.83334V9.16667C6.91666 10.8695 8.29711 12.25 9.99999 12.25C11.7029 12.25 13.0833 10.8695 13.0833 9.16667ZM9.99999 1.66667C7.6988 1.66667 5.83332 3.53215 5.83332 5.83334V9.16667C5.83332 11.4679 7.6988 13.3333 9.99999 13.3333C12.3012 13.3333 14.1667 11.4679 14.1667 9.16667V5.83334C14.1667 3.53215 12.3012 1.66667 9.99999 1.66667ZM10.5412 15.8113C10.5415 15.8186 10.5416 15.826 10.5416 15.8333V17.7917C10.5416 18.0908 10.2991 18.3333 9.99997 18.3333C9.70082 18.3333 9.4583 18.0908 9.4583 17.7917V15.8333C9.4583 15.826 9.45845 15.8186 9.45874 15.8113C7.88879 15.6835 6.40798 15.0028 5.28595 13.8807C4.16466 12.7594 3.48405 11.2799 3.35557 9.71109C3.33101 9.41111 3.57732 9.16667 3.8783 9.16667C4.17928 9.16667 4.42051 9.41126 4.44986 9.7108C4.57524 10.9901 5.13986 12.1932 6.05666 13.11C7.10249 14.1558 8.52095 14.7434 9.99999 14.7434C11.479 14.7434 12.8975 14.1558 13.9433 13.11C14.8601 12.1932 15.4247 10.9901 15.5501 9.7108C15.5795 9.41125 15.8207 9.16667 16.1217 9.16667C16.4227 9.16667 16.669 9.41111 16.6444 9.71109C16.5159 11.2799 15.8353 12.7594 14.714 13.8807C13.592 15.0028 12.1112 15.6835 10.5412 15.8113Z"
                    fill="white"
                    fillOpacity="0.6"
                />
            </svg>
        );
    }
}

export default MicIcon;
