import {
    SEARCH_CLEAR,
    SEARCH_FAILURE,
    SEARCH_REQUEST,
    SEARCH_SUCCESS,
    ARTICLE_IS_QUEUED_FOR_SEARCH_PENDING,
    ARTICLE_IS_QUEUED_FOR_SEARCH_REJECTED,
    ARTICLE_IS_QUEUED_FOR_SEARCH_SUCCESS,
    PLAYLIST_IS_QUEUED_FOR_SEARCH_SUCCESS,
    PLAYLIST_IS_QUEUED_FOR_SEARCH_PENDING,
    PLAYLIST_IS_QUEUED_FOR_SEARCH_REJECTED,
} from '../constants/search';
import ISegment from '../types/models/ISegment';

export interface IState {
    loading: boolean;
    segments?: ISegment[];
    pendingQueueArticleIds: number[];
    pendingQueuePlaylistIds: number[];
    error?: any;
}

interface IAction {
    type: string;
    segments?: ISegment[];

    error?: Error;
    isQueued?: {
        itemId: number;
        status: boolean;
    };
}

export const initialState: IState = {
    loading: false,
    segments: [],
    pendingQueueArticleIds: [],
    pendingQueuePlaylistIds: [],
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                segments: action.segments,
                loading: false,
            };
        case SEARCH_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case SEARCH_CLEAR:
            return {
                ...state,
                segments: [],
            };
        case PLAYLIST_IS_QUEUED_FOR_SEARCH_SUCCESS: {
            const { segments } = state;

            if (!segments) return state;

            const { itemId, status } = action.isQueued!;

            const [segmentIndex, itemIndex] = (() => {
                for (let i = 0; i < segments.length; i += 1) {
                    const { items } = segments[i];

                    for (let j = 0; j < items.length; j += 1) {
                        if (items[j].id === itemId) {
                            return [i, j];
                        }
                    }
                }

                return [null, null];
            })();

            if (segmentIndex === null || itemIndex === null) return state;

            const clonedItems = [...segments[segmentIndex].items];
            clonedItems[itemIndex] = { ...clonedItems[itemIndex], isQueued: status };

            const clonedSegments = [...segments];
            clonedSegments[segmentIndex] = { ...clonedSegments[segmentIndex], items: clonedItems };

            return {
                ...state,
                segments: clonedSegments,
                pendingQueuePlaylistIds: state.pendingQueuePlaylistIds.filter((playlistId) => playlistId !== itemId),
            };
        }

        case PLAYLIST_IS_QUEUED_FOR_SEARCH_PENDING: {
            const { itemId } = action.isQueued!;
            return { ...state, pendingQueuePlaylistIds: [...state.pendingQueuePlaylistIds, itemId] };
        }

        case PLAYLIST_IS_QUEUED_FOR_SEARCH_REJECTED: {
            const { itemId } = action.isQueued!;
            return {
                ...state,
                pendingQueuePlaylistIds: state.pendingQueuePlaylistIds.filter((playlistId) => playlistId !== itemId),
            };
        }
        case ARTICLE_IS_QUEUED_FOR_SEARCH_SUCCESS: {
            const { segments } = state;

            if (!segments) return state;

            const { itemId, status } = action.isQueued!;

            const [segmentIndex, itemIndex] = (() => {
                for (let i = 0; i < segments.length; i += 1) {
                    const { items } = segments[i];

                    for (let j = 0; j < items.length; j += 1) {
                        if (items[j].id === itemId) {
                            return [i, j];
                        }
                    }
                }

                return [null, null];
            })();

            if (segmentIndex === null || itemIndex === null) return state;

            const clonedItems = [...segments[segmentIndex].items];
            clonedItems[itemIndex] = { ...clonedItems[itemIndex], isQueued: status };

            const clonedSegments = [...segments];
            clonedSegments[segmentIndex] = { ...clonedSegments[segmentIndex], items: clonedItems };

            return {
                ...state,
                segments: clonedSegments,
                pendingQueueArticleIds: state.pendingQueueArticleIds.filter((articleId) => articleId !== itemId),
            };
        }

        case ARTICLE_IS_QUEUED_FOR_SEARCH_PENDING: {
            const { itemId } = action.isQueued!;
            return { ...state, pendingQueueArticleIds: [...state.pendingQueueArticleIds, itemId] };
        }

        case ARTICLE_IS_QUEUED_FOR_SEARCH_REJECTED: {
            const { itemId } = action.isQueued!;
            return {
                ...state,
                pendingQueueArticleIds: state.pendingQueueArticleIds.filter((articleId) => articleId !== itemId),
            };
        }

        default:
            return state;
    }
};
