import * as React from 'react';

interface IProps {
    className: string;
    onClick: React.MouseEventHandler;
    [x: string]: any;
}

class IconShareWhite extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.6133 9.8125C18.6133 10.1165 18.6618 10.4094 18.7514 10.6839L12.6688 14.1516C12.1528 13.5611 11.3944 13.1875 10.5508 13.1875C9.00047 13.1875 7.73828 14.449 7.73828 16C7.73828 17.551 9.00047 18.8125 10.5508 18.8125C11.3946 18.8125 12.153 18.4388 12.6691 17.8481L18.7515 21.3158C18.6618 21.5904 18.6133 21.8834 18.6133 22.1875C18.6133 23.7385 19.8756 25 21.4258 25C22.9761 25 24.2383 23.7385 24.2383 22.1875C24.2383 20.6365 22.9761 19.375 21.4258 19.375C20.5824 19.375 19.8243 19.7484 19.3082 20.3386L13.2253 16.8707C13.3149 16.5964 13.3633 16.3038 13.3633 16C13.3633 15.6961 13.3148 15.4033 13.2252 15.1289L19.308 11.6612C19.824 12.2515 20.5823 12.625 21.4258 12.625C22.9761 12.625 24.2383 11.3635 24.2383 9.8125C24.2383 8.26151 22.9761 7 21.4258 7C19.8756 7 18.6133 8.26151 18.6133 9.8125ZM19.7383 9.8125C19.7383 8.88168 20.4958 8.125 21.4258 8.125C22.3557 8.125 23.1133 8.88168 23.1133 9.8125C23.1133 10.7433 22.3557 11.5 21.4258 11.5C20.4958 11.5 19.7383 10.7433 19.7383 9.8125ZM21.4258 20.5C20.4958 20.5 19.7383 21.2567 19.7383 22.1875C19.7383 23.1183 20.4958 23.875 21.4258 23.875C22.3557 23.875 23.1133 23.1183 23.1133 22.1875C23.1133 21.2567 22.3557 20.5 21.4258 20.5ZM8.86328 16C8.86328 15.0692 9.62079 14.3125 10.5508 14.3125C11.4808 14.3125 12.2383 15.0692 12.2383 16C12.2383 16.9308 11.4808 17.6875 10.5508 17.6875C9.62079 17.6875 8.86328 16.9308 8.86328 16Z"
                    fill="white"
                    fillOpacity="0.87"
                />
            </svg>
        );
    }
}

export default IconShareWhite;
