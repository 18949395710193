import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { Container, Row, Col } from 'reactstrap';
import IPublisher from '../../types/models/IPublisher';
import IUser from '../../types/models/IUser';
import theme from '../../theme';
import { openModal } from '../../actions/modal';
import ModalTypes from '../../types/ModalTypes';
import newtheme from '../../newtheme';
import IconPlayBlue from '../../assets/icons/IconPlayBlue';

interface IProps {
    publisher: IPublisher;
    user?: IUser;
    share: () => void;
    openModal: typeof openModal;
    children?: any;
    play: any;
}

interface IState { }

class ViewPublisher extends React.Component<IProps, IState> {
    public onClickGetTheApp = () => {
        this.props.openModal(ModalTypes.GetTheApp);
    };

    /*
    <div className={css(styles.publisherDiv)}>
        <div className={css(styles.centerItems)}>
            <div
                className={css(styles.image)}
                style={{ background: ` url(${publisher.largeImage})` }}
            />

            <h1 className={css(styles.title)}>{publisher.name}</h1>
            <h3 className={css(styles.description)}>{publisher.description}</h3>

            <button className={css(styles.playButton)} onClick={this.props.play}>Start Listening</button>

        </div>
    </div>
    */

    public render() {
        const { publisher } = this.props;

        return (
            <Container className={css(styles.main)}>
                <Row>
                    <Col xs={12} lg={{ size: 8, offset: 2 }}>
                        <div className={css(styles.content)}>
                            <img alt={`${publisher.name} logo`} src={publisher.largeImage} className={css(styles.image)} />

                            <h1 className={css(styles.title)}>{publisher.name}</h1>
                            <h3 className={css(styles.description)}>{publisher.description}</h3>
                            <button type="button" className={css(styles.playButton)} onClick={this.props.play}>
                                <IconPlayBlue className={css(styles.iconPlayPause)} />
                                {' '}
                                Start Listening
                            </button>
                        </div>
                    </Col>

                    <Col xs={12} className={css(styles.articleList)}>
                        {this.props.children}
                    </Col>
                </Row>
            </Container>
        );
    }
}

const styles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.medium]: {
            paddingTop: 35,
        },
        [newtheme.cssBreakpoints.small]: {
            paddingTop: 25,
            paddingBottom: 25,
        },
        paddingTop: 50,
        paddingBottom: 50,
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        color: newtheme.darkmodeColors.white,
        minHeight: '100vh',
    },
    content: {
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 35,
        },
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 25,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 15,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginBottom: 50,
    },
    image: {
        [newtheme.cssBreakpoints.medium]: {
            height: 160,
            width: 160,
        },
        [newtheme.cssBreakpoints.small]: {
            height: 130,
            width: 130,
        },
        height: 200,
        width: 200,
        marginBottom: 25,
        borderRadius: 100,
    },
    title: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 42,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 36,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 32,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 24,
            marginBottom: 5,
        },
        fontSize: 48,
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: '140%',
        marginBottom: 7,
    },
    description: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 19,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 18,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 15,
        },
        textAlign: 'center',
        fontWeight: 400,
        fontSize: 20,
        lineHeight: '140%',
        color: newtheme.darkmodeColors.fadedText,
        marginBottom: 24,
    },
    playButton: {
        ':hover': {
            transform: 'scale(1.03)',
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        transition: 'transform .2s',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: newtheme.colors.primaryBlue,
        color: 'white',
        border: 'none',
        fontSize: 18,
        fontWeight: 400,
        padding: '10px 20px',
        borderRadius: 24,
    },
    iconPlayPause: {
        height: 26,
        width: 26,
        marginRight: 5,
        fill: 'white',
    },
    articleList: {
        [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
            marginTop: 30,
        },
    },
});

export default ViewPublisher;
