import {
    ADVERT_FAILURE,
    ADVERT_REQUEST,
    ADVERT_SUCCESS,
    ADVERT_CLEAR,
} from '../constants/advert';
import IAdvert from '../types/models/IAdvert';

export interface IState {
    loading: boolean;
    advert?: IAdvert;
    error?: Error;
}

interface IAction {
    type: string;
    advert?: IAdvert;
    error?: Error;
}

export const initialState: IState = {
    loading: false,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case ADVERT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ADVERT_SUCCESS: {
            return {
                ...state,
                advert: action.advert,
                loading: false,
            };
        }
        case ADVERT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case ADVERT_CLEAR:
            return {
                ...state,
                advert: null,
            };
        default:
            return state;
    }
};
