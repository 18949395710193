export const PLAYER_PLAY = 'PLAYER_PLAY';
export const PLAYER_PAUSE = 'PLAYER_PAUSE';

export const PLAYER_CURRENT_TIME = 'PLAYER_CURRENT_TIME';

export const TRACKING_LISTEN_INCREMENT = 10;

export const CURRENTLY_PLAYING_LOADER = 'CURRENTLY_PLAYING_LOADER';
export const CURRENTLY_PLAYING_SUCCESS = 'CURRENTLY_PLAYING_SUCCESS';
export const CURRENTLY_PLAYING_PENDING = 'CURRENTLY_PLAYING_PENDING';
export const CURRENTLY_PLAYING_REJECTED = 'CURRENTLY_PLAYING_REJECTED';
export const PLAY_NEXT_QUEUE_ARTICLE_PENDING = 'PLAY_NEXT_QUEUE_ARTICLE_PENDING';
export const PLAY_NEXT_QUEUE_ARTICLE_REJECTED = 'PLAY_NEXT_QUEUE_ARTICLE_REJECTED';
export const PLAY_PREV_QUEUE_ARTICLE_PENDING = 'PLAY_PREV_QUEUE_ARTICLE_PENDING';
export const PLAY_PREV_QUEUE_ARTICLE_REJECTED = 'PLAY_PREV_QUEUE_ARTICLE_REJECTED';
export const SET_ARTICLE_TO_CURRENTLY_PLAYING_PENDING = 'SET_ARTICLE_TO_CURRENTLY_PLAYING_PENDING';
export const SET_ARTICLE_TO_CURRENTLY_PLAYING_REJECTED = 'SET_ARTICLE_TO_CURRENTLY_PLAYING_REJECTED';
export const SET_PLAYLIST_TO_CURRENTLY_PLAYING_PENDING = 'SET_PLAYLIST_TO_CURRENTLY_PLAYING_PENDING';
export const SET_PLAYLIST_TO_CURRENTLY_PLAYING_REJECTED = 'SET_PLAYLIST_TO_CURRENTLY_PLAYING_REJECTED';

export const ADD_ARTICLE_TO_QUEUE_LOADER = 'ADD_ARTICLE_TO_QUEUE_LOADER';

export const REMOVE_ARTICLE_FROM_QUEUE_LOADER = 'REMOVE_ARTICLE_FROM_QUEUE_LOADER';

export const ADD_PLAYLIST_TO_QUEUE_LOADER = 'ADD_PLAYLIST_TO_QUEUE_LOADER';

export const REMOVE_PLAYLIST_FROM_QUEUE_LOADER = 'REMOVE_PLAYLIST_FROM_QUEUE_LOADER';

export const SET_NEXT_ARTICLE_TO_CURRENTLY_PLAYING_LOADER = 'SET_NEXT_ARTICLE_TO_CURRENTLY_PLAYING_LOADER';

export const SET_PREVIOUS_ARTICLE_TO_CURRENTLY_PLAYING_LOADER = 'SET_PREVIOUS_ARTICLE_TO_CURRENTLY_PLAYING_LOADER';

export const SET_CURRENTLY_PLAYING_ARTICLE_LOADER = 'SET_CURRENTLY_PLAYING_ARTICLE_LOADER';

export const SET_CURRENTLY_PLAYING_PLAYLIST_LOADER = 'SET_CURRENTLY_PLAYING_PLAYLIST_LOADER';

export const PLAYER_ENDED = 'PLAYER_ENDED';

export const SET_ARTICLES_TO_LOCAL_QUEUE = 'SET_ARTICLES_TO_LOCAL_QUEUE';

export const INCREASE_SPEED = 'INCREASE_SPEED';
export const DECREASE_SPEED = 'DECREASE_SPEED';
