export enum mpTrackingScreens {
    landing = 'Landing',
    doesYourEmployerPayCheck = 'DoesYourEmployerPayCheck',
    doesYourEmployerPayPersonalAddress = 'DoesYourEmployerPayPersonalAddress',
    doesYourEmployerPayNoSubFound = 'DoesYourEmployerPayNoSubFound',
    landingEmbed = 'LandingEmbed',
    welcome = 'Welcome',
    signup = 'Signup',
    login = 'Login',
    home = 'Home',
    resetPassword = 'ResetPassword',
    resetPasswordConfirm = 'ResetPasswordConfirm',
    articles = 'Articles',
    subscribe = 'Subscribe',
    completeSubscription = 'CompleteSubscription',
    search = 'Search',
    library = 'Library',
    menu = 'Menu',
    meetTheTeam = 'MeetTheTeam',
    ourStory = 'OurStory',
    editorialPrinciples = 'EditorialPrinciples',
    about = 'About',
    faq = 'Faq',
    ourPublishers = 'OurPublishers',
    terms = 'Terms',
    privacy = 'Privacy',
    cookiePolicy = 'CookiePolicy',
    gift = 'Gift',
    enterpriseGift = 'EnterpriseGift',
    careers = 'Careers',
    contact = 'Contact',
    singleSeries = 'SingleSeries',
    singlePublisher = 'SinglePublisher',
    singleJournalist = 'SingleJournalist',
    singleCategory = 'SingleCategory',
    singleCollection = 'SingleCollection',
    singleProfile = 'SingleProfile',
    articlePlayer = 'ArticlePlayer',
    embedPlayer = 'EmbedPlayer',
    recentlyPlayed = 'RecentlyPlayed',
    downloadApp = 'DownloadApp',
    signupSuccessDownloadApp = 'SignupSuccessDownloadApp',
    subscriptionSuccessDownloadApp = 'SubscriptionSuccessDownloadApp',
    initDeleteAccount = 'InitDeleteAccount',
    cannotDeleteAccount = 'CannotDeleteAccount',
    successfulDeleteAccount = 'SuccessfulDeleteAccount',
}
