import {
    GET_PLAYLISTS_FAILURE,
    GET_PLAYLISTS_REQUEST,
    GET_PLAYLISTS_SUCCESS,
    PLAYLIST_IS_QUEUED,
} from '../constants/playlist';
import IPlaylistNew from '../types/models/IPlaylistNew';

export interface IState {
    playlists?: IPlaylistNew[];
    loading: boolean;
    error?: any;
}

interface IAction {
    type: string;
    error?: Error;
    playlists?: IPlaylistNew[];
    isQueued?: {
        playlistId: number;
        status: boolean;
    };
}

export const initialState: IState = {
    loading: false,
    playlists: undefined,
    error: null,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_PLAYLISTS_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case GET_PLAYLISTS_SUCCESS:
            return {
                ...state,
                playlists: action.playlists,
                loading: false,
            };
        case GET_PLAYLISTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case PLAYLIST_IS_QUEUED: {
            const { playlists } = state;

            if (!playlists) return state;

            const { playlistId, status } = action.isQueued!;
            const playlistIndex = playlists.findIndex((p) => p.id === playlistId);

            if (playlistIndex === -1) return state;

            const updatedPlaylist = { ...playlists[playlistIndex], isQueued: status };
            const clonedPlaylists = [...playlists];
            clonedPlaylists[playlistIndex] = updatedPlaylist;

            return {
                ...state,
                playlists: clonedPlaylists,
            };
        }
        default:
            return state;
    }
};
