import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';
import { Container } from 'reactstrap';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import { mpTrackButtonClick } from '../../tracking/mixpanel';
import { MpTrackingButtons } from '../../config/buttons';
import IconNewNoaLogoWhiteText from '../../assets/icons/IconNewNoaLogoWhiteText';
// import PhoneInputField from '../PhoneInputField';
import { getLocationInfo } from '../../actions/location';
import config from '../../config';
import { IState } from '../../reducers';
import { reset, sendSMS } from '../../actions/sms';
import IconSocialTwitter from '../../assets/icons/IconSocialTwitter';
import IconSocialLinkedin from '../../assets/icons/IconSocialLinkedin';
import IconSocialFacebook from '../../assets/icons/IconSocialFacebook';
import IconSocialInstagram from '../../assets/icons/IconSocialInstagram';
import newtheme from '../../newtheme';
import text from '../../locale';
import { openModal } from '../../actions/modal';
import ModalTypes from '../../types/ModalTypes';
import IUser from '../../types/models/IUser';

interface IPropsFromState {
    loading: boolean;
    smsSent: boolean;
    error: boolean;
    isOpen: boolean;
    selectedCountryAlpha2?: string;
    user?: IUser;
}

interface IPropsFromDispatch {
    sendSMS: typeof sendSMS;
    reset: typeof reset;
    getLocationInfo: typeof getLocationInfo;
    openModal: typeof openModal;
}
class LandingPageNewSitemap extends React.Component<IPropsFromState & IPropsFromDispatch & RouteComponentProps> {
    public onClickIosAppStore = () => {
        mpTrackButtonClick(MpTrackingButtons.NAVIGATE_TO_IOS_APP_STORE);
    };

    public onClickAndroidAppStore = () => {
        mpTrackButtonClick(MpTrackingButtons.NAVIGATE_TO_ANDROID_APP_STORE);
    };

    public showSignup = () => {
        mpTrackButtonClick(MpTrackingButtons.SHOW_SIGNUP_SITEMAP);

        if (this.props.user) {
            this.props.history.push(config.routes.homeWebPlayer);
            return;
        }

        this.props.openModal(ModalTypes.SignUpNew);
    };

    public trackClick = (button: MpTrackingButtons) => {
        mpTrackButtonClick(button);
    };

    public render() {
        const year = new Date().getFullYear();

        return (
            <Container className={css(styles.container)}>
                <div className={css(styles.topSection)}>
                    <div className={css(styles.logoAndPhoneInput)}>
                        <IconNewNoaLogoWhiteText className={css(styles.noaLogo)} />

                        {!isMobile ? (
                            // <>
                            //     <p className={css(styles.phoneLinkText)}>Text yourself a link to download Noa</p>

                            //     <PhoneInputField
                            //         colour="DARK"
                            //         smsSent={this.props.smsSent}
                            //         sendSMS={this.props.sendSMS}
                            //         smsMode={config.smsMode.regular}
                            //         loading={this.props.loading}
                            //         error={this.props.error}
                            //         reset={this.props.reset}
                            //         selectedCountryAlpha2={this.props.selectedCountryAlpha2}
                            //     />
                            // </>
                            <div className={css(styles.storeButtons)}>
                                <a href={config.androidAppLink} onClick={this.onClickAndroidAppStore}>
                                    <img
                                        alt="Google play store"
                                        src="/downloadGoogleStore.png"
                                        className={css(styles.icons)}
                                    />
                                </a>

                                <a href={config.iosAppLink} onClick={this.onClickIosAppStore}>
                                    <img
                                        alt="Apple app store"
                                        src="/downloadAppleStore.png"
                                        className={css(styles.icons)}
                                    />
                                </a>
                            </div>
                        ) : (
                            <div className={css(styles.storeButtons)}>
                                <a href={config.androidAppLink} onClick={this.onClickAndroidAppStore}>
                                    <img
                                        alt="Google play store"
                                        src="/downloadGoogleStore.png"
                                        className={css(styles.icons)}
                                    />
                                </a>

                                <a href={config.iosAppLink} onClick={this.onClickIosAppStore}>
                                    <img
                                        alt="Apple app store"
                                        src="/downloadAppleStore.png"
                                        className={css(styles.icons)}
                                    />
                                </a>
                            </div>
                        )}
                    </div>
                    <div className={css(styles.links)}>
                        {/* Why Noa?
                        <ul className={css(styles.linksColumn, styles.whyNoaLinks)}>
                            <h5 className={css(styles.linksColumnHeader)}>Why Noa?</h5>

                            <Link to={config.routes.about} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Take Us Everywhere</li>
                            </Link>

                            <Link to={config.routes.about} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Quality Journalism</li>
                            </Link>

                            <Link to={config.routes.about} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Curation</li>
                            </Link>

                            <Link to={config.routes.about} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>What are Series?</li>
                            </Link>
                        </ul>
                        */}

                        {/* Company */}
                        <ul className={css(styles.linksColumn, styles.whyNoaLinks)}>
                            <h5 className={css(styles.linksColumnHeader)}>Explore</h5>

                            <Link to={config.routes.homeWebPlayer} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Web Player</li>
                            </Link>

                            <Link to={config.routes.ourPublishers} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Publishers</li>
                            </Link>

                            {!this.props.user && (
                                <li onClick={this.showSignup} className={css(styles.link, styles.linkText)}>
                                    Sign Up
                                </li>
                            )}

                            <Link to={config.routes.subscribe} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Pricing</li>
                            </Link>

                            <Link to={config.routes.gift} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>Gift</li>
                            </Link>
                        </ul>

                        {/* Who we are */}
                        <ul className={css(styles.linksColumn, styles.whyNoaLinks)}>
                            <h5 className={css(styles.linksColumnHeader)}>Useful Links</h5>

                            <Link to={config.routes.about} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>About Us</li>
                            </Link>

                            <Link to={config.routes.faq} className={css(styles.link)}>
                                <li className={css(styles.linkText)}>FAQs</li>
                            </Link>

                            <li id={text.nav.CONTACT} className={css(styles.link, styles.linkText)}>
                                Contact Us
                            </li>
                        </ul>
                    </div>
                </div>
                {/* End of top section */}

                {/* Bottom links */}
                <div className={css(styles.bottomLinks)}>
                    <div className={css(styles.copyrightAndTermsWrapper)}>
                        <p className={css(styles.copyright)}>©{year} Noa News Ltd. ALL RIGHTS RESERVED</p>
                        <p className={css(styles.privacyAndTerms)}>
                            <Link to={config.routes.privacy} className={css(styles.link, styles.bottomLink)}>
                                Privacy
                            </Link>
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            <Link to={config.routes.terms} className={css(styles.link, styles.bottomLink)}>
                                Terms &amp; Conditions
                            </Link>
                            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                            <Link to={config.routes.cookiePolicy} className={css(styles.link, styles.bottomLink)}>
                                Cookies
                            </Link>
                        </p>
                    </div>

                    <div className={css(styles.socialIconWrapper)}>
                        <a
                            href={config.externalRoutes.instagramNoa}
                            target="_blank"
                            onContextMenu={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_INSTAGRAM)}
                            onClick={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_INSTAGRAM)}
                            rel="noreferrer">
                            <IconSocialInstagram className={css(styles.socialIcon)} />
                        </a>
                        <a
                            href={config.externalRoutes.facebookNoa}
                            target="_blank"
                            onContextMenu={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_FACEBOOK)}
                            onClick={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_FACEBOOK)}
                            rel="noreferrer">
                            <IconSocialFacebook className={css(styles.socialIcon)} />
                        </a>
                        <a
                            href={config.externalRoutes.linkedInNoa}
                            target="_blank"
                            onContextMenu={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_LINKEDIN)}
                            onClick={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_LINKEDIN)}
                            rel="noreferrer">
                            <IconSocialLinkedin className={css(styles.socialIcon)} />
                        </a>
                        <a
                            href={config.externalRoutes.twitterNoa}
                            target="_blank"
                            onContextMenu={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_TWITTER)}
                            onClick={() => this.trackClick(MpTrackingButtons.NAVIGATE_TO_TWITTER)}
                            rel="noreferrer">
                            <IconSocialTwitter className={css(styles.socialIcon)} />
                        </a>
                    </div>
                </div>
            </Container>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            padding: 40,
            paddingBottom: 130,
        },
        [newtheme.cssBreakpointsWithoutHeight.minuscule]: {
            padding: 30,
            paddingBottom: 130,
        },
        padding: '100px 0px',
        paddingBottom: 130,
        color: 'white',
    },
    topSection: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            flexDirection: 'column',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    logoAndPhoneInput: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            width: '70%',
            marginBottom: 45,
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            width: '100%',
            marginBottom: 10,
        },
        width: '40%',
    },
    noaLogo: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            width: 140,
            height: 50,
        },
        width: 180,
        height: 65,
        marginBottom: 35,
    },
    phoneLinkText: {
        [newtheme.cssBreakpointsWithoutHeight.small]: {
            fontSize: 16,
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            fontSize: 14,
        },
        fontSize: 18,
        fontWeight: 400,
    },
    links: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            width: '100%',
            justifyContent: 'flex-start',
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            flexDirection: 'column',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '60%',
    },
    linksColumn: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            paddingLeft: 0,
            paddingRight: 100,
        },
        paddingRight: 50,
    },
    linksColumnHeader: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            marginBottom: 25,
        },
        [newtheme.cssBreakpointsWithoutHeight.small]: {
            marginBottom: 20,
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            marginTop: 30,
            marginBottom: 10,
            fontSize: 16,
        },
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 25,
    },
    whyNoaLinks: {},
    link: {
        ':hover': {
            color: newtheme.colors.primaryBlue,
            textDecoration: 'none',
        },
        fontSize: 16,
        fontWeight: 400,
        color: '#9AA2A9',
        cursor: 'pointer',
    },
    linkText: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    bottomLinks: {
        [newtheme.cssBreakpointsWithoutHeight.small]: {
            flexDirection: 'column',
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 80,
        fontSize: 14,
        fontWeight: 400,
    },
    copyrightAndTermsWrapper: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            alignItems: 'center',
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    copyright: {
        [newtheme.cssBreakpointsWithoutHeight.medium]: {
            marginBottom: 20,
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            marginRight: 0,
            textAlign: 'center',
        },
        [newtheme.cssBreakpointsWithoutHeight.smallerThanTiny]: {
            fontSize: 12,
        },
        margin: 0,
        marginRight: 60,
        fontSize: 14,
    },
    privacyAndTerms: {
        [newtheme.cssBreakpointsWithoutHeight.smallerThanTiny]: {
            fontSize: 12,
        },
        margin: 0,
        fontSize: 14,
    },
    bottomLink: {
        color: 'white',
        padding: '10px 0',
    },
    socialIcon: {
        ':hover': {
            fill: newtheme.colors.primaryBlue,
        },
        fill: 'white',
        height: 28,
        marginLeft: 20,
        marginRight: 20,
    },
    socialIconWrapper: {
        [newtheme.cssBreakpointsWithoutHeight.small]: {
            order: -1,
            marginBottom: 60,
            marginLeft: -20,
        },
    },
    storeButtons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icons: {
        [newtheme.cssBreakpointsWithoutHeight.large]: {
            width: 170,
            height: 53,
        },
        [newtheme.cssBreakpointsWithoutHeight.tiny]: {
            width: 140,
            height: 43,
        },
        [newtheme.cssBreakpointsWithoutHeight.minuscule]: {
            width: 120,
            height: 37,
        },
        ':hover': {
            color: newtheme.colors.primaryBlue,
            fill: newtheme.colors.primaryBlue,
            cursor: 'pointer',
        },
        marginRight: 20,
        width: 200,
        height: 62,
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isOpen: state.modal.isOpen,
        loading: state.sms.loading,
        smsSent: state.sms.sent,
        error: state.sms.error,
        selectedCountryAlpha2: state.info.selectedCountryAlpha2
            ? state.info.selectedCountryAlpha2.toLowerCase()
            : undefined,
        user: state.user.user,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            sendSMS,
            reset,
            getLocationInfo,
            openModal,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPageNewSitemap));
