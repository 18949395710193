import { Dispatch } from 'redux';
import { toast } from 'react-toastify';

import {
    PLAYER_PAUSE,
    PLAYER_PLAY,
    TRACKING_LISTEN_INCREMENT,
    PLAYER_CURRENT_TIME,
    CURRENTLY_PLAYING_SUCCESS,
    SET_ARTICLES_TO_LOCAL_QUEUE,
    CURRENTLY_PLAYING_REJECTED,
    CURRENTLY_PLAYING_PENDING,
    SET_ARTICLE_TO_CURRENTLY_PLAYING_PENDING,
    SET_ARTICLE_TO_CURRENTLY_PLAYING_REJECTED,
    SET_PLAYLIST_TO_CURRENTLY_PLAYING_PENDING,
    SET_PLAYLIST_TO_CURRENTLY_PLAYING_REJECTED,
    PLAY_NEXT_QUEUE_ARTICLE_PENDING,
    PLAY_NEXT_QUEUE_ARTICLE_REJECTED,
    PLAY_PREV_QUEUE_ARTICLE_PENDING,
    PLAY_PREV_QUEUE_ARTICLE_REJECTED,
    INCREASE_SPEED,
    DECREASE_SPEED,
} from '../constants/player';
import { IState } from '../reducers';
import config from '../config';
import ItemTypes from '../types/ItemTypes';

import { mpTrackButtonClick, mpTrackIntervalListen } from '../tracking/mixpanel';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';
import IPlaylist from '../types/models/IPlaylist';

import IArticle from '../types/models/IArticle';
import { saveHistory } from './history';
import IPlayItem from '../types/IPlayItem';
import {
    setArticleIsQueuedForSinglePagePending,
    setArticleIsQueuedForSinglePageRejected,
    setArticleIsQueuedForSinglePageSuccess,
    setArticleIsQueuedPending,
    setArticleIsQueuedRejected,
    setArticleIsQueuedSuccess,
} from './article';
import {
    setSectionPlaylistIsQueuedPending,
    setSectionPlaylistIsQueuedRejected,
    setSectionPlaylistIsQueuedSuccess,
} from './section';
import {
    setArticleIsQueuedForRecommendationPagePending,
    setArticleIsQueuedForRecommendationPageRejected,
    setArticleIsQueuedForRecommendationPageSuccess,
    setRecommendationPlaylistIsQueuedPending,
    setRecommendationPlaylistIsQueuedRejected,
    setRecommendationPlaylistIsQueuedSuccess,
} from './recommendation';
import {
    setArticleIsQueuedForSearchPending,
    setArticleIsQueuedForSearchRejected,
    setArticleIsQueuedForSearchSuccess,
    setSearchPlaylistIsQueuedPending,
    setSearchPlaylistIsQueuedRejected,
    setSearchPlaylistIsQueuedSuccess,
} from './search';
import {
    setPlaylistViewIsQueuedPending,
    setPlaylistViewIsQueuedRejected,
    setPlaylistViewIsQueuedSuccess,
} from './playlist';
import { MpTrackingButtons } from '../config/buttons';

export const maybeTrackListenInterval =
    (item: IPlayItem, time: number) => async (dispatch: Dispatch, getState: () => IState) => {
        const { lastAudioIncrementTracked } = getState().player;
        const nextAudioIncrementToTrack = lastAudioIncrementTracked + TRACKING_LISTEN_INCREMENT;

        let intervalToTrack = lastAudioIncrementTracked;
        if (nextAudioIncrementToTrack < time && item.type === ItemTypes.Articles) {
            intervalToTrack = Math.floor(time / 10) * 10;
            mpTrackIntervalListen(item, intervalToTrack);
        }

        // @ts-ignore
        return dispatch({ type: PLAYER_CURRENT_TIME, currentTime: time, intervalToTrack });
    };

export const play = () => async (dispatch: Dispatch) => dispatch({ type: PLAYER_PLAY });

export const pause = () => async (dispatch: Dispatch) => dispatch({ type: PLAYER_PAUSE });

export const increaseSpeed = () => async (dispatch: Dispatch) => dispatch({ type: INCREASE_SPEED });
export const decreaseSpeed = () => async (dispatch: Dispatch) => dispatch({ type: DECREASE_SPEED });

export const getCurrentlyPlayingArticle =
    ({ errorMessage, keepPaused, article }: { errorMessage?: string; keepPaused?: boolean; article?: IArticle } = {}) =>
    (dispatch: Dispatch, getState: () => IState) => {
        if (article) {
            return dispatch(getCurrentlyPlayingArticleSuccess({ data: article }));
        }
        const requestUrl = `${config.api.url}${config.api.routes.queue}`;

        dispatch(getCurrentlyPlayingArticlePending());

        return fetch(getState)(requestUrl)
            .then(responseCheck)
            .then((response) => {
                dispatch(getCurrentlyPlayingArticleSuccess(response));

                if (keepPaused) {
                    pause()(dispatch);
                }
            })
            .catch(() => {
                dispatch(getCurrentlyPlayingArticleRejected(errorMessage));
                if (keepPaused) {
                    pause()(dispatch);
                }
            });
    };

export const setNextArticleToCurrentlyPlaying = () => (dispatch: Dispatch, getState: () => IState) => {
    const requestUrl = `${config.api.url}${config.api.routes.queue}/next`;
    dispatch(setNextArticlePending());

    return fetch(getState)(requestUrl, { method: 'POST' })
        .then(responseCheck)
        .then(() => {
            getCurrentlyPlayingArticle({ errorMessage: 'Unable to play next article.' })(dispatch, getState);
        })
        .catch(() => dispatch(setNextArticleRejected()));
};

export const setPreviousArticleToCurrentlyPlaying = () => (dispatch: Dispatch, getState: () => IState) => {
    const requestUrl = `${config.api.url}${config.api.routes.queue}/prev`;
    dispatch(setPrevArticlePending());

    return fetch(getState)(requestUrl, { method: 'POST' })
        .then(responseCheck)
        .then(() => {
            getCurrentlyPlayingArticle({ errorMessage: 'Unable to play previous article.' })(dispatch, getState);
        })
        .catch(() => dispatch(setPrevArticleRejected()));
};

export const addArticleToQueue = (article: IArticle) => (dispatch: Dispatch, getState: () => IState) => {
    mpTrackButtonClick(MpTrackingButtons.QUEUE_ARTICLE, article);

    setArticleIsQueuedPendingFn({ dispatch, articleId: article.id });

    const requestUrl = `${config.api.url}${config.api.routes.queue}/article`;
    const payload = {
        article: {
            articleId: article.id,
        },
    };

    return fetch(getState)(requestUrl, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => setArticleIsQueuedSuccessFn({ dispatch, articleId: article.id, isQueued: true }))
        .catch(() => setArticleIsQueuedRejectedFn({ dispatch, articleId: article.id }));
};

export const removeArticleFromQueue = (article: IArticle) => (dispatch: Dispatch, getState: () => IState) => {
    mpTrackButtonClick(MpTrackingButtons.REMOVE_QUEUE_ARTICLE, article);
    setArticleIsQueuedPendingFn({ dispatch, articleId: article.id });

    const requestUrl = `${config.api.url}${config.api.routes.queue}/article`;
    const payload = {
        article: {
            articleId: article.id,
        },
    };

    return fetch(getState)(requestUrl, {
        method: 'DELETE',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => setArticleIsQueuedSuccessFn({ dispatch, articleId: article.id, isQueued: false }))
        .catch(() => setArticleIsQueuedRejectedFn({ dispatch, articleId: article.id }));
};

export const setCurrentlyPlayingArticle = (article: IArticle) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch(setCurrentlyPlayingArticlePending());

    const requestUrl = `${config.api.url}${config.api.routes.queue}/currently-playing`;
    const payload = {
        nextArticle: {
            articleId: article.id,
        },
    };

    return fetch(getState)(requestUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => {
            getCurrentlyPlayingArticle({ errorMessage: 'Unable to play this article.' })(dispatch, getState);
        })
        .catch(() => dispatch(setCurrentlyPlayingArticleRejected()));
};

export const setCurrentlyPlayingPlaylist = (playlist: IPlaylist) => (dispatch: Dispatch, getState: () => IState) => {
    dispatch(setCurrentlyPlayingPlaylistPending());

    const requestUrl = `${config.api.url}${config.api.routes.queue}/currently-playing/playlist`;
    const payload = {
        playlistId: playlist.id,
    };

    return fetch(getState)(requestUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => {
            getCurrentlyPlayingArticle({ errorMessage: 'Unable to play this playlist.' })(dispatch, getState);
        })
        .catch(() => dispatch(setCurrentlyPlayingPlaylistRejected()));
};

export const addPlaylistToQueue = (playlist: IPlaylist) => (dispatch: Dispatch, getState: () => IState) => {
    mpTrackButtonClick(MpTrackingButtons.QUEUE_PLAYLIST, undefined, playlist.id);
    setPlaylistIsQueuedPending({ dispatch, playlistId: playlist.id });

    const requestUrl = `${config.api.url}${config.api.routes.queue}/playlist`;
    const payload = {
        playlistId: playlist.id,
    };

    return fetch(getState)(requestUrl, {
        method: 'PUT',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => setPlaylistIsQueuedSuccessFn({ dispatch, playlistId: playlist.id, isQueued: true }))
        .catch(() => setPlaylistIsQueuedRejectedFn({ dispatch, playlistId: playlist.id }));
};

export const removePlaylistFromQueue = (playlist: IPlaylist) => (dispatch: Dispatch, getState: () => IState) => {
    mpTrackButtonClick(MpTrackingButtons.REMOVE_QUEUE_PLAYLIST, undefined, playlist.id);
    setPlaylistIsQueuedPending({ dispatch, playlistId: playlist.id });

    const requestUrl = `${config.api.url}${config.api.routes.queue}/playlist`;
    const payload = {
        playlistId: playlist.id,
    };

    return fetch(getState)(requestUrl, {
        method: 'DELETE',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(responseCheck)
        .then(() => setPlaylistIsQueuedSuccessFn({ dispatch, playlistId: playlist.id, isQueued: false }))
        .catch(() => setPlaylistIsQueuedRejectedFn({ dispatch, playlistId: playlist.id }));
};

const setPlaylistIsQueuedSuccessFn = ({ dispatch, playlistId, isQueued }) => {
    setSectionPlaylistIsQueuedSuccess({ playlistId, isQueued })(dispatch);
    setRecommendationPlaylistIsQueuedSuccess({ playlistId, isQueued })(dispatch);
    setSearchPlaylistIsQueuedSuccess({ playlistId, isQueued })(dispatch);
    setPlaylistViewIsQueuedSuccess({ playlistId, isQueued })(dispatch);
};
const setPlaylistIsQueuedPending = ({ dispatch, playlistId }) => {
    setSectionPlaylistIsQueuedPending({ playlistId })(dispatch);
    setRecommendationPlaylistIsQueuedPending({ playlistId })(dispatch);
    setSearchPlaylistIsQueuedPending({ playlistId })(dispatch);
    setPlaylistViewIsQueuedPending({ playlistId })(dispatch);
};
const setPlaylistIsQueuedRejectedFn = ({ dispatch, playlistId }) => {
    toast('Unable to queue this playlist.');

    setSectionPlaylistIsQueuedRejected({ playlistId })(dispatch);
    setRecommendationPlaylistIsQueuedRejected({ playlistId })(dispatch);
    setSearchPlaylistIsQueuedRejected({ playlistId })(dispatch);
    setPlaylistViewIsQueuedRejected({ playlistId })(dispatch);
};

const setArticleIsQueuedPendingFn = ({ dispatch, articleId }) => {
    setArticleIsQueuedPending({ articleId })(dispatch);
    setArticleIsQueuedForSearchPending({ articleId })(dispatch);
    setArticleIsQueuedForSinglePagePending({ articleId })(dispatch);
    setArticleIsQueuedForRecommendationPagePending({ articleId })(dispatch);
};

const setArticleIsQueuedRejectedFn = ({ dispatch, articleId }) => {
    toast('Unable to queue this article.');

    setArticleIsQueuedRejected({ articleId })(dispatch);
    setArticleIsQueuedForSearchRejected({ articleId })(dispatch);
    setArticleIsQueuedForSinglePageRejected({ articleId })(dispatch);
    setArticleIsQueuedForRecommendationPageRejected({ articleId })(dispatch);
};

const setArticleIsQueuedSuccessFn = ({ dispatch, articleId, isQueued }) => {
    setArticleIsQueuedSuccess({ articleId, isQueued })(dispatch);
    setArticleIsQueuedForSearchSuccess({ articleId, isQueued })(dispatch);
    setArticleIsQueuedForSinglePageSuccess({ articleId, isQueued })(dispatch);
    setArticleIsQueuedForRecommendationPageSuccess({ articleId, isQueued })(dispatch);
};

export const onArticleEnded =
    (isAdvertCurrentlyPlaying?: boolean) => async (dispatch: Dispatch, getState: () => IState) => {
        saveHistory()(dispatch, getState).then(() => {
            if (!isAdvertCurrentlyPlaying) setNextArticleToCurrentlyPlaying()(dispatch, getState);
            else getCurrentlyPlayingArticle()(dispatch, getState);
        });
    };

export const setArticlesToLocalQueue = (articles: IArticle[]) => async (dispatch: Dispatch, getState: () => IState) => {
    const firstArticle = articles[0];

    getCurrentlyPlayingArticle({ article: firstArticle })(dispatch, getState);
    dispatch({ type: SET_ARTICLES_TO_LOCAL_QUEUE, articles });
};

export const setNextLocalArticleToCurrentlyPlaying = () => async (dispatch: Dispatch, getState: () => IState) => {
    const { currentlyPlaying, localQueue } = getState().player;

    if (!currentlyPlaying || !localQueue.length) {
        const firstArticle = localQueue?.[0];
        return firstArticle && getCurrentlyPlayingArticle({ article: { ...firstArticle } })(dispatch, getState);
    }

    const currentlyPlayingArticleIndex = localQueue.findIndex((a) => a.id === currentlyPlaying.id);

    if (currentlyPlayingArticleIndex === -1 || currentlyPlayingArticleIndex >= localQueue.length - 1) {
        const firstArticle = localQueue[0];

        return getCurrentlyPlayingArticle({ article: { ...firstArticle } })(dispatch, getState);
    }

    const nextArticle = localQueue[currentlyPlayingArticleIndex + 1];
    return getCurrentlyPlayingArticle({ article: { ...nextArticle } })(dispatch, getState);
};

export const setPreviousLocalArticleToCurrentlyPlaying = () => async (dispatch: Dispatch, getState: () => IState) => {
    const { currentlyPlaying, localQueue } = getState().player;

    if (!currentlyPlaying || !localQueue.length) {
        const firstArticle = localQueue?.[0];
        return firstArticle && getCurrentlyPlayingArticle({ article: { ...firstArticle } })(dispatch, getState);
    }

    const currentlyPlayingArticleIndex = localQueue.findIndex((a) => a.id === currentlyPlaying.id);

    if (currentlyPlayingArticleIndex === -1) {
        const firstArticle = localQueue[0];
        return getCurrentlyPlayingArticle({ article: { ...firstArticle } })(dispatch, getState);
    }

    if (currentlyPlayingArticleIndex <= 0) {
        const lastArticle = localQueue[localQueue.length - 1];
        return getCurrentlyPlayingArticle({ article: { ...lastArticle } })(dispatch, getState);
    }

    const previousArticle = localQueue[currentlyPlayingArticleIndex - 1];
    return getCurrentlyPlayingArticle({ article: { ...previousArticle } })(dispatch, getState);
};

const getCurrentlyPlayingArticleSuccess = (response: any) => ({
    type: CURRENTLY_PLAYING_SUCCESS,
    currentlyPlaying: response.data,
});

const getCurrentlyPlayingArticlePending = () => ({
    type: CURRENTLY_PLAYING_PENDING,
});

const getCurrentlyPlayingArticleRejected = (errorMessage?: string) => {
    if (errorMessage) {
        toast(errorMessage);
    }
    return {
        type: CURRENTLY_PLAYING_REJECTED,
    };
};

const setCurrentlyPlayingArticlePending = () => ({
    type: SET_ARTICLE_TO_CURRENTLY_PLAYING_PENDING,
});

const setCurrentlyPlayingArticleRejected = () => ({
    type: SET_ARTICLE_TO_CURRENTLY_PLAYING_REJECTED,
});

const setCurrentlyPlayingPlaylistPending = () => ({
    type: SET_PLAYLIST_TO_CURRENTLY_PLAYING_PENDING,
});

const setCurrentlyPlayingPlaylistRejected = () => ({
    type: SET_PLAYLIST_TO_CURRENTLY_PLAYING_REJECTED,
});

const setNextArticlePending = () => ({
    type: PLAY_NEXT_QUEUE_ARTICLE_PENDING,
});

const setNextArticleRejected = () => ({
    type: PLAY_NEXT_QUEUE_ARTICLE_REJECTED,
});

const setPrevArticlePending = () => ({
    type: PLAY_PREV_QUEUE_ARTICLE_PENDING,
});

const setPrevArticleRejected = () => ({
    type: PLAY_PREV_QUEUE_ARTICLE_REJECTED,
});
