import config from '../config';

export const isRouteValidForBottomNav = (route: string): boolean => route !== config.routes.articleEmbed;

export const isRouteValidForPlayer = (route: string): boolean =>
    route !== config.routes.articleEmbed &&
    route !== config.routes.signup &&
    route !== config.routes.login &&
    route !== config.routes.resetPasswordInit &&
    route !== config.routes.resetPasswordFinal &&
    route !== config.routes.home;

export const isRouteValidForNavbar = (pathname: string): boolean =>
    pathname !== config.routes.welcome &&
    pathname !== config.routes.success &&
    pathname !== config.routes.loading &&
    pathname !== config.routes.androidPay &&
    pathname !== config.routes.articleEmbed &&
    pathname !== config.routes.landingEmbed &&
    pathname !== config.routes.subscriptionSuccess &&
    pathname !== config.routes.signupSuccess;

export const isRouteStandalone = (pathname: string): boolean =>
    pathname === config.routes.downloadAirport ||
    pathname === config.routes.mobileTerms ||
    pathname === config.routes.mobilePrivacy ||
    pathname === config.routes.mobileAbout ||
    pathname === config.routes.loginAlexa ||
    pathname === config.routes.signupAlexa ||
    pathname === config.routes.welcome ||
    pathname === config.routes.landing ||
    pathname === config.routes.home ||
    pathname === config.routes.download ||
    pathname === config.routes.ourPublishers ||
    pathname === config.routes.ourTeam ||
    pathname === config.routes.ourStory ||
    pathname === config.routes.editorialPrinciples;

export const isRouteValidForListenBalance = (pathname: string): boolean =>
    isRouteValidForBottomNav(pathname) &&
    pathname !== config.routes.subscribe &&
    pathname !== config.routes.completeSubscription &&
    pathname !== config.routes.gift;
