import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconRightArrow extends React.Component<IProps> {
    public render() {
        return (
            <svg width="39" height="19" viewBox="0 0 39 19" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className} onClick={this.props.onClick}>
                <path d="M1 9.5H37.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M29 1L37.5 9.5L29 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default IconRightArrow;
