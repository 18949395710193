import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import IconTickBlue from '../../../assets/icons/IconTickBlue';
import newtheme from '../../../newtheme';

interface IProps {
    text: string;
}

class TickAndText extends React.Component<IProps> {
    public render() {
        return (
            <div className={css(styles.container)}>
                <IconTickBlue className={css(styles.icon)} />
                <p className={css(styles.benefitText)}>{this.props.text}</p>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        [newtheme.cssBreakpoints.large]: {
            fontSize: 22,
        },
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 20,
        },
        [newtheme.cssBreakpoints.small]: {
            fontSize: 18,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            fontSize: 16,
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 24,
    },
    benefitText: {
        margin: 0,
        textAlign: 'start',
    },
    icon: {
        width: 25,
        height: 25,
        minWidth: 25,
        minHeight: 25,
        marginRight: 10,
    },
});

export default TickAndText;
