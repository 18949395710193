import { css, StyleSheet } from 'aphrodite';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router';
import config from '../config';
import newtheme from '../newtheme';
import { get as getLocalItem, set as setLocalItem } from '../utils/storage';
import Button from './buttons/Button';

export const COOKIES_STATUS_KEY = 'consent-gdpr';

const CookiesBanner: React.FC = () => {
    const [showBanner, setShowBanner] = useState(false);
    const { push: redirectTo } = useHistory();

    useEffect(() => {
        const cookiesStatus = getLocalItem(COOKIES_STATUS_KEY);
        if (cookiesStatus !== 'true') {
            setShowBanner(true);
        }
    }, []);

    const keepBrowsing = () => {
        setLocalItem(COOKIES_STATUS_KEY, 'true');
        setShowBanner(false);
    };

    const cookiePolicy = () => {
        redirectTo(config.routes.cookiePolicy);
    };

    if (showBanner) {
        return createPortal(
            <div className={css(styles.banner)}>
                <div className={css(styles.bannerInner)}>
                    <div className={css(styles.textContainer)}>
                        <h1 className={css(styles.heading)}>What do we use cookies for?</h1>
                        <p className={css(styles.text)}>
                            We use cookies to recognize visitors and remember their preferences. We do not use them to
                            personalise advertisements on our site. To learn more about Noa{"'"}s use of cookies,
                            including how to disable non-essential cookies, visit our Cookie Policy page at the bottom
                            of our website.
                            <br />
                            <br />
                            By tapping {"'"}Accept Cookies{"'"}, you consent to the use of these methods by us and third
                            parties. You can always change your preferences by visiting our Cookie Policy page.
                        </p>
                    </div>
                    <div className={css(styles.buttons)}>
                        <Button className={css(styles.button)} onClick={keepBrowsing}>
                            Accept Cookies
                        </Button>
                        <Button className={css(styles.button)} onClick={cookiePolicy}>
                            Manage Cookies
                        </Button>
                    </div>
                </div>
            </div>,
            document.getElementById('cookies-banner')!,
        );
    }
    return null;
};

const styles = StyleSheet.create({
    banner: {
        [newtheme.cssBreakpoints.medium]: {
            padding: '35px',
        },
        [newtheme.cssBreakpoints.small]: {
            padding: '35px 14px',
        },
        padding: '35px 70px',
        width: '100%',
        backgroundColor: newtheme.darkmodeColors.white,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        zIndex: 10000,
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    },

    bannerInner: {
        [newtheme.cssBreakpoints.small]: {
            flexDirection: 'column',
        },
        width: '100%',
        maxWidth: '1377px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    heading: {
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: '16px',
            marginBottom: 8,
        },

        fontSize: '21px',
        fontWeight: 500,
        color: '#000000',
        marginBottom: 15,
    },
    textContainer: {
        maxWidth: '875px',
    },

    text: {
        [newtheme.cssBreakpoints.small]: {
            marginBottom: 26,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 13,
            marginBottom: 19,
        },

        color: '#000000',
        fontSize: '16px',
        textAlign: 'start',
        width: '100%',
        margin: 0,
    },

    buttons: {
        [newtheme.cssBreakpoints.small]: {
            marginLeft: 0,
            flexDirection: 'row',
        },

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 30,
        width: '100%',
    },

    button: {
        [newtheme.cssBreakpoints.small]: {
            width: '100%',
            margin: 0,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 12,
            height: 37,
        },

        ':last-child': {
            [newtheme.cssBreakpoints.small]: {
                marginLeft: '15px',
            },

            [newtheme.cssBreakpoints.tiny]: {
                marginLeft: '10px',
            },

            border: '1px solid #79747E',
            background: 'none',
            color: newtheme.colors.primaryBlue,
        },

        width: '255px',
        fontSize: '15px',
        borderRadius: '10px',
        height: 42,
        margin: '10px 0px',
    },
});

export default CookiesBanner;
