import * as React from 'react';

interface IProps {
    className?: string;
}

class ErrorCrossRoundedIcon extends React.Component<IProps> {
    public render() {
        return (
            <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.485 7.929L11.607 5.808C11.7946 5.62036 11.9001 5.36586 11.9001 5.1005C11.9001 4.83514 11.7946 4.58064 11.607 4.393C11.4194 4.20536 11.1649 4.09994 10.8995 4.09994C10.6341 4.09994 10.3796 4.20536 10.192 4.393L8.072 6.515L5.95 4.393C5.76236 4.20549 5.50792 4.1002 5.24265 4.1003C4.97738 4.10039 4.72301 4.20586 4.5355 4.3935C4.34799 4.58114 4.2427 4.83558 4.2428 5.10085C4.24289 5.36612 4.34836 5.62049 4.536 5.808L6.656 7.928L4.536 10.05C4.35384 10.2386 4.25305 10.4912 4.25533 10.7534C4.2576 11.0156 4.36277 11.2664 4.54818 11.4518C4.73359 11.6372 4.9844 11.7424 5.2466 11.7447C5.5088 11.747 5.7614 11.6462 5.95 11.464L8.071 9.344L10.192 11.464C10.2848 11.5569 10.3951 11.6306 10.5164 11.6809C10.6377 11.7312 10.7678 11.7572 10.8991 11.7572C11.0305 11.7572 11.1606 11.7314 11.2819 11.6812C11.4033 11.631 11.5136 11.5573 11.6065 11.4645C11.6994 11.3717 11.7731 11.2614 11.8234 11.1401C11.8737 11.0188 11.8997 10.8887 11.8997 10.7574C11.8997 10.626 11.8739 10.4959 11.8237 10.3746C11.7735 10.2532 11.6998 10.1429 11.607 10.05L9.485 7.929ZM8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16Z"
                    fill="#DE350B"
                />
            </svg>
        );
    }
}

export default ErrorCrossRoundedIcon;
