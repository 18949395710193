import { Dispatch } from 'redux';

import config from '../config';
import {
    ADVERT_FAILURE,
    ADVERT_REQUEST,
    ADVERT_SUCCESS,
    ADVERT_CLEAR,
} from '../constants/advert';
import { IState } from '../reducers';
import fetch from '../utils/fetch';
import responseCheck from '../utils/response-check';

export const clearAdvert = () => (dispatch: Dispatch) => dispatch({ type: ADVERT_CLEAR });

export const getAdvert = () => (dispatch: Dispatch, getState: () => IState) => {
    dispatch({ type: ADVERT_REQUEST });

    return fetch(getState)(`${config.api.url}${config.api.routes.adverts}`)
        .then(responseCheck)
        .then((response) => dispatch(advertSuccess(response)))
        .catch((error) => dispatch(advertFailure(error)));
};

const advertSuccess = (response: any) => ({ type: ADVERT_SUCCESS, advert: response.data.advert });

const advertFailure = (error: Error) => ({ type: ADVERT_FAILURE, error });
