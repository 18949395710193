import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';

const LoadableCollections = loadable(() => import('../containers/CollectionPage'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadCollections extends React.Component {
    render() {
        return <LoadableCollections />;
    }
}

export default LazyLoadCollections;
