import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { css, StyleSheet } from 'aphrodite';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { IState } from '../reducers';
import { openModal, closeModal } from '../actions/modal';
import SocialSign from './SocialSign';
import Input from '../components/Input';
import text from '../locale';
import Button from '../components/buttons/Button';
import { isEmailValid } from '../utils/text';
import BaseModal from '../components/modals/newModals/BaseModal';
import theme from '../theme';
import config from '../config';
import { login, forgotPassword, signup, getUser } from '../domaindriven/auth/state/actions/user';
import { SIGNUP_SUCCESS, LOGIN_SUCCESS } from '../domaindriven/auth/state/constants/user';
import { event, EventCategories, EventActions, EventLabels } from '../utils/metric';
import IUser from '../types/models/IUser';
import IconClose from '../assets/icons/IconClose';
import newtheme from '../newtheme';
import { isServer } from '../../store';
import { mpTrackPopupView } from '../tracking/mixpanel';
import { mpTrackingPopups } from '../config/popups';
import ISubscriptionProduct from '../domaindriven/subscribe/types/ISubscriptionProduct';
import { getSelectedSubscriptionProduct } from '../domaindriven/subscribe/state/selectors';
import IconTickBlue from '../assets/icons/IconTickBlue';

interface ILocalProps {
    showLoginFirst: boolean;
    open: boolean;
    close: any;
    dontRedirect?: boolean;
    redirectToOverride?: string;
}

interface IPropsFromState {
    open: boolean;
    user?: IUser;
    subscriptionProduct?: ISubscriptionProduct;
    loading: boolean;
    error?: Error;
}

interface IPropsFromDispatch {
    login: typeof login;
    signup: typeof signup;
    forgotPassword: typeof forgotPassword;
    getUser: typeof getUser;
}

interface ILocalState {
    name: string;
    email: string;
    password: string;
    showLogin: boolean;
    showManualAuthFields: boolean;
    isInvalidName: boolean;
    isInvalidEmail: boolean;
    isInvalidPassword: boolean;
    isPasswordVisible: boolean;
}

class AuthModalNew extends React.Component<
    ILocalProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps,
    ILocalState
> {
    constructor(props: ILocalProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps) {
        super(props);

        this.state = {
            name: '',
            email: '',
            password: '',
            showLogin: props.showLoginFirst,
            showManualAuthFields: false,
            isInvalidName: false,
            isInvalidEmail: false,
            isInvalidPassword: false,
            isPasswordVisible: false,
        };
    }

    public togglePasswordVisibility = () => {
        this.setState((prev) => ({
            isPasswordVisible: !prev.isPasswordVisible,
        }));
    };

    public componentDidMount = () => {
        mpTrackPopupView(mpTrackingPopups.authenticate);
    };

    public switchAuthMode = () => {
        this.setState((prevState) => ({ showLogin: !prevState.showLogin }));
    };

    public close = () => {
        this.props.close();
    };

    public submitForm = () => {
        if (this.state.showLogin) {
            this.login();
        } else {
            this.signup();
        }
    };

    public signup = () => {
        const { name, email, password } = this.state;

        if (!name.trim().length) {
            return this.setState({ isInvalidName: true });
        }

        if (!isEmailValid(email)) {
            return this.setState({ isInvalidEmail: true });
        }

        if (password.trim().length < 6) {
            return this.setState({ isInvalidPassword: true });
        }

        this.props
            .signup({
                name,
                email,
                password,
            })
            // @ts-ignore
            .then((resp) => {
                if (resp && resp.type === SIGNUP_SUCCESS) {
                    this.props.getUser();
                    this.close();
                    this.maybeRedirectAfterAuth();
                }
            });
    };

    public login = () => {
        const { email, password } = this.state;

        if (!isEmailValid(email)) {
            return this.setState({ isInvalidEmail: true });
        }

        if (password.trim().length < 6) {
            return this.setState({ isInvalidPassword: true });
        }

        this.props
            .login({
                email,
                password,
            })
            // @ts-ignore
            .then((resp) => {
                if (resp && resp.type === LOGIN_SUCCESS) {
                    event(EventCategories.SIGN, EventActions.SUCCESS, EventLabels.LOGIN);
                    this.props.getUser();
                    this.close();
                    this.maybeRedirectAfterAuth();
                }
            });
    };

    public maybeRedirectAfterAuth = () => {
        const { subscriptionProduct, dontRedirect, redirectToOverride, history } = this.props;

        if (this.props.location.pathname !== config.routes.home) {
            return;
        }

        if (dontRedirect) {
            return;
        }

        if (redirectToOverride) {
            history.push(redirectToOverride);
            return;
        }

        if (subscriptionProduct && subscriptionProduct.metadata.homePage.redirectToPage) {
            history.push(subscriptionProduct.metadata.homePage.redirectToPage);
            return;
        }

        history.push(config.routes.homeWebPlayer);
    };

    public onSocialSign = () => {
        this.close();
        this.maybeRedirectAfterAuth();
    };

    public onChangeName = (value: string) => {
        this.setState({ name: value });
    };

    public onChangeEmail = (value: string) => {
        this.setState({ email: value });
    };

    public onChangePassword = (value: string) => {
        this.setState({ password: value });
    };

    public onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
    };

    public showManualSignupFields = () => {
        this.setState({ showManualAuthFields: true });
    };

    public renderSignupHeader = () => (
        <div className={css(rightStyles.header)}>
            <h1 className={css(rightStyles.title)}>
                To listen 🎧
                <br />
                Create a Noa account.
            </h1>
            <p className={css(rightStyles.desc)}>Why sign up?</p>
            <div className={css(rightStyles.reasonToSignUp)}>
                <IconTickBlue className={css(rightStyles.icon)} />
                <p className={css(rightStyles.desc)}>
                    <span className={css(rightStyles.bold)}>Save time.</span>
                    Listen when you don’t have time to read.
                </p>
            </div>
            <div className={css(rightStyles.reasonToSignUp)}>
                <IconTickBlue className={css(rightStyles.icon)} />
                <p className={css(rightStyles.desc)}>
                    <span className={css(rightStyles.bold)}>Convenience.</span>
                    Anytime, anywhere. Access Noa on mobile, web and in-car.
                </p>
            </div>
        </div>
    );

    public renderLoginHeader = () => (
        <div className={css(rightStyles.header)}>
            <h1 className={css(rightStyles.title)}>Log in</h1>
            <p className={css(rightStyles.desc)}>Log in to begin listening</p>
        </div>
    );

    public renderSignupButtons = () => (
        <>
            <SocialSign onSignIn={this.onSocialSign} orientation="column" />
            <Button
                className={css(rightStyles.continueWithMail)}
                onClick={this.showManualSignupFields}
                backgroundColor={newtheme.colors.facebookPrimary}
                borderRadius={10}>
                <span>
                    <FontAwesomeIcon icon={faEnvelope} />
                    &nbsp;&nbsp; Continue with Mail
                </span>
            </Button>
        </>
    );

    public renderManualAuthFields = () => {
        const { name, email, password, showLogin } = this.state;

        return (
            <form onSubmit={this.onSubmit} className={css(rightStyles.form)}>
                {!this.state.showLogin && (
                    <>
                        <Input
                            id="signup-name"
                            type="text"
                            placeholder={text.signupModal.NAME}
                            onChange={this.onChangeName}
                            value={name}
                            onFocus={() =>
                                this.setState({
                                    isInvalidName: false,
                                })
                            }
                        />
                        {this.state.isInvalidName && <p className={css(rightStyles.invalidText)}>Name is required</p>}
                    </>
                )}
                <Input
                    id="signup-email"
                    type="email"
                    placeholder={text.signupModal.EMAIL}
                    onChange={this.onChangeEmail}
                    value={email}
                    onFocus={() =>
                        this.setState({
                            isInvalidEmail: false,
                        })
                    }
                />
                {this.state.isInvalidEmail && (
                    <p className={css(rightStyles.invalidText)}>Please use a valid email address</p>
                )}
                <div className={css(rightStyles.passwordWrapper)}>
                    <Input
                        id="signup-password"
                        type={this.state.isPasswordVisible ? 'text' : 'password'}
                        styles={[rightStyles.passwordInput]}
                        placeholder={text.signupModal.PASSWORD}
                        onChange={this.onChangePassword}
                        value={password}
                        onFocus={() =>
                            this.setState({
                                isInvalidPassword: false,
                            })
                        }
                    />
                    <button
                        className={css(rightStyles.passwordEyeButton)}
                        onClick={this.togglePasswordVisibility}
                        type="button">
                        <img
                            className={css(rightStyles.passwordEyeButtonImage)}
                            src={this.state.isPasswordVisible ? '/EyeClosedDark.png' : '/EyeOpenDark.png'}
                            alt="show-password"
                        />
                    </button>
                </div>
                {this.state.isInvalidPassword && (
                    <p className={css(rightStyles.invalidText)}>Your password must be at least 6 characters long</p>
                )}

                <Button
                    borderRadius={10}
                    className={css(rightStyles.signupButton)}
                    text={showLogin ? 'Log in and start listening' : 'Sign up and start listening'}
                    onClick={this.submitForm}
                    disabled={this.props.loading}
                    submit
                />
                {this.props.error && (
                    <p className={css([rightStyles.invalidText, rightStyles.invalidRequestText])}>
                        Oops, Please check your email & password and try again
                    </p>
                )}

                {showLogin && (
                    <Link
                        onClick={this.close}
                        to={config.routes.resetPasswordInit}
                        className={css(rightStyles.link, rightStyles.forgotPasswordLink)}>
                        Forgot your password?
                    </Link>
                )}
            </form>
        );
    };

    public render() {
        const { open } = this.props;
        const { showLogin, showManualAuthFields } = this.state;

        if (isServer) {
            return <></>;
        }

        return (
            <BaseModal
                modalId="ModalSignup"
                isOpen={open}
                onClose={this.close}
                title="Signup"
                height="EXTRA_LARGE"
                scrollable={false}
                fullScreenMobile>
                <div className={css(styles.modalWrapper)}>
                    {/* Right styles */}
                    <div className={css(rightStyles.main)}>
                        <div className={css(rightStyles.closeIconWrapper)} onClick={this.close}>
                            <IconClose className={css(rightStyles.closeIcon)} />
                        </div>

                        {showLogin ? this.renderLoginHeader() : this.renderSignupHeader()}

                        {showManualAuthFields ? this.renderManualAuthFields() : this.renderSignupButtons()}

                        <div>
                            <div className={css(rightStyles.terms)}>
                                <span>
                                    By continuing, you indicate that you have read and agree to Noa&apos;s&nbsp;
                                    <Link to={config.routes.terms} target="_blank">
                                        Terms of Use
                                    </Link>
                                    &nbsp;and&nbsp;
                                    <Link to={config.routes.privacy} target="_blank">
                                        Privacy Policy
                                    </Link>
                                    .
                                </span>
                            </div>

                            <div className={css(rightStyles.links)}>
                                {showLogin ? (
                                    <p>
                                        Don&apos;t have an account?
                                        <a className={css(rightStyles.link)} onClick={this.switchAuthMode}>
                                            Sign up
                                        </a>
                                    </p>
                                ) : (
                                    <p>
                                        Already have an account?
                                        <a className={css(rightStyles.link)} onClick={this.switchAuthMode}>
                                            Log in
                                        </a>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </BaseModal>
        );
    }
}

const rightStyles = StyleSheet.create({
    main: {
        [newtheme.cssBreakpoints.large]: {
            padding: 40,
        },
        [newtheme.cssBreakpoints.medium]: {
            padding: '40px 30px',
        },
        [newtheme.cssHeightBreakpoints.large]: {
            padding: '40px 30px',
        },
        [newtheme.cssHeightBreakpoints.medium]: {
            padding: '20px 30px',
        },
        [newtheme.cssBreakpoints.small]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        [newtheme.cssBreakpoints.tiny]: {
            padding: 20,
            paddingTop: 5,
            paddingBottom: 30,
        },
        [newtheme.cssBreakpoints.smallerThanTiny]: {
            padding: 10,
        },
        height: '100%',
        backgroundColor: 'white',
        width: 540,
        padding: '60px 40px',
    },
    header: {
        marginBottom: 20,
    },
    title: {
        fontSize: 30,
        [newtheme.cssHeightBreakpoints.large]: {
            fontSize: 24,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 27,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 24,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 20,
        },
        width: '100%',
        textAlign: 'left',
        marginBottom: 15,
        fontWeight: 600,
    },
    desc: {
        fontSize: 16,
        [newtheme.cssHeightBreakpoints.large]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 15,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 14,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
        },
        width: '100%',
        textAlign: 'left',
        marginBottom: 10,
        color: '#06032F',
        whiteSpace: 'pre-wrap',
    },
    reasonToSignUp: {
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        height: 45,
        width: 45,
        marginRight: 10,
    },
    bold: {
        fontWeight: 700,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
    },
    continueWithMail: {
        [newtheme.cssHeightBreakpoints.large]: {
            height: 40,
            minHeight: 40,
            fontSize: 14,
            marginBottom: 10,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 50,
            minHeight: 50,
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            height: 40,
            minHeight: 40,
            fontSize: 13,
        },
        fontFamily: 'Poppins',
        height: 55,
        minHeight: 55,
        borderRadius: 10,
        fontWeight: 'normal',
        fontSize: 18,
        marginBottom: 20,
        width: '100%',
        backgroundColor: newtheme.colors.primaryBlue,
    },
    signupButton: {
        [newtheme.cssHeightBreakpoints.large]: {
            height: 45,
            minHeight: 45,
            fontSize: 15,
            borderRadius: 5,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            marginTop: 10,
            width: '100%',
            minWidth: '100%',
            height: 55,
            minHeight: 55,
            fontSize: 16,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            height: 40,
            minHeight: 40,
            fontSize: 13,
        },
        width: '100%',
        minWidth: '100%',
        marginTop: 10,
        height: 55,
        minHeight: 55,
        fontSize: 18,
        fontWeight: 500,
        alignSelf: 'flex-start',
        borderRadius: 10,
    },
    terms: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 12,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 10,
        },
        fontSize: 13,
        textAlign: 'center',
        color: theme.textColor,
        marginBottom: 20,
    },
    links: {
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 16,
        },
        fontSize: 18,
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    link: {
        color: theme.linkColor,
        cursor: 'pointer',
        textAlign: 'left',
    },
    forgotPasswordLink: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 12,
        },
        fontSize: 13,
        marginTop: 10,
    },
    hr: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            display: 'none',
        },
    },
    closeIconWrapper: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        right: 0,
        width: 100,
        height: 70,
    },
    closeIcon: {
        position: 'absolute',
        top: 18,
        right: 25,
        width: 16,
        height: 16,
        cursor: 'pointer',
    },

    passwordWrapper: {
        position: 'relative',
        width: '100%',
    },

    passwordEyeButton: {
        position: 'absolute',
        top: '40%',
        transform: 'translateY(-60%)',
        right: '10px',
        background: 'none',
        padding: '0',
        margin: '0',
        border: 'none',
        outline: 'none',
    },

    passwordEyeButtonImage: {
        width: '30px',
    },

    passwordInput: {
        paddingRight: '46px !important',
    },
    invalidText: {
        marginTop: '-12px',
        marginLeft: '6px',
        fontSize: '12px',
        textAlign: 'start',
        alignSelf: 'flex-start',
        color: newtheme.colors.googleRed,
    },
    invalidRequestText: {
        marginTop: '10px !important',
        textAlign: 'center',
        marginBottom: '10px',
        alignSelf: 'center',
    },
});

const styles = StyleSheet.create({
    modalWrapper: {
        [newtheme.cssBreakpoints.small]: {
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [newtheme.cssBreakpoints.tiny]: {
            padding: '30px 20px',
        },
        maxWidth: '100%',
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'row',
    },
});

function mapStateToProps(state: IState, ownProps: ILocalProps): IPropsFromState {
    return {
        loading: state.user.loading,
        error: state.user.error,
        open: ownProps.open,
        user: state.user ? state.user.user : undefined,
        subscriptionProduct: getSelectedSubscriptionProduct(state),
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            openModal,
            login,
            forgotPassword,
            signup,
            getUser,
            closeModal,
        },
        dispatch,
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthModalNew));
