import {
    FOLLOW_PROFILE_SUCCESS, GET_PROFILE_FAILURE, GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, UNFOLLOW_PROFILE_SUCCESS,
} from '../constants/profile';
import IUserProfile from '../domaindriven/profiles/types/IUserProfile';

export interface IState {
    profile?: IUserProfile;
    loading: boolean;
    error?: any;
}

interface IAction {
    type: string;
    error?: Error;
    profile?: IUserProfile;
}

export const initialState: IState = {
    loading: false,
    profile: undefined,
    error: null,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_PROFILE_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                profile: action.profile,
                loading: false,
            };
        case GET_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case FOLLOW_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.profile,
            };
        case UNFOLLOW_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                profile: action.profile,
            };

        default:
            return state;
    }
};
