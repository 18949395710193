import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableSearch = loadable(() => import('../component/Search'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadSearch extends React.Component {
    render() {
        return (
            <Page noCrawl={false} title="Search" trackingScreen={mpTrackingScreens.search}>
                <LoadableSearch />
            </Page>
        );
    }
}

export default LazyLoadSearch;
