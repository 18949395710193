import loadable from '@loadable/component';
import * as React from 'react';

import LoadingPage from '../../../components/LoadingPage';
import Page from '../../../components/Page';
import { mpTrackingScreens } from '../../../config/screens';

const LoadableFaq = loadable(() => import('../components/FaqPage'), {
    fallback: <LoadingPage isDark />,
});

class LazyLoadFaq extends React.Component {
    render() {
        return (
            <Page noCrawl={false} title="FAQ" trackingScreen={mpTrackingScreens.faq}>
                <LoadableFaq />
            </Page>
        );
    }
}

export default LazyLoadFaq;
