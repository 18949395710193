const common = {
    domain: 'localhost',
    toggle: {
        loginPopup: false,
    },
    publishers: {
        22: {
            name: 'Financial Times',
            shortName: 'FT',
            guestPassBannerText: 'All FT readers get a free Premium pass.',
            guestPassBoxText1: 'All FT readers get',
            guestPassBoxText2: '1-day free',
            color: '#337E81',
            currencyCode: 'GBP',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        32: {
            name: 'The Independent',
            shortName: 'Indy',
            guestPassBannerText: 'Indy readers get a free Premium pass.',
            guestPassBoxText1: 'All Indy readers get',
            guestPassBoxText2: '1-day free',
            color: '#337E81',
            currencyCode: 'GBP',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        47: {
            name: 'The Irish Times',
            shortName: 'Irish Times',
            guestPassBannerText: 'Irish Times readers get a free Premium pass.',
            guestPassBoxText1: 'All Irish Times readers',
            guestPassBoxText2: 'get 1-day free',
            color: '#793113',
            currencyCode: 'EUR',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        67: {
            name: 'Evening Standard',
            shortName: 'ES',
            guestPassBannerText: 'Evening Standard readers get a free Premium pass.',
            guestPassBoxText1: 'All Evening Standard',
            guestPassBoxText2: 'readers get 1-day free',
            color: '#dc062b',
            currencyCode: 'GBP',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        73: {
            name: 'Business Insider',
            shortName: 'BI',
            guestPassBannerText: 'Business Insider readers get a free Premium pass.',
            guestPassBoxText1: 'All Business Insider',
            guestPassBoxText2: 'readers get 1-day free',
            color: '#456777',
            currencyCode: 'USD',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        76: {
            name: 'Law Society Gazette',
            shortName: 'LSG',
            guestPassBannerText: 'All LSG readers get a free Premium pass.',
            guestPassBoxText1: 'All LSG readers get',
            guestPassBoxText2: '1-day free',
            color: '#b2131b',
            currencyCode: 'EUR',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        79: {
            name: 'Harvard Business Review',
            shortName: 'HBR',
            guestPassBannerText: 'All HBR readers get a free Premium pass.',
            guestPassBoxText1: 'All HBR readers',
            guestPassBoxText2: 'get 1-day free',
            color: '#c82502',
            currencyCode: 'USD',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        87: {
            name: 'The Telegraph',
            shortName: 'Telegraph',
            guestPassBannerText: 'Telegraph readers get a free Premium pass.',
            guestPassBoxText1: 'All Telegraph readers',
            guestPassBoxText2: 'get 1-day free',
            color: '#04777b',
            currencyCode: 'GBP',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
        97: {
            name: 'The TLS',
            shortName: 'TLS',
            guestPassBannerText: 'All TLS readers get a free Premium pass.',
            guestPassBoxText1: 'All TLS readers',
            guestPassBoxText2: 'get 1-day free',
            color: '#04777b',
            currencyCode: 'GBP',
            offerPage: '/subscribe?planId=noa_premium_coronavirus_offer',
        },
    },
    site: {
        url: 'https://newsoveraudio.com',
        facebookAppId: 'newsoveraudio',
        title: 'Noa | News Over Audio',
        description:
            'Understand the news with audio articles from The NYTimes, FT, Washington Post, Harvard Business Review & more. Become ‘Warren Buffett smart’ with our learning-focused Series, covering hundreds of timely topics. Get 30-days free or listen to a selection of articles each week.',
        twitter: '@newsoveraudio',
        sep: ' | ',
        defaultImage: 'https://www.newsoveraudio.com/assets/images/logos/noa_logo.png',
        feedbackEmail: 'lucas@noanews.ie',
        contentFeedbackEmail: 'lucas@noanews.ie',
    },
    externalRoutes: {
        instagramNoa: 'https://www.instagram.com/newsoveraudio/',
        linkedInNoa: 'https://www.linkedin.com/company/11100533/',
        twitterNoa: 'https://twitter.com/newsoveraudio',
        facebookNoa: 'https://www.facebook.com/newsoveraudio/',
    },
    routes: {
        publisher: {
            nyt: '/publishers/the-new-york-times-449201',
            ft: '/publishers/financial-times-230167',
            washPost: '/publishers/the-washington-post-884681',
            telegraph: '/publishers/the-telegraph-878413',
            hbr: '/publishers/harvard-business-review-654138',
            economist: '/publishers/the-economist-631624',
            indoUK: '/publishers/the-independent-509293',
            it: '/publishers/the-irish-times-7775',
        },
        redirects: [
            {
                from: '/hacks',
                to: '/?offerId=hacks',
            },
            {
                from: '/david',
                to: '/?offerId=david',
            },
            {
                from: '/bagel',
                to: '/subscribe?offerId=plain_bagel_offer_91lpv_afl',
            },
            {
                from: '/patrickboyle',
                to: '/subscribe?offerId=patrick_boyle_offer_814ca_afl',
            },
            {
                from: '/pensioncraft',
                to: '/subscribe?offerId=pensioncraft_offer_07hkv_afl',
            },
            {
                from: '/afzal',
                to: '/subscribe?offerId=azfal_hussein_offer_542fg_afl',
            },
            {
                from: '/generalknowledge',
                to: '/subscribe?offerId=general_knowledge_offer_16ohx_afl',
            },
            {
                from: '/mediatribe',
                to: '/subscribe?offerId=media_tribe_offer_05kgd_afl',
            },
            {
                from: '/rebooting',
                to: '/subscribe?offerId=the_rebooting_offer_46kfz_afl',
            },
            {
                from: '/shanmugan',
                to: '/subscribe?offerId=prashanth_shanmugan_01',
            },
        ],
        home: '/',
        landing: '/landing',
        landingEmbed: '/landing-embed',
        homeWebPlayer: '/home',
        enterprise: '/enterprise',
        latest: '/latest',
        search: '/search',
        articles: '/articles',
        playlists: '/playlists',
        sections: '/sections',
        journalists: '/journalists',
        publishers: '/publishers',
        collections: '/collections',
        profiles: '/profiles',
        myLibrary: '/library',
        favourites: '/library/favourites',
        played: '/library/played',
        articleEmbed: '/embed/article',
        contact: '/contact',
        about: '/about',
        mobileAbout: '/about-mobile',
        faq: '/faq',
        ourTeam: '/team',
        ourStory: '/our-story',
        editorialPrinciples: '/editorial-principles',
        ourPublishers: '/our-publishers',
        terms: '/terms',
        privacy: '/privacy',
        cookiePolicy: '/cookie-policy',
        mobileTerms: '/mobile-terms',
        mobilePrivacy: '/mobile-privacy',
        myAccount: '/my_account',
        deleteAccount: '/delete-account',
        cannotDeleteAccount: '/cannot-delete-account',
        successfulDeleteAccount: '/successful-delete-account',
        subscribe: '/subscribe',
        completeSubscription: '/complete-subscription',
        subscriptionSuccess: '/subscription-success',
        signupSuccess: '/signup-success',
        subscribeDefaultOffer: '/subscribe',
        subscribeStudentOffer: '/subscribe?offerId=noa_premium_student_gb2cd',
        androidPay: '/android-pay',
        welcome: '/welcome',
        signup: '/signup',
        login: '/login',
        loginAlexa: '/pages/login',
        signupAlexa: '/pages/signup',
        resetPasswordInit: '/reset-password-init',
        resetPasswordFinal: '/reset_password',
        employerSubscriptionCheck: '/employer-subscription-check',
        success: '/success',
        loading: '/loading',
        download: '/download',
        downloadAirport: '/dublinAirport',
        gift: '/gift',
        careerEditor: '/careers/editor',
        careerAssocBusinessEditor: '/careers/associate-business-editor',
        careerVpMarketing: '/careers/vp-marketing',
        careerSeniorsBackendEngineer: '/careers/senior-backend-engineer',
        careerMobileDeveloper: '/careers/mobile-developer',
        careerProducerEconomics: '/careers/producer-business-economics',
    },
    api: {
        url: 'http://localhost:3001',
        routes: {
            search: '/v2/discovery/search',
            articles: '/v3/articles',
            sections: '/v3/sections',
            sectionsV4: '/v4/sections',
            playlists: '/v1/playlist',
            journalists: '/v2/journalists',
            publishers: '/v2/publishers',
            collections: '/v1/collections',
            profiles: '/v4/profiles',
            users: '/v3/users/me',
            queue: '/v1/users/me/queue',
            landing: '/v1/website/landing',
            accountDetailsV4: '/v4/users/me/account',
            usersV4: '/v4/users/me',
            usersV5: '/v5/users/me',
            subscription: {
                product: '/v3/subscriptions/stripe/product',
                discount: '/v2/subscriptions/stripe/discount',
                subscribe: '/v1/subscriptions/stripe/subscription',
                cancel: '/v1/subscriptions/stripe/subscription/cancel',
            },
            companyAccess: '/v1/company/access',
            gift: '/v1/gift',
            giftLink: '/v1/giftlink',
            adverts: '/v2/adverts',
            userArticleHistory: '/v1/history/articles',
            userAdvertHistory: '/v1/history/adverts',
            recommendation: '/v2/discovery/recommendation',
            articlePlayer: '/v1/player/article',
            login: '/auth/noa_login',
            signup: '/auth/noa_register',
            forgotPassword: '/auth/request_pass_reset',
            resetPassword: '/auth/pass_reset',
            socialLogin: '/auth/sso_login',
            linkedInAuth: '/v1/auth/linkedin',
            specialaccess: '/v1/specialaccess',
            infoClient: '/v1/info/client',
            codes: '/v1/codes',
            smsDownload: '/v1/sms/download-app',
            company: '/v1/company',
        },
    },
    playerAds: {
        image: '/noa.png',
        audio: '/noa.mp3',
    },
    embed: {
        allowedUrls: [
            'lucas-noa.github.io/',
            'hbr.org/',
            'independent.co.uk/',
            'telegraph.co.uk/',
            'lawsociety.ie/',
            'lawsociety.co.uk/',
            'lawsociety.com/',
            'businessinsider.com/',
            'businessinsider.co.uk/',
            'standard.co.uk/',
            'insider.com/',

            'insider.co.uk/',
            'irishtimes.com/',
            'economist.com/',
            'nytimes.com/',
            'theguardian.com/',
            'washingtonpost.com/',
            'bloomberg.com/',
            'ft.com/',
        ],
        specialUrls: ['hbr.org/'],
        publishers: {
            hbr: 'uir3z2',
        },
    },
    facebook: {
        appId: '182559745744629',
        methodTypeId: 3,
    },
    linkedIn: {
        appId: '78zge6rdqsztcx',
        methodTypeId: 5,
    },
    google: {
        appId: '609323233739-nod26tmek1kpdfmult8l4mtbe4hmd9tt.apps.googleusercontent.com',
        methodTypeId: 2,
    },
    apple: {
        appId: 'com.newsoveraudio.signin',
        methodTypeId: 4,
        redirectUrl: 'https://newsoveraudio.com/',
    },
    stripe: {
        key: 'pk_test_rSTWswOFs68KbeeixxDUcP1i',
    },
    codes: {
        dublinAirport: 'dublin_airport',
    },
    smsMode: {
        enterprise: 'enterprise',
        daa: 'daa',
        regular: 'regular',
    },
    countToAd: 3,
    timeCountToAd: 20,
    timeBetweenWatermark: 40,
    limitArticles: 10,
    listenTimeout: 30,
    minHistoryAudioLength: 3,
    ravenUrl: 'https://cf1aa5c0c367481388d7ef8b6819fe03@sentry.io/1369615',
    sentryDSN: 'https://abbe5e964ca74898850851a78ab529dc@sentry.io/1473767',
    downloadAppLink: 'http://bit.ly/noa-download',
    androidAppLink: 'https://play.google.com/store/apps/details?id=com.newsoveraudio.noa',
    iosAppLink: 'https://itunes.apple.com/ie/app/noa-journalism-narrated/id1135037186?mt=8',
    iosSupportAirplay: 'https://support.apple.com/en-gb/HT202809',
    iosSupportApplePay: 'https://support.apple.com/en-gb/HT201266',
    andoridSupportGooglePay: 'https://support.google.com/googleplay/answer/4646404?co=GENIE.Platform%3DAndroid&hl=en',
};

const development = {
    ...common,
    apple: {
        ...common.apple,
        redirectUrl: 'https://webapp-dev.newsoveraudio.com/',
    },
    domain: '.newsoveraudio.com',
    site: {
        ...common.site,
        url: 'https://webapp-dev.newsoveraudio.com',
    },
    api: {
        ...common.api,
        url: 'https://app-server-dev.newsoveraudio.com',
    },
};

const staging = {
    ...common,
    domain: '.newsoveraudio.com',
    api: {
        ...common.api,
        url: 'https://app-server-staging.newsoveraudio.com',
    },
    site: {
        ...common.site,
        url: 'https://webapp-staging.newsoveraudio.com',
    },
    stripe: {
        ...common.stripe,
        key: 'pk_live_PxMPTvLK0rPeMfOlYCM0eeUn',
    },
};

const production = {
    ...common,
    domain: '.newsoveraudio.com',
    api: {
        ...common.api,
        url: 'https://api.newsoveraudio.com',
    },
    site: {
        ...common.site,
        feedbackEmail: 'feedback@newsoveraudio.com',
        contentFeedbackEmail: 'content-feedback@newsoveraudio.com',
    },
    stripe: {
        ...common.stripe,
        key: 'pk_live_PxMPTvLK0rPeMfOlYCM0eeUn',
    },
};

// eslint-disable-next-line import/no-mutable-exports
let config = common;
const env = process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV;

if (env === 'production') {
    config = production;
} else if (env === 'staging') {
    config = staging;
} else if (env === 'development') {
    config = development;
} else if (env === 'local') {
    config = common;
}

export default config;
