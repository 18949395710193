import * as React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { css, StyleSheet } from 'aphrodite';
import jwtDecode from 'jwt-decode';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleSignin from 'react-apple-signin-auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin, faApple } from '@fortawesome/free-brands-svg-icons';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import Button from '../components/buttons/Button';
import theme from '../theme/index';
import config from '../config/index';
import { IState } from '../reducers';
import Loading from '../components/Loading';
import IUser from '../types/models/IUser';
import { socialLogin, getUser } from '../domaindriven/auth/state/actions/user';
import text from '../locale';
import { getFirstName } from '../utils/user';
import { LOGIN_SUCCESS } from '../domaindriven/auth/state/constants/user';
import newtheme from '../newtheme';
import IGiftLink from '../types/models/IGiftLink';
import GoogleLoginButton from '../domaindriven/auth/components/GoogleLoginButton';

interface IProps {
    orientation?: 'column' | 'row';
    onSignIn?: (response: any) => void;
    mode?: 'signup' | 'login' | 'generic';
    guestPassDays?: number;
    lightAppleButton?: boolean;
}

interface IPropsFromState {
    loading: boolean;
    user?: IUser;
    giftLink?: IGiftLink;
}

interface IPropsFromDispatch {
    socialLogin: typeof socialLogin;
    getUser: typeof getUser;
}

class SocialSign extends React.Component<IProps & IPropsFromState & IPropsFromDispatch> {
    public onLinkedInAuthCodeRetrieved = (code: string, redirectUri: string) => {
        const { onSignIn, guestPassDays, giftLink } = this.props;

        this.props
            .socialLogin({
                linkedInAuthCode: code,
                linkedInRedirectUri: redirectUri,
                methodTypeId: config.linkedIn.methodTypeId,
                guestPassDays,
                giftLinkParam: giftLink && giftLink.giftLinkParam,
            })
            // @ts-ignore
            .then((resp) => {
                if (resp && resp.type === LOGIN_SUCCESS) {
                    this.props.getUser();

                    if (onSignIn) {
                        onSignIn(resp);
                    }
                }
            });
    };

    public onFacebookResponse = (response: any) => {
        const { onSignIn, guestPassDays, giftLink } = this.props;

        if (response) {
            this.props
                .socialLogin({
                    ssoId: response.id,
                    email: response.email,
                    firstName: getFirstName(response.name) || '',
                    methodTypeId: config.facebook.methodTypeId,
                    guestPassDays,
                    giftLinkParam: giftLink && giftLink.giftLinkParam,
                })
                // @ts-ignore
                .then((resp) => {
                    if (resp && resp.type === LOGIN_SUCCESS) {
                        this.props.getUser();

                        if (onSignIn) {
                            onSignIn(resp);
                        }
                    }
                });
        }
    };

    public onGoogleSuccess = (response: any) => {
        const { onSignIn, guestPassDays, giftLink } = this.props;

        if (response && response.profileObj) {
            this.props
                .socialLogin({
                    ssoId: response.googleId,
                    email: response.profileObj.email,
                    firstName: response.profileObj.givenName,
                    methodTypeId: config.google.methodTypeId,
                    guestPassDays,
                    giftLinkParam: giftLink && giftLink.giftLinkParam,
                })
                // @ts-ignore
                .then((resp) => {
                    if (resp && resp.type === LOGIN_SUCCESS) {
                        this.props.getUser();

                        if (onSignIn) {
                            onSignIn(resp);
                        }
                    }
                });
        }
    };

    public onAppleSuccess = (response: any) => {
        const { onSignIn, guestPassDays, giftLink } = this.props;

        const jwtDecoded = response?.authorization?.id_token && (jwtDecode(response.authorization.id_token) as any);

        if (jwtDecoded && jwtDecoded.email && jwtDecoded.sub) {
            this.props
                .socialLogin({
                    ssoId: jwtDecoded.sub,
                    email: jwtDecoded.email,
                    methodTypeId: config.apple.methodTypeId,
                    guestPassDays,
                    giftLinkParam: giftLink && giftLink.giftLinkParam,
                })
                // @ts-ignore
                .then((resp) => {
                    if (resp && resp.type === LOGIN_SUCCESS) {
                        this.props.getUser();

                        if (onSignIn) {
                            onSignIn(resp);
                        }
                    }
                });
        }
    };

    public onGoogleFailure = () => {
        // toast(text.notifications.NOT_POSSIBLE_TO_LOGIN);
    };

    public getOrientation = () => {
        const { orientation } = this.props;

        if (!orientation) {
            return 'row';
        }

        return orientation;
    };

    public getText = (platform: string) => {
        const orientation = this.getOrientation();

        const { mode } = this.props;

        const buttonPrefix =
            !mode || mode === 'generic'
                ? text.signup.CONTINUE_WITH
                : mode === 'signup'
                ? text.signup.SIGN_UP_WITH
                : text.signup.LOG_IN_WITH;

        if (orientation === 'column') {
            return `${buttonPrefix} ${platform}`;
        }

        return platform;
    };

    public render() {
        const { loading } = this.props;

        const orientation = this.getOrientation();

        const linkedInUrl = `${window.location.origin}/auth/linkedin_callback`;

        return (
            <div>
                <Loading loading={loading} />
                <div className={css(styles.socialLogin, orientation === 'column' && styles.socialLoginColumn)}>
                    {/* tslint:disable-next-line: jsx-no-lambda */}

                    <GoogleLoginButton
                        onSuccess={this.onGoogleSuccess}
                        onFailure={this.onGoogleFailure}
                        text={this.getText('Google')}
                        orientation={orientation}
                    />

                    {/* tslint:disable-next-line: jsx-no-lambda */}
                    <FacebookLogin
                        fields="name,email"
                        appId={config.facebook.appId}
                        callback={this.onFacebookResponse}
                        disableMobileRedirect
                        render={(props: any) => (
                            <Button
                                className={css(
                                    styles.facebookButton,
                                    orientation === 'column' && styles.facebookButtonColumn,
                                    styles.button,
                                )}
                                onClick={props.onClick}
                                backgroundColor={newtheme.colors.facebookPrimary}
                                borderRadius={10}>
                                <span>
                                    <FontAwesomeIcon icon={faFacebookSquare} /> &nbsp;&nbsp;
                                    {this.getText('Facebook')}
                                </span>
                            </Button>
                        )}
                    />

                    <LinkedIn
                        clientId={config.linkedIn.appId}
                        redirectUri={linkedInUrl}
                        scope="r_liteprofile r_emailaddress"
                        onSuccess={(code) => this.onLinkedInAuthCodeRetrieved(code, linkedInUrl)}
                        onError={(error) => {
                            console.log(`LinkedIn error: ${JSON.stringify(error)}`);
                        }}>
                        {({ linkedInLogin }) => (
                            <Button
                                className={css(
                                    styles.facebookButton,
                                    orientation === 'column' && styles.facebookButtonColumn,
                                    styles.button,
                                )}
                                onClick={linkedInLogin}
                                backgroundColor={newtheme.colors.linkedInPrimary}
                                borderRadius={10}>
                                <span>
                                    <FontAwesomeIcon icon={faLinkedin} /> &nbsp;&nbsp; Continue with LinkedIn
                                </span>
                            </Button>
                        )}
                    </LinkedIn>

                    <AppleSignin
                        authOptions={{
                            clientId: config.apple.appId,
                            scope: 'name email',
                            redirectURI: config.apple.redirectUrl,
                            state: 'state',
                            nonce: 'nonce',
                            usePopup: true,
                        }}
                        noDefaultStyle
                        skipScript={false}
                        onSuccess={this.onAppleSuccess}
                        onError={(error) => console.log(`Apple error: ${JSON.stringify(error)}`)}
                        render={(props) => (
                            <Button
                                className={css(
                                    styles.appleButton,
                                    orientation === 'column' && styles.appleButtonColumn,
                                    styles.button,
                                )}
                                onClick={props.onClick}
                                backgroundColor={
                                    this.props.lightAppleButton ? newtheme.colors.white : newtheme.colors.applePrimary
                                }
                                color={
                                    this.props.lightAppleButton ? newtheme.colors.applePrimary : newtheme.colors.white
                                }>
                                <span>
                                    <FontAwesomeIcon icon={faApple} /> &nbsp;&nbsp;
                                    {this.getText('Apple')}
                                </span>
                            </Button>
                        )}
                    />
                </div>
            </div>
        );
    }
}
const styles = StyleSheet.create({
    socialLogin: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
    },
    socialLoginColumn: {
        flexDirection: 'column',
    },
    button: {
        [newtheme.cssHeightBreakpoints.large]: {
            height: 40,
            minHeight: 40,
            fontSize: 14,
            marginBottom: 10,
        },
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            height: 50,
            minHeight: 50,
            fontSize: 16,
        },
        [newtheme.cssBreakpoints.minuscule]: {
            height: 40,
            minHeight: 40,
            fontSize: 13,
        },
        fontFamily: 'Poppins',
        height: 55,
        minHeight: 55,
        borderRadius: 10,
        fontWeight: 'normal',
        fontSize: 18,
        marginBottom: 20,
    },
    facebookIcon: {
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            width: 14,
        },
        width: 16,
        fill: 'white',
        marginRight: 15,
    },
    googleIcon: {
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            width: 16,
        },
        width: 18,
        fill: 'white',
        marginRight: 15,
    },
    facebookButton: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            width: '100%',
            minWidth: '100%',
            marginBottom: 10,
            marginRight: 0,
            flex: 0,
        },
        borderRadius: 10,
        flex: 1,
    },
    facebookButtonColumn: {
        marginBottom: 10,
    },
    appleButton: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            width: '100%',
            minWidth: '100%',
            marginBottom: 10,
            marginLeft: 0,
            flex: 0,
        },
        flex: 1,
        marginLeft: 5,
    },
    appleButtonColumn: {
        marginLeft: 0,
        marginBottom: 20,
    },
});

function mapStateToProps(state: IState, ownProps: IProps): IProps & IPropsFromState {
    return {
        ...ownProps,
        user: state.user.user,
        loading: state.user.loading,
        giftLink: state.userSource.giftLink,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators(
        {
            socialLogin,
            getUser,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialSign);
