import {
    GET_GIFT_PRICES_FAILURE, GET_GIFT_PRICES_REQUEST, GET_GIFT_PRICES_SUCCESS, INITIATE_PURCHASE_GIFT_REQUEST, INITIATE_PURCHASE_GIFT_SUCCESS,
    PURCHASE_GIFT_FAILURE, PURCHASE_GIFT_REQUEST, PURCHASE_GIFT_SUCCESS, RESET_GIFT_FORM,
} from '../constants/gift';
import IConfirmPayment from '../types/IConfirmPayment';
import IGiftPrice from '../types/models/IGiftPrice';

export interface IState {
    giftPrices?: IGiftPrice[];
    loading: boolean;
    purchaseGiftSuccessful: boolean;
    error?: any;
    confirmPayment?: IConfirmPayment;
}

interface IAction {
    type: string;
    error?: Error;
    giftPrices?: IGiftPrice[];
    confirmPayment?: IConfirmPayment;
}

export const initialState: IState = {
    loading: false,
    giftPrices: undefined,
    purchaseGiftSuccessful: false,
    confirmPayment: undefined,
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        // GET GIFT PRICES
        case GET_GIFT_PRICES_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                purchaseGiftSuccessful: false,
                confirmPayment: undefined,
            };
        case GET_GIFT_PRICES_SUCCESS:
            return {
                ...state,
                giftPrices: action.giftPrices,
                loading: false,
                purchaseGiftSuccessful: false,
                confirmPayment: undefined,
            };
        case GET_GIFT_PRICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                purchaseGiftSuccessful: false,
                confirmPayment: undefined,
            };

        // INITIATE PURCHASE OF GIFT
        case INITIATE_PURCHASE_GIFT_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
                purchaseGiftSuccessful: false,
                confirmPayment: undefined,
            };
        case INITIATE_PURCHASE_GIFT_SUCCESS: {
            const confirmPaymentStatus = action.confirmPayment?.intentStatus;
            const paymentRequiresSCA = (confirmPaymentStatus === 'requires_action' || confirmPaymentStatus === 'requires_payment_method');

            return {
                ...state,
                loading: paymentRequiresSCA,
                error: undefined,
                purchaseGiftSuccessful: !paymentRequiresSCA,
                confirmPayment: action.confirmPayment,
            };
        }
        // PURCHASE GIFT
        case PURCHASE_GIFT_REQUEST:
            return {
                ...state,
                error: null,
                loading: true,
                purchaseGiftSuccessful: false,
            };
        case PURCHASE_GIFT_SUCCESS:
            return {
                ...state,
                loading: false,
                purchaseGiftSuccessful: true,
            };
        case PURCHASE_GIFT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
                purchaseGiftSuccessful: false,
            };

        // RESET GIFT FORM
        case RESET_GIFT_FORM:
            return {
                ...state,
                loading: false,
                error: null,
                purchaseGiftSuccessful: false,
            };

        default:
            return state;
    }
};
