import {
    ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_PENDING,
    ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_REJECTED,
    ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_SUCCESS,
    GET_RECOMMENDED_ARTICLES_FAILURE,
    GET_RECOMMENDED_ARTICLES_REQUEST,
    GET_RECOMMENDED_ARTICLES_SUCCESS,
    GET_RECOMMENDED_FAILURE,
    GET_RECOMMENDED_REQUEST,
    GET_RECOMMENDED_SUCCESS,
    RECOMMENDATION_CLEAR,
    RECOMMENDATION_IS_QUEUED_PENDING,
    RECOMMENDATION_IS_QUEUED_REJECTED,
    RECOMMENDATION_IS_QUEUED_SUCCESS,
} from '../constants/recommendation';
import IArticle from '../types/models/IArticle';
import ISegment from '../types/models/ISegment';

export interface IState {
    loading: boolean;
    articles: IArticle[];
    segments: ISegment[];
    error?: Error;
    pendingQueueArticleIds: number[];
    pendingQueuePlaylistIds: number[];
}

interface IAction {
    type: string;
    articles?: IArticle[];
    segments?: ISegment[];
    error?: Error;
    isQueued?: {
        playlistId?: number;
        status: boolean;
        articleId?: number;
    };
}

export const initialState: IState = {
    loading: false,
    segments: [],
    articles: [],
    pendingQueueArticleIds: [],
    pendingQueuePlaylistIds: [],
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case GET_RECOMMENDED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_RECOMMENDED_SUCCESS: {
            return {
                ...state,
                segments: action.segments,
                loading: false,
            };
        }
        case GET_RECOMMENDED_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case GET_RECOMMENDED_ARTICLES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_RECOMMENDED_ARTICLES_SUCCESS: {
            return {
                ...state,
                articles: action.articles,
                loading: false,
            };
        }
        case GET_RECOMMENDED_ARTICLES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case RECOMMENDATION_CLEAR:
            return {
                ...state,
                segments: [],
                articles: [],
            };
        case RECOMMENDATION_IS_QUEUED_SUCCESS: {
            const { segments } = state;

            const { playlistId, status } = action.isQueued!;

            if (!playlistId) return state;

            const [segmentIndex, itemIndex] = (() => {
                for (let i = 0; i < segments.length; i += 1) {
                    const { items } = segments[i];

                    for (let j = 0; j < items.length; j += 1) {
                        if (items[j].id === playlistId) {
                            return [i, j];
                        }
                    }
                }

                return [null, null];
            })();

            if (segmentIndex === null || itemIndex === null) return state;

            const clonedItems = [...segments[segmentIndex].items];
            clonedItems[itemIndex] = { ...clonedItems[itemIndex], isQueued: status };

            const clonedSegments = [...segments];
            clonedSegments[segmentIndex] = { ...clonedSegments[segmentIndex], items: clonedItems };

            return {
                ...state,
                segments: clonedSegments,
                pendingQueuePlaylistIds: state.pendingQueuePlaylistIds.filter((pid) => pid !== playlistId),
            };
        }
        case RECOMMENDATION_IS_QUEUED_REJECTED: {
            const { playlistId } = action.isQueued!;

            return {
                ...state,
                pendingQueuePlaylistIds: state.pendingQueuePlaylistIds.filter((pid) => pid !== playlistId),
            };
        }
        case RECOMMENDATION_IS_QUEUED_PENDING: {
            const { playlistId } = action.isQueued!;

            return {
                ...state,
                pendingQueuePlaylistIds: [...state.pendingQueuePlaylistIds, playlistId],
            };
        }

        case ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_SUCCESS: {
            const { articleId, status } = action.isQueued!;

            const articleIndex = state.articles.findIndex((a) => a.id === articleId);

            if (articleIndex === -1) {
                return {
                    ...state,
                    pendingQueueArticleIds: state.pendingQueueArticleIds.filter((aid) => aid !== articleId),
                };
            }

            const clonedArticles = [...state.articles];
            clonedArticles[articleIndex] = { ...state.articles[articleIndex], isQueued: status };
            return {
                ...state,
                articles: clonedArticles,
                pendingQueueArticleIds: state.pendingQueueArticleIds.filter((aid) => aid !== articleId),
            };
        }

        case ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_PENDING: {
            const { articleId } = action.isQueued!;

            return {
                ...state,
                pendingQueueArticleIds: [...state.pendingQueueArticleIds, articleId],
            };
        }

        case ARTICLE_IS_QUEUED_FOR_RECOMMENDATION_PAGE_REJECTED: {
            const { articleId } = action.isQueued!;

            return {
                ...state,
                pendingQueueArticleIds: state.pendingQueueArticleIds.filter((aid) => aid !== articleId),
            };
        }

        default:
            return state;
    }
};
