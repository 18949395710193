import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconSocialInstagram extends React.Component<IProps> {
    public render() {
        return (
            <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <path d="M14.6665 6.82088C10.6884 6.82088 7.5183 10.0531 7.5183 13.9691C7.5183 17.9473 10.6884 21.1174 14.6665 21.1174C18.5826 21.1174 21.8148 17.9473 21.8148 13.9691C21.8148 10.0531 18.5826 6.82088 14.6665 6.82088ZM14.6665 18.631C12.118 18.631 10.0046 16.5798 10.0046 13.9691C10.0046 11.4206 12.0559 9.36939 14.6665 9.36939C17.2151 9.36939 19.2663 11.4206 19.2663 13.9691C19.2663 16.5798 17.2151 18.631 14.6665 18.631ZM23.7417 6.57225C23.7417 5.63987 22.9958 4.89396 22.0634 4.89396C21.1311 4.89396 20.3852 5.63987 20.3852 6.57225C20.3852 7.50463 21.1311 8.25053 22.0634 8.25053C22.9958 8.25053 23.7417 7.50463 23.7417 6.57225ZM28.4658 8.25053C28.3415 6.01282 27.8442 4.02374 26.2281 2.40761C24.6119 0.791486 22.6229 0.294216 20.3852 0.169898C18.0853 0.0455808 11.1857 0.0455808 8.88579 0.169898C6.64807 0.294216 4.72115 0.791486 3.04287 2.40761C1.42674 4.02374 0.92947 6.01282 0.805152 8.25053C0.680835 10.5504 0.680835 17.45 0.805152 19.7499C0.92947 21.9876 1.42674 23.9145 3.04287 25.5928C4.72115 27.2089 6.64807 27.7062 8.88579 27.8305C11.1857 27.9549 18.0853 27.9549 20.3852 27.8305C22.6229 27.7062 24.6119 27.2089 26.2281 25.5928C27.8442 23.9145 28.3415 21.9876 28.4658 19.7499C28.5901 17.45 28.5901 10.5504 28.4658 8.25053ZM25.4822 22.1741C25.0471 23.4173 24.0525 24.3496 22.8715 24.8469C21.0067 25.5928 16.6556 25.4063 14.6665 25.4063C12.6153 25.4063 8.2642 25.5928 6.4616 24.8469C5.21842 24.3496 4.28604 23.4173 3.78877 22.1741C3.04287 20.3715 3.22934 16.0204 3.22934 13.9691C3.22934 11.9801 3.04287 7.62895 3.78877 5.76418C4.28604 4.58317 5.21842 3.65079 6.4616 3.15352C8.2642 2.40761 12.6153 2.59409 14.6665 2.59409C16.6556 2.59409 21.0067 2.40761 22.8715 3.15352C24.0525 3.58863 24.9849 4.58317 25.4822 5.76418C26.2281 7.62895 26.0416 11.9801 26.0416 13.9691C26.0416 16.0204 26.2281 20.3715 25.4822 22.1741Z" />
            </svg>
        );
    }
}

export default IconSocialInstagram;
