import * as React from 'react';
import { isIE } from 'react-device-detect';
import { css, StyleSheet } from 'aphrodite';
import ReactPhoneInput from 'react-phone-input-2';
import theme from '../theme';
import 'react-phone-input-2/lib/style.css';
import newtheme from '../newtheme';

interface IProps {
    loading: boolean;
    smsSent: boolean;
    error: boolean;
    sendSMS: (phoneNumber: string, mode: string) => void;
    reset: () => void;
    smsMode: string;
    selectedCountryAlpha2?: string;
    colour: 'DARK' | 'TRANSPARENT'
}

interface IState {
    phoneNumber: string;
}

class PhoneInputField extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = { phoneNumber: this.generateDefaultNumber() };
    }

    public generateDefaultNumber = (): string => (isIE ? '+353 ' : '');

    public handleNumberChanged = (newPhoneNumber: string) => {
        this.setState({ phoneNumber: newPhoneNumber });
        this.props.reset();
    };

    public handleNumberChangedIE = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phoneValue = event.target.value;
        const re = /^[0-9 +]+$/;

        if (phoneValue.match(re)) {
            this.setState({ phoneNumber: event.target.value });
            this.props.reset();
        }
    };

    public submitNumber = () => {
        this.props.sendSMS(`+${this.state.phoneNumber}`, this.props.smsMode);
    };

    public reset = () => {
        this.setState({ phoneNumber: this.generateDefaultNumber() });
        this.props.reset();
    };

    public isValidNumber = () => this.state.phoneNumber.length >= 11;

    public getButtonStyle = (isDisabled: boolean) => {
        if (this.props.loading) {
            return styles.buttonSending;
        }

        if (isDisabled) {
            if (this.props.colour === 'TRANSPARENT') {
                return styles.buttonDisabledTransparentColour;
            }
            return styles.buttonDisabled;
        }

        return styles.buttonEnabled;
    };

    public renderSubmitButton = (): any => {
        const isDisabled = !this.isValidNumber() || this.props.loading || this.props.error;
        const text = this.props.loading ? 'Sending...' : 'Send';
        const buttonColorStyle = this.getButtonStyle(isDisabled);

        return (
            <button type="button" disabled={isDisabled} className={css(styles.submitButton, buttonColorStyle)} onClick={this.submitNumber}>
                {text}
            </button>
        );
    };

    public render() {
        return (
            <div className={css(styles.container)}>
                <div className={css(styles.phoneAndButton)}>
                    {!isIE && (
                        <ReactPhoneInput
                            country={this.props.selectedCountryAlpha2 ? this.props.selectedCountryAlpha2 : 'us'}
                            preferredCountries={['us', 'ie', 'gb', 'ca', 'au', 'nz', 'in']}
                            value={this.state.phoneNumber}
                            onChange={this.handleNumberChanged}
                            inputClass={css(styles.phoneInputClass, this.props.colour === 'TRANSPARENT' && styles.phoneInputClassTransparent)}
                            dropdownClass={css(styles.phoneInputDropdownClass)}
                            enableSearch
                        />
                    )}

                    {isIE && <input className={css(styles.phoneInputClass, styles.phoneInputIE)} type="tel" value={this.state.phoneNumber} onChange={this.handleNumberChangedIE} />}

                    {this.renderSubmitButton()}
                </div>

                <div className={css(styles.phoneInputErrorWrapper)}>
                    {this.props.error && isIE && <p className={css(styles.phoneInputError)}>Invalid number. Are you missing your country code (e.g. +353)?</p>}
                    {this.props.error && !isIE && <p className={css(styles.phoneInputError)}>Sorry, this number is not valid.</p>}
                </div>
            </div>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    phoneAndButton: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            height: 35,
        },
        display: 'flex',
        flexDirection: 'row',
        height: 50,
        width: '100%',
    },
    phoneInputClass: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 14,
        },
        height: '100%',
        width: '100%',
        fontSize: 18,
        color: 'white',
        backgroundColor: '#153755',
        border: 'none',
        borderRadius: 10,
    },
    phoneInputClassTransparent: {
        backgroundColor: 'transparent',
        color: 'black',
        border: '1px solid #cacaca',
    },
    phoneInputDropdownClass: {
        color: 'black',
    },
    phoneInputIE: {
        paddingLeft: 10,
        paddingRight: 10,
        border: '1px solid #D1D1D1',
        borderRadius: 10,
    },
    'input[type=tel]::-ms-clear': {
        display: 'none',
    },
    phoneInputErrorWrapper: {
        position: 'absolute',
        height: 30,
    },
    phoneInputError: {
        fontSize: 12,
        fontWeight: 500,
        color: 'red',
        paddingTop: 5,
    },
    submitButton: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 14,
            width: 80,
        },
        color: '#fff',
        fontSize: 18,
        fontWeight: 500,
        width: 140,
        height: '100%',
        marginLeft: 10,
        borderRadius: 10,
        border: '0px solid gray',
        cursor: 'pointer',
    },
    buttonEnabled: {
        backgroundColor: newtheme.colors.primaryBlue,
    },
    buttonDisabled: {
        backgroundColor: newtheme.darkmodeColors.inputNavy,
    },
    buttonDisabledTransparentColour: {
        backgroundColor: 'grey',
    },
    buttonSending: {
        backgroundColor: 'white',
        color: 'black',
    },
});

export default PhoneInputField;
