import * as React from 'react';

interface IProps {
    className?: string;
    onClick?: any;
}

class IconSocialFacebook extends React.Component<IProps> {
    public render() {
        return (
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className} onClick={this.props.onClick}>
                <path d="M28.6874 15.0715C28.6874 7.2735 22.3693 0.955441 14.5714 0.955441C6.77341 0.955441 0.455356 7.2735 0.455356 15.0715C0.455356 22.1295 5.57811 27.9922 12.3515 29.0167V19.1697H8.7656V15.0715H12.3515V11.9978C12.3515 8.46881 14.4575 6.47663 17.645 6.47663C19.2388 6.47663 20.8325 6.76123 20.8325 6.76123V10.2333H19.068C17.3035 10.2333 16.7343 11.3148 16.7343 12.4532V15.0715H20.6618L20.0356 19.1697H16.7343V29.0167C23.5077 27.9922 28.6874 22.1295 28.6874 15.0715Z" />
            </svg>
        );
    }
}

export default IconSocialFacebook;
