import * as React from 'react';

interface IProps {
    className?: string;
}

class QueueUncheckedIcon extends React.Component<IProps> {
    public render() {
        return (
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={this.props.className}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.1721 4C17.7089 4 17.3334 4.38802 17.3334 4.86667C17.3334 5.34531 17.7089 5.73333 18.1721 5.73333L28.4947 5.73333C28.9579 5.73333 29.3334 5.34531 29.3334 4.86667C29.3334 4.38802 28.9579 4 28.4947 4L18.1721 4ZM15.5334 24L3.53342 24C3.05477 24 2.66675 24.388 2.66675 24.8667C2.66675 25.3453 3.05477 25.7333 3.53341 25.7333L15.5334 25.7333C16.0121 25.7333 16.4001 25.3453 16.4001 24.8667C16.4001 24.388 16.0121 24 15.5334 24ZM25.7335 24L28.8668 24C29.3454 24 29.7334 24.388 29.7334 24.8667C29.7334 25.3453 29.3454 25.7333 28.8668 25.7333L25.7335 25.7333L25.7335 28.8667C25.7335 29.3453 25.3455 29.7333 24.8668 29.7333C24.3882 29.7333 24.0002 29.3453 24.0002 28.8667L24.0002 25.7333L20.8668 25.7333C20.3881 25.7333 20.0001 25.3453 20.0001 24.8667C20.0001 24.388 20.3881 24 20.8668 24L24.0002 24L24.0002 20.8667C24.0002 20.388 24.3882 20 24.8668 20C25.3455 20 25.7335 20.388 25.7335 20.8667L25.7335 24ZM3.53342 17.3333C3.05477 17.3333 2.66675 17.7214 2.66675 18.2C2.66675 18.6786 3.05477 19.0667 3.53342 19.0667L28.4668 19.0667C28.9454 19.0667 29.3334 18.6786 29.3334 18.2C29.3334 17.7214 28.9454 17.3333 28.4668 17.3333L3.53342 17.3333ZM2.66675 11.5333C2.66675 11.0547 3.05477 10.6667 3.53342 10.6667L28.4668 10.6667C28.9454 10.6667 29.3334 11.0547 29.3334 11.5333C29.3334 12.012 28.9454 12.4 28.4668 12.4L3.53341 12.4C3.05477 12.4 2.66675 12.012 2.66675 11.5333Z"
                    fill="white"
                    fillOpacity="0.87"
                />
            </svg>
        );
    }
}

export default QueueUncheckedIcon;
