import * as React from 'react';

interface IProps {
    className: string;
}

class IconPlus extends React.Component<IProps> {
    public render() {
        return (
            <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={this.props.className}>
                <circle cx="25" cy="25" r="14.6094" stroke="white" strokeWidth="2.03125" />
                <path fillRule="evenodd" clipRule="evenodd" d="M18.75 24.0625C18.2322 24.0625 17.8125 24.4822 17.8125 25C17.8125 25.5178 18.2322 25.9375 18.75 25.9375L24.0625 25.9375L24.0625 31.25C24.0625 31.7678 24.4822 32.1875 25 32.1875C25.5178 32.1875 25.9375 31.7678 25.9375 31.25L25.9375 25.9375L31.25 25.9375C31.7678 25.9375 32.1875 25.5178 32.1875 25C32.1875 24.4822 31.7678 24.0625 31.25 24.0625L25.9375 24.0625L25.9375 18.75C25.9375 18.2322 25.5178 17.8125 25 17.8125C24.4822 17.8125 24.0625 18.2322 24.0625 18.75L24.0625 24.0625L18.75 24.0625Z" fill="white" />
            </svg>
        );
    }
}

export default IconPlus;
