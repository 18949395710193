import * as React from 'react';

interface IProps {
    className: string;
}

class IconListened extends React.Component<IProps> {
    public render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 36.771 36.771"
                className={this.props.className}
            >
                <g
                    id="Group_224"
                    data-name="Group 224"
                    transform="translate(-446.628 -591.724)"
                >
                    <path
                        id="Path_197"
                        data-name="Path 197"
                        d="M18.385,0A18.385,18.385,0,1,0,36.771,18.385,18.4,18.4,0,0,0,18.385,0Zm0,34.267A15.881,15.881,0,1,1,34.267,18.385,15.889,15.889,0,0,1,18.385,34.267Z"
                        transform="translate(446.628 591.724)"
                    />
                    <path
                        id="Path_198"
                        data-name="Path 198"
                        d="M126.538,145.718l-9.891,11.011-5.216-5a1.161,1.161,0,0,0-1.6,1.681l6.066,5.834a1.139,1.139,0,0,0,.811.328h.039a1.159,1.159,0,0,0,.811-.386l10.7-11.9a1.162,1.162,0,0,0-.1-1.642A1.122,1.122,0,0,0,126.538,145.718Z"
                        transform="translate(345.996 458.175)"
                    />
                </g>
            </svg>
        );
    }
}

export default IconListened;
