import { PUBLISHER_FAILURE, PUBLISHER_REQUEST, PUBLISHER_SUCCESS } from '../constants/publisher';
import IPublisher from '../types/models/IPublisher';

export interface IState {
    loading: boolean;
    publishers?: IPublisher[];
    error?: any;
}

interface IAction {
    type: string;
    publishers?: IPublisher[];
    error?: Error;
}

export const initialState: IState = {
    loading: false,
    publishers: [],
};

export const reducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case PUBLISHER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PUBLISHER_SUCCESS:
            return {
                ...state,
                publishers: action.publishers,
                loading: false,
            };
        case PUBLISHER_FAILURE:
            return {
                ...state,
                error: action.error,
                publishers: [],
                loading: false,
            };
        default:
            return state;
    }
};
