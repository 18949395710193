import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import * as Modal from 'react-modal';

import { event, EventCategories, EventActions } from '../../../utils/metric';
import theme from '../../../theme';
import newtheme from '../../../newtheme';

interface IProps {
    modalId: string;
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    description?: string;
    children?: any;
    footer?: any;
    height?: 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE';
    scrollable?: boolean;
    fullScreenMobile?: boolean;
}

class BaseModal extends React.Component<IProps> {
    public onAfterOpen = () => {
        event(EventCategories.POPUP, EventActions.OPEN, this.props.modalId);
    };

    public onAfterClose = () => {
        event(EventCategories.POPUP, EventActions.CLOSE, this.props.modalId);
    };

    public render() {
        const { children, isOpen, onClose } = this.props;

        return (
            // @ts-ignore
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                closeTimeoutMS={200}
                ariaHideApp={false}
                className={css(styles.modal, this.props.fullScreenMobile && styles.fullScreenMobile)}
                onAfterOpen={this.onAfterOpen}
                onAfterClose={this.onAfterClose}
            >
                {children && <div className={css(styles.content)}>{children}</div>}
            </Modal>
        );
    }
}

const styles = StyleSheet.create({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: 'calc(50px -50%)',
        transform: 'translate(-50%, -50%)',
        maxWidth: '90%',
    },
    content: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            padding: 0,
        },
        backgroundColor: 'white',
        borderRadius: 5,
        boxShadow: '0px -5px 20px 0px rgba(0, 0, 0, 0.1)',
    },
    fullScreenMobile: {
        [newtheme.cssBreakpoints.small]: {
            width: '100%',
            maxWidth: '100%',
            transform: 'none',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
});

export default BaseModal;
