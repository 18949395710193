import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';

import { isMobile, isIOS, isAndroid } from 'react-device-detect';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { toast } from 'react-toastify';
import text from '../../../locale/index';
import theme from '../../../theme/index';
import config from '../../../config';
import BaseModal from './BaseModal';
import PhoneInputField from '../../PhoneInputField';
import { IState } from '../../../reducers';
import { closeModal, openModal } from '../../../actions/modal';
import { sendSMS, reset } from '../../../actions/sms';
import { mpTrackButtonClick, mpTrackPopupView } from '../../../tracking/mixpanel';
import { mpTrackingPopups } from '../../../config/popups';
import { MpTrackingButtons } from '../../../config/buttons';
import { getLocationInfo } from '../../../actions/location';
import newtheme from '../../../newtheme';

interface IProps {
    isFeature: boolean;
}

interface IPropsFromState {
    loading: boolean;
    smsSent: boolean;
    error: boolean;
    isOpen: boolean;
    selectedCountryAlpha2?: string;
}

interface IPropsFromDispatch {
    sendSMS: (phoneNumber: string, mode: string) => void;
    reset: () => void;
    closeModal: () => void;
    getLocationInfo: typeof getLocationInfo;
}

class ModalGetTheApp extends React.Component<IProps & IPropsFromState & IPropsFromDispatch & RouteComponentProps> {
    public UNSAFE_componentWillReceiveProps(props) {
        if (!props || !props.smsSent) {
            return;
        }

        if (props.smsSent) {
            toast("Check your phone, we've sent you a download link!");
            this.props.closeModal();
        }
    }

    public componentDidMount = () => {
        mpTrackPopupView(mpTrackingPopups.downloadApp);
        this.props.getLocationInfo();
    };

    public onClose = () => {
        this.props.closeModal();
    };

    public onClickDownloadTheApp = () => {
        window.open(config.downloadAppLink, '_blank');
    };

    public onClickIosAppStore = (e: any) => {
        e.stopPropagation();
        mpTrackButtonClick(MpTrackingButtons.NAVIGATE_TO_IOS_APP_STORE);
    };

    public onClickAndroidAppStore = (e: any) => {
        e.stopPropagation();
        mpTrackButtonClick(MpTrackingButtons.NAVIGATE_TO_ANDROID_APP_STORE);
    };

    public render() {
        const { isOpen, isFeature } = this.props;

        if (isMobile && !isFeature) {
            this.onClickDownloadTheApp();
        }

        if (isMobile) {
            return (
                <BaseModal modalId="ModalGetTheApp" isOpen={isOpen} onClose={this.onClose}>
                    <div className={css(styles.modalWrapper)}>
                        <h1 className={css(styles.title)}>{isFeature ? text.getTheAppModal.FEATURE_TITLE : text.getTheAppModal.TITLE}</h1>
                        {isFeature && <h2 className={css(styles.subtitle)}>{text.getTheAppModal.FEATURE_SUBTITLE}</h2>}

                        <div className={css(styles.appStoreImageWrapper)}>
                            {isIOS ? (
                                <a target="_blank" href={config.iosAppLink} onClick={this.onClickIosAppStore} rel="noreferrer">
                                    <img alt="iOS App Store" className={css(styles.appStoreImageSolo)} src="/downloadApple.png" />
                                </a>
                            ) : isAndroid ? (
                                <a target="_blank" href={config.androidAppLink} onClick={this.onClickAndroidAppStore} rel="noreferrer">
                                    <img alt="Android Play Store" className={css(styles.appStoreImageSolo)} src="/downloadAndroid.png" />
                                </a>
                            ) : (
                                <div className={css(styles.appStoreImages)}>
                                    <a target="_blank" href={config.androidAppLink} onClick={this.onClickAndroidAppStore} rel="noreferrer">
                                        <img alt="Android Play Store" className={css(styles.appStoreImage)} src="/downloadAndroid.png" />
                                    </a>
                                    <a target="_blank" href={config.iosAppLink} onClick={this.onClickIosAppStore} rel="noreferrer">
                                        <img alt="iOS App Store" className={css(styles.appStoreImage)} src="/downloadApple.png" />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </BaseModal>
            );
        }

        return (
            <BaseModal modalId="ModalGetTheApp" isOpen={isOpen} onClose={this.onClose}>
                <div className={css(styles.modalWrapper)}>
                    <h1 className={css(styles.title)}>{isFeature ? text.getTheAppModal.FEATURE_TITLE : text.getTheAppModal.TITLE}</h1>
                    {isFeature && <h2 className={css(styles.subtitle)}>{text.getTheAppModal.FEATURE_SUBTITLE}</h2>}

                    <div className={css(styles.phoneInput)}>
                        <p>Simply enter your mobile number to get the app!</p>
                        <PhoneInputField
                            colour="DARK"
                            smsSent={this.props.smsSent}
                            sendSMS={this.props.sendSMS}
                            smsMode={config.smsMode.regular}
                            loading={this.props.loading}
                            error={this.props.error}
                            reset={this.props.reset}
                            selectedCountryAlpha2={this.props.selectedCountryAlpha2}
                        />
                    </div>

                    <div className={css(styles.appStoreImageWrapper)}>
                        <p>Or find us on the app store.</p>
                        <div className={css(styles.appStoreImages)}>
                            <a target="_blank" href={config.iosAppLink} onClick={this.onClickIosAppStore} rel="noreferrer">
                                <img alt="Apple app store" src="/downloadAppleStore.png" className={css(styles.appStoreImage)} />
                            </a>
                            <a target="_blank" href={config.androidAppLink} onClick={this.onClickAndroidAppStore} rel="noreferrer">
                                <img alt="Google play store" src="/downloadGoogleStore.png" className={css(styles.appStoreImage)} />
                            </a>
                        </div>
                    </div>
                </div>
            </BaseModal>
        );
    }
}

const styles = StyleSheet.create({
    modalWrapper: {
        maxWidth: '100%',
        width: 600,
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: newtheme.darkmodeColors.backgroundNavy,
        color: newtheme.darkmodeColors.white,
    },
    title: {
        fontSize: 30,
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 27,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 25,
        },
    },
    subtitle: {
        fontSize: 16,
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            fontSize: 15,
            fontWeight: 300,
            marginBottom: 0,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 14,
        },
        fontWeight: 500,
        marginBottom: 25,
    },
    description: {
        [`@media (max-width: ${theme.breakpoints.small}px)`]: {
            fontSize: 13,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            fontSize: 12,
        },
        fontSize: 16,
        marginBottom: 50,
    },
    phoneInput: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            width: '95%',
        },
        paddingTop: 30,
        width: '85%',
        margin: '0 auto',
    },
    appStoreImageWrapper: {
        marginTop: 35,
    },
    appStoreImages: {
        display: 'flex',
        justifyContent: 'center',
    },
    appStoreImage: {
        [`@media (max-width: ${theme.breakpoints.tiny}px)`]: {
            width: 130,
        },
        [`@media (max-width: ${theme.breakpoints.minuscule}px)`]: {
            width: 110,
        },
        width: 160,
        marginLeft: 10,
        marginRight: 10,
    },
    appStoreImageSolo: {
        width: '50%',
        margin: '0 auto',
    },
});

function mapStateToProps(state: IState): IPropsFromState {
    return {
        isOpen: state.modal.isOpen,
        loading: state.sms.loading,
        smsSent: state.sms.sent,
        error: state.sms.error,
        selectedCountryAlpha2: state.info.selectedCountryAlpha2 ? state.info.selectedCountryAlpha2.toLowerCase() : undefined,
    };
}

function mapDispatchToProps(dispatch: Dispatch): IPropsFromDispatch {
    return bindActionCreators({
        closeModal, openModal, sendSMS, reset, getLocationInfo,
    }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalGetTheApp));
