import * as React from 'react';

interface IProps {
    className: string;
}

class IconNavSearch extends React.Component<IProps> {
    public render() {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
                <path
                    d="M20.8682 19.7732C20.6258 19.4928 20.202 19.462 19.9216 19.7044C19.6413 19.9468 19.6105 20.3706 19.8529 20.6509L20.8682 19.7732ZM23.9924 25.4389C24.2348 25.7192 24.6585 25.75 24.9389 25.5076C25.2192 25.2653 25.25 24.8415 25.0076 24.5611L23.9924 25.4389ZM22.3289 14.5C22.3289 18.2715 19.2715 21.3289 15.5 21.3289V22.6711C20.0127 22.6711 23.6711 19.0127 23.6711 14.5H22.3289ZM15.5 21.3289C11.7285 21.3289 8.67105 18.2715 8.67105 14.5H7.32895C7.32895 19.0127 10.9873 22.6711 15.5 22.6711V21.3289ZM8.67105 14.5C8.67105 10.7285 11.7285 7.67105 15.5 7.67105V6.32895C10.9873 6.32895 7.32895 9.98725 7.32895 14.5H8.67105ZM15.5 7.67105C19.2715 7.67105 22.3289 10.7285 22.3289 14.5H23.6711C23.6711 9.98725 20.0127 6.32895 15.5 6.32895V7.67105ZM19.8529 20.6509L23.9924 25.4389L25.0076 24.5611L20.8682 19.7732L19.8529 20.6509Z"
                    fill="white"
                    fillOpacity="0.87"
                />
            </svg>
        );
    }
}

export default IconNavSearch;
