import * as React from 'react';

import { css, StyleSheet } from 'aphrodite';
import { Col, Container, Row } from 'reactstrap';
import IArticle from '../../../types/models/IArticle';
import newtheme from '../../../newtheme';
import Item from '../../../containers/Item';
import IArticleTab from '../types/IArticleTab';
import ArticleTab from './ArticleTab';
import { getArticles } from '../../../actions/article';
import config from '../../../config';
import Loading from '../../../components/Loading';

interface IProps {
    title: string;
    articles: IArticle[];
    loading: boolean;
    backgroundColor: string;
    getArticles: typeof getArticles;
    publisherTabs?: IArticleTab[];
}

interface IState {
    activeTabIndex: number;
}

class ArticleTabs extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            activeTabIndex: 0,
        };
    }

    public componentDidMount() {
        this.fetchArticlesForActiveTab(this.state.activeTabIndex);
    }

    public getTabs = (): IArticleTab[] => {
        if (this.props.publisherTabs) {
            return this.props.publisherTabs;
        }
        return [
            {
                text: 'Harvard Business Review',
                url: `${config.api.url}/v3/articles?publisherId=79`,
            },
            {
                text: 'The Economist',
                url: `${config.api.url}/v3/articles?publisherId=77`,
            },
            {
                text: 'I by IMD',
                url: `${config.api.url}/v3/articles?publisherId=104`,
            },
            {
                text: 'Fast Company',
                url: `${config.api.url}/v3/articles?publisherId=101`,
            },
            {
                text: 'MIT Technology Review',
                url: `${config.api.url}/v3/articles?publisherId=83`,
            },
        ];
    };

    public onTabClicked = (tabIndex: number) => {
        this.setState({ activeTabIndex: tabIndex });
        this.fetchArticlesForActiveTab(tabIndex);
    };

    public fetchArticlesForActiveTab = (index: number) => {
        const tab = this.getTabs()[index];
        this.props.getArticles({ url: tab.url, limit: 5, disablePremiumOnly: true });
    };

    public render() {
        const { articles, loading, backgroundColor } = this.props;
        const { activeTabIndex } = this.state;

        const tabs = this.getTabs();

        return (
            <div className={css(styles.main)} style={{ backgroundColor }}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h5 className={css(styles.heading)}>{this.props.title}</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={css(styles.tabsWrapper)}>
                            {tabs.map((tab, index) => (
                                <ArticleTab
                                    key={tab.url}
                                    tab={tab}
                                    isActive={index === activeTabIndex}
                                    onClick={() => this.onTabClicked(index)}
                                />
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        {loading ? (
                            <Loading loading />
                        ) : (
                            articles.map((article) => <Item key={article.id} item={article} />)
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
}

// To make initial loading look better
const styles = StyleSheet.create({
    main: {
        paddingBottom: 25,
    },
    heading: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 20,
        },
        color: 'rgba(255, 255, 255, 0.87)',
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 20,
    },
    tabsWrapper: {
        '::-webkit-scrollbar': {
            display: 'none',
        },
        [newtheme.cssBreakpoints.medium]: {
            marginBottom: 20,
        },
        [newtheme.cssBreakpoints.tiny]: {
            marginBottom: 15,
        },
        marginBottom: 50,
        overflowX: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        '-webkit-overflow-scrolling': 'touch',
        '-ms-overflow-style': '-ms-autohiding-scrollbar',
    },
    articleCard: {
        ':hover': {
            cursor: 'pointer',
        },
        [newtheme.cssBreakpoints.tiny]: {
            padding: 10,
            borderRadius: 10,
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: 20,
        padding: '10px 25px',
    },
    articleName: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 22,
        },
        [newtheme.cssBreakpoints.tiny]: {
            fontSize: 16,
        },
        fontSize: 28,
        fontWeight: 500,
        lineHeight: '139%',
        color: 'rgba(255, 255, 255, 0.87)',
        marginTop: 20,
        marginBottom: 0,
    },
    playButton: {
        [newtheme.cssBreakpoints.medium]: {
            fontSize: 14,
        },
        [newtheme.cssBreakpoints.tiny]: {
            padding: '7px 15px',
        },
        transition: 'transform .2s',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        color: 'black',
        border: 'none',
        fontSize: 18,
        fontWeight: 400,
        padding: '10px 20px',
        borderRadius: 24,
        marginTop: 20,
        marginBottom: 7,
    },
    buttonHover: {
        [newtheme.cssBreakpoints.tiny]: {
            ':hover': {
                transform: 'none',
            },
        },
        backgroundColor: newtheme.darkmodeColors.buttonNavy,
        color: newtheme.darkmodeColors.white,
        cursor: 'pointer',
        transform: 'scale(1.03)',
    },
    iconPlayPause: {
        [newtheme.cssBreakpoints.medium]: {
            height: 22,
            width: 22,
        },
        height: 26,
        width: 26,
        marginRight: 5,
        fill: newtheme.colors.primaryBlue,
    },
    articleInfo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    timeAndPublisher: {},
    timeAndDate: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 12,
        },
        color: newtheme.darkmodeColors.fadedText,
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 400,
        margin: 0,
        padding: 0,
    },
    publisherText: {
        [newtheme.cssBreakpoints.small]: {
            fontSize: 12,
        },
        color: 'white',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 400,
        margin: 0,
        padding: 0,
    },
    publisherImage: {
        [newtheme.cssBreakpoints.small]: {
            height: 32,
            width: 32,
            borderRadius: 8,
        },
        height: 40,
        width: 40,
        borderRadius: 10,
        marginRight: 15,
    },
});

export default ArticleTabs;
